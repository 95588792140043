import React from 'react'
import { NavDropdown, NavLink } from 'react-bootstrap'
import Account from '../Classes/Account'

class UserNavbarMenu extends React.Component {

    render() {
        if (!sessionStorage.getItem('key')) {
          return (
            <NavLink href="/login">Přihlásit se</NavLink>
          )
        }
        return (
        <NavDropdown title={Account.current().username} id="user-dropdown" alignRight>
            <NavDropdown.Item href="/preferences">Nastavení</NavDropdown.Item>
            <NavDropdown.Item href="/support">Podpora</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={this.props.logout}>Odhlásit se</NavDropdown.Item>
        </NavDropdown>
        )
    }
}
export default UserNavbarMenu