import settings from "../Settings";
import MessageCenter from "../Components/MessageCenter";
import React from 'react'
import fetchProxy from "../Helpers/fetchProxy";
import User from "./User";

class Account {
    static user_menu = React.createRef()
    constructor() {
        this.key = sessionStorage.getItem("key")
        if (!this.key) {
            throw Error("Without key, user can't barely communicate with the server")
        }
        const user = JSON.parse(sessionStorage.getItem("user"))
        Object.keys(user).forEach(i => {
            this[i] = user[i]
        })
    }

    label() {
        return User.prototype.label.call(this)
    }

    static current() {
        return new Account()
    }

    static async  authenticate(username, password) {
        const data = {username, password}
        const res = await fetchProxy( settings.BASE_API_URL + "auth/login/", {
                method: 'POST',
                mode: 'cors',
                redirect: 'follow',
                body: JSON.stringify(data)
        }, true)
        const res_data = await res.json()
        if (res.status < 200 || res.status >= 300) {
            MessageCenter.addMessage({
                title: 'Chyba při ukládání!',
                text:'Bohužel se nepovedlo přihlásit. Zkuste to prosím znovu, nebo kontaktujte podporu',
                detail: 'Data použitá k uložení: \n' + JSON.stringify(data)
            })
            return false
        }
        sessionStorage.setItem("key", res_data.key)
        await this.loadUser()
        return true
    }

    static async loadUser() {
        const res = await fetchProxy(settings.BASE_API_URL + "auth/user/")
        const user = await res.json()
        sessionStorage.setItem("user", JSON.stringify(user))
    }

    static async logout() { 
        await fetchProxy(settings.BASE_API_URL + "auth/logout/", 
        {
            method: 'POST',
            mode: 'cors',
            redirect: 'follow',
        })
        sessionStorage.removeItem("key")
        sessionStorage.removeItem("user")
    }

    static isLogged() {
        return !!sessionStorage.getItem("key")
    }

    static currentUser() {
        return new Account()
    }

    async changePassword(current, new_one) {
        const req = await fetchProxy(User.base_url + this.id + "/change_passwd/", {
            method: 'POST',
            body: JSON.stringify({
                current,
                password: new_one
            })
        })
        const res = await req.json()
        return {res: res, status: req.status}
    }

    hasPerm(permission) {
        return this.is_superuser || !!this.user_permissions.filter(i => {return i.codename === permission}).length
    }
}
export default Account