import { ListGroup, ListGroupItem, Button } from "react-bootstrap"

export function OrderListGroup(props) {
    const { orders, displaySum } = props

    const last_order_executed = orders.every(o => o.last_location_time != null ) ? new Date(
        Math.max(...orders.map(o => new Date(o.last_location_time))) 
    ) : null;
    if (orders.length === 0) return <ListGroup>
        <ListGroupItem>
            Nenalezeny žádné objednávky
        </ListGroupItem>
    </ListGroup>

    return <><ListGroup>
        {orders.map(o => <ListGroupItem key={o.id}>
            {!props.enableHref ? <div className='d-flex justify-content-between'>{o.code} <small># {o.id}</small></div> :
            <a href={`/order/${o.id}`}><div className='d-flex justify-content-between'>{o.code} <small># {o.id}</small></div></a>}
            {o.annotated_margin <= 0 && <div className="text-center text-danger">{o.annotated_margin === 0 ? "Nulová marže" : "Záporná marže"}</div>}
            <div className='text-muted'>{o.route_text}</div>
            <div>
            {o.last_location_time && <>
                <strong>Dokončeno: </strong> 
                {new Date(o.last_location_time).toLocaleString()}
            </>}
            </div>
            <div className='d-flex justify-content-between align-items-center'>
                <div className="">
                    <div>
                        <strong>Cena</strong>:
                        {'\u00A0'}{o.price_customer}{'\u00A0'}
                        {o.customer_currency?.label || o.customer_currency}
                        {o.surcharge_customer && <><small label="Příplatek" className="text-danger">
                            {" "} + {o.surcharge_customer} 
                        </small></>}
                    </div>
                    <div>
                        <small>cena dopravy: {o.price_carrier} {o.carrier_currency?.label}</small>
                    </div>
                </div>
                {o.customer_order && <div className="d-flex flex-column justify-content-between">
                    <div><strong>Objednávka u zákazníka</strong></div>
                    <div className="text-right">{props.valueClicked ? <Button onClick={() => props.valueClicked && props.valueClicked("customer_order", o.customer_order)} variant='link' size="sm">
                        {o.customer_order}
                    </Button> : o.customer_order}</div>
                </div>}
            </div>
        </ListGroupItem>)}
    </ListGroup>
    {displaySum && <ListGroup className='mt-2'>
        <ListGroupItem>
        {last_order_executed ? <>
            <strong>Vykonáno: </strong><span>{last_order_executed.toLocaleString()} <em>Objednávky nemá zastávky</em></span>
        </> : <em><small>Nelze určit čas vykonání; Objednávky nemají zastávky. </small></em>}
        </ListGroupItem>
        <ListGroupItem>
            <strong>Součet: </strong>
            <span>
                {orders.map(o => o.price_customer).reduce((partialSum, a) => partialSum + a, 0)}
                {" "} {orders[0]?.customer_currency.label || orders[0]?.customer_currency}
            </span>
        </ListGroupItem>
    </ListGroup>}
    </>
}