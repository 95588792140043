const LOCAL_STORAGE_KEY = "_preferences"

class Profile {
    static instance;
    preferences = null

    static getInstance() {
        if (this.instance === undefined) {
            this.instance = new Profile()
            this.instance.load()
        }
        return this.instance
    }

    defaultPreferences = {
        "language" : "cs-CZ"
    }

    load() {
        try {
            this.preferences = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
        }
        catch (SyntaxError) {

        }
        if (!this.preferences) {
            console.info("No preferences found, set the default")
            this.preferences = this.defaultPreferences
            this.save()
        }
    }

    save() {
        try {
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.preferences))
        }
        catch {
            console.log("Error! Can't save preferences")
        }
    }
}

const localProfile = Profile.getInstance()

export default localProfile