import React from 'react'
import { withRouter } from "react-router-dom";
import { Jumbotron, Row, Container, Col, Card, ListGroup, Spinner, Tabs, Tab, Alert, ListGroupItem } from 'react-bootstrap';
import fetchProxy from '../Helpers/fetchProxy';
import { Building, Map, Truck } from 'react-bootstrap-icons';
import Calendar from '../Components/Calendar'
import Order from '../Classes/Order';
import Location from '../Classes/Location';
import Account from '../Classes/Account';

async function load(url, name) {
    this.setState({ [name]: await (await fetchProxy(url)).json() })
}

class DashboardView extends React.Component {
    state = {}

    componentDidMount() {
        const user_id = Account.currentUser().id
        load.call(this, Order.base_url + `?user=${user_id}&order_by=-updated_at&limit=5`, "last_orders")
        load.call(this, Order.base_url + `?user=${user_id}&annotated_margin__lte=0&order_by=-updated_at&limit=5`, "no_margin_orders")
        load.call(this, Order.base_url + `?user=${user_id}&carrier=&limit=5`, "no_carrier_orders")
        load.call(this, Order.base_url + `?state=TRASH`, "to_be_deleted")
        load.call(this, 'https://news.logisticiq.cz/news/', 'news')
    }

    render() {
        return (
            <Container>
                <Row className="mt-3">
                    <Col sm={4}>
                        <Jumbotron className="p-2 text-center">
                            <h2>Přehledy</h2>
                        </Jumbotron>
                        <Card>
                            <ListGroup>
                                {!this.state.last_orders ? <Spinner animation="border" role="status" size="sm">
                                    <span className="sr-only">Čekáme na data...</span>
                                </Spinner> : this.state.last_orders.map(item => {
                                    return <ListGroup.Item key={item.id}>
                                        <a href={`/order/${item.id}`}><strong>{item.code}</strong></a>
                                        <> <br /><Map />{'\u00A0'}{item.locations.map(i => { return new Location(i).prettyName() }).join(" \u{1f852} ") || "Bez zastávek"}</>
                                        {(item.customer || item.carrier) && <>
                                            {item.customer && <><br /><a href={`/customer/${item.customer.id}`}><Truck /> - {item.customer.name}</a></>}
                                            <> </>
                                            {item.carrier && <><br /><a href={`/carrier/${item.carrier.id}`}><Building /> - {item.carrier.name}</a></>}
                                        </>}
                                    </ListGroup.Item>
                                })}
                            </ListGroup>
                        </Card>
                        <Tabs defaultActiveKey="margin__lte" className="mt-2">
                            <Tab eventKey="margin__lte" title="Bez marže">
                                {!this.state.no_margin_orders ? <Spinner animation="border" role="status" size="sm">
                                    <span className="sr-only">Čekáme na data...</span>
                                </Spinner> : this.state.no_margin_orders.map(item => {
                                    return <ListGroup.Item key={item.id}>
                                        <a href={`/order/${item.id}`}><strong>{item.code}</strong></a>
                                        {item.customer && <> <br /><Building />{'\u00A0'}{item.customer.name}</>}
                                        <br />{item.margin} {item.customer_currency.label}
                                    </ListGroup.Item>
                                })}
                            </Tab>
                            <Tab eventKey="no_carrier" title="Chybějící dopravce">
                                {!this.state.no_carrier_orders ? <Spinner animation="border" role="status" size="sm">
                                    <span className="sr-only">Čekáme na data...</span>
                                </Spinner> : this.state.no_carrier_orders.map(item => {
                                    return <ListGroup.Item key={item.id}>
                                        <a href={`/order/${item.id}`}><strong>{item.code}</strong></a>
                                        {item.customer && <> <br /><Building />{'\u00A0'}{item.customer.name}</>}
                                        <> <br /><Map />{'\u00A0'}{item.locations.map(i => { return new Location(i).prettyName() }).join(" \u{1f852} ")}</>
                                    </ListGroup.Item>
                                })}
                            </Tab>
                        </Tabs>
                    </Col>
                    <Col sm="8">
                        <Tabs defaultActiveKey="logistic" id="dashboard-tabs">
                            <Tab eventKey="logistic" title="Logistic">
                                <Jumbotron className='py-3 mb-1'>
                                    <h1>Vítej</h1>
                                    <p>{Account.current().first_name} {Account.current().last_name}</p>
                                </Jumbotron>
                                <Alert variant={this.state.to_be_deleted && this.state.to_be_deleted.length !== 0 ? "danger" : "success"}>
                                    {
                                        this.state.to_be_deleted === undefined ? <>Koukám do koše na objednávky ... <Spinner animation='border' size="sm" /></>
                                            : this.state.to_be_deleted.length === 0 ?
                                                <em>Žádné objednávky nejsou v koši.</em> :
                                                <>
                                                    Počet objednávek, které <a href="/order-filter?state=TRASH">jsou v koši</a> a je třeba je smazat: {this.state.to_be_deleted.length} <br /><small>Tyto objednávky se mohou mimoděk objevovat v některých exportech, průměrech, nebo přehledech.</small>
                                                </>
                                    }
                                </Alert>
                                <div>
                                    <h2 className='text-primary'>Novinky z vývoje</h2>
                                    <ListGroup>
                                        {this.state.news && this.state.news.data.map((news, index) => <ListGroupItem key={index}>
                                            <strong>{news.headline}</strong><br />
                                            {news.content}
                                        </ListGroupItem>)}
                                    </ListGroup>
                                </div>
                            </Tab>
                            <Tab eventKey="calendar" title="Kalendář">
                                <Calendar />
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withRouter(DashboardView)