import { Alert, Toast } from 'react-bootstrap';
import { ExclamationSquareFill } from 'react-bootstrap-icons';

export default function Message(props) {
    /*render() {
        return (
            <Alert variant={!!this.props.variant ? this.props.variant : "warning"} dismissible onClose={() => {this.props.onClose && this.props.onClose()}}>
                <Alert.Heading>{this.props.title ? this.props.title : "Neznámá chyba"}</Alert.Heading>
                    {!!this.props.text ? <p>{this.props.text}</p>  : ""}
            </Alert>
        );
    }*/
    return <>
        <Toast bg={props.variant || "warning"} onClose={() => props.onClose && props.onClose()}>
            <Toast.Header className={'d-flex justify-content-between align-items-center '}>
                <ExclamationSquareFill />
                <strong>{props.title || "Neznámá chyba"}</strong>
            </Toast.Header>
            <Toast.Body className={props.variant === 'dark' && 'text-white'}>
                {props.text}
            </Toast.Body>
        </Toast>
    </>
}