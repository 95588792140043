import Base from './Base'
import settings from '../Settings'
import fetchProxy from '../Helpers/fetchProxy'


class Support extends Base{
    static async send_email(data) {
        const res = await fetchProxy(settings.BASE_API_URL + "service/support/support_email", /*TODO: this is ugly*/
            {
                method: 'POST',
                mode: 'cors',
                redirect: 'follow',
                body: JSON.stringify(data)
            })
        if (res.status !== 200) {
            console.error(`Error sending the message! HTTP RES: ${res.status}\n`, await res.json())
            return false
        }
        else {
            return true
        }
    }
}

export default Support