import profile from '../Classes/Profile'


const status_handler = {
    400: () => {console.log(400)}
}

let lastCall = null

async function fetchProxy(url, fetch_data, no_authorization) {
    const data = fetch_data || {}
    data.headers = data.headers || {}
    // If content-type explicitly is undefined let browser to calculate the content-type
    data.headers['Content-Type'] = 'Content-Type' in data.headers ? data.headers['Content-Type'] : 'application/json'
    if (data.headers['Content-Type'] === undefined) {
        delete data.headers['Content-Type']
    }
    // Authorization tokens if explicitly authorization is blocked
    if (!no_authorization) {
        data.headers['Authorization'] = 'Token ' + sessionStorage.getItem('key')
    }
    // Language settings
    data.headers['Accept-Language'] = data.headers['Accept-Language'] || profile.preferences.language
    // Prevent JS to send any other credentials than BearerToken
    data['credentials'] = 'omit'
    // If not api auth call, log it
    if (url.search("/auth") === -1) {
        lastCall = {
            url,
            data: data,
        }
    }
    // Make the call
    const res = await fetch(url, data)
    //Check if we have a status handler
    status_handler[res.status] && status_handler[res.status]()
    return res
}

export default fetchProxy
export {lastCall}
