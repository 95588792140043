import React from 'react'
import { Table, Alert } from 'react-bootstrap';
import parse from 'html-react-parser';

function renderBody(text) {
    try {
        return parse(text)
    }
    catch {
        return <>
            <Alert variant="warning">
                Obsah nemohl být vyrenderován, zobrazuji jako kód.
            </Alert>
            <div>
                {JSON.stringify(text)}
            </div>
        </>
    }
}

function OrderEmailRenderer(log_entry) {
    return <>
    <Table>
        <tbody>
            <tr>
                <th>Objednávka</th>
                <td>{log_entry.data.kwargs.pk}</td>
            </tr>
            <tr>
                <th>Autor e-mailu</th>
                <td>{log_entry.user ? log_entry.user.username : "Unidentified"}</td>
            </tr>
            <tr>
                <th>Předmět zprávy</th>
                <td>{log_entry.data.subject}</td>
            </tr>
            <tr>
                <th>Adresát</th>
                <td>{log_entry.data.to && log_entry.data.to.join(", ")}</td>
            </tr>
        </tbody>
    </Table>
    <div>
        <h4>Obsah zprávy</h4>
        <div className="border border-rounded">{renderBody(log_entry.data.body)}</div>
    </div>
    </>
}

export default OrderEmailRenderer;