import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'
import Queryset from "../Classes/Queryset"
import Location from "../Classes/Location"
import Place from "../Classes/Place"
import Order from "../Classes/Order"
import { Alert, Jumbotron, Row, Col, Container, ListGroup, Spinner } from 'react-bootstrap'
import { getOptions } from "../Classes/Base"
import LoadingBanner from '../Components/LoadingBanner'
import FormField from '../Components/FormField'
import { Map, Truck, Building } from 'react-bootstrap-icons'

const SAVE_DELAY = 700

class PlaceDetail extends Component {
    state = {
        queryset: new Queryset(Place),
        options : undefined,
        updater: undefined,
        related_orders: new Queryset(Order)
    }

    async componentDidMount() {
        const load_object = this.state.queryset.one(this.props.match.params.id)
        const options = await getOptions(Place)
        await load_object
        this.setState({options})
        await this.state.related_orders.filter({locations__place:this.props.match.params.id})
        this.forceUpdate()
    }

    async valueChanged(value, name) {
        this.state.queryset.object[name] = value
        this.forceUpdate()
        this.handleSave()
    }

    async handleSave() {
        if (this.state.updater) {
            clearTimeout(this.state.updater)
        }
        this.setState({
            updater: setTimeout(async () => {
                await this.state.queryset.object.save()
                this.setState({updater: undefined})
            }, SAVE_DELAY)
        })
    }

    render() {
        return <Container>{!this.state.queryset.object ? <LoadingBanner /> : <>
            <Jumbotron>
                <h1>{this.state.queryset.object.name}</h1>
                <h2>{this.state.queryset.object.label()} <small className="text-secondary">#{this.state.queryset.object.id}</small></h2>
            </Jumbotron>
            <div>
                <Row>
                    <Col md="7">
                        {this.state.queryset.object.country === "" && <Alert variant="warning"><strong>Chybí země</strong><br/>Vyplňte ji, aby bylo možné kategorizovat objednávky podle země určení.</Alert>}
                        <FormField id="name" label="Pojmenování místa" placeholder="Jméno místa nebo firmy"
                            value={this.state.queryset.object.name} onChange={e => {this.valueChanged(e.target.value, "name")}} />
                        <div className="d-flex">
                            <FormField id="street" label="Ulice" placeholder="Ulice" className="flex-grow-1 w-auto"
                            value={this.state.queryset.object.street} onChange={e => {this.valueChanged(e.target.value, "street")}} />
                            <FormField id="house_number" className="ml-1 w-25 flex-shrink-0" label="č.p." placeholder="č.p."
                            value={this.state.queryset.object.house_number} onChange={e => {this.valueChanged(e.target.value, "house_number")}} />
                        </div>
                        <FormField id="town" label="Město" placeholder="Město"
                            value={this.state.queryset.object.town} onChange={e => {this.valueChanged(e.target.value, "town")}} />
                        <FormField id="postal_code" label="PSČ" placeholder="PSČ"
                            value={this.state.queryset.object.postal_code} onChange={e => {this.valueChanged(e.target.value, "postal_code")}} />
                        <FormField id="type" label="Země" as="select" custom
                            value={this.state.queryset.object.country} onChange={(e) => this.valueChanged(e.target.value, "country")}>
                                <option value="">Není vybrána</option>
                                {this.state.options && this.state.options.actions.POST.country.choices.map((item, key) => {
                                return <option key={key} value={item.value}>
                                    {item.display_name}
                                </option>})}
                        </FormField>
                        {this.state.updater && <div className="d-flex">
                            <Alert variant="secondary" className="ml-auto p-1">
                            <Spinner animation="border" role="status" size="sm" className="mr-3">
                                <span className="sr-only">Loading...</span>
                            </Spinner> Ukládá se...
                            </Alert>
                        </div>}
                    </Col>
                    <Col md="5">
                        {this.state.related_orders.count === -1 ? <LoadingBanner /> : <>
                        <h2>Objednávky s tímto místem</h2>
                        <p><small className="text-secondary">Celkem: {this.state.related_orders.objects.length}</small></p>
                        <ListGroup>
                            {this.state.related_orders.objects.map(item => {
                                return <ListGroup.Item key={item.id}>
                                    <a href={`/order/${item.id}`}><strong>{item.code}</strong></a>
                                    <br/><Map />{'\u00A0'}{item.locations.map(i => {console.log(i); return Location.prototype.prettyName.call(i)}).join(" \u{1f852} ") || "Bez zastávek"}
                                    {(item.customer || item.carrier) && <>
                                            {item.customer && <><br /><a href={`/customer/${item.customer.id}`}><Truck /> - {item.customer.name}</a></>}
                                            <> </>
                                            {item.carrier && <><br /><a href={`/carrier/${item.carrier.id}`}><Building /> - {item.carrier.name}</a></>}
                                            </>}
                                </ListGroup.Item>
                            })}
                        </ListGroup>
                        </>}
                    </Col>
                </Row>
            </div>
        </>}
        </Container>
    }
}

export default withRouter(PlaceDetail)
