import { withRouter } from "react-router-dom";
import Order from '../Classes/Order'
import React from 'react'
import Omnibar from '../Components/Omnibar'
import { Container, Pagination, Dropdown, ButtonGroup } from "react-bootstrap";
import Queryset from "../Classes/Queryset";
import ListView from "../Components/ListView";
import { getOptions } from "../Classes/Base";
import BulkEditModal from "../Components/BulkEditModal";
import MessageCenter from "../Components/MessageCenter";
import { HelpComponent } from "../Components/HelpComponent";
import { ArrowLeftShort, ChevronDoubleLeft, ChevronDoubleRight, ArrowRightShort} from "react-bootstrap-icons";

const PAGE_LIMIT = 12
const PAGES_VISIBLE = 15


const OrderKeysLimit = [
    "id",
    "locations__place__street",
    "locations__place__house_number",
    "locations__place__town",
    "locations__place__postal_code",
    "locations__place__country",
    "locations__type",
    "locations__code",
    "locations__time",
    "locations__end_time",
    "locations__place__name",
    "vehicle__spz",
    "vehicle__carrier",
    "user",
    "user__username",
    "created_by",
    "created_by__username",
    "labels",
    "customer_currency",
    "carrier_currency",
    "carrier_invoice",
    "load_currency",
    "customer",
    "customer__name",
    "customer__ico",
    "customer__town",
    "customer__postal_code",
    "customer__country",
    "carrier",
    "carrier__name",
    "carrier__ico",
    "carrier__raal",
    "carrier__town",
    "carrier__postal_code",
    "carrier__country",
    "carrier__invoice",
    "code",
    "created_at",
    "updated_at",
    "order_created",
    "due_days",
    "exported_date",
    "load_price",
    "price_carrier",
    "price_customer",
    "state",
    "license_plate",
    "customer_order",
    "vehicle_type",
    "annotated_margin",
    "price_per_distance",
    "distance",
    "requirements"
]

class OrderFilterView extends React.Component {

    state = {
        queryset: new Queryset(Order),
        options: undefined,
        selection: {},
        filters: undefined,
        server_count: undefined,
        page: 1,
        stats: undefined,
    }

    async addAll() {
        const desired_count = this.state.server_count
        const queryset = new Queryset(Order)
        const query_filters = {}
        //Prepare filters
        this.state.filters.forEach(filter => {
            query_filters[filter.field.key + (filter.operator || "") ] = filter.value
        })
        //Make the request
        await queryset.filter(query_filters)
        if (queryset.objects.length < desired_count) {
            MessageCenter.addMessage(`Některé záznamy (${desired_count - queryset.objects.length}) se nepovedlo přidat!`)
        }
        // append all to the selection
        const selection = this.state.selection
        queryset.objects.forEach(order => {
            selection[order.id] = order
        })
        await this.setState({selection})
        this.selectionUpdated()
    }

    async clearSelection() {
        await this.setState({selection: {}})
        this.selectionUpdated()
    }


    async bulk_edit(action, value) {
        await Order.bulkEdit(Object.values(this.state.selection), action, value)
        this.searchTriggered(this.state.filters)
    }

    export(type) {
        const orders = Object.values(this.state.selection).map(i => {return i.id})
        switch (type) {
            case 'pohoda':
                Order.bulkPohodaXMLUrl(orders)
                break;
            case 'csv':
                Order.bulkCSVExport(orders)
                break
            default:
                MessageCenter.addMessage(`Operace "${type}" není podporována`)
                break;
        }
    }

    async updateStats() {
        this.setState({stats: undefined})
        const orders = Object.values(this.state.selection).map(i => {return i.id})
        const stats = await Order.stats(orders)
        this.setState({stats})
    }

    updateBrowserUrl() {
        const params = new URLSearchParams()
        Object.values(this.state.filters).forEach(i => {
            params.set(i.field.key + (i.operator || ""), i.value)
        })
        this.props.history.push(`?${params.toString()}`)
    }

    async searchTriggered(filters) {
        const query_filters = {}
        filters.forEach(filter => {
            // If operator is explicitly set append it!
            query_filters[filter.field.key + (filter.operator || "")] = filter.value
        })
        query_filters.limit = PAGE_LIMIT
        query_filters.page = this.state.page
        await this.state.queryset.filter(query_filters)
        // Count filters
        const count_filters = {...query_filters}
        delete count_filters['page']
        delete count_filters['limit']
        delete count_filters['search']
        const server_count = await Order.serverCount(count_filters)
        this.setState({filters, server_count})
        this.updateBrowserUrl()
    }

    async componentDidMount() {
        this.setState({options: await getOptions(Order)})
    }

    async setPage(i) {
        await this.setState({page: i})
        this.searchTriggered(this.state.filters)
    }

    renderPagination() {
        const items = []
        const pages = Math.ceil(this.state.server_count / PAGE_LIMIT) + 1
        // Let's start the pagination in the middle
        let start = this.state.page - Math.floor(PAGES_VISIBLE/2)
        if (start + PAGES_VISIBLE > pages) {
            start-=(start+PAGES_VISIBLE - pages)
        }
        // Make sure we start at 1
        start = start >= 1 ? start : 1
        let end = Math.min(start + PAGES_VISIBLE, pages)

        for (let i=start; i<end;++i) {
            items.push(<Pagination.Item key={i} active={i === this.state.page} onClick={() => {this.setPage(i)}} >
                    {i}
            </Pagination.Item>)
        }

        return <Pagination className='justify-content-center'>
            <Pagination.Item onClick={() => {this.setPage(this.state.page -1)}} disabled={this.state.page === 1}><ArrowLeftShort /></Pagination.Item>
            <Pagination.Item onClick={() => {this.setPage(1)}} disabled={this.state.page === 1}><ChevronDoubleLeft /></Pagination.Item>
            {items}
            <Pagination.Item onClick={() => {this.setPage(pages - 1)}} disabled={this.state.page === pages - 1}><ChevronDoubleRight /></Pagination.Item>
            <Pagination.Item onClick={() => {this.setPage(this.state.page +1)}} disabled={this.state.page === pages - 1}><ArrowRightShort /></Pagination.Item>
        </Pagination>
    }

    renderBulkActions() {
        return <ButtonGroup className="justify-content-end">
            <Dropdown className='ml-2'>
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    Výběr
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => this.addAll()} disabled={this.state.queryset.objects.length === 0}>Nalezené do výběru</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.clearSelection()} disabled={!this.state.selection || !Object.keys(this.state.selection).length}>Zrušit výběr</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown className='ml-2'>
                <Dropdown.Toggle variant="success" id="dropdown-basic" disabled={!this.state.selection || !Object.keys(this.state.selection).length}>
                    Hromadná akce
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <BulkEditModal label="Přiřadit dopravce" type="carrier" valueSelected={value => {this.bulk_edit("carrier", value.id)}}/>
                    <BulkEditModal label="Přiřadit zákazníka" type="customer" valueSelected={value => {this.bulk_edit("customer", value.id)}}/>
                    <BulkEditModal label="Přidat štítek" type="label" valueSelected={value => {this.bulk_edit("add_label", value.id)}}/>
                    <BulkEditModal label="Odebrat štítek" type="label" valueSelected={value => {this.bulk_edit("remove_label", value.id)}}/>
                    <BulkEditModal label="Změnit stav" type="state" valueSelected={value => {this.bulk_edit("state", value)}}/>
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown className='ml-2'>
                <Dropdown.Toggle variant="success" id="dropdown-basic" disabled={!this.state.selection || !Object.keys(this.state.selection).length}>
                    Exportovat
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => this.export('csv')}>CSV soubor (MS Excel)</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.export('pohoda')}>Pohoda XML</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </ButtonGroup>
    }

    async selectionUpdated(selection) {
        if (selection) {
            this.setState({selection})
        }
        this.updateStats()
    }

    async entryDeleted(entry) {
        this.state.queryset.remove(entry)
        this.forceUpdate()
    }

    async rowClicked(index) {
        this.props.history.push("/order/" + this.state.queryset.objects[index].id)
    }

    render() {
        return <Container>
            <div className="float-right mt-2 mr-2">
                <HelpComponent helpTopic="orderFilter">
                    <p>Zde můžete filtrovat objednávky. Pamatujte, že pokud nemáte privilegovaný účet, <strong>cizí objednávky nevidíte.</strong></p>
                    <h5>Jak používat filtrování?</h5>
                    <ol style={{paddingLeft: "10px"}}>
                        <li>Začnětě psát do textového pole a ze seznamu si vyberte filtr, který chcete použít. </li>
                        <li>
                            Vyberte hodnotu pro vybraný filtr, psaním získáte nápovědu, co můžete doplnit. 
                            <small className="text-silent"> Některé hodnoty, třeba zákazník, berou číselné ID</small>
                        </li>
                        <li>Pokud chcete filtry odebírat, stačí na ně klepnout.</li>
                    </ol>
                </HelpComponent>
            </div>
            <h2 style={{marginTop: "0.4em"}}>Filtrování objednávek</h2>
            <Omnibar model={Order} searchTriggered={filters => this.searchTriggered(filters)} limitedKeys={OrderKeysLimit}/>
            <ListView
                updating={this.state.queryset.count === -1}
                rowClicked={i => this.rowClicked(i)}
                entries={this.state.queryset.objects}
                columns={Order.columns}
                options={this.state.options}
                selection={this.state.selection}
                selectionUpdated={selection => this.selectionUpdated(selection)}
                entryDeleted={entry => this.entryDeleted(entry)}
                transformations={Order.valueTransforms}
                navigate={item => {this.props.history.push("/order/" + encodeURIComponent(item.id))}}
                />
            <div className="records d-flex mb-1 justify-content-center">
                {!this.state.stats || (this.state.stats.count === 0) ? null : <>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Počet</h5>
                            <p className="card-text">{this.state.stats.count}</p>
                        </div>
                    </div>
                    <div className="card ml-2">
                        <div className="card-body">
                            <h5 className="card-title">Součet</h5>
                            <p className="card-text">{Math.round(this.state.stats.stats.sum * 100) / 100} {this.state.stats.home_currency.code}</p>
                        </div>
                    </div>
                    <div className="card ml-2">
                        <div className="card-body">
                            <h5 className="card-title">Průměr</h5>
                            <p className="card-text text-center">{Math.round(this.state.stats.stats.avg * 100) / 100} {this.state.stats.home_currency.code}</p>
                        </div>
                    </div>
                    <div className="card ml-2">
                        <div className="card-body">
                            <h5 className="card-title">Největší marže</h5>
                            <p className="card-text"><a href={`/order/${this.state.stats.records.max.id}`}>{this.state.stats.records.max.code}</a></p>
                        </div>
                    </div>
                    <div className="card ml-2">
                        <div className="card-body">
                            <h5 className="card-title">Nejmenší marže</h5>
                            <p className="card-text"><a href={`/order/${this.state.stats.records.min.id}`}>{this.state.stats.records.min.code}</a></p>
                        </div>
                    </div>
                </>}
            </div>
            <div className="d-flex justify-content-between">
                <div>
                    {this.renderPagination()}
                </div>
                <div>
                    {this.renderBulkActions()}
                </div>
            </div>
        </Container>
    }
}

export default withRouter(OrderFilterView);