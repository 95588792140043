import React, { useState } from 'react'
import { Modal, Dropdown, Button, Form, Alert, ListGroup, ListGroupItem } from 'react-bootstrap';
import Carrier from '../Classes/Carrier'
import Label from '../Classes/Label'
import Customer from '../Classes/Customer'
import ModelSelector from './ModelSelector';
import LoadingBanner from './LoadingBanner';
import { useEffect } from 'react';
import { getOptions } from '../Classes/Base';
import Order from '../Classes/Order';


function OrderStateSelector(props) {
  const [stateChoices, setStateChoices] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    getOptions(Order).then(options => {
      setStateChoices(options.actions.POST.state.choices)
    }).catch(() => {setError("Nastala chyba při načítání stavů. Zkuste to znovu, nebo kontaktujte podporu")})
  }, [])

  if (error) {
    return <Alert variant='danger'>
      {error}
    </Alert>
  }

  if (stateChoices === undefined) {
    return <LoadingBanner />
  }

  return <ListGroup>
    {stateChoices.map(i => <ListGroupItem action key={i.value} onClick={() => props.itemSelected(i.value)}>
      {i.display_name}
    </ListGroupItem>)}
  </ListGroup>
}

//TODO Fix timezone issues!
class BulkEditModal extends React.Component {
    selector = React.createRef()

    state = {
        show: false,
    }

    valueSelected(item) {
        this.props.valueSelected && this.props.valueSelected(item)
        this.setState({show: false})
    }

    renderBody() {
        if (this.props.type === 'carrier') {
            return <ModelSelector ref={this.selector}
                klass={Carrier}
                show_controls={false}
                select={1}
                show_search={false}
                itemSelected={item => {this.valueSelected(item)}}
                />
        }
        if (this.props.type === 'customer') {
            return <ModelSelector ref={this.selector}
                klass={Customer}
                show_controls={false}
                select={1}
                show_search={false}
                itemSelected={item => {this.valueSelected(item)}}
                />
        }
        if (this.props.type === 'label') {
            return <ModelSelector ref={this.selector}
                klass={Label}
                show_controls={false}
                select={1}
                show_search={false}
                itemSelected={item => {this.valueSelected(item)}}
                />
        }
        if (this.props.type === 'state') {
          return <OrderStateSelector itemSelected={item => this.valueSelected(item)} />
        }
    }

    render() {
        return (
            <>
                <Dropdown.Item onClick={() => this.setState({show: true})}>
                    {this.props.label}
                </Dropdown.Item>
        
              <Modal show={this.state.show} onHide={() => this.setState({show: false})} size="xl">
                <Modal.Header closeButton>
                  <Modal.Title>{this.props.label}</Modal.Title>
                  {this.props.type !== "state" && <Form inline className="ml-auto">
                    <input type="text" className="form-control" placeholder="Hledat" onChange={(event) => {this.selector.current && this.selector.current.searchChanged(event.target.value)}} />
                </Form>}
                </Modal.Header>
                <Modal.Body>
                    {this.renderBody()}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => this.setState({show: false})}>
                    Zavřít
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          );
    }
  }
  
  export default BulkEditModal;

  
