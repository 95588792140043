import React from 'react'
import { Dropdown, Spinner } from 'react-bootstrap';
import Queryset from '../Classes/Queryset';
import EmailTemplate from '../Classes/EmailTemplate'
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import {Folder2} from 'react-bootstrap-icons'

class EmailTemplateDropdown extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            queryset: new Queryset(EmailTemplate)
        }
        this.callback = props.onSelected ? props.onSelected : () => {}
    }

    async componentDidMount() {
        await this.state.queryset.all()
        this.forceUpdate()
    }    
 
    render() {
        return (
            <Dropdown>
            <Dropdown.Toggle variant="success">
                <Folder2 />
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight>
                {
                    this.state.queryset.count < 0 ? <Spinner animation="border" role="status" size="sm"><span className="sr-only">Načítání...</span></Spinner> : (
                        this.state.queryset.count === 0 ? <DropdownItem>Bez záznamu</DropdownItem> : this.state.queryset.objects.map(item => {
                            return <DropdownItem key={item.id} onClick={() => {this.callback(item)}}>({item.language}) {item.name}</DropdownItem>
                        })
                    )
                }
            </Dropdown.Menu>
            </Dropdown>
        );
    }
}

export default EmailTemplateDropdown;
