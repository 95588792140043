import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import Queryset from "../Classes/Queryset";
import CompanyIdentity from "../Classes/CompanyIdentity";
import { Alert, Button, Col, Container, Form, Jumbotron, Row, Spinner } from "react-bootstrap";
import LoadingBanner from "../Components/LoadingBanner";
import { getOptions } from "../Classes/Base";
import { Link } from "react-bootstrap-icons";
import MessageCenter from "../Components/MessageCenter";
import fetchProxy from "../Helpers/fetchProxy";

export function CompanyIdentityDetail() {

    const [companyIdentity, setCompanyIdentity] = useState();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [options, setOptions] = useState();
    const [error, setError] = useState();
    const [changes, setChanges] = useState({});
    
    const location = useHistory().location
    useEffect(() => {
        setLoading(true);
        const id = location.pathname.split("/").at(-1);
        if (!id) {
            setCompanyIdentity(null);
        }
        const queryset = new Queryset(CompanyIdentity);
        queryset.one(id).then(
            _ => {
                if (!queryset.object) { 
                    throw new Error("Požadovaný objekt nebyl nalezen.")
                }
                else {
                    setCompanyIdentity(queryset.object)
                    setError()
                }
            }
        ).catch(err => {
            setError("" + err);
        })
        getOptions(CompanyIdentity).then(options => setOptions(options.actions.POST)).catch( err => {
            console.error(err);
            setError("Nastala chyba při načítání");
        })
        setLoading(false);
    }, [location])
    
    function updateField(name, value) {
        setChanges({
            ...changes,
            [name]: value
        })
    }

    async function saveChanges() {
        setSaving(true)
        const url = CompanyIdentity.base_url + companyIdentity.id + "/"
        const data = new FormData();
        for (let [key, value] of Object.entries(changes)) {
            data.append(key, value)
        }
        try {
            const request = await fetchProxy(url, {
                method: "PATCH",
                body: data,
                headers: {
                    'Content-Type': undefined
                }
            })
    
            if (request.status !== 200) {
                let error = "Neočekávaná odpověď serveru " + request.status + ". "
                if (request.status === 400) {
                    error += "Další informace: " + await request.text()
                }
                throw new Error(error)
            }

            setCompanyIdentity(new CompanyIdentity(await request.json()))
        }
        catch (exc) {
            MessageCenter.addMessage({
                "title": "Nepodařilo se uložit změny",
                "text": exc.message
            })
        }
        setSaving(false)
        setChanges({})
    }

    function updateImageFileField(name, file) {
        if (!file || file.size === 0) {
            if (file) console.log("Size: ", file.size)
            MessageCenter.addMessage({
                "title": "Nepodařilo se načíst soubor",
                "text": "Zkuste to prosím znovu a soubor zkontrolujte"                
            })
            return
        }
        if (!file.type.startsWith("image/")) {
            MessageCenter.addMessage({
                "title": "Nepodařilo se načíst obrazová data",
                "text": "Zdá se, že nahrávaný soubor není obrázek"                
            })
            return
        }
        setChanges({
            ...changes,
            [name]: file
        })
    }

    //Handle all the fields the same
    const text_fields = ["name", "town", "postal_code", "street", "house_number", "email", "phone_number", "ico", "vat"]
    const image_fields = ["stamp", "logo"]

    
    if (error) return <Container>
        <Alert variant="danger">{error}</Alert>
    </Container>

    if (loading) return <Container>
        <LoadingBanner />
    </Container>

    if (!companyIdentity || !options) return <Container>
            <Alert variant="danger">Požadovaný objekt se nepodařilo načíst</Alert>
    </Container>

    return <Container>
        <Jumbotron>
            <h1>Identita společnosti</h1>
            <h2>{companyIdentity?.name} <span className="text-muted">#{companyIdentity.id}</span></h2>
            <Button disabled={!Object.keys(changes).length || saving }size="sm" variant="primary" onClick={saveChanges}>
                Uložit změny {saving ? <Spinner size="sm" animation="border" /> : Object.keys(changes).length ? `(${Object.keys(changes).length})` : ""}
            </Button>
        </Jumbotron>
        <Row>
            <Col md={8}>
                <Alert variant="warning">
                    Data se automaticky neukládají, je třeba ručně klepnout na uložit změny.
                </Alert>
                {text_fields.map(field => {
                const definition = options[field];
                return <Form.Group controlId={field} key={field}>
                    <Form.Label>{definition.label}</Form.Label>
                    <Form.Control
                        name={field}
                        type="text"
                        value={changes[field] || companyIdentity[field] || ""}
                        onChange={e => updateField(field, e.target.value)}
                        >
                    </Form.Control>
                    {definition.help_text && <Form.Text><small>{definition.help_text}</small></Form.Text>}
                </Form.Group>}
                )}
                <Form.Group controlId="country">
                    <Form.Label>{options['country'].label}</Form.Label>
                    <Form.Control
                        as="select"
                        value={changes["country"] || companyIdentity["country"] || ""}
                        onChange={e => updateField("country", e.target.value)}
                    >
                        {options['country'].choices.map(country => <option key={country.value} value={country.value}>{country.display_name}</option>)}
                    </Form.Control>
                </Form.Group>
                {image_fields.map(field => {
                    const definition = options[field];
                    return <Form.Group controlId={field} key={field}>
                        <Form.Label>{definition.label}</Form.Label>
                        <Form.Control type="file" onChange={e => updateImageFileField(field, e.target?.files[0])}/>
                        <Form.Text><Link /> <a target="_blank" rel="noreferrer" href={companyIdentity[field]}>{companyIdentity[field]}</a></Form.Text>
                    </Form.Group>
                })}
            </Col>
            <Col md={4}>

            </Col>
        </Row>
    </Container>
}