import React, { createContext, useEffect } from 'react'
import LoginView from './Views/LoginView';
import NotFoundView from './Views/NotFoundView'
import generateMenu from './menu'
import { Switch, Route, Redirect } from 'react-router-dom'
import DashboardView from './Views/DashboardView';
import { withRouter } from 'react-router-dom'
import Account from './Classes/Account';
import PreferencesView from './Views/PreferencesView';
import 'bootstrap/dist/css/bootstrap.min.css';
import ErrorBoundary from './Components/ErrorBoundary';
import { usePersistStorage, ConfigContext as ConfigContext } from './Context';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Container } from 'react-bootstrap';
import LoadingBanner from './Components/LoadingBanner';
import MessageCenter from './Components/MessageCenter';
import settings from './Settings';
import { useMemo } from 'react';

function App(props) {
  const routes = []
  let detailRoutes = []


  const [config, setConfig] = usePersistStorage('config', undefined)
  const pathname = useHistory().location.pathname
  const menu = useMemo(() => generateMenu(config), [config]) 


  useEffect(() => {
    if (config === undefined) {
      fetch(settings.BASE_API_URL + "service/branding").then(res => {
        if (res.status != 200) {
          throw new Error("Unexpected http response for the branding request")
        }
        return res.json()
      }).then(data => {
        setConfig(data)
      }).catch(error => {
          MessageCenter.addMessage({
            'title': 'Selhalo načítání konfigurace',
            'text': 'Selhalo načtení konfigurace, zkuste to znovu, nebo později.'
          })
          console.error(error)
        }
      )
    }
  }, [config])

  if (config === undefined) return <Container>
    <LoadingBanner label="Načítání konfigurace" />
  </Container>



  menu.forEach(item => {
    if (item.type === "menuitem") {
      routes.push(<Route key={item.path} path={item.path} render={() => item.renders} />)
      if (item.detailView) {
        detailRoutes.push(<Route key={"detail" + item.path} path={item.path + "/:id"} render={() => item.detailView} />)
      }
    }
    else if (item.type === "menu") {
      item.objects.forEach(menuitem => {
        routes.push(menuitem.toRoute())
        if (menuitem.detailView) {
          detailRoutes.push(<Route key={"detail" + menuitem.path} path={menuitem.path + "/:id"} render={() => menuitem.detailView} />)
        }
      })
    }
  })


  if (!Account.isLogged()) {
    if (!pathname.startsWith("/login") && !pathname.startsWith("/logout")) {
      return <Redirect to="/login" />
    }
  }
  const main_switch = <Switch>
    <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
    {detailRoutes}
    {routes}
    <Route key="login" path="/login" render={() => { return (<LoginView message="yield" />) }} />
    <Route key="logout" path="/logout" render={() => { return (<LoginView message="logout" />) }} />
    <Route key="dashboard" exact path="/" render={() => { return (<DashboardView />) }} />
    <Route key="preferences" path="/preferences" render={() => { return (<PreferencesView />) }} />
    <Route key="default" path="" render={() => { return (<NotFoundView />) }} />
  </Switch>

  return <div className="d-flex">
    <ErrorBoundary>
      <ConfigContext.Provider value={config}>
        {main_switch}
      </ConfigContext.Provider>
    </ErrorBoundary>
  </div>
}

export default withRouter(App)
