import React from 'react'
import {Form, InputGroup} from 'react-bootstrap'

class FormField extends React.Component {

    isValid() {
        return !this.props.errors
    }

    render() {
        return <><InputGroup className={`mb-1 ${this.props.className}`}>
            {this.props.label && <InputGroup.Prepend>
                <InputGroup.Text>{this.props.label}</InputGroup.Text>
            </InputGroup.Prepend>}
            <Form.Control
                className={!this.isValid() && "is-invalid"}
                disabled={this.props.disabled}
                type={this.props.type}
                as={this.props.as}
                custom={this.props.custom}
                placeholder={this.props.placeholder}
                value={this.props.value} onChange={e => this.props.onChange && this.props.onChange(e)}>
                {this.props.children && this.props.children}
            </Form.Control>
        </InputGroup>
        <Form.Text className="text-danger ml-1">{this.props.errors && this.props.errors.join(". ")}</Form.Text>
        </>
    }
}

export default FormField