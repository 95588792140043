import React from 'react'
import Papa from 'papaparse'
import { Container, Table, Row, Col, Spinner, ListGroup, ListGroupItem, Button, Form } from 'react-bootstrap'
import { getOptions } from '../Classes/Base'
import Order from '../Classes/Order'
import '../css/OrderMapping.css'
import Customer from '../Classes/Customer'
import Currency from '../Classes/Currency'
import Location from '../Classes/Location'
import SearchModal from '../Components/SearchModal'
import { OrderFromData, LocationFromData } from '../Helpers/ImportHelpers'
import MessageCenter from '../Components/MessageCenter'

const NON_MAPPED_FIELD_TYPES = [
    'field',
    "nested object",
    "choices",
    "choice"
]

const MAX_DISPLAYED_ROWS = 10

class OrderImportView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            active_pane: 0,
            show: false,
            mapped: [],
            imported: [],
            mapping: [],
            hidden_columns: [],
            locations: [],
            ignored_rows: [],
            res: {data: []},
        }
    }

    dragStarted(e) {
        e.dataTransfer.setData("name", e.target.dataset.name)
    }

    dropped(e) {
        const locations = this.state.locations
        const mapping = this.state.mapping
        const mapped = this.state.mapped
        const name = e.dataTransfer.getData("name")
        if (mapping[e.target.dataset.index] !== undefined) {
            const mapped_i = mapped.indexOf(name)
            mapped_i !== -1 && mapped.slice(mapped_i, 1)
        }
        if (this.state.active_pane === 2) {
            locations[locations.length-1][name] = e.target.dataset.index
            if (mapping[e.target.dataset.index] !== undefined) {
                locations[locations.length-1][mapping[e.target.dataset.index]] = undefined
            }
        }
        mapping[e.target.dataset.index] = name
        mapped.push(name)
        this.setState({mapping, mapped, locations})
    }

    async componentDidMount() {
        const options = await getOptions(Order)
        this.setState({options})
        const location_options = await getOptions(Location)
        this.setState({location_options})
        this.forceUpdate()
    }

    headerText = (i) => {
        if (!this.state.mapping[i]  || !this.state.options || !this.state.location_options) {
            return i;
        }
        switch (this.state.active_pane) {
            case 1:
                return this.state.options.actions.POST[this.state.mapping[i]].label
            case 2:
                return this.state.location_options.actions.POST[this.state.mapping[i]].label
            default:
                return i;
        }
    }

    buildTable() {
        let row = 0;
        return <Table className="mt-3">
            <thead>
            <tr>
                <th>
                    #
                </th>
                {this.state.res.data.length && 
                    Object.keys(this.state.res.data[0]).map(i => {
                        if (this.state.hidden_columns[i]) return null;
                        return <th key={`h-${i}`} onDragOver={e => {e.preventDefault()}} onDrop={e => this.dropped(e)} data-index={i}>
                                {this.headerText(i)}
                            </th>
                    })
                }
            </tr>
            </thead>
            <tbody>
                {this.state.res.data.slice(0, MAX_DISPLAYED_ROWS).map(item => {
                        if (item.length === 1 && item[0] === "") {
                            return null
                        }
                        const tr_ = <tr key={`r-${row}`} className={this.state.ignored_rows[row] ? "text-danger" : ""}>
                            <td onClick={this.toggleRow} data-row={row}>{row}</td>
                            {Object.keys(item).map(i => {
                                if (this.state.hidden_columns[i]) return null;
                                return <td key={`r-${row}-${i}`}>{item[i]}</td>
                            })}
                        </tr>
                        row = parseInt(row) + 1;
                        return tr_;
                    })
                }
            </tbody>
        </Table>
    }

    triggerFileRead = (event) => {
        Papa.parse(event.target.files[0], {complete:this.readFile})
    }

    readFile = (res) => {
        this.setState({active_pane: 1, res: res})
    }

    setShow = (show) => {
        this.setState({show})
    }

    handleShow = () => this.setShow(true)
    handleClose = () => {
        this.setShow(false)
    }

    saveChanges = () => {
        this.props.closeCallback && this.props.closeCallback()
    }

    toggleRow = (e) => {
        const row = e.target.dataset.row
        const ignored = this.state.ignored_rows
        if (this.state.ignored_rows[row]) {
            ignored[row] = false
        }
        else {
            ignored[row] = true
        }
        this.setState({ignored_rows: ignored})
    }

    addLocation = () => {
        const locations = this.state.locations
        locations.push({type: "LOAD"})
        this.setState({locations})
        this.hideMapped()
    }

    deleteLocation = () => {
        const current = this.state.locations.pop()
        const mapping = this.state.mapping
        Object.values(current).forEach(i => {
            if (this.state.mapping[i] !== undefined) {
                mapping[i] = undefined
            }
        })
        this.setState({mapped:[], mapping})
        this.forceUpdate()
    }

    hideMapped = () => {
        const hidden_columns = [];
        Object.keys(this.state.mapping).forEach(i => {
            if (this.state.mapping[i]) {
                hidden_columns[i] = true
            }
        })
        this.setState({mapped: []})
        this.setState({hidden_columns})
    }

    locationTypeChanged = e => {
        const locations = this.state.locations
        locations[locations.length-1].type = e.target.value
        this.setState({locations})
    }

    initImport = async () => {
        const imported = []
        this.setState({active_pane: 5, finished: 0, length: this.state.res.data.length})
         Object.keys(this.state.res.data).forEach(async i => {
            let import_data = {_result: false, row: i, failed_locations: 0};
            let ignore = false
            if (this.state.ignored_rows[i]) {
                import_data._reason = "Ignorováno uživatelem"
                ignore = true
            }
            const item = this.state.res.data[i]
            if (item.length === 1 && item[0] === "") {
                import_data._reason = "Zachycen prázdný objekt"
                ignore = true
            }
            /* TODO CUSTOMER, CURRENCIES ???? */ 
            
            const order = ignore ? {} : await OrderFromData(this.state.res.data[i], this.state.mapping).save()
            if (order.id === 0) {
                MessageCenter.addMessage(`Objednávku z řádku ${import_data.row} se nepodařilo importovat`)
                order.id = null
                import_data._reason = "Chyba při ukládání!"
            }
            order._import = import_data
            if (order.id) {
                import_data._result = true
                const locations_import = this.state.locations.map(async mapping => {
                    const location = LocationFromData(item, mapping)
                    location.order = order.id
                    location.save().then((res) => {
                        if (res.id === undefined) {
                            let message = `Bohužel se nepovedlo importovat lokalitu k objednávce ${location.order} (řádek ${import_data.row}). Více informací: `
                            Object.keys(res).forEach(i => {
                                message += `${i}: ${res[i]}`
                            })
                            MessageCenter.addMessage(message)
                        }
                        else {
                            order.locations.push(location)
                        }
                    })
                })
                for (let i=0; i<locations_import.length; ++i) {
                    await locations_import[i]
                }
            }
            imported.push(order)
            this.setState({imported})
        })
    }

    render() {
        let pane;
        switch (this.state.active_pane) {
            case 0:
                pane = <Container>
                    <p>
                        Chystáte se importovat objednávky. V prvním kroku je potřeba vybrat soubor CSV, který objednávky obsahuje. 
                        Pokud jsou objednávky ve formátu XLS, je nutné je do formátu CSV překonvertovat. To je možné v každém tabulkovém editoru.
                    </p>
                    <p>
                        V dalším kroku bude provedeno mapování jednotlivých sloupců na hodnoty. 
                    </p> 
                    <input type="file" onChange={this.triggerFileRead}/>
                </Container>
                break;
            case 1:
                pane = <div>
                    <Row>
                        <Col md="2">
                            <ListGroup className="mapper-source-group">
                                {this.state.options === undefined ? <Spinner></Spinner> :
                                    Object.entries(this.state.options.actions.POST).map((i) => {
                                        const key = i[0]
                                        const item = i[1]
                                        return NON_MAPPED_FIELD_TYPES.indexOf(item.type) === -1 && this.state.mapped.indexOf(key) === -1 && !item.read_only ? 
                                            <ListGroupItem key={key} onDragStart={(e) => {this.dragStarted(e)}} draggable="true" data-name={key}>{item.label}</ListGroupItem> : null
                                    })
                                }
                            </ListGroup>
                            <Button className="d-inline-block float-right" onClick={() => {this.setState({active_pane: 2}); this.hideMapped()}} disabled={this.state.mapped.length === 0}>Další krok</Button>
                        </Col>
                        <Col md="10">
                            <p>Teď je potřeba říct, který sloupec má jakou informaci z pohledu objednávky. Přetáhněte informace z nabídky vpravo na vybraný sloupec.</p>
                            <p>Některé řádky můžete chtít ignorovat. Takové je vhodné označit klepnutím, je-li jejich číslo červené, budou ignorovány.</p>
                            <p>Další krok určí nakládky a vykládky. Pro lepší přehlednost dojde ke skrytí již mapovaných sloupců</p>
                            {this.buildTable()}
                            <hr/>
                            <p>
                                {this.state.res.data.length > MAX_DISPLAYED_ROWS && `Celkem bude importováno ${this.state.res.data.length} záznamů, zobrazeno pouze prvních ${MAX_DISPLAYED_ROWS}. `}
                                { this.state.ignored_rows.length > 0 && `Počet ignorovaných je ${(this.state.ignored_rows.filter(i => {return i !== undefined})).length} `}
                            </p>
                        </Col>
                    </Row>
                </div>
                break;
            case 2:
                pane = <div>
                    <Row>
                        <Col md="2">
                            <h2>{this.state.locations.length ? `Lokalita #${this.state.locations.length}` : `Přidejte lokalitu.`}</h2>
                            <Button className="mb-2" onClick={() => {this.addLocation()}}>Další lokalita</Button>
                            <Button className="ml-2 mb-2" variant="danger" disabled={!this.state.locations.length} onClick={() => {this.deleteLocation()}}>Smazat lokalitu</Button>
                            <Form.Group>
                                <Form.Control disabled={!this.state.locations.length} as="select" custom 
                                        value={this.state.locations && this.state.locations[this.state.locations.length]}
                                        onChange={e => {this.locationTypeChanged(e)}}>
                                    <option value="LOAD">Nakládka</option>
                                    <option value="UNLOAD">Vykládka</option>
                                </Form.Control>
                            </Form.Group>
                            <ListGroup className="mapper-source-group">
                                {this.state.location_options === undefined ? <Spinner></Spinner> :
                                    Object.entries(this.state.location_options.actions.POST).map((i) => {
                                        const key = i[0]
                                        const item = i[1]
                                        return NON_MAPPED_FIELD_TYPES.indexOf(item.type) === -1 && this.state.mapped.indexOf(key) === -1 && !item.read_only ? 
                                            <ListGroupItem key={key} onDragStart={(e) => {this.dragStarted(e)}} 
                                                    draggable={!!this.state.locations.length}
                                                    data-name={key}
                                                    disabled={!this.state.locations.length}>
                                                    {item.label}
                                                    </ListGroupItem> : null
                                    })
                                }
                            </ListGroup>
                        </Col>
                        <Col>
                            <Button className="m-3 float-right" onClick={() => {this.setState({active_pane: 3})}}>Další krok</Button>
                            <p>Teď budou mapovány jednotlivé zastávky. Pokud nechcete žádnou importovat, klidně klepněte na další.</p>
                            <p>Zastávky se mapují jedna po druhé. Jakmile namapujete první, klepněte pro přidání další lokality.</p>
                            <p>Pokud zastávka neobsahuje datum a čas, tak bude přiřazeno dnešní datum.</p> 
                            {this.buildTable()}
                        </Col>
                    </Row>
                </div>
                break;
            case 3: pane = <Container>
                    <p>Výborně. Mapování proběhlo úspěšně. Teď už jenom nastavit nějaké dodatečné informace. </p>
                    <h2>Zákazník</h2>
                    <p>{this.state.customer !== undefined && this.state.customer !== null ? this.state.customer.name : "Nevybrán"}</p>
                    <SearchModal klass={Customer} closeCallback={(entry) => { this.setState({customer: entry}) }}></SearchModal>
                    <Button onClick={() => {return this.setState({customer: undefined})}} className="ml-2" variant="danger">Bez zákazníka</Button>
                    {Object.values(this.state.mapping).filter(i => {if (i && i.startsWith("price_")) return i; return undefined}).length && <>
                        <h2>Měna</h2>
                        <p>{this.state.currency !== undefined && this.state.currency !== null ? this.state.currency.name : "Výchozí měna"}</p>
                        <SearchModal klass={Currency} closeCallback={(entry) => { this.setState({currency: entry}) }}></SearchModal>
                    </>}
                    <hr/>
                    <Button onClick={() => {this.setState({active_pane: 4})}}>Importovat</Button>
                </Container>
            break;
            case 4: pane = <Container>
                <p>Celkově se chystáme importovat {this.state.res.data.length - Object.keys(this.state.ignored_rows.filter(i => {if (i) return i; return undefined})).length} objednávek </p>
                <Button onClick={() => {this.initImport()}}>Spustit import!</Button>
                <h2>Shrnutí mapování</h2>
                <Row>
                    <Col md={4}>
                    <h3>Atributy objednávky:</h3>
                    <Table className="mt-3" size="sm">
                    <tbody>
                    {Object.keys(this.state.res.data[0]).map(i => {
                        return <tr key={`h-${i}`}>
                                <th onDragOver={e => {e.preventDefault()}} onDrop={e => this.dropped(e)} data-index={i}>
                                    {i}
                                </th>
                                <td>
                                    {this.state.options && this.state.options.actions.POST[this.state.mapping[i]] && this.state.options.actions.POST[this.state.mapping[i]].label}
                                </td>
                            </tr>
                    })}
                    </tbody>
                    </Table>                    
                    </Col>
                    <Col md={8}>
                        <h3>Lokality: </h3>
                        <Table>
                            <tbody>
                        {
                            Object.keys(this.state.locations).map(i => {
                                const location = this.state.locations[i]
                                return <tr key={i}>
                                    <td>{i}</td>
                                    {
                                        Object.keys(location).map(key => {
                                            return <td key={`${i}-${key}`}>
                                                {this.state.location_options && this.state.location_options.actions.POST[key].label} - &nbsp;
                                                {this.state.location_options && <strong>{location[key]}</strong>}
                                            </td>
                                        })
                                    }
                                </tr>
                            })
                        }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
                break;
            case 5:
                pane = <Container>
                    <Row>
                        <Col md="10">
                            <h2>Průběh</h2>
                            {this.state.imported.length} / {this.state.length}
                            <em>Import probíhá, pravděpodobně to bude chvíli trvat. Níže se bude objevovat protokol o importu :)</em>
                            <hr />
                            <div className="imported">
                            {this.state.imported.map(i => {
                                return <div className="alert alert-secondary">
                                    Řádek {i.row} {i.id === undefined ? <>nebyl importován! Důvod: {i._import._reason}</> : <> importován s ID: {i.id}. 
                                        {` Importováno ${i.locations.length} zastávek (z celkového počtu pokusů ${this.state.locations.length})`}! <a href={`/order/${i.id}`}>Přejít na objednávku</a>
                                    </>}
                                </div>
                            })}
                            </div>
                        </Col>
                    </Row>
                </Container>
                break;
            default:
                pane = <p><strong>Error state!</strong></p>
        }
        return <Container fluid>
            <h1>Import objednávek</h1>
            <p className="text-silent">Krok {this.state.active_pane + 1}</p>
            {pane}
        </Container>
    }
}

export default OrderImportView;