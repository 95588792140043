import React, { Component } from 'react'
import { Container, Jumbotron, Spinner, Form, Button, ButtonToolbar, ButtonGroup, InputGroup } from 'react-bootstrap'
import { TypeBold, TypeItalic, TypeUnderline, ListOl, ListUl } from 'react-bootstrap-icons';
import { Check2 } from 'react-bootstrap-icons'
import {withRouter} from 'react-router-dom'
import { getOptions } from '../Classes/Base'
import Snippet from '../Classes/Snippet'
import Queryset from '../Classes/Queryset'
import {Editor, EditorState, RichUtils} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import '../css/Editor.css';
import 'draft-js/dist/Draft.css';

const SAVE_DELAY = 1000

export const HEADER_TYPES = [
	{ label: "Normální", style: "paragraph" },
	{ label: "Nadpis 1", style: "header-one" },
	{ label: "Nadpis 2", style: "header-two" },
	{ label: "Nadpis 3", style: "header-three" },
	{ label: "Nadpis 4", style: "header-four" },
	{ label: "Nadpis 5", style: "header-five" },
	{ label: "Nadpis 6", style: "header-six" }
];

class SnippetDetail extends Component {
    state = {
        editorState: EditorState.createEmpty(),
        queryset: new Queryset(Snippet),
        options: undefined,
        language: undefined,
        updater: {}
    }

    async componentDidMount() {
        await this.state.queryset.one(this.props.match.params.id)
        const options = await getOptions(Snippet)
        const language = Object.keys(options.actions.POST.body.children)[0]
        const editorState = EditorState.createWithContent(stateFromHTML(this.state.queryset.object.body[language] || ""))
        this.setState({
            options,
            language,
            editorState
        })
    }

    async languageChanged(language) {
        const editorState = EditorState.createWithContent(stateFromHTML(this.state.queryset.object.body[language] || ""))
        this.setState({editorState, language})
    }

    async delete() {
        
    }

    async valueChanged(event) {
        // RACE CONDITION FOR UPDATING BODY ATTRIBUTE FOR MULTIPLE LANGUAGES!
        const et = this.state.queryset.object
        const queryset = this.state.queryset
        et[event.target.id] = event.target.value
        queryset.object = et
        if (event.target.id === "hook") {
            event.target.value = null
        }
        await this.saveChanges(event.target.id)
        this.forceUpdate()
    }

    async saveChanges(name) {
        // Helper function for the timeout
        const storeChange = (name) => {
            const et = this.state.queryset.object
            const updater = this.state.updater
            const data = { id: et.id }
            data[name] = et[name]
            et.save(data).then(() => {
                updater[name] = undefined
                this.state.queryset.object = et
                this.setState(updater)
            })
        }
        let updater = this.state.updater
        if (updater[name] !== undefined) {
            clearTimeout(updater[name])
        }
        updater[name] = setTimeout(storeChange.bind(this, name), SAVE_DELAY)
        this.setState(updater)
    }

    onStyleButtonClick (ev) {
        const type = ev.target.dataset.type;
        this.editorChange(RichUtils.toggleInlineStyle(this.state.editorState, type));  
    }

    editorChange = async (editorState) => {
        const last_change = editorState.getLastChangeType()
        if (last_change === null) {
            // I assume this means that the cursor position changed
            this.forceUpdate()
            return
        }
        this.setState({editorState})
        const event = {
            target: {
                id: "body",
                name: "body",
                value: {
                    [this.state.language] : stateToHTML(editorState.getCurrentContent())
                }
            }
        }
        this.valueChanged(event)
    }

    changeStyle = async (style) => {
        console.log(RichUtils.getCurrentBlockType(this.state.editorState))
        await this.editorChange(RichUtils.toggleBlockType(this.state.editorState, style))
    }
  
    render() {
        if (!this.state.queryset.object || !this.state.options) {
            return <Container>
                Načítáme data...
            </Container>
        } else {
            const snippet = this.state.queryset.object;
            return <Container>
            <Jumbotron>
                <h1>Úryvek</h1>
                <h2>{snippet.title}</h2>
            </Jumbotron>
            <h2>Text</h2>
            <div className="editor-wrapper border rounded">
                <ButtonToolbar aria-label="Toolbar with button groups" className="border-bottom p-2">
                    <InputGroup className="mr-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="btnGroupAddon">Styl:</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control as="select" onChange={e => {this.changeStyle(e.target.value)}} value={RichUtils.getCurrentBlockType(this.state.editorState)}>
                            {HEADER_TYPES.map(item => {
                                return <option key={item.style} value={item.style}>
                                    {item.label}
                                </option>
                            })}
                        </Form.Control>
                    </InputGroup>
                    <ButtonGroup className="mr-2" aria-label="Seznamy">
                        <Button variant="outline-secondary" onMouseDown={(ev) => {ev.preventDefault()}} onClick={ev => {this.changeStyle("ordered-list-item")}}><ListOl /></Button>
                        <Button variant="outline-secondary" onMouseDown={(ev) => {ev.preventDefault()}} onClick={ev => {this.changeStyle("unordered-list-item")}}><ListUl /></Button>
                    </ButtonGroup>
                    <ButtonGroup className="mr-2" aria-label="Řez písma">
                        <Button variant="outline-secondary" onMouseDown={(ev) => {ev.preventDefault()}} onClick={ev => {this.onStyleButtonClick(ev)}} data-type="BOLD"><TypeBold /></Button>
                        <Button variant="outline-secondary" onMouseDown={(ev) => {ev.preventDefault()}} onClick={ev => {this.onStyleButtonClick(ev)}} data-type="ITALIC"><TypeItalic /></Button>
                        <Button variant="outline-secondary" onMouseDown={(ev) => {ev.preventDefault()}} onClick={ev => {this.onStyleButtonClick(ev)}} data-type="UNDERLINE"><TypeUnderline /></Button>
                    </ButtonGroup>
                    <ButtonGroup className="ml-auto">
                    <select onChange={event => {this.languageChanged(event.target.value)}}>
                        {this.state.options === undefined ? <option>Loading...</option> : Object.keys(this.state.options.actions.POST.body.children).map(i => {
                            return <option key={i}>{i}</option>
                        })}
                    </select>
                    </ButtonGroup>
                </ButtonToolbar>
                <div className="p-2">
                    <Editor editorState={this.state.editorState} onChange={this.editorChange} />
                </div>
                <input type="submit" className="invisible" />
            </div>
            <Form.Text>
                    {this.state.updater.body === undefined ? <><Check2 /> Změny uloženy</> :
                        <>
                            <Spinner animation="border" role="status" size="sm" className="mr-1">
                                <span className="sr-only">Saving...</span>
                            </Spinner>
                            Ukládání změn
                        </>
                    }
            </Form.Text>
            <h2>Zavěšení úryvku v systému: </h2>
            <Form.Group controlId="hook" className="mr-1">
                <Form.Control as="select" custom value={snippet.hook || ""} onChange={(event) => { this.valueChanged(event) }} className="mt-2">
                {this.state.options === undefined ? <option>Loading...</option> : this.state.options.actions.POST.hook.choices.map(i => {
                    return <option key={i.value} value={i.value}>{i.display_name}</option>
                })}
                <option value={""}>Žádný</option>
                </Form.Control>
                {this.state.updater.hook === undefined ? <><Check2 /> Změny uloženy</> :
                    <>
                        <Spinner animation="border" role="status" size="sm" className="mr-1">
                            <span className="sr-only">Ukládání ...</span>
                        </Spinner>
                        Ukládání změn
                    </>
                }
            </Form.Group>
        </Container>
        }
    }
}

export default withRouter(SnippetDetail)
