import React from 'react'
import { withRouter } from "react-router-dom";
import ModelView from './ModelView';
import Label from '../Classes/Label'
import { Button, Modal, Form } from "react-bootstrap";
import MessageCenter from '../Components/MessageCenter';

class LabelView extends ModelView {
    /**
     * Initializes a new instance of the LabelView class.
     *
     * @param {Object} props - The properties passed to the constructor.
     */
    constructor(props) {
        super(Label, props)
        this.state['show'] = false
        this.state['new_name'] = ''
        this.state['new_color'] = "#000000"
    }

    help = <div>
        <p>Štítky označujete objednávky, abyste se v nich mohli lépe orientovat.</p>
        <p>Výborná vlastnost je, že můžete automaticky přiřazovat štítky k objednávkám, které exportujete, nebo odešlete. Podívejte se do nastavení</p>
    </div>

    helpTopic = "label"

    /**
     * Saves a new label with the provided name and color.
     *
     * @return {Promise<void>} A Promise that resolves when the label is saved successfully, or rejects with an error message if the label cannot be saved.
     */
    async addLabel() {
        const label = new Label({
            id: 0,
            name: this.state.new_name,
            color: this.state.new_color.replace("#", "")
        })
        await label.save()
        if (label.id === 0) {
            MessageCenter.addMessage("Nepodařilo se přidat zvolený štítek")
        }
        this.loadData()
        this.hideDialog()
    }

    hideDialog() {
        this.setState({ show: false, new_name: "", new_color: '#000000' })
    }

    valueChanged(name, value) {
        this.setState({ [name]: value })
    }

    async setDefaultFilters() {
        // const filter_with = {}
        // this.filters && this.filters.forEach(filter => {
        //     if (filter.default !== null)
        //         filter_with[filter.name] = filter.default
        // })
        // filter_with.order_by = "-id"
        // filter_with.page = 1
        // filter_with.limit = 15
        // await this.setState({ filter: filter_with })
        super.setDefaultFilters({order_by:"-id"})
    }

    jumbotronTitle = "Štítky objednávek"
    jumbotronSubtitle = "Správa štítků u objednávek"
    //Create new Label button
    new_button = () => {
        return (
            <>
                <Button variant="success" onClick={async () => { await this.setState({ show: true }); this.forceUpdate() }}>
                    Přidat štítek
                </Button>

                <Modal show={this.state.show} onHide={() => this.hideDialog()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Nový štítek</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Název štítku</Form.Label>
                                <Form.Control type="text" placeholder="Zadejte jméno" value={this.state.new_name} onChange={ev => { this.valueChanged("new_name", ev.target.value) }} />
                                <Form.Text className="text-muted">
                                    Text na štítku
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Barva</Form.Label>
                                <Form.Control type="color" value={this.state.new_color} placeholder="Barva" onChange={ev => { this.valueChanged("new_color", ev.target.value) }} />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.hideDialog()}>
                            Zavřít
                        </Button>
                        <Button variant="primary" onClick={() => this.addLabel()}>
                            Přidat
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default withRouter(LabelView);