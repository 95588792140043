import { useState } from "react";
import { Alert, Button, ButtonGroup, Dropdown, DropdownButton, Form, InputGroup, Modal, Table } from "react-bootstrap"
import { Calendar2Check, CashCoin, Search, SignTurnSlightRight } from "react-bootstrap-icons"
import Carrier from "../Classes/Carrier";
import Customer from "../Classes/Customer";
import Order from "../Classes/Order";
import Queryset from "../Classes/Queryset";
import User from "../Classes/User";
import Vehicle from "../Classes/Vehicle";
import LoadingBanner from "./LoadingBanner";
import MessageCenter from "./MessageCenter";

export function OrderSearchModal(props) {
    const [show, setShow] = useState(false);
    const [query, setQuery] = useState("");
    const [selectedOrder, setSelectedOrder] = useState({})
    const [orders, setOrders] = useState(undefined);
    const [searchRunner, setSearchRunner] = useState(false);

    const handleClose = () => {
        setShow(false);
        setQuery("")
        setOrders()
        setSelectedOrder({})
        if (searchRunner) {
            clearTimeout(searchRunner)
            setSearchRunner(undefined)
        }
    };

    const queryChanged = (query) => {
        setQuery(query)
        setOrders(undefined)
        setSelectedOrder({})
        if (searchRunner) {
            clearTimeout(searchRunner)
        }
        if (query) {
            setSearchRunner(setTimeout(() => search(query), 300))
        }
    }

    async function search(value) {
        const queryset = new Queryset(Order)
        try {
            await queryset.searchByFields(['id', 'customer_order', 'code'], value)
            setOrders(queryset.objects)
        } catch (e) {
            MessageCenter.addMessage({
                "title": "Selhalo hledání objednávek",
                "text": "Zkuste to znovu, nebo kontaktujte podporu"
            })
            console.error(e)
        } finally {
            setSearchRunner()
        }
    }

    function orderSelected(action) {
        if (selectedOrder.margin <= 0 && !window.confirm("Chcete vybrat objednávku se zápornou marží?")) return;
        props.onOrderSelected && props.onOrderSelected(selectedOrder, action || "DEFAULT")
        handleClose()
    }

    return <>
        <Button size="sm" variant='link' onClick={() => setShow(true)}><Search /></Button>
        <Modal show={show} size="xl" onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Hledat objednávku</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group style={{ maxWidth: "400px", margin: "10px auto" }} className="d-flex align-items-center">
                    <Form.Label style={{whiteSpace: "nowrap", marginTop: "10px", marginRight: "15px"}}>Hledaný výraz</Form.Label>
                    <Form.Control
                        value={query}
                        autoFocus
                        placeholder="ID, kód objednávky, nebo označení objednávky u zákazníka"
                        onChange={e => queryChanged(e.target.value)}
                    />
                </Form.Group>
                {!query ? <Alert variant="secondary" style={{maxWidth: "500px", margin: "0 auto"}}>
                    Zadejte ID, kód objednávky, nebo označení objednávky u zákazníka.
                </Alert> : searchRunner ? <LoadingBanner/> : !orders.length ? <Alert variant="secondary">
                    <strong>Nebylo nic nalezeno. Zkuste hledat jinak.</strong> <br />
                    <div className="mt-2">Pro hledaný výraz "{query}" nebyly nalezeny žádné objednávky (hledá se ID, kód objednávky, či označení objednávky u zákazníka). Zkuste ho upravit. </div>
                </Alert> : <Table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Kód objednávky</th>
                                <th>Zákazník</th>
                                <th>Označení u zákazníka</th>
                                <th>Cena zákazník</th>
                                <th>Doprava</th>
                                <th>Cena dopravy</th>
                                <th>Marže</th>
                                <th>Operátor</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map(order => <tr
                                    style={{cursor: "pointer"}}
                                    key={order.id}
                                    className={order.id === selectedOrder.id ? "table-primary" : ""}
                                    onClick={() => setSelectedOrder(order)}
                                >
                                <td>{order.id}</td>
                                <td>{order.code}</td>
                                <td>{order.customer && new Customer(order.customer).label()}</td>
                                <td>{order.customer_order}</td>
                                <td>{order.price_customer} {order.customer_currency?.code}</td>
                                <td>
                                    {order.vehicle ?
                                        Vehicle.icon_as_component() :
                                        order.carrier && Carrier.icon_as_component()}
                                    {(order.vehicle || order.carrier) && " "}
                                    {order.vehicle ?
                                        new Vehicle(order.vehicle).label() :
                                        order.carrier && new Carrier(order.carrier).label()}
                                </td>
                                <td>
                                    {order.price_carrier} {order.carrier_currency?.code}
                                </td>
                                <td className={order.margin > 0 ? "" : "text-danger"}>
                                    {Math.floor(order.margin * 100) / 100}
                                </td>
                                <td>{order.user && new User(order.user).label()}</td>
                            </tr>)}
                        </tbody>
                    </Table>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Storno
                </Button>
                <ButtonGroup>
                    <Button disabled={!selectedOrder.id} variant="primary" onClick={() => orderSelected()}>
                        Vybrat
                    </Button>
                    {props.extraActions?.length > 0 && <DropdownButton size="sm" disabled={!selectedOrder.id} as={ButtonGroup} variant="outline-primary" title="">
                        {props.extraActions.map(action =>
                            <Dropdown.Item
                                key={action[0]}
                                onClick={() => orderSelected(action[0])}
                            >
                                {action[1]}
                            </Dropdown.Item>
                        )}
                    </DropdownButton>}
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    </>
}