import React from 'react'
import { Col } from 'react-bootstrap';
import '../css/MessageCenter.css'
import Message from './Message';

class MessageCenter extends React.Component {
    static instance;
    constructor(props) {
        super(props)
        this.state = { messages: [] }
        MessageCenter.instance = this
    }

    static addMessage(message) {
        if (typeof (message) === 'string') {
            message = { text: message }
        }
        message.stack = new Error().stack
        console.error(message)
        this.instance.state.messages.unshift(message)
        this.instance.forceUpdate()
    }

    static removeMessage(index) {
        this.instance.state.messages.splice(index, 1)
        this.instance.forceUpdate()
    }

    render() {
        return (
            <Col className="position-fixed" style={{right: 0, width: "400px"}}id="message-center">
                {Object.entries(this.state.messages).map(item => {
                    return (<Message key={item[0]}
                        title={item[1].title}
                        text={item[1].text}
                        onClose={() => { MessageCenter.removeMessage(item[0]) }}
                    />)
                })}
            </Col>
        );
    }
}

export default MessageCenter;