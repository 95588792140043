import { useEffect, useState } from "react"
import LoadingBanner from "./LoadingBanner";
import { getOptions } from "../Classes/Base";
import { Alert, Form, FormGroup } from "react-bootstrap";


export function NewObjectForm(props) {
    const {klass, objectValues, onStateChange} = props
    const [klassOptions, setKlassOptions] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);

    function valueChanged(name, value) {
        if (!onStateChange) {
            console.warn("No callback function for storing the values!")
            return
        }
        onStateChange({
            ...objectValues,
            [name]: value
        })
    }

    useEffect(() => {
        setLoading("Načítání informace potřebných pro vytvoření záznamů")
        getOptions(klass)
            .then(options => 
                setKlassOptions( 
                    !props.fields ?
                        Object.entries(options.actions.POST).filter(i => i[1].required) :
                        Object.entries(options.actions.POST).filter(i => props.fields.indexOf(i[0]) > -1)
                )
            )
            .catch(error => {
                console.error(error)
                setError("To je nepříjemné, nastala chyba. Zkuste to znovu, či kontaktujte podporu")
            })
            .finally(() => setLoading(false))
    }, [klass, props.fields])

    if (!objectValues) {
        return "Chybí objekt pro ukládání informací o objektu"
    }

    if (!klass) {
        return "Bohužel chybí informace o tom jaká třída má být sestavena."
    }

    if (loading) {
        const loading_props = {}
        if (typeof loading === 'string') {
            loading_props['label'] = loading
        }
        return <LoadingBanner {...loading_props}></LoadingBanner>
    }

    if (error) {
        return <Alert variant="danger">
            {error}
        </Alert>
    }

    return <div>
        {klassOptions.map(option => {
            const [fieldName, field] = option;
            let fieldElement = <div>Typ {field.type} není možné zobrazit.</div>
            if (field.type === 'string' || field.type === 'text' || field.type === 'email') {
                fieldElement = <Form.Control
                    value={objectValues[fieldName] || ""}
                    onChange={e => valueChanged(fieldName, e.target.value)}
                    type={field.type === 'email' ? 'email' : 'text'}
                    />
            }
            if (field.type === 'boolean') {
                fieldElement = <Form.Check
                    checked={objectValues[fieldName] || ""}
                    onChange={e => valueChanged(fieldName, e.target.checked)}
                    type='checkbox'
                    />
            }
            
            return <Form.Group controlId={fieldName} key={fieldName}>
                <Form.Label>{field.label}</Form.Label>
                {fieldElement}
            </Form.Group>
        })}
    </div>
}