import React from "react"
import { withRouter } from "react-router-dom";
import ModelView from './ModelView';
import CompanyIdentity from "../Classes/CompanyIdentity";

class CompanyIdentityView extends ModelView {
    modalNewLabel = "Vytvořit novou"
    modalNewTitle = "Vytvořit novou identitu"

    constructor(props) {
        super(CompanyIdentity, props)
    }

    help = <div>
        <p>Zde máte možnost vytvořit si další firemní identity pro použití například v obejdnávkách.</p>
    </div>
}

export default withRouter(CompanyIdentityView);