import React, { Component } from 'react'
import {Link, withRouter} from 'react-router-dom'
import Queryset from "../Classes/Queryset"
import Vehicle from "../Classes/Vehicle"
import { Alert, Jumbotron, Row, Col, Container, Spinner, Form, Button } from 'react-bootstrap'
import { getOptions } from "../Classes/Base"
import LoadingBanner from '../Components/LoadingBanner'
import FormField from '../Components/FormField'
import { OrderFilterWidget } from '../Components/OrderFilterWidget'
import ModelSelector from '../Components/ModelSelector'
import Carrier from '../Classes/Carrier'
import SearchModal from '../Components/SearchModal'
import { Globe, Trash } from 'react-bootstrap-icons'
import { NotesWidget } from '../Components/NotesWidget'

const SAVE_DELAY = 700

class VehicleDetail extends Component {
    state = {
        queryset: new Queryset(Vehicle),
        options : undefined,
        updater: undefined,
    }

    async componentDidMount() {
        const load_object = this.state.queryset.one(this.props.match.params.id)
        const options = await getOptions(Vehicle)
        await load_object
        this.setState({options})
    }

    async valueChanged(value, name) {
        this.state.queryset.object[name] = value
        this.forceUpdate()
        this.handleSave()
    }

    async handleSave() {
        if (this.state.updater) {
            clearTimeout(this.state.updater)
        }
        this.setState({
            updater: setTimeout(async () => {
                await this.state.queryset.object.save()
                this.setState({updater: undefined})
            }, SAVE_DELAY)
        })
    }

    render() {
        return <Container>{!this.state.queryset.object ? <LoadingBanner /> : <>
            <Jumbotron>
                <h1>Vozidlo</h1>
                <h2>{this.state.queryset.object.spz} <small className="text-secondary">#{this.state.queryset.object.id}</small></h2>
            </Jumbotron>
            <Row>
                <Col md="8">
                <FormField id="spz" label="SPZ" placeholder="Poznávací značka"
                    value={this.state.queryset.object.spz} onChange={e => {this.valueChanged(e.target.value.toUpperCase(), "spz")}} />
                <FormField id="type" label="Typ vozidla" placeholder="Typ vozidla"
                    value={this.state.queryset.object.type || ""} onChange={e => {this.valueChanged(e.target.value, "type")}} />
                <FormField id="load_capacity" label="Nosnost (kg)" placeholder="Nosnost (kg)" type="number"
                    value={this.state.queryset.object.load_capacity} onChange={e => {this.valueChanged(e.target.value, "load_capacity")}} />
                <Row>
                    <Col lg="4">
                        <FormField label="Šířka" type="number" value={this.state.queryset.object.width} onChange={e => {this.valueChanged(e.target.value, "width")}}/>
                    </Col>
                    <Col lg="4">
                        <FormField label="Výška" type="number" value={this.state.queryset.object.height} onChange={e => {this.valueChanged(e.target.value, "height")}}/>
                    </Col>
                    <Col lg="4">
                        <FormField label="Délka" type="number" value={this.state.queryset.object.length} onChange={e => {this.valueChanged(e.target.value, "length")}}/>
                    </Col>
                </Row>
                <FormField value={this.state.queryset.object.description} id="description" label="Poznámky k vozu" as="textarea" onChange={e => {this.valueChanged(e.target.value, "description")}}/>
                {this.state.updater && <div className="d-flex">
                    <Alert variant="secondary" className="ml-auto p-1">
                    <Spinner animation="border" role="status" size="sm" className="mr-3">
                        <span className="sr-only">Loading...</span>
                    </Spinner> Ukládá se...
                    </Alert>
                </div>}
                </Col>
                <Col>
                    <h3>Dopravce</h3>
                    <div className='border rounded px-3 py-1 mb-2'>
                        <Form.Group>
                            <div>
                                { !this.state.queryset.object.carrier ? 
                                    <SearchModal label="Vybrat dopravce" variant="outline-primary" klass={Carrier} fields={['ID', 'name']} 
                                        closeCallback={entry => {this.valueChanged(entry, "carrier")}} /> :
                                    <div className="d-flex justify-content-between">
                                        <Link to={`/carrier/${this.state.queryset.object.carrier.id}`}>
                                            <Globe /> {new Carrier(this.state.queryset.object.carrier).label()}
                                        </Link>
                                        <Button variant="danger" size="sm"
                                            onClick={() => {
                                                if (window.confirm("Chcete odebrat dopravce?")) {
                                                    this.valueChanged(null, "carrier")
                                                }
                                            }}>
                                            <Trash />
                                        </Button>
                                    </div>}
                            </div>
                        </Form.Group>
                    </div>
                    <h3 className='mb-0'>Poznámky k vozidlu</h3>
                    <div className="text-muted"><small>Při vyplnění data se poznámka zobrazí v přehledu vozidel.</small></div>
                    <NotesWidget relates_to_type='vehicle' relates_to_id={this.state.queryset.object.id} />
                    <h3>Objednávky</h3>
                    <OrderFilterWidget filter={{vehicle: this.state.queryset.object.id}}/>
                </Col>
            </Row>
        </>}
        </Container>
    }
}

export default withRouter(VehicleDetail)
