import React, { useContext, useEffect, useState } from 'react'
import { Container, Form, Button, Row, Col, Spinner, Alert } from 'react-bootstrap'
import Account from '../Classes/Account'
import { withRouter } from 'react-router-dom'
import { ConfigContext, usePersistStorage } from '../Context'
import settings from '../Settings'
import MessageCenter from '../Components/MessageCenter'

const MESSAGES = {
    "yield": 'Pro práci se systémem se prosím přihlaste!',
    "logout": 'Byli jste bezpečně odhlášeni'
}

function LoginView(props) {
    const [loading, setLoading] = useState(false)
    const config = useContext(ConfigContext)
    const [_, setConfig] = usePersistStorage('config', undefined);
    const message = props.message && MESSAGES[props.message]
    
    useEffect(() => {
        fetch(settings.BASE_API_URL + "service/branding").then(response => {
            if (response.status != 200 ) {
                throw new Error("Neočekávaná odpověď serveru")
            }
            return response.json()
        }).then(newConfig => {
            if (!Object.entries(newConfig).map(i => config[i[0]] === i[1]).every(i => i === true)) {
                setConfig(newConfig);
            }
        }).catch(err => {
            console.error(err)
            MessageCenter.addMessage({
                "title": "Nepodařilo se načíst informace o nastavení",
                "text": "Při používání aplikace mohou nastat problémy"
            })
        })
    }, [config])

    async function onSubmit(event) {
        event.preventDefault()
        const inputs = event.target.querySelectorAll("input")
        const username = inputs[0].value
        const password = inputs[1].value

        setLoading(true)

        let login_result = false
        try {
            login_result = await Account.authenticate(username, password)
        }
        catch {
            console.error("Error on authentication")
        }
        finally {
            setLoading(false)
        }
        if (login_result) window.location.href = "/";
    }


    return (
        <Container>
            <Row>
                <Col md={4} sm={6} className="mx-auto">
                    <img src={settings.BASE_URL + config._MEDIA_URL + config.company__logo} style={{
                        maxWidth: "100%",
                        maxHeight: "200px",
                        display: 'block',
                        margin: '10px auto'
                    }} />
                    {message && <Alert variant="success">{message}</Alert>}
                    <Form onSubmit={event => { onSubmit(event) }}>
                        <Form.Group controlId="username">
                            <Form.Label>Uživatelské jméno</Form.Label>
                            <Form.Control type="text" placeholder="Uživatelské jméno" required />
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.Label>Heslo</Form.Label>
                            <Form.Control type="password" placeholder="Heslo" required />
                        </Form.Group>
                        <Form.Group>
                            <Button variant="primary" type="submit">
                                Přihlásit se
                            </Button>
                            {loading &&
                                <Spinner as="span" size="sm" className="ml-3" animation="border" role="status">
                                    <span className="sr-only">Načítání...</span>
                                </Spinner>}
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default withRouter(LoginView);