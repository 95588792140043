import { useCallback, useEffect, useState } from "react"
import { Alert, Container, Row, Col, ListGroup, ListGroupItem, Form, Button, Dropdown, Spinner, InputGroup } from "react-bootstrap"
import { Check, Download, Link, Pencil, PersonCircle, Save2Fill, Search, Trash, X } from "react-bootstrap-icons"
import { useRouteMatch } from "react-router-dom"
import Attachment from "../Classes/Attachment"
import Customer from "../Classes/Customer"
import Invoice from "../Classes/Invoice"
import Queryset from "../Classes/Queryset"
import User from "../Classes/User"
import LoadingBanner from "../Components/LoadingBanner"
import MessageCenter from "../Components/MessageCenter"
import { OrderListGroup } from "../Components/OrderListGroup"
import { dateToInputValue } from "../helpers"
import settings from "../Settings"
import SearchModal from "../Components/SearchModal"
import fetchProxy from "../Helpers/fetchProxy"
import Payment from "../Classes/Payment"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

function PricingInfo(props) {
    if (!Array.isArray(props?.orders)) {
        return "Nepodařilo se spočítat hodnotu"
    }

    let price_carrier = 0;
    let price_customer = 0;
    let currency;

    props.orders.forEach(order => {
        price_carrier += order.price_carrier
        price_customer += order.price_customer + order.surcharge_customer
        currency = order?.customer_currency
    })

    const margin = price_customer - price_carrier;
    return <div>
        <strong>Hodnota objednávek {currency && `(v ${currency})`}: </strong>
        <strong label={margin <= 0 ? "Bez marže!" : undefined} className={margin <= 0 ? "text-danger" : undefined}>{margin}</strong>
        {<small className="ml-1">
            <span title="Cena zákazník">{price_customer}</span> - <span title="Náklady na dopravce">{price_carrier}</span>
        </small>}
    </div>
}

export function InvoiceDetail() {
    const invoiceId = useRouteMatch().params.id
    const history = useHistory();
    const [invoice, setInvoice] = useState()
    const [attachments, setAttachments] = useState(undefined)
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [changed, setChanged] = useState(new Set())
    const [editConnection, setEditConnection] = useState(false)
    const [externalConnectionEdit, setExternalConnectionEdit] = useState({})

    async function save() {
        setSaving(true)
        const data = Object.fromEntries(
            Object.entries(invoice).filter(e => changed.has(e[0]))
        )
        data["id"] = invoice.id
        try {
            const res = await invoice.save(data)
            if (invoice.__status !== 200) {
                console.error(`Neodpovídá odpověď serveru! ${invoice.__status}`, res)
                throw Error()
            }
            setChanged(new Set())
        }
        catch (e) {
            MessageCenter.addMessage({
                "title": "Selhalo uložení!",
                "text": "Uložení selhalo. Zkuste to znovu, nebo kontaktujte podporu"
            })
            console.error(e)
        }
        finally {
            setSaving(false)
        }
    }

    const setExternalConnectionFromInvoice = useCallback(invoice => {
        setExternalConnectionEdit({
            external_connector: invoice.external_connector,
            external_id: invoice.external_id  || "",
        })
    }, [setExternalConnectionEdit])

    const loadInvoiceById = useCallback(invoiceId => {
        let queryset = new Queryset(Invoice)
        setLoading(true)
        queryset.one(invoiceId).then(() => {
            if (queryset.status === 200) {
                setInvoice(queryset.object)
                setExternalConnectionFromInvoice(queryset.object)
                const orderIds = queryset.object.orders.map(i => i.id)
                Attachment.by_orders(orderIds).then(attachments => {
                    setAttachments(attachments)
                })

            }
            else {
                setError("Zdá se, že objekt neexistuje, nebo se něco pokazilo. Zkuste to znovu.")
            }
        }).catch(e => {
            MessageCenter.addMessage({ "title": "Něco se pokazilo", "text": "Bohužel načítání faktury se nepodařilo, zkuste to znovu, nebo kontaktujte podporu." })
            setError("Chyba načítání objednávky")
            console.error(e)
        }).finally(() => {
            setLoading(false)
        })
    }, [setExternalConnectionFromInvoice])

    useEffect(() => {
        loadInvoiceById(invoiceId)
    }, [invoiceId, loadInvoiceById])
    if (loading || error || invoice === undefined) return <Container>
        {(loading || invoice === undefined) && <LoadingBanner />}
        {error && <Alert variant="danger">
            {error}
        </Alert>}
    </Container>

    function valueChanged(key, value) {
        const newChanged = new Set(changed)
        newChanged.add(key)
        setChanged(newChanged)
        setInvoice(new Invoice({
            ...invoice,
            [key]: value
        }))
    }

    async function externalConnectionButtonClicked() {
        if (editConnection) {
            setSaving(true)
            try {
                await invoice.save({
                    id: invoice.id,
                    ...externalConnectionEdit
                })
                if (invoice.__status !== 200) throw new Error("Neočekávaná odpověď serveru. ")
            }
            catch {
                MessageCenter.addMessage({
                    "title": "Nepodařilo se uložit změnu",
                    "text": "Selhala změna externího propojení, zkuste to znovu, nebo kontaktujte podporu"
                })
            }
            finally {
                setSaving(false)
            }
            
        }
        setEditConnection(!editConnection)
    }

    async function onPaidForClicked() {
        if (!window.confirm("Chcete označit fakturu za splacenou?")) return;
        let payment_request = new Payment({ invoice: invoice.id, id: 0 })
        let payment = await payment_request.save()
        if (payment_request.__status !== 201) {
            MessageCenter.addMessage({
                title: "Nepodařilo se uložit informace o splatnosti",
                text: `Neočekávaná odpověď serveru: ${payment_request.__status}. Zkuste to později, nebo kontaktujte podporu`
            })
            return;
        }
        setInvoice(new Invoice({
            ...invoice,
            payment: payment
        }))
    }

    async function deleteInvoice() {
        let message = ["Opravdu chcete smazat tuto fakturu? Operaci nelze vrátit!"];
        if (invoice.external_connector || invoice.external_id) {
            message.push(`POZOR! Faktura obsahuje externí vazbu - ${invoice.external_connector}/${invoice.external_id}. Jste si jistí? \nNezapomeňte smazat provázanou fakturu i v externím zdroji.`)
        }
        if (!window.confirm(message.join("\n\n"))) {
            return;
        }
        if (await invoice.delete()) {
            history.push("/invoice")
        }
    }

    async function defaultNotesText() {
        if (invoice.notes !== "" && !window.confirm("Chcete přepsat poznámku?")) return;
        const req = await fetchProxy(settings.BASE_API_URL + "service/preferences/labeling__phooda_invoice_text/")
        if (req.status !== 200) {
            MessageCenter.addMessage("Nepodařilo se načíst výchozí text objednávky. Zkuste to později znovu")
        }
        valueChanged("notes", await req.json().value)
    }

    return <Container className="mt-2">
        <div className="d-flex align-items-center">
            <h2 className="mb-0">Faktura #{invoiceId}</h2>
            <Button size="sm" variant="danger" className="ml-auto mr-1" onClick={deleteInvoice}>
                <Trash /> Smazat
            </Button>
            <Dropdown size="sm" className="mr-1">
                <Dropdown.Toggle size="sm" variant="outline-primary" id="exports">
                    <Download /> Exportovat
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item size="sm" onClick={() => {
                        invoice.to_pohoda();
                        loadInvoiceById(invoice.id)
                    }}>
                        <small>{Invoice.icon_as_component()} Faktura - Pohoda XML</small>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Button size="sm" className="d-flex align-items-center" onClick={save} disabled={changed.size === 0}>
                {!saving ?
                    <Save2Fill /> :
                    <Spinner animation="border" size="sm" />}
                <span className="ml-1">Uložit změny</span>
            </Button>
        </div>
        <Row>
            <Col md={8}>
                <div className="border-bottom mb-2 d-flex align-items-center">
                    <div className="d-flex align-items-center">
                        { invoice.customer ? <a href={`/customer/${invoice.customer.id}`}>
                            {Customer.icon_as_component()} {new Customer(invoice.customer).label()}
                        </a> : <SearchModal variant="link" closeCallback={customer => valueChanged("customer", customer)} klass={Customer} label="Vybrat zákazníka" createNew />}
                    </div>
                    <div className="ml-auto"><strong>Vytvořeno</strong> {new Date(invoice.created).toLocaleString()}</div>
                    {invoice.created_by && <div><PersonCircle />{new User(invoice.created_by).label()}</div>}
                </div>
                <div className="text-right border-bottom mb-2">
                    {Array.isArray(invoice?.orders) && <PricingInfo orders={invoice.orders} />}
                </div>
                <Row>
                    <Col>
                        <Form.Group controlId="due_date">
                            <Form.Label>Splatnost</Form.Label>
                            <Form.Control isInvalid={invoice.isDue()} type="date" as="input" value={invoice.due_date ? dateToInputValue(invoice.due_date) : ""} onChange={e => valueChanged("due_date", e.target.value)} />
                            {invoice?.customer && <Form.Text className='text-muted'>Splatnost uvedená u zákazníka je ({invoice.customer?.maturity})</Form.Text>}
                            <Form.Control.Feedback type="invalid">Faktura je po splatnosti!</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="pay_date">
                            <Form.Label>Informace o zaplacení</Form.Label>
                            <div>
                                {invoice.payment ? <>
                                    Rozpoznáno: {invoice.payment.detection_type}<br />
                                    {new Date(invoice.payment.paid_on).toLocaleString()}
                                </> : <>
                                    <Button size="sm" variant="link" onClick={onPaidForClicked}>Označit za splacenou</Button>
                                    <Form.Text className="text-muted">Informace o zaplacení umí systém vyčíst automaticky z externích zdrojů</Form.Text>
                                </>}
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Group controlId="customer_order">
                    <Form.Label>Číslo objednávky u zákazníka</Form.Label>
                    <Form.Control as="input" value={invoice.customer_order} onChange={e => valueChanged("customer_order", e.target.value)} />
                    <Form.Text className='text-muted'>Číslo objednávky u zákazníka. Můžete klepnout na hodnotu z objednávek pro její vybrání.</Form.Text>
                </Form.Group>
                <Form.Group controlId='notes'>
                    <Form.Label>Poznámka na faktuře</Form.Label>
                    <Form.Control rows={8} as="textarea" value={invoice.notes} onChange={e => valueChanged("notes", e.target.value)} />
                    <Form.Text>Text, který se objeví na faktuře při exportu do Pohody, nebo PDF. Můžete <a style={{ cursor: 'pointer' }} className='text-primary' onClick={defaultNotesText}>vložit výchozí text</a></Form.Text>
                </Form.Group>
            </Col>
            <Col md={4}>
                <div className="border rounded py-2 px-3 mb-2">
                    <div className="d-flex justify-content-between align-items-center">
                        <strong>Externí vazba: </strong>
                        <div>
                        {editConnection && <Button size="sm" variant="outline-danger"
                                onClick={() => {setEditConnection(false); setExternalConnectionFromInvoice(invoice)}}>
                                <X />
                            </Button>}
                        {saving ? <Spinner size="sm"/> : <Button
                            onClick={() => externalConnectionButtonClicked()}
                            variant={`${editConnection ? "outline-success" : "outline-primary"}`} 
                            disabled={editConnection && (!externalConnectionEdit.external_connector || !externalConnectionEdit.external_id)}
                            size="sm">
                            {editConnection ? <Check /> : <Pencil />}
                        </Button>}
                        </div>
                    </div>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <Form.Control as="select" value={externalConnectionEdit.external_connector} disabled={!editConnection}
                                onChange={e => setExternalConnectionEdit({...externalConnectionEdit, external_connector: e.target.value})}
                            >
                                <option value="">chybí</option>
                                <option value="pohoda">Pohoda</option>
                            </Form.Control>
                        </InputGroup.Prepend>
                        <Form.Control type="text" placeholder="označení v externím systému" readOnly={!editConnection || !externalConnectionEdit.external_connector}
                            value={externalConnectionEdit.external_id}
                            onChange={e => setExternalConnectionEdit({...externalConnectionEdit, external_id: e.target.value})}
                        />
                    </InputGroup>
                    <Form.Text>Zadání vazby umožňuje při importu z externích systémů lepší párování s již existujícím záznamy. </Form.Text>
                    {/*invoice.external_id ? <span className="text-monospace">{invoice.external_connector}/{invoice.external_id}</span> : <em>Chybí</em>*/}
                </div>
                <div className="border rounded py-2 px-3 mb-2 d-flex justify-content-between align-items-center">
                    <div>
                        <strong>Vyřizuje: </strong>
                        {invoice.owner ? new User(invoice.owner).label() : <em>Nemá přiřazeného vlastníka</em>}
                    </div>
                    <SearchModal klass={User} label={<Search />} size="sm" closeCallback={owner => valueChanged("owner", owner)}/>
                </div>
                <h3>Objednávky</h3>
                <OrderListGroup orders={invoice.orders} displaySum={true} enableHref />
                <h3 className="mt-2">Přílohy objednávek</h3>
                {attachments && <ListGroup>
                    {attachments.length === 0 && <ListGroupItem><em>Neexistují žádné přílohy</em></ListGroupItem>}
                    {attachments.map(a => <ListGroupItem className="overflow-hidden" key={a.id}>
                        <a href={settings.BASE_URL + a.file}>{decodeURIComponent(a.file.split("/").at(-1))}</a>
                    </ListGroupItem>)}
                </ListGroup>}
            </Col>
        </Row>
    </Container>
}