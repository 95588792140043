import React from 'react'
import { withRouter } from "react-router-dom";
import { Jumbotron, Row, Container, Col, Form, Button, Alert } from 'react-bootstrap';
import Account from '../Classes/Account';
import MessageCenter from '../Components/MessageCenter'
import Support from '../Classes/Support'


class SupportMailView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: '',
      text: '',
      copy_to_sender: 'true',
      warning:''
    };
    this.handleChangeSubject = this.handleChangeSubject.bind(this);
    this.handleChangeText = this.handleChangeText.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  saveChanges = (event) => {
    /*validator*/ 
    const data = {
        'subject': this.state.subject,
        'body': this.state.text,
        'copy_to_sender' : this.state.copy_to_sender
    }
    if(!data.subject){
      this.setState({warning:"Vyplňte předmět"})
      return
    }
    if(!data.body){
      this.setState({warning:"Vyplňte zprávu"})
      return
    }
    event.preventDefault();
    this.submitOrderEmail(data)
  }

  async submitOrderEmail(data) {
    try {
        if (!await Support.send_email(data)) {
            throw Error("Server returned an error")
        } else {
          MessageCenter.addMessage(
            {
              title: 'Zpráva odeslána',
              text:'Děkujeme za váš podnět'
            }
          )
          this.setState({
            subject:"",
            text:"",
            warning: ""
          })    
        }
    } catch {
        MessageCenter.addMessage({
          title: 'Chyba při odesílání zprávy!',
          text:'Bohužel se někde něco rozbilo, zkuste to znovu a případně kontaktujte podporu!',
          detail: 'Data použitá k uložení: \n' + JSON.stringify(data)
        })    
    }
  }


  handleChangeSubject(value) {    
    this.setState({'subject': value});  
  }

  handleChangeText(value) {    
    this.setState({'text': value});  

  }
  handleCheckbox(value) {    
    this.setState({'copy_to_sender': value});  
  }


  render() {
    return ( 
      <Container>
            <Row className="mt-3">         
                <Col sm="12">
                    <Jumbotron>
                    <h2>Podpora</h2>
                        <p>Napište zde vaší zprávu pro podporu.</p>
                        <p>Pro snadné vyřešení problému uveďte, kde jste se nacházeli, co jste dělali, co se mělo stát a co se stalo místo toho. Naše podpora Vás bude kontaktovat co nejdříve a společně problém vyřešíme. </p>
                    </Jumbotron>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col sm={{ span: 6, offset: 3}}>
                    <Form.Group controlId="subjectForm">
                        <Form.Label>Předmět </Form.Label>
                        <Form.Control type="text" placeholder="Předmět" value={this.state.subject} onChange={(event) => this.handleChangeSubject(event.target.value)}/>
                        <Form.Text className="text-muted">
                    </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="mailForm">
                      <Form.Label>Text zprávy</Form.Label>
                      <Form.Control as="textarea" rows={3} value={this.state.text} onChange={(event) => this.handleChangeText(event.target.value)} />
                    </Form.Group>
                    { Account.current().email ? <Form.Check
                                      type="checkbox" 
                                      label={"Poslat kopii na " + Account.current().email}
                                      checked={this.state.copy_to_sender} onChange={(ev) => this.handleCheckbox(ev.target.checked)} /> 
                                  : "" }
                    <Button variant="primary" type="button" onClick={(event) => this.saveChanges(event)}>
                      Odeslat
                    </Button>
                    {this.state.warning && <Alert variant="warning"> {this.state.warning} </Alert>}
              </Col>
            </Row>
      </Container>
    )
  } 
}

export default withRouter(SupportMailView);
