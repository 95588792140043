import React from 'react'
import { NavDropdown } from 'react-bootstrap'

class Menu {
    constructor(label, objects, className) {
        this.type = "menu"
        this.label = label
        this.objects = objects
        this.className = className === undefined ? "ml-auto" : ""
    }

    toReactComponent(menu_id=0) {
        return <NavDropdown key={this.type + " " + menu_id} active title={this.label} className={this.className}>
            {this.objects && this.objects.map(item => {
                    return item.asDropdownItem()
            })}
        </NavDropdown>
    }
}

export default Menu