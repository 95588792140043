import React from "react"
import { withRouter } from "react-router-dom";
import ModelView from './ModelView';
import EmailTemplate from '../Classes/EmailTemplate'

class EmailTemplateView extends ModelView {
    constructor(props) {
        super(EmailTemplate, props)
    }
    modalNewPlaceholder = "Text"
    modalNewLabel = "Nová šablona"
    modalNewTitle = "Přidávání nové šablony"
    jumbotronTitle = "E-mailové šablony"
    jumbotronSubtitle = "Správa e-mailových šablon"
    help = <div>
        <p>Emailové šablony se nabízí při odesílání e-mailů. V současné chvíli je to pouze při odesílání jednotlivých objednávek e-mailem, ale do budoucna</p>
        <p>Šablony vytváříte i pro cizí jazyky. Seznam dostupných jazyků záleží na konfiguraci aplikace</p>
        <p>Šablony podporují některé proměnné, vkládané přes speciální syntaxi - <code>${'{order.code}'}</code></p>
    </div>
    helpTopic = "emailtemplate"
}

export default withRouter(EmailTemplateView);