import Base from './Base'
import settings from '../Settings'
import { Person } from 'react-bootstrap-icons'
import fetchProxy from '../Helpers/fetchProxy'

class User extends Base{
    static base_url = settings.BASE_API_URL + "user/"
    static columns = [{
        name: "id",
        label: "#",
    },
    {
        name: "username",
        label: "Uživatelské jméno"
    },
    {
        name: "first_name",
        label: "Křestní jméno"
    },
    {
        name: "last_name",
        label: "Příjmení"
    }]

    label() {
        if (this.first_name || this.last_name) {
            return [this.first_name, this.last_name].join(" ").trim()
        }
        else {
            return this.username
        }
    }

    async terminateSessions() {
        const req = await fetchProxy(User.base_url + this.id + "/terminate_sessions/", {method: 'POST'})
        return req.status === 200
    }

    static icon() {
        return Person
    }
}

export default User