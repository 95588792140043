import Base from './Base'
import settings from '../Settings'
import fetchProxy from '../Helpers/fetchProxy'

class LogEntry extends Base{
    static base_url = settings.BASE_API_URL + "log/"

    static async views_actions() {
        const res = await fetchProxy(this.base_url + "log_options/", 
            {
                method: 'GET',
                mode: 'cors',
                redirect: 'follow'
            })
        if (res.status !== 200) {
            throw Error("The views_actions request threw an error")
        }
        else {
            return await res.json()
        }
    }
}

export default LogEntry