import { startTransition } from "react";
import { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Save, Save2Fill } from "react-bootstrap-icons";
import { useRouteMatch } from "react-router-dom";
import Label from "../Classes/Label";
import Queryset from "../Classes/Queryset";
import LoadingBanner from "../Components/LoadingBanner";
import MessageCenter from "../Components/MessageCenter";

export function LabelDetail(props) {
    const pk = useRouteMatch().params.id
    const [label, setLabel] = useState(undefined);
    const [override, setOverride] = useState({});
    useEffect(() => {
        MessageCenter.addMessage({
            title: "Pro uložení klepněte na ikonu diskety",
            text: "Měníme způsob práce s aplikací, chceme ztransparentnit ukládání tak, abyste vždy měli pod kontrolou výsledek"
        })
        const queryset = new Queryset(Label)
        queryset.one(pk).then(() => setLabel(queryset.object)).catch(
            e => {
                MessageCenter.addMessage({"title": "Nepodařilo se načíst štítek."})
                console.log(e)
            }
        )
    }, [pk])

    if (!label) {
        return <Container>
            <LoadingBanner />
        </Container>
    }

    function update(key, value) {
        setOverride({
            ...override,
            [key]: value
        })
    }

    async function saveChanges() {
        const to_be_saved = {
            ...override,
            id: pk
        }
        await label.save(to_be_saved)
        if (label.__status !== 200) {
            MessageCenter.addMessage({
                "title": "Chyba při ukládání",
                "text": "Nepovedlo se uložit změny. Zkuste to znovu, nebo kontaktujte podporu."
            })
        }
        Object.entries(override).forEach(item => label[item[0]] = item[1])
        setLabel(label)
        setOverride({})
    }

    function toColor(value) {
        if (value && !value.startsWith('#')) {
            return "#" + value
        }
        return value
    }

    return <Container style={{marginTop: "1em"}}>
        <Row>
            <Col sm={6}>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <span style={{
                                backgroundColor: toColor(override.color || label.color),
                                display: "inline-block",
                                width: "1rem",
                                height:"1rem",
                                marginRight: "1em",
                                borderRadius: "50%"
                            }} />
                        <h1 style={{margin: 0, padding: 0}}> Úprava štítku <span className="text-muted">#{label.id}</span></h1>
                    </div>
                    <Button variant="outline-success" disabled={Object.keys(override).length < 1} onClick={() => saveChanges()} size="sm">
                        <Save2Fill />
                    </Button>
                </div>
                <Form.Group>
                    <Form.Label>Jméno</Form.Label>
                    <Form.Control value={override.name || label.name} onChange={e => update("name", e.target.value)} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Barva</Form.Label>
                    <Form.Control value={toColor(override.color !== undefined ? override.color : label.color)} type="color" onChange={e => update("color", e.target.value)} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Blokující</Form.Label>
                    <Form.Check checked={override.blocking !== undefined ? override.blocking : label.blocking} type="checkbox" onChange={e => update("blocking", e.target.checked)} />
                </Form.Group>
            </Col>
            <Col>
                {/*<h2>Objednávky</h2>*/}
            </Col>
        </Row>
    </Container>
}