import React from "react"
import { withRouter } from "react-router-dom";
import ModelView from './ModelView';
import Vehicle from '../Classes/Vehicle'

class VehicleView extends ModelView {
    constructor(props) {
        super(Vehicle, props)
    }
    modalNewPlaceholder = "Poznávací značka"
    modalNewLabel = "Nové vozidlo"
    modalNewTitle = "Přidávání nového vozidla"
    jumbotronTitle = "Vozidla"
    jumbotronSubtitle = "Přehled vozidel, která máte k dispozici"
    newAttribute = "spz"

    help = <div>
        <p>Správa vozidel vám umožňuje definovat vozidla, které máte k dispozici. Vozidla můžete používat u objednávek místo toho, abyste zakázku předávali externímu dopravci</p>
    </div>
}

export default withRouter(VehicleView);