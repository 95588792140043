import React from 'react'
import { Nav, NavDropdown } from 'react-bootstrap'
import { Route } from 'react-router-dom'

class MenuItem {
    constructor(values) {
        this.type = "menuitem"
        this.path = values.path
        this.renders = values.renders
        this.label = values.label
        this.title = values.title !== undefined ? values.title : values.label
        this.detailView = values.detailView
    }

    toReactComponent() {
        return <Nav.Link active href={this.path} key={this.path}>{this.label}</Nav.Link>
    }

    toRoute() {
        return <Route key={this.path} path={this.path} render={() => this.renders} />
    }

    asDropdownItem() {
        return <NavDropdown.Item href={this.path} key={this.path}>{this.label}</NavDropdown.Item>
    }
}

export default MenuItem