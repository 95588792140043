import React from 'react'
import {Modal, Button, Form} from 'react-bootstrap';

class ModalNew extends React.Component {
    constructor(props) {
        super(props)
        this.fields = props.fields !== undefined ? props.fields : ['name']
        this.user_form = React.createRef()
        if (props.closeCallback !== undefined) {
            this.closeCallback = props.closeCallback
        }
        const state = {
            show: false,
        }
        for (let field in this.fields) {
            state[this.fields[field]] = ''
        }
        this.state = state
    }

    onChange = (event) => {
        this.setState({name: event.target.value})
    }

    setShow = (show) => {
        this.setState({show})
    }

    closeCallback = () => {
        // Default callback can be implemented here. 
    }

    handleShow = () => this.setShow(true)
    handleClose = () => {
        this.setShow(false)
        this.setState({name: ""})
    }

    saveChanges = () => {
        if (this.closeCallback !== null) {
            this.closeCallback()
        }
    }
  
    render() {
        return (
        <>
            <Button variant="success" onClick={this.handleShow}>{this.props.showText}</Button>
            <Modal show={this.state.show} onHide={this.handleClose}>    
            <Modal.Header closeButton>
                <Modal.Title>{this.props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form.Group controlId="form_last_name">
                    <Form.Label>{this.props.label}</Form.Label>
                    <Form.Control type="text" placeholder={this.props.placeholder} value={this.state.name} onChange={this.onChange}/>
            </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                Zavřít
                </Button>
                <Button variant="primary" onClick={this.saveChanges}>
                Uložit změny
                </Button>
            </Modal.Footer>
            </Modal>
        </>)
    }
  }
  
  export default ModalNew;