import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Queryset from "../Classes/Queryset"
import Order from "../Classes/Order"
import User from "../Classes/User"
import { Container, Jumbotron, Form, Col, Row, Button, Table } from 'react-bootstrap'
import Currencies from '../Classes/Currencies'
import { Alarm } from 'react-bootstrap-icons'

class ReportView extends Component {
    state = {
        orders: new Queryset(Order),
        users: new Queryset(User),
        currencies: new Currencies(),
        values: {
            "before": "",
            "after": "",
            "toggler": "",
        },
        checked: {}
    }

    checkboxTriggered(e) {
        const checked = this.state.checked;
        if (!checked[e.target.dataset.id]) {
            checked[e.target.dataset.id] = true;
        }
        else {
            delete checked[e.target.dataset.id];
        }
        this.setState(checked)
    }

    checkAll(e) {
        const checked = this.state.checked;
        this.state.orders.objects.forEach((item) => {
            if (e.target.checked) {
                checked[item.id] = true
            }
            else {
                delete checked[item.id]
            }
        })
        this.setState(checked)
        console.log(Object.keys(this.state.checked).length)
    }

    valueChanged(event) {
        const values = this.state.values
        values[event.target.id] = event.target.value
        this.setState({values})
    }
    async componentDidMount() {
        await this.state.currencies.all()
        await this.state.orders.loadOptions()
        await this.loadData()
        await this.state.users.all()
        this.forceUpdate()
    }

    async loadData() {
        //this.state.orders.filter
        await this.state.orders.filter(this.state.values)
        this.choices = {}
        this.state.orders.options.actions.POST.state.choices.forEach(item => {
            this.choices[item.value] = item.display_name
        })
        this.forceUpdate()
    }

    async userSelected(event) {
        if (parseInt(event.target.value) === -1) {
            delete this.state.values.user
        }
        else {
            this.state.values.user = event.target.value
        }
    }

    async exportData() {
        Order.bulkPohodaXMLUrl(Object.keys(this.state.checked))
    }

    render() {
        let due_payment = 0
        let sum = 0
        return (<Container>
            <Jumbotron>
                <h1>Přehled</h1>
            </Jumbotron>
            <div>
                <div>
                    <h2>Nastavení</h2>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="after" as={Row}>
                                    <Form.Label column sm="3" text-aling="right">Od</Form.Label>
                                    <Col sm="9">
                                        <Form.Control type="date" value={this.state.values.after} onChange={(event) => {this.valueChanged(event)}}/>
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="before" as={Row}>
                                    <Form.Label column sm="3" text-aling="right">Do</Form.Label>
                                    <Col sm="9">
                                        <Form.Control type="date" value={this.state.values.before} onChange={(event) => {this.valueChanged(event)}}/>
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Group controlId="user_filter"  as={Col}>
                                <Row>
                                    <Form.Label column sm="auto">
                                    Uživatel: 
                                    </Form.Label>
                                    <Col>
                                        <Form.Control as="select" custom onChange={(event) => this.userSelected(event)}>
                                            <option defaultValue={true} value="-1">Všichni</option>
                                            {this.state.users.objects.map((user) => {
                                                    return <option key={user.id} value={user.id}>{user.label()}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Col md="auto ml-auto">
                                <Button variant="success" className="mr-2" onClick={() => {this.loadData()}}>Vybrat</Button>
                                <Button disabled={Object.keys(this.state.checked).length === 0} onClick={() => {this.exportData()}}>Export do pohody</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
                {
                    this.state.orders.count < 0 ? "Provedťe hledání" : 
                        (this.state.orders.count === 0 ? "Nic nenalazeno" :
                        <div>
                            <Table striped bordered hover className="mt-3">
                                <thead>
                                    <tr>
                                        <th scope="col"><input type="checkbox" onChange={e => {this.checkAll(e)}} /></th>
                                        <th scope="col">Kód objednávky</th>
                                        <th scope="col">Vytvořeno</th>
                                        <th scope="col">Zákazník</th>
                                        <th scope="col">Dopravce</th>
                                        <th scope="col">Cena zákazníka</th>
                                        <th scope="col">Cena dopravce</th>
                                        <th scope="col">Zisk</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.orders.objects.map(item=>{
                                        const order = item
                                        if (order.isPaymentDue()) {
                                            due_payment++;
                                        }
                                        sum += this.state.currencies.convert(order.customer_currency, this.state.currencies.objects[0], order.margin)
                                        return (<tr key={order.id}>
                                            <td>
                                                <input type="checkbox" data-id={order.id} checked={this.state.checked[order.id] ? true : false} onChange={e => {this.checkboxTriggered(e)}} />
                                                {order.isPaymentDue() ? <Alarm className="ml-1"/> : ""}
                                            </td>
                                            <td>
                                                {order.code}    
                                            </td>
                                            <td>
                                                {(new Date(order.order_created)).toLocaleDateString()}
                                            </td>
                                            <td>
                                                {order.customer ? order.customer.name : "Není určen"}
                                            </td>
                                            <td>
                                                {order.carrier ? order.carrier.name : "Není určen"}
                                            </td>
                                            <td>
                                                {order.price_customer} {order.customer_currency.symbol}
                                            </td>
                                            <td>
                                                {order.price_carrier} {order.carrier_currency.symbol}
                                            </td>
                                            <td>
                                                {Math.round((order.margin + Number.EPSILON) * 100) / 100} {order.customer_currency.code}
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                            Celkově nalezeno: {this.state.orders.count} Po splatnosti: {due_payment}
                            <p>
                                Marže: {this.state.currencies.objects.map((item) => {
                                    const margin = this.state.currencies.convert(this.state.currencies.objects[0], item, sum)
                                    return <span key={item.code}>{Math.round((margin + Number.EPSILON) * 100) / 100} {item.code},- </span>
                                })}
                            </p>
                        </div>)
                }
            </div>
        </Container>)
    }
}

export default withRouter(ReportView)
