import React from 'react'
import {Form} from 'react-bootstrap';

class UserForm extends React.Component {
    
    constructor(props) {
        super(props)
        const user = props.user
        user.operatorprofile = user.operatorprofile  || {phone_number: ""}
        this.state = {user}
    }

    state = {
        user: {id: 0, operatorprofile:{phone_number:''}}
    }

    getUser = () => {
        return this.state.user;
    }

    handleChange = (event) => {
        let user = this.state.user
        if (event.target.dataset.name === 'phone_number') {
            user['operatorprofile']['phone_number'] = event.target.value
        }
        else if (event.target.dataset.name === 'is_superuser') {
            user.is_superuser = !this.state.user.is_superuser
        }
        else {
            user[event.target.dataset.name] = event.target.value
        }
        this.setState({user})
    }

    render() { 
        return (
            <Form>
                <input name="id" type="hidden" value={this.state.user.id}></input>
                <Form.Group controlId="form_username">
                    <Form.Label className="f"><strong>* Uživatelské jméno</strong></Form.Label>
                    <Form.Control type="text" placeholder="Uživatelské jméno" value={this.state.user.username} data-name="username" onChange={this.handleChange} readOnly={!this.props.new}/>
                    <Form.Text className="text-muted">
                        {this.props.new ? <><strong>Vyžadováno</strong> Uživatelské jméno může obsahovat pouze písmena bez háčků a čárek a znaky a _, @, +, . nebo -</> : "Uživatelské jméno není možné měnit"}
                    </Form.Text>
                </Form.Group>
                <Form.Group controlId="form_first_name">
                    <Form.Label>Křestní jméno</Form.Label>
                    <Form.Control type="text" placeholder="Zadejte křestní jméno" value={this.state.user.first_name} data-name="first_name" onChange={this.handleChange}/>
                </Form.Group>
                <Form.Group controlId="form_last_name">
                    <Form.Label>Příjmení</Form.Label>
                    <Form.Control type="text" placeholder="Zadejte příjmení" value={this.state.user.last_name} data-name="last_name" onChange={this.handleChange}/>
                </Form.Group>
                <Form.Group controlId="form_email">
                    <Form.Label>E-mailová adresa</Form.Label>
                    <Form.Control type="email" placeholder="Vložte e-mail" value={this.state.user.email} data-name="email" onChange={this.handleChange}/>
                    <Form.Text className="text-muted">
                    E-mail se používá při ztrátě hesla a na fakturách.
                    </Form.Text>
                </Form.Group>
                <Form.Group controlId="form_last_name">
                    <Form.Label>Telefonní číslo</Form.Label>
                    <Form.Control type="text" maxLength={15} placeholder="Zadejte telefonní číslo" value={this.state.user.operatorprofile.phone_number} data-name="phone_number" onChange={this.handleChange}/>
                </Form.Group>
                <Form.Group controlId="form_is_superuser">
                    <Form.Check type="checkbox" label="Správcovská oprávnění" checked={!!this.state.user.is_superuser} data-name="is_superuser" onChange={this.handleChange}/>
                    <Form.Text className="text-muted">
                        Uživatel má přístup ke všem komponentám systému, může měnit všechny objednávky a vytvářet nové uživatele.
                    </Form.Text>
                </Form.Group>
            </Form>
        )
    }
  }
  
  export default UserForm;