import Base from './Base'
import settings from '../Settings'
import fetchProxy from '../Helpers/fetchProxy'


class IcoAutocomplete extends Base{
    static async ico_autocomplete(icopacket) {
        const res = await fetchProxy(settings.BASE_API_URL + "service/ico/ico_autocomplete", /*TODO: this is ugly*/
            {
                method: 'POST',
                mode: 'cors',
                redirect: 'follow',
                body: JSON.stringify(icopacket),
                credentials: 'include'
            })
        if (res.status !== 200) {
            console.error(`Error sending the message! HTTP RES: ${res.status}\n`, await res.json())
            return false
        }
        else {
            return true
        }
    }
}

export default IcoAutocomplete