import { withRouter } from "react-router-dom";
import React from 'react'
import Currencies from '../Classes/Currencies'
import { Jumbotron, Container } from "react-bootstrap";
import BankingAccountSelector from "../Components/BankingAccountSelector";
import moment from "moment"
import { HelpComponent } from "../Components/HelpComponent";


class CurrencyView extends React.Component {

    state = {
        currencies : new Currencies()
    }

    async componentDidMount() {
        await this.state.currencies.all()
        this.forceUpdate()
    }


    async bankingAccountUpdated(/*account, index*/) {
        window.location.reload()
    }

    render() {
        return(
            <Container>
                <Jumbotron>
                    <h1>Přehled měn</h1>
                    <HelpComponent helpTopic="currencies">
                        <p>
                            Tady vidíte kurzovní lístek, včetně poslední aktualizace.
                            Aktualizace jsou automatické, pokud se Vám zdá, že kurz neodpovídá, nebo je zastaralý, kontaktujte podporu
                        </p>
                        <p>
                            Měnám přiřadťe bankovní účty, při exportu se pokusíme je použít a Váš učetní software se bude snažit je propojit s fakturou.
                        </p>
                    </HelpComponent>
                </Jumbotron> 
            { 
                this.state.currencies.count < 0 ? "Načítání měn" :  <table className="table">
                <thead>
                <tr>
                    <th>Měna</th>
                    <th>Kurz</th>
                    <th>Účet</th>
                    <th>Aktualizováno</th>
                </tr>
                </thead>
                <tbody>
                    {this.state.currencies.objects.map((main_currency, index) => {
                        return <tr  key={main_currency.code}>
                            <td>{main_currency.label}</td>
                            <td>{main_currency.exchange_rate}</td>
                            <td><BankingAccountSelector currency={main_currency} onChange={data => this.bankingAccountUpdated(data, index)} /></td>
                            <td>{moment(main_currency.updated).format("DD.MM.YYYY HH:MM")}</td>
                        </tr>
                    })}
                </tbody>
                </table>
            }
            <p><em>Kurz se vztahuje k jedné měně, která má pak kurz 1. Typicky jde o euro, funkcionalita do budoucna počítá s nastavením "hlavní měny".</em></p>
            </Container>
        )
    }
}

export default withRouter(CurrencyView);