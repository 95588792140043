import React from 'react'
import { ButtonToolbar, ButtonGroup, Form, Button, Jumbotron, Row, Container } from 'react-bootstrap';
import UserCard from '../Components/UserCard';
import UserModal from '../Components/UserModal';
import UserModalPasswd from '../Components/UserModalPasswd'
import MessageCenter from '../Components/MessageCenter'
import settings from '../Settings'
import { Search } from 'react-bootstrap-icons';
import fetchProxy from '../Helpers/fetchProxy';
import User from '../Classes/User';
import { HelpComponent } from '../Components/HelpComponent';
import LoadingBanner from '../Components/LoadingBanner';

class UserView extends React.Component {
  state = {
    "users": null
  }

  user_modal = React.createRef();
  passwd_modal = React.createRef();

  async actions(action, data) {
    const user = new User(data)
    switch (action) {
      case "edit":
        this.user_modal.current.loadUser(user)
        break;
      case "delete":
        this.deleteUser(user)
        break;
      case "passwd":
        this.passwd_modal.current.user = user
        this.passwd_modal.current.setState({ show: true })
        break;
      case "terminate-sessions":
        if (!window.confirm("Zneplatněním přihlášení může dojít k nesprávnému chování aplikace na straně odhlášeného uživatele (je nutné restartovat prohlížeč a přihlásit se znovu). \nPokud se u účtu objevilo podezřelé chování, nezapomeňte také změnit heslo!\n\nChcete pokračovat?")) {
          return;
        }
        if (!(await user.terminateSessions())) {
          MessageCenter.addMessage({
            title: "Selhalo zneplatnění přihlášení uživatele",
            text: "Zkuste to prosím znovu, nebo kontaktujte podporu."
          })
        }
        break;
      default:
        MessageCenter.addMessage({
          title: 'Neznámá akce!',
          text: 'To se nepovedlo. Vyvolaná akce není definovaná',
          detail: 'Data: \n' + JSON.stringify({ user, action })
        })
    }
  }

  async deleteUser(user) {
    let url = settings.BASE_API_URL + "user/" + user.id + "/";
    fetchProxy(url, {
      method: "DELETE",
      mode: 'cors',
      redirect: 'follow',
    })
      .then((response) => {
        if (response.status === 204) {
          let users = this.state.users
          let index = users.indexOf(user)
          if (index === -1) {
            MessageCenter.addMessage({
              title: 'Chyba při mazání uživatele!',
              text: 'Nenalezen v lokálním polu uživatelů.',
              detail: 'Data: \n' + JSON.stringify(user, this.state.users)
            })
          }
          else {
            user = users.splice(index, 1)[0];
            MessageCenter.addMessage({
              title: 'Uživatel smazán',
              text: `Uživatelský účet ${User.prototype.label.bind(user)()} odstraněn.`
            })
            this.setState({ users })
          }
        }
        else {
          MessageCenter.addMessage({
            title: 'Chyba při mazání uživatele!',
            text: 'Bohužel se nepovedlo smazat uživatele. Zkuste to prosím znovu, nebo kontaktujte podporu',
            detail: 'Data: \n' + JSON.stringify(user, response)
          })
        }
      })
  }

  async userPasswdSave() {
    let passwd = this.passwd_modal.current.getPasswd();
    if (passwd !== null) {
      this.passwd_modal.current.setState({ show: false })
      let url = User.base_url + this.passwd_modal.current.user.id + "/passwd/";
      const res = await fetchProxy(url, {
        method: "POST",
        mode: 'cors',
        redirect: 'follow',
        body: JSON.stringify({ password: passwd })
      })
      const data = await res.json()
      if (res.status !== 200) {
        MessageCenter.addMessage({
          title: 'Nepovedlo se nastavit heslo!',
          text: `Bohužel se nepovedlo nastavit heslo uživatele. Chyba: ${data.result}`,
          detail: 'Data: \n' + JSON.stringify(this.passwd_modal.current.user, res, data)
        })
      }
      else {
        MessageCenter.addMessage({
          title: "Změna hesla proběhla úspěšně"
        })
      }
      return;
    }
  }

  userModalSave() {
    this.user_modal.current.reset()
    this.saveUser(this.user_modal.current.state.user)
  }

  async saveUser(user) {
    let url = settings.BASE_API_URL + "user/";
    let method = "POST";
    if (user.id !== 0) {
      method = "PUT";
      url += user.id + "/";
    }
    const res = await fetchProxy(url,
      {
        method: method,
        mode: 'cors',
        redirect: 'follow',
        body: JSON.stringify(user)
      })
    const new_user = await res.json()
    const users = this.state.users
    switch (res.status) {
      case 200:
        // User updated
        var index = users.lenght - 1;
        while (index >= 0) {
          if (users[index].id === user.id) {
            users[index] = new_user;
            this.setState({ users })
            break;
          }
          index--;
        }
        if (index < 0) {
          MessageCenter.addMessage({
            title: 'Uživatel nenalezen!',
            text: 'Uživatel nenalezen, nemohl být lokálně aktualizován',
            detail: 'Data: \n' + JSON.stringify(new_user, users)
          })
        } else {
          this.setState({ users })
        }
        break;
      case 201:
        users.push(new_user)
        this.setState({ users })
        break;
      default:
        MessageCenter.addMessage({
          title: 'Nepodařilo se uložit uživatele!',
          text: 'Bohužel se nepovedlo uložit uživatele. Zkuste to prosím znovu, nebo kontaktujte podporu',
          detail: 'Data: \n' + JSON.stringify({ user, res })
        })
        break;
    }
  }

  async componentDidMount() {
    try {
      const res = await fetchProxy(settings.BASE_API_URL + 'user/');
      const users = await res.json();
      this.setState({
        users
      });
    } catch (e) {
      MessageCenter()
    }
  }

  render() {
    return (
      <Container className="userview">
        <Jumbotron>
          <h1>Správa uživatelů</h1>
        </Jumbotron>
        <ButtonToolbar className="align-items-center">
          <ButtonGroup>
            <UserModal ref={this.user_modal} closeCallback={() => this.userModalSave()} />
            <UserModalPasswd ref={this.passwd_modal} closeCallback={() => this.userPasswdSave()} />
            <HelpComponent helpTopic="users">
              <p>Na této stránce spravujete uživatele. Kontaktní údaje se mohou zobrazovat na objednávkách a dalších dokumentech.</p>
            </HelpComponent>
          </ButtonGroup>
          <div className="ml-auto mr-2">
            {this.state.users && <>Celkem: {this.state.users.length}</>}
          </div>
          <Form className="btn-group input-group">
            <input type="text" className="form-control" placeholder="Hledat uživatele"></input>
            <Button variant="secondary" className="flex-grow-0">
              <Search />
            </Button>
          </Form>
        </ButtonToolbar>
        {this.state.users === null ? <LoadingBanner /> : <Row>{this.state.users.map((item, key) =>
              <UserCard key={item.id} user={item} actions={(action, user) => this.actions(action, user)} />
            )}
        </Row>}
      </Container>
    );
  }
}

export default UserView;
