import React from 'react'
import { InfoCircle } from 'react-bootstrap-icons'
import { Button, Popover, OverlayTrigger } from 'react-bootstrap'
import settings from '../Settings'
import { OverlayComponent } from './OverlayComponent'

class HelpComponent extends OverlayComponent {

    constructor(props) {
        super(props)
        this.state = {
            show: false
        }
    }
    
    render() {
        const { helpTopic, placement, children} = this.props
        return (
            <OverlayComponent 
                placement={placement || "bottom"} 
                trigger="click"
                text={<Button><InfoCircle /></Button>}
                helpTopic={helpTopic}
                title={"Nápověda"}
            >
                {children}
                {helpTopic && <Button href={settings.DOCS_TOPICS_URL + helpTopic} target="blank" block variant="link">Více informací</Button>}
            </OverlayComponent>
        )
    }
}

export { HelpComponent }