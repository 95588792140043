import React from 'react'
import { Form, ListGroup, Spinner } from 'react-bootstrap'
import { Plus } from 'react-bootstrap-icons'
import { VehicleTypeModal } from './VehicleTypeModal'

const AUTOCOMPLETE_COOLDOWN = 200

export class AutocompleteInput extends React.Component {
    state = {
        value: this.props.value || "",
        active: -1,
        selected: !!this.props.value,
        items: [],
        autocomplete_timeout: undefined
    }

    activate(id) {
        let item = this.state.items[id]
        // In case we got an object, which is probably the
        // <Plus> text for adding a new object, we have to get it's text
        // This will become tricky later on, we should use some array of object with display and value
        if (typeof item === "object") {
            item = item.props.children[2].trim()
        }
        this.itemSelected(item)
    }

    async onChange(e) {
        if (this.state.autocomplete_timeout) {
            clearTimeout(this.state.autocomplete_timeout)
        }
        this.setState({
            inputFocus: true,
            value: e ? e.target.value : this.state.value,
            autocomplete_timeout: setTimeout(async () => {
                this.setState({
                    items: await this.loadAutocomplete(),
                    autocomplete_timeout: undefined
                })
            }, AUTOCOMPLETE_COOLDOWN)
        })
    }

    itemSelected(text) {
        text = text.trim()
        this.setState({menuHover: false, inputFocus: false, value: text})
        this.props.itemSelected && this.props.itemSelected(text)
    }

    async loadAutocomplete() {
        const items = await this.props.loadAutocomplete(this.state.value)
        items.unshift(<>
            <Plus /> {this.state.value}
        </>)
        return items
    }

    inputKeyDown(e) {
        let active = this.state.active
        switch (e.keyCode) {
            case 40:
                active++;
                e.preventDefault()
                break;
            case 38:
                active--;
                e.preventDefault()
                break;
            case 13:
                this.activate(this.state.active)
                e.preventDefault()
                break;
            default: 
                break;
        }
        // Make it circular, handle edge cases
        active = active < 0 ? this.state.items.length - 1 : active
        active = active >= this.state.items.length ? 0 : active
        this.setState({active})
    }

    toggleHover(e) {
        this.setState({menuHover: e.type==="mouseenter"})
    }

    render() {
        return <div className="p-relative d-flex align-items-center">
            <Form.Control value={this.state.value}
                onKeyDown={e => this.inputKeyDown(e)}
                onChange={e => this.onChange(e)}
                onFocus={() => {this.setState({inputFocus: true}); this.onChange()}}
                onBlur={() => {this.setState({inputFocus: false})}}
                className="mr-1"
            />
            {this.props.is_vehicle_type && <VehicleTypeModal valueSelected={item => {this.props.itemSelected(item)}} />}
            <div className="autocomplete-menu"
                style={{position: "absolute",
                            left: 0,
                            top: "40px",
                            zIndex: 1,
                            width: "100%",
                            display: (this.state.inputFocus || this.state.menuHover) ? "block" : "none"
                        }}
                onMouseEnter={e => this.toggleHover(e)}
                onMouseLeave={e => this.toggleHover(e)}
            >
                <ListGroup className="shadow" style={{margin: "0px 15px"}}>
		    {!this.state.autocomplete_timeout ? this.state.items.map((item, key) => {
                        return <ListGroup.Item key={key}
                        onClick={e => this.itemSelected(e.target.innerText)}
                        style={{padding: "5px 10px"}}
                        className={(key === this.state.active) ? "active" : ""}
                        onMouseMove={() => this.setState({active: key})}
                            >
                            {item}
                        </ListGroup.Item>
                    }): <ListGroup.Item><Spinner size="sm" animation="grow" /> Načítání dat</ListGroup.Item>}
                </ListGroup>
            </div>
        </div>
    }
}
