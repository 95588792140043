import Base from './Base'
import settings from '../Settings'
import Customer from './Customer'
import { Receipt } from 'react-bootstrap-icons'
import { dateAddDays, dateRemoveTime } from '../helpers'
import MessageCenter from '../Components/MessageCenter'
import fetchProxy from '../Helpers/fetchProxy'

export default class Invoice extends Base {
    static base_url = settings.BASE_API_URL + "invoice/"

    static columns = [
        {
            name: "id",
            label: "#",
        },
        {
            name: "orders",
            label: "Objednávky",
            transform: (values) => values.map(o => o.code).join(", ")
        },
        {
            name: "customer_order",
            label: "Objednávka u zákazníka"
        },
        {
            name: "customer",
            label: "Zákazník",
            transform: (customer) => new Customer(customer).label()
        },
        {
            name: "due_date",
            label: "Datum splatnosti",
            transform: (_, __, invoice) => invoice.due_date && <span className={invoice.isDue && invoice.isDue() ? "text-danger" : ""}>
                {new Date(invoice.due_date).toLocaleString()}
            </span>
        },
    ]

    static icon() {
        return Receipt
    }

    isDue() {
        return this.due_date && !this.pay_date && dateRemoveTime(new Date()) > new Date(this.due_date)
    }

    label() {
        return this.id
    }

    async to_pohoda() {
        const req = await fetchProxy(this.constructor.base_url + this.id + "/to_pohoda/", {
            method: "GET",
        })
        if (req.status !== 200) {
            MessageCenter.addMessage({"title": "Stažení selhalo!", message: `Bohužel se nepodařilo zahájit stahování. Odpověď serveru: ${req.status}`})
            return;
        }
        const blob = await req.blob()
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `invoice_export-${this.id}.xml`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();    
        a.remove();  //afterwards we remove the element again    
    }

    static async bulkPohoda(ids) {
        const req = await fetchProxy(this.base_url + "bulk_pohoda/?id=" + ids.join("&id="), {
            method: "GET",
        })
        if (req.status !== 200) {
            MessageCenter.addMessage({"title": "Stažení selhalo!", message: `Bohužel se nepodařilo zahájit stahování. Odpověď serveru: ${req.status}`})
            return;
        }
        const blob = await req.blob()
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `invoice_export-${ids.join("_")}.xml`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();    
        a.remove();  //afterwards we remove the element again
        return true 
    }

    async save(data) {
        if (data) {
            for (const key of ["due_date", "pay_date"]) {
                if (data[key] === "") {
                    data[key] = null
                }
            }
        }
        return await Base.prototype.save.call(this, data)
    }

    static async connectorInspectFile(file) {
        return await fetchProxy(this.base_url + "connector/inspect", {
            method: "POST",
            body: file,
            headers: {
                'Content-Type': undefined // Set content-type to undefined otherwise, my great system will try to push json :-) 
            }
        }) 
    }
}