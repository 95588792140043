import moment from 'moment'

const DATE_REGEX = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])/;

class OpenHolidaysApiResponse {
    /**
     * 
     * @param {Array<Object<string, Array<Object>>>} raw_response - List representing country with queried holidays
     */
    constructor(raw_response = {}) {
        this.raw_response = raw_response
    }

    /**
     * Group holidays by countries
     * 
     * @returns {Object<String, Array<Object> } - Object of countries with their holidays
     */
    byCountries() {
        const result = {}
        this.raw_response.forEach(entry => result[entry.country] = entry.holidays)
        return result
    }

    /**
     * 
     */
    toHolidaysHelper() {
        return new HolidaysHelper(this.byCountries())
    }
}

/**
 * @class OpenHolidays
 * 
 * @description
 * Represents a wrapper around the Open Holidays API.
 * 
 * @author lotricek
 */
class OpenHolidaysApi {

    /**
     * Fetches the holidays from the Open Holidays API.
     *
     * @param {Array<string>} [countries=["CZ"]] - The ISO codes of the countries for which to fetch the holidays.
     * @param {string} [languageIsoCode="CS"] - The ISO code of the language in which to fetch the holiday names.
     * @param {Date} [startDate=new Date(2024, 0, 1)] - The start date from which to fetch the holidays (YYYY-MM-DD).
     * @param {Date} [endDate=new Date(2024, 11, 31)] - The end date until which to fetch the holidays (YYYY-MM-DD).
     * @return {Promise<Array<Object>>} An array of objects containing theholiday name, start date, and end date.
     */
    static async getHolidays(countries = ["CZ"], languageIsoCode = "CS", startDate, endDate) {
        startDate = startDate || new Date(new Date().getFullYear(), 0, 1);
        endDate = endDate || new Date(new Date().getFullYear() + 2, 11, 31);
        const uniqueCountries = [...new Set(countries)]
        const holidays = await Promise.all(uniqueCountries.map(async element => {
            // Build the URL params
            const url_params = new URLSearchParams({
                "countryIsoCode": element.toUpperCase(),
                "languageIsoCode": languageIsoCode.toUpperCase(),
                "validFrom": moment(startDate).format('YYYY-MM-DD'),
                "validTo": moment(endDate).format('YYYY-MM-DD'),
            });
            // Request the values
            const response = await fetch(
                `https://openholidaysapi.org/PublicHolidays?` + url_params.toString(),
                { 
                    headers: { 'Accept': 'application/json' } 
                }
            )
            if (response.status !== 200)
                throw new Error(`Unexpected http response (${response.status}) for the holidays request`)
            const responseJson = await response.json();
            return {
                country: element.toLocaleUpperCase(),
                holidays: responseJson.map(holiday => {return {
                    ...holiday,
                    startDate: new Date(holiday.startDate),
                    endDate: new Date(holiday.endDate)
                }})
            };
        }))
        // Map holidays to dictionary based on the country
        // This is questionable if we're supposed to do this. 
        // Maybe some metaresult which can be transformed into 1) list 2) dict would be better
        // This could be done inside of that promise
        return new OpenHolidaysApiResponse(holidays)
    }

}

class HolidaysHelper {
    /**
     * Returns a new Holidays helper
     * 
     * @param {*} holidays - Holidays by country (dict[str, list[Holiday]])
     */
    constructor(holidays) {
        this.holidays = holidays
    }

    /**
     * Checks if there are any holidays in the given country and date range.
     *
     * @param {string} [countryIsoCode="CZ"] - The ISO code of the country to check.
     * @param {Date} [startDate=new Date(new Date().getFullYear(), 0, 1)] - The start date of the range to check (YYYY-MM-DD).
     * @param {Date} [endDate=new Date(new Date().getFullYear() + 2, 11, 31)] - The end date of the range to check (YYYY-MM-DD).
     * @return {Array} - Returns matched holidays
     */
    hasHolidays(countryIsoCode = "CZ", start, end) {
        
        if (!start || !end) {
            console.error("Missing start/end parameter!")
            return false
        }
        // Test regex on the date and take out the string
        
        let startDate = DATE_REGEX.exec(start)[0]
        let endDate = DATE_REGEX.exec(end)[0]
        if (!startDate === undefined) {
            console.error("Start date wasn't parsed properly!")
            return false
        }
        if (!endDate === undefined) {
            console.error("End date wasn't parsed properly!")
            return false
        }

        startDate = new Date(startDate)
        endDate = new Date(endDate)
        //Check holidays
        const holidays = this.holidays[countryIsoCode.toUpperCase()];
        if (holidays === undefined || !Array.isArray(holidays)) {
            console.error(`Country ${countryIsoCode.toUpperCase()} holidays had unexpected value: ${typeof holidays}!`)
            return false
        }
        
        //Check single holidays
        const matchedHolidays = []
        for (const holiday of holidays) {
            let eventInRange = false;
            eventInRange ||= holiday.startDate <= startDate && startDate <= holiday.endDate;
            eventInRange ||= holiday.startDate <= endDate && endDate <= holiday.endDate;
            eventInRange ||= startDate <= holiday.startDate && holiday.startDate <= endDate;
            eventInRange ||= startDate <= holiday.endDate && holiday.endDate <= endDate;
            if (eventInRange) {
                //Instead accumulate the holidays
                matchedHolidays.push(holiday)
            }
        }
        return matchedHolidays
    }

    /**
     * Retrieves the holidays for a given country from the local holidays array.
     *
     * @param {string} [countryIsoCode="CZ"] - The ISO code of the country for which to fetch the holidays.
     * @param {Date} [startDate=new Date(new Date().getFullYear(), 0, 1);] - The start date from which to fetch the holidays (YYYY-MM-DD).
     * @param {Date} [endDate=new Date(new Date().getFullYear() + 2, 11, 31);] - The end date until which to fetch theholidays (YYYY-MM-DD).
     * @return {Array<Object>} An array of objects containing the holiday name, start date, and end date.
     */
    getLocal(countryIsoCode = "CZ", startDate, endDate) {
        startDate = startDate ? startDate : new Date(new Date().getFullYear(), 0, 1);
        endDate = endDate ? endDate : new Date(new Date().getFullYear() + 2, 11, 31);
        const holidays = this.holidays[countryIsoCode.toUpperCase()];
        if (!holidays) return [];
        const result = [];
        for (const holiday of holidays) {
            const endDateInRange = holiday.startDate <= Date.parse(endDate) && Date.parse(endDate) <= holiday.endDate;
            const holidayStartDateInRange = Date.parse(startDate) <= holiday.startDate && holiday.startDate <= Date.parse(endDate);
            const holidayEndDateInRange = Date.parse(startDate) <= holiday.endDate && holiday.endDate <= Date.parse(endDate);
            if (Math.max(Date.parse(startDate), holiday.startDate) <= Math.min(Date.parse(endDate), holiday.endDate)) {
                result.push(holiday);
            }
        }

        return result;
    }
}

export {
    OpenHolidaysApi,
    OpenHolidaysApiResponse,
    HolidaysHelper
}
