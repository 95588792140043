import React from 'react'
import { Modal, Button, Form, Col, Badge, Nav, ButtonToolbar, ButtonGroup, Alert } from 'react-bootstrap';
import { Building, Truck } from 'react-bootstrap-icons'
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import EmailTemplateDropdown from '../Components/EmailTemplateDropdown';
import ContactList from '../Components/ContactList'
import template from 'es6-template-strings'
import 'draft-js/dist/Draft.css';
import '../css/Editor.css';
import { TypeBold, TypeItalic, TypeUnderline } from 'react-bootstrap-icons';
import { Editor, EditorState, RichUtils } from 'draft-js';
import Queryset from '../Classes/Queryset'
import Contact from '../Classes/Contact'
import Customer from '../Classes/Customer';
import Account from '../Classes/Account';
import { HelpComponent } from '../Components/HelpComponent'

//TODO Fix timezone issues!
async function loadCustomerContacts(customer) {
    const qs = new Queryset(Customer)
    if (customer) {
        await qs.filter({ customer })
    }
    else {
        return []
    }
    return qs.objects.filter(i => { return !!i.email }).map(i => { return [i.name, i.email] })
}

async function loadCarrierContacts(carrier) {
    const qs = new Queryset(Contact)
    if (carrier) {
        await qs.filter({ carrier })
    }
    else {
        return []
    }
    return qs.objects.filter(i => { return !!i.email }).map(i => { return [i.name, i.email] })
}

class EmailModal extends React.Component {

    constructor(props) {
        super(props)
        if (props.submitCallback !== null) {
            this.submitCallback = props.submitCallback
        }
        this.form = React.createRef()
        this.state = {
            editorState: EditorState.createEmpty(),
            show: false,
            email_input: "",
            emails: [],
            validated: false,
            subject: '',
            copy_to_sender: true,
        }
    }

    reset = () => {
        this.setState({
            editorState: EditorState.createEmpty(),
            email_input: "",
            emails: [],
            validated: false,
            subject: '',
            copy_to_sender: true,
        })
    }

    setShow = (show) => {
        show && this.reset()
        this.setState({ show })
    }

    submitCallback = () => {
        // close call back should be implemented here
    }

    handleShow = () => this.setShow(true)
    handleClose = () => {
        this.setShow(false)
    }

    editorChange = (editorState) => {
        this.setState({ editorState })
    }

    saveChanges = () => {
        if (!this.validate()) {
            return
        }
        if (this.submitCallback) {
            const to = this.state.emails
            if (this.state.email_input !== "") {
                to.push(this.state.email_input)
                this.setState({ 'email_input': '' })
            }
            const data = {
                "to": to,
                'subject': this.state.subject,
                'body': stateToHTML(this.state.editorState.getCurrentContent()),
                'copy_to_sender': this.state.copy_to_sender
            }
            this.submitCallback(data)
        }
        this.handleClose()
    }

    onEmailInputChange(ev) {
        this.setState({
            email_input: ev.target.value
        })
    }

    addEmail(email) {
        const emails = this.state.emails
        emails.push(email)
        this.setState({ emails })
    }

    onKeyDown(ev) {
        const val = ev.target.value.trim()
        if (['Enter', 'Tab', ','].includes(ev.key) && val !== "") {
            this.addEmail(val)
            this.setState({
                email_input: ''
            })
            ev.preventDefault();
        }
    }

    removeEmail(key) {
        const emails = this.state.emails
        emails.splice(key, 1)
        this.setState(emails)
    }

    handleKeyCommand(command, editorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChange(newState);
            return 'handled';
        }
        return 'not-handled';
    }
    onStyleButtonClick(ev) {
        const type = ev.target.dataset.type;
        this.editorChange(RichUtils.toggleInlineStyle(this.state.editorState, type));
    }

    validateEmails() {
        return this.state.emails.length > 0 || this.state.email_input.length > 0
    }

    validate() {
        this.form.current.querySelector('input[type=submit]').click() /* Trigger native validation */
        this.setState({ validated: true })
        return this.form.current.checkValidity() && this.validateEmails()
    }

    onSubjectChange(ev) {
        this.setState({ subject: ev.target.value })
    }

    importTemplate(item) {
        const order = this.props.order
        const contentState = stateFromHTML(template(item.body, { order }))
        this.setState({
            subject: this.state.subject || template(item.subject, { order }),
            editorState: EditorState.createWithContent(contentState)
        })
    }

    render() {
        const emails_invalid = this.state.validated && !this.validateEmails()
        return (
            <>
                <Modal show={this.state.show} onHide={this.handleClose} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>Editor zpráv <small className="text-muted">{Account.current().email}</small></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className="pt-2" ref={this.form} onSubmit={(e) => { e.preventDefault(); }}>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label>E-mail</Form.Label>
                                    <div className="d-flex">
                                        <ContactList className="mr-1"
                                            loadContacts={async () => { return loadCustomerContacts(this.props.order.customer && this.props.order.customer.id) }}
                                            itemSelected={item => { this.addEmail(item) }}>
                                            <Building />
                                        </ContactList>
                                        <ContactList className="mr-1"
                                            loadContacts={async () => { return loadCarrierContacts(this.props.order.carrier && this.props.order.carrier.id) }}
                                            itemSelected={item => { this.addEmail(item) }}>
                                            <Truck />
                                        </ContactList>
                                        <Form.Control type="email" placeholder="Komu" onKeyDown={(ev) => { this.onKeyDown(ev) }} onChange={(ev) => this.onEmailInputChange(ev)} value={this.state.email_input} />
                                    </div>
                                    {
                                        this.state.emails.length ?
                                            <div className="mt-1">
                                                {this.state.emails.map((value, key) => {
                                                    return <Badge key={key} className="ml-1" pill variant="success">{value}
                                                        <Nav.Link onClick={() => { this.removeEmail(key) }} className="py-0 px-1 d-inline-block">{/*<FontAwesomeIcon icon={faTimes} className="text-danger"/>*/}X</Nav.Link>
                                                    </Badge>
                                                })}
                                            </div>
                                            : ""
                                    } <Alert variant={emails_invalid ? "danger" : "info"}>
                                        {emails_invalid ?
                                            "Vyplňte alespoň jeden e-mail" :
                                            "tip: více e-mailů oddělte čárkou, tabulátorem, nebo potvrďte enterem"}
                                    </Alert>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="subject">
                                    <Form.Label>Předmět</Form.Label>
                                    <Form.Control type="text" required minLength="1" value={this.state.subject} onChange={ev => { this.onSubjectChange(ev) }} placeholder="Předmět" />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    <Form.Label>Text zprávy</Form.Label>
                                    <div className="editor-wrapper border rounded">
                                        <ButtonToolbar aria-label="Toolbar with button groups" className="border-bottom p-2">
                                            <ButtonGroup className="mr-2" aria-label="First group">
                                                <Button variant="outline-secondary" onMouseDown={(ev) => { ev.preventDefault() }} onClick={ev => { this.onStyleButtonClick(ev) }} data-type="BOLD"><TypeBold /></Button>
                                                <Button variant="outline-secondary" onMouseDown={(ev) => { ev.preventDefault() }} onClick={ev => { this.onStyleButtonClick(ev) }} data-type="ITALIC"><TypeItalic /></Button>
                                                <Button variant="outline-secondary" onMouseDown={(ev) => { ev.preventDefault() }} onClick={ev => { this.onStyleButtonClick(ev) }} data-type="UNDERLINE"><TypeUnderline /></Button>
                                            </ButtonGroup>
                                            <ButtonGroup className="ml-auto" aria-label="Second group">
                                                <EmailTemplateDropdown onSelected={item => { this.importTemplate(item) }} />
                                            </ButtonGroup>
                                        </ButtonToolbar>
                                        <div className="p-2">
                                            <Editor editorState={this.state.editorState} onChange={this.editorChange} handleKeyCommand={this.handleKeyCommand} />
                                        </div>
                                        <input type="submit" className="invisible" />
                                    </div>
                                </Col>
                            </Form.Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <em className="mr-auto">Kopie zprávy bude odeslána supervizorovi.</em>
                        {Account.current().email ? <Form.Check
                            type="checkbox"
                            label={"Poslat kopii na " + Account.current().email}
                            checked={this.state.copy_to_sender} onChange={(ev) => { this.setState({ copy_to_sender: ev.target.checked }) }} />
                            : ""}
                        <HelpComponent helpTopic="emailOrder" placement="top">
                            <p>
                                Pokud chcete objednávku (PDF soubor pro zákazníka) odeslat na nějaký e-mail, můžete využít tento formulář.
                                Podporuje základní formátování a pro rychlejší práci můžete využít některou z předpřipravených šablon.
                            </p>
                            <p>
                                Pokud je už k objednávce přidaný dopravce, nebo zákazník, můžete rychle přidat jeho e-mailovou adresu pomocí tlačítek vedle adresního řádku.
                            </p>
                        </HelpComponent>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Zavřít
                        </Button>
                        <Button variant="primary" onClick={this.saveChanges}>
                            Odeslat
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>)
    }
}

export default EmailModal;


