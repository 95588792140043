import Base from './Base'
import settings from '../Settings'
import Place from './Place'
import moment from 'moment'
import fetchProxy from '../Helpers/fetchProxy'

class Location extends Base{
    static base_url = settings.BASE_API_URL + "location/"

    fixDatetimeFormat() {
        this.time = this.time.replace(" ", "T")
        if (!/^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}/.test(this.time)) {
            this.time = this.date + "T" + this.time
        }
    }

    prettyName(with_date) {
        let name = this.place ? Place.prototype.address.call(this.place) : "Zastávka bez místa"
        return !with_date ? name : name + " (" + moment(this.time).format("DD.MM.YYYY") + ")"
    }

    async save() {
        this.time = this.time === "" ? null : this.time
        this.time && this.fixDatetimeFormat()
        // Save the place to tmp variable UGLY HACK BECAUSE SAVE CANT HANDLE NESTED OBJECTS
        const place = this.place
        this.place = this.place ? this.place.id : null
        const res = await super.save()
        this.place = place
        res.place = place
        return res
    }

    async reorder(position) {
        const res = await fetchProxy(Location.base_url + this.id + "/reorder/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                position: position
            })
        })
        return res.status === 200
    }
}
export default Location
