import Base from './Base'
import {Badge} from 'react-bootstrap'
import React from 'react'
import Location from './Location'
import settings from '../Settings'
import MessageCenter from '../Components/MessageCenter'
import User from './User'
import fetchProxy from '../Helpers/fetchProxy'
import {dateToLocal, downloadFetchRequest, sameDay} from '../helpers'
import moment from 'moment'
import Attachment from './Attachment'
import { Paperclip, Receipt } from 'react-bootstrap-icons'
import Currency from './Currency'
import Carrier from './Carrier'
import Customer from './Customer'
import Label from './Label'
import Vehicle from './Vehicle'
import Invoice from './Invoice'

const MAX_LABELS = 2;
const price_related_fields = ['price_carrier', 'price_customer', 'customer_currency', 'carrier_currency', 'distance']

const locationTransform = location => {
    if (!location) {
        return  null
    }
    else {
        return new moment(location.time).format("DD.MM.YYYY HH:SS")
    }
}

const marginTransform = margin => {
    return <span className={margin > 0 ? "text-success" : "text-danger"}>
        {margin}
    </span>
}

export class OrderAccountingError extends Error {
    constructor(errors) {
        super("Objednávku nelze fakturovat!")
        this.name = "OrderAccountingError"
        this.errors = errors
    }
}

class Order extends Base{
    static base_url = settings.BASE_API_URL + "order/"

    static valueTransforms = {
        'labels' : data => {
            let counter = -1;
            const labels = Object.keys(data).map(i => {
                counter++
                if (counter >= MAX_LABELS) {
                    return null
                }
                const item = data[i]
                return <Badge key={i} pill className="mr-1" style={{backgroundColor: `rgb(${item.rgb[0]},${item.rgb[1]},${item.rgb[2]})`,
                            color: `rgb(${item.rgb_inverted[0]},${item.rgb_inverted[1]},${item.rgb_inverted[2]})`}}>{item.name}
                        </Badge>
            })
            if (counter >= MAX_LABELS) {
                const hidden_labels = data.splice(MAX_LABELS)
                return <>
                    {labels} + <span title={hidden_labels.map(i =>{return i.name}).join(", ")}>{hidden_labels.length} další</span>
                </>
            }
            return labels},
        'created_at' : data => {
            const date = moment(data)
            return date.isValid() ? date.format("DD.MM.Y HH:mm") : " - "
        },
        'locations' : data => {
            return data.map(i => {return Location.prototype.prettyName.call(i)}).join(" \u{1F812} ") || <i>Bez zastávek</i>
        },
        'route' : data => {
            return data.map(i => {return Location.prototype.prettyName.call(i)}).join(" \u{1F812} ") || <i>Bez zastávek</i>
        },
        'last_unload' : locationTransform,
        'first_load' : locationTransform,
        'margin': marginTransform,
        'annotated_margin' : marginTransform,
        'requirements': data => {
            if (data.length === 0) return <em style={{fontSize: '0.7em'}}>Bez požadavků</em>
            return data.map(requirement => <Badge variant='primary' key={requirement.id}>{requirement.name}</Badge>)
        }
    }

    static filter_by = {
        "customer": {type: "model", klass: Customer},
        "carrier": {type: "model", klass: Carrier},
        "vehicle": {type: "model", klass: Vehicle},
        "labels": {type: "model", klass: Label},
        "annotated_margin__lte": {type:"bool", value: 0, label: "Marže je 0 nebo méně."},
    }

    static filter_by_help = <div>
        Pokud potřebujete vybrat objednávky, které nemají dopravu, vyberte "chybí" u dopravce i vozidla. <br />
        <strong>Každá objednávka může mít pouze DOPRAVCE nebo VOZIDLO.</strong> Máte-li vozidla patřící některému dopravci není možné vyhledat všechny objednávky tohoto dopravce, musíte filtrovat objednávky podle vozidla.<br />
    </div>

    static wide_columns = [
        {
            name: "id",
            label: "#",
            transform: (item, options, obj) => <div className="d-flex justify-content-between align-items-center">
                {item}
                {obj.attachments.length > 0 && <span title="Obsahuje přílohu" className='ml-1'>
                    {Attachment.icon_as_component()}
                </span>}
                {obj.invoice && <a href={`/invoice/${obj.invoice.id}`}>
                    <span 
                        className={obj?.invoice?.payment ? 'ml-1 text-success' :'ml-1 text-dark'}
                        title={obj?.invoice?.payment ? "Faktura je splacená" : "Má přiřazenou fakturu"}
                        >
                        {Invoice.icon_as_component()}
                    </span>
                </a>}
            </div>
        },
        {
            name: "customer",
            label: "Zákazník",
            transform: item => {
                return item && item['name']
            }
        },
        {
            name: "carrier",
            label: "Dopravce",
            transform: (item, options, obj) => {
                if (obj.vehicle) {
                    return obj.vehicle.carrier ?
                        obj.vehicle.carrier['name'] :
                        <em>{obj.vehicle.spz}</em>
                }
                return item && item['name']
            },
            sortable: false
        },
        {
            name: "first_location_time",
            label: "První zastávka",
            transform: (value) => dateToLocal(value)
        },
        {
            name: "route_text",
            label: "Trasa",
            sortable: false
        },
        {
            name: "price_customer",
            label: "Nákup",
            transform: (value, options, obj) => {
                const currency = new Currency(obj.customer_currency)
                return `${value} ${currency.label}`
            }
        },
        {
            name: "price_carrier",
            label: "Prodej",
            transform: (value, options, obj) => {
                const currency = new Currency(obj.carrier_currency)
                return `${value} ${currency.label}`
            }
        },
        {
            name: "user",
            label: "Uživatel",
            transform: (user, options, obj) => {
                if (!user) return <em>Bez uživatele</em>
                user = new User(user)
                return user.label()
            }
        },
        {
            name: 'labels',
            label: "Štítky",
            transform : this.valueTransforms.labels
        }
    ]

    static columns = [{
        name: "id",
        label: "#",
        transform: (item, options, obj) => <div className="d-flex justify-content-between align-items-center">
                {item}
                {obj.attachments.length > 0 && <span className='ml-1'>
                    {Attachment.icon_as_component()}
                </span>}
            </div>
    },
    {
        name: "code",
        label: "Kód"
    },
    {
        name: "customer",
        label: "Zákazník",
        transform: item => {
            return item && item['name']
        }
    },
    {
        name: "carrier",
        label: "Dopravce",
        transform: (item, options, obj) => {
            if (obj.vehicle) {
                return obj.vehicle.carrier ?
                    obj.vehicle.carrier['name'] :
                    <em>{obj.vehicle.spz}</em>
            }
            return item && item['name']
        }
    },
    {
        name: "user",
        label: "Pracovník",
        transform: item => {
            if (!item) {
                return "-"
            }
            return item && item['username']
        },
        filterable: true
    },
    {
        name: "created_at",
        label: "Vytvořeno",
        transform: item => {
            if (item) {
                try {
                    return new Intl.DateTimeFormat("cs-CZ", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        }).format(new Date(item))   
                } catch (error) {
                    return <span class="text-red">nelze formátovat</span>
                }
            }
        }
    },
    {
        name: "state",
        label: "Stav",
        transform: (item, options) => {
            for (let i=0;i<options.actions.POST.state.choices.length;++i) {
                if (options.actions.POST.state.choices[i].value === item) {
                    return options.actions.POST.state.choices[i].display_name
                }
            }
            return item
        }
    },
    {
        name: 'labels',
        label: "Štítky",
        transform : this.valueTransforms.labels
    }]

    constructor(data) {
        super(data)
        if (this.locations === undefined) {
            this.locations = []
        }
        this.locations = this.locations.map((location) => {return new Location(location)})
        if (!!this.user && this.user !== 0) {
            this.user =  new User(this.user)
        }
    }

    pdfUrl() {
        return this.constructor.base_url + this.id + "/pdf"
    }

    carrierPdfUrl() {
        return this.constructor.base_url + this.id + `/carrierpdf?language=${this.language}` 
    }

    pohodaXMLUrl(type) {
        type = type ? `?type=${type}` : ""
        return this.constructor.base_url + this.id + "/to_pohoda/" + type 
    }

    carrierPdfDownload() {
        downloadFetchRequest(fetchProxy(this.carrierPdfUrl()), `obj-${this.code}.pdf`)
    }

    pohodaXMLDownload(type) {
        type = type || "issued"
        downloadFetchRequest(fetchProxy(this.pohodaXMLUrl(type)),  `pohoda-${this.code}-${type}.xml`)
    }

    uploadAttachment = async file => {
        const data = new FormData()
        data.append("file", file)
        data.append("order", this.id)
        try {
            const res = await fetchProxy(Attachment.base_url, {
                method: "POST",
                body: data,
                headers: {
                    "Content-Type": undefined
                }
            })
            if (res.status !== 201) {
                throw new Error("Chyba při nahrávání, kód " + res.status)
            }
            else {
                this.attachments && this.attachments.push(await res.json())
            }
        } catch (e) {
            MessageCenter.addMessage({
                title: "Nahrání přílohy selhalo",
                text: e.message
            })
        }
    }

    deleteAttachment = async file => {
        try {
            const res = await fetchProxy(Attachment.base_url + `${file.id}/`, {
                method: "DELETE"
            })
            if (res.status !== 204) {
                console.error(await res.json())
                throw new Error(`Nastala chyba při mazání souboru. Kód chyby: ${res.status}`)
            }
            else {
                if (this.attachments) {
                    this.attachments = this.attachments.filter(i => {return i.id !== file.id})
                }
            }
        } catch (e) {
            MessageCenter.addMessage({
                title: "Smazání přílohy selhalo",
                text: e.message
            })
        }
        
    }

    static async bulkPohodaXMLUrl(orders) {
        const req = await fetchProxy(this.base_url + "bulk_pohoda_xml/", {
            method: "POST",
            body: JSON.stringify({orders}),
        })
        const blob = await req.blob()
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "export_pohoda.xml";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();    
        a.remove();  //afterwards we remove the element again    
    }

    static async stats(orders) {
        const req = await fetchProxy(this.base_url + "stats/", {
            method: "POST",
            body: JSON.stringify({orders}),
        })
        return await req.json()
    }

    static async bulkCSVExport(orders) {
        const req = await fetchProxy(this.base_url + "bulk_csv/", {
            method: "POST",
            body: JSON.stringify({orders}),
        })
        const blob = await req.blob()
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "export.csv";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();    
        a.remove();  //afterwards we remove the element again    
    }

    async setCarrier(carrier) {
        if (this.carrier && carrier.id === this.carrier.id) {
            return true
        }
        let obj = await this.save({carrier:carrier.id})
        if(obj.id && this.carrier && obj.carrier.id === this.carrier.id) {
            return true
        }
        this.carrier = carrier
        return true
    }

    async setCustomer(customer) {
        if (this.customer && customer.id === this.customer.id) {
            return true
        }
        let obj = await this.save({customer:customer.id})
        if(obj.id && this.customer && obj.customer.id === this.customer.id) {
            return true
        }
        this.customer = customer
        return true
    }

    async timeshift(seconds) {
        console.log(this.id);
        const req = await fetchProxy(this.constructor.base_url + this.id + "/timeshift/", {
            method: "POST",
            body: JSON.stringify({value: seconds})
        })
        if (req.status === 200) {
            return true
        }
        else {
            console.error("Error!", await req.text())
            return false
        }
    }

    isPaymentDue() {
        if (!this.due_date) {
            return false
        }
        const due_date = new Date(this.due_date)
        const today = new Date()
        if (due_date > today) {
            return false
        }
        return !this.paid_date
    }

    isRunning(date, time_insensitive) { 
        date = new Date(date)
        const first_location_time = new Date(this.first_location_time)
        if (first_location_time <= date) {
            return true
        }
        if (time_insensitive && sameDay(date, first_location_time)) {
            return true
        }
        return false
    }

    isOver(date, time_insensitive) {
        date = new Date(date)
        const last_location_time = new Date(this.last_location_time)
        if (last_location_time <= date) {
            return true
        }
        if (time_insensitive && sameDay(date, last_location_time)) {
            return true
        }
        return false
    }

    transportationLabel(no_means_label) {
        if (this.carrier) {
            return Carrier.prototype.label.call(this.carrier)
        }
        if (this.vehicle) {
            if (this.vehicle.carrier) {
                return `${this.vehicle.carrier.name}/${this.vehicle.spz}`
            }
            else {
                return this.vehicle.spz
            }
        }
        if (no_means_label) return no_means_label;
        return "Bez dopravy"
    }

    addLocation(location) {
        const time = new Date(location.time)
        if (this.locations.length === 0 || time > new Date(this.locations[this.locations.length-1].time)) {
            this.locations.push(location)
            return;
        }
        if ( time < new Date(this.locations[0].time)) {
            this.locations.unshift(location)
            return;
        }
        // binary search
        let step = parseInt(this.locations.length/2)
        let index = step
        while ( new Date(this.locations[index]) > time  || new Date(this.locations[index+1]) < time ) {
                step=Math.max(parseInt(step/2), 1)
                if ( time < new Date(this.locations[index])) {
                        index -= step
                }
                else {
                        index += step
                }
        }
        this.locations.splice(index+1, 0, location)
    }

    editLocation(location) {
        let i = this.locations.length - 1
        while(i >= 0) {
            if (this.locations[i].id === location.id) {
                this.locations[i] = location
                return true
            }
            i--
        }
        return false
    }

    removeLocation(location) {
        if (location.id === undefined || location.id === 0) {
            MessageCenter.addMessage("Missing or invalid id, cant remove location. Value was: " + location.id)
            return
        }
        let i = this.locations.length - 1
        while(i >= 0) {
            if (this.locations[i].id === location.id) {
                this.locations.splice(i, 1)
                return true
            }
            i--
        }
        return false
    }

    async save(data) {
        if (data !== undefined) {
            data.id = data.id || this.id
            // Try to save the object
            const new_data = await super.save(data)
            if (new_data.error) {
                MessageCenter.addMessage({
                    "title": "Nepodařilo se uložit objekt",
                    "text": `${new_data.error.message}. Zkuste to znovu, nebo kontaktujte podporu`
                })
                return this
            }
            if (this.__status > 201) {
                return this
            }
            // Update the keys
            let price_related_field_updated = false
            for (let key in data) {
                this[key] = new_data[key]
                // |= sounds better. But I would like to keep things boolean
                price_related_field_updated = price_related_field_updated || price_related_fields.indexOf(key)
            }
            if (price_related_field_updated) {
                this['margin'] = new_data['margin'] || this['margin']
                this['price_per_distance'] = new_data['price_per_distance'] || this['price_per_distance']
            }
            return this
        }
        let carrier  = this.carrier
        let customer = this.customer
        let user = this.user
        
        
        if (carrier) {
            this.carrier = carrier.id
        }
        if (customer) {
            this.customer = customer.id
        }
        if (user) {
            this.user = user.id
        }
        let object = await Base.prototype.save.call(this)
        if (object.error !== undefined) {
            return object
        }
        this.carrier = carrier
        this.customer = customer
        this.error = object.error
        return this
    }

    checkAccounting() {
        const errors = []
        if ((!this.carrier && !this.vehicle) || !this.customer) {
            errors.push("Objednávce chybí doprava nebo zákazník!")
        }
        if (this.invoice) {
            errors.push("Objednávka už byla fakturována!")
        }
        if (errors.length) {
            throw new OrderAccountingError(errors)
        }
        return true
    }

    async send_email(data) {
        const res = await fetchProxy(this.constructor.base_url + this.id + "/send_email/", 
            {
                method: 'POST',
                mode: 'cors',
                redirect: 'follow',
                body: JSON.stringify(data)
            })
        if (res.status !== 200) {
            console.error(`Error sending the message! HTTP RES: ${res.status}\n`, await res.json())
            return false
        }
        else {
            return true
        }
    }

    async copy() {
        const res = await fetchProxy(this.constructor.base_url + this.id + "/copy/", 
            {
                method: 'POST',
                mode: 'cors',
                redirect: 'follow',
            })
        if (res.status === 200) {
            return new Order(await res.json())
        }
    }

    static async autocomplete (field, value) {
        const res = await fetchProxy(this.base_url + "autocomplete/",
        {
            method: 'POST',
            mode: 'cors',
            redirect: 'follow',
            body: value === "" ? JSON.stringify({field}) : JSON.stringify({field, value})
        })
        return res.json()
    }

    static async bulkEdit(orders, field, value) {
        const body = {
            order: orders.map(order => {return order.id}),
            field: field,
            value: value
        }
        await fetchProxy(this.base_url + "bulk_edit/",
        {
            method: 'POST',
            body: JSON.stringify(body),
            redirect: 'follow',
            mode: 'cors'
        })
    }

    static async loadReport(startingDate, endingDate, filters, download) {
        const res = await fetchProxy(this.base_url + "reporting/" + (download ? "?download" : ""), {
            method: "POST",
            mode: 'cors',
            redirect: 'follow',
            body: JSON.stringify({
                startingDate,
                endingDate,
                filters
            })
        })
        if (download) {
            return await res.blob()
        }
        return {
            http_status: res.status,
            data: await res.json()
        }
    }

    static icon() {
        return Receipt
    }

    static async loadAgenda(focus, start, end) {
        const url_params = Object.entries({focus, start, end}).map(item => {
            return encodeURIComponent(item[0]) + "=" + encodeURIComponent(item[1])
        }).join("&")
        const res = await fetchProxy(settings.BASE_API_URL + "weekly?" + url_params)
        const data = await res.json()
        data.http_status = res.status
        return data
    }
}

export default Order
