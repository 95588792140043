import React from 'react'
import {Modal, Button, Form, Alert} from 'react-bootstrap';

class UserModalPasswd extends React.Component {    
    constructor(props) {
        super(props)
        if (props.closeCallback !== null) {
            this.closeCallback = props.closeCallback
        }
    }

    passwd = null
    passwdrepeat = null
    state = {
        show: false,
        error: null,
    }

    setShow = (show) => {
        this.setState({show})
    }

    inputChanged = (event) => {
        this[event.target.id] = event.target.value
    }

    handleShow = () => this.setShow(true)
    handleClose = () => {
        this.setShow(false)
        this.setState({error: null})
    }

    saveChanges = () => {
        this.closeCallback() 
    }
    getPasswd = () => {
        switch (this.passwd) {
            case "":
            case null:
                this.setState({"error": "Heslo je prázdné!"})
                return null
            case this.passwdrepeat:
                return this.passwd
            default:
                this.setState({"error": "Heslo se neshodují"})
                return null
        }
    }
  
    render() {
        let error = this.state.error !== null ? <Alert variant="danger" onClose={() => this.setState({error: null})} dismissible>{this.state.error}</Alert> : ""
        return (
        <>
            <Modal show={this.state.show} onHide={this.handleClose}>    
                <Modal.Header closeButton>
                    <Modal.Title>Nastavení hesla pro uživatele <b>{this.props.username}</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {error}
                <Form>
                    <Form.Group controlId="passwd">
                        <Form.Label>Nové heslo</Form.Label>
                        <Form.Control type="password" onChange={this.inputChanged}/>
                        <Form.Text className="text-muted">
                            Tvořte bezpečná a unikátní hesla. Lepší delší, než kratší a pokud možno s pestrou směsicí znaků. Než heslo opakovat, použijte raději správce hesel
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="passwdrepeat">
                        <Form.Label>Potvrďte nové heslo</Form.Label>
                        <Form.Control type="password" onChange={this.inputChanged}/>
                        <Form.Text className="text-muted">
                            Zopakujte nové heslo, jestli jste náhodou neudělali překlep :). 
                        </Form.Text>
                    </Form.Group>
                </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                    Zavřít
                    </Button>
                    <Button variant="primary" onClick={this.saveChanges}>
                    Uložit změny
                    </Button>
                </Modal.Footer>
            </Modal>
        </>)
    }
  }
  
  export default UserModalPasswd;