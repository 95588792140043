import React from 'react'
import { Badge, InputGroup, Button, FormControl } from 'react-bootstrap'
import { Plus, Search, X } from 'react-bootstrap-icons';
import Label from "../Classes/Label"
import ModelSelector from "./ModelSelector"
import '../css/LabelManager.css'

class LabelManager extends React.Component {

    state = {
        data : this.props.labels || [],
        show_add_menu: false,
    }

    removeLabel = index => {
        const data = this.state.data
        data.splice(index, 1)
        this.setState({data})
        this.props.updateLabels && this.props.updateLabels(data)
    }

    addLabel = label => {
        const data = this.state.data
        data.push(label)
        this.props.updateLabels && this.props.updateLabels(data)
        this.setState({data, show_add_menu: false})
    }

    renderLabel = (_, __, item) => {
        return <>
            <span className="colored-dot" style={{backgroundColor: `rgb(${item.rgb[0]}, ${item.rgb[1]}, ${item.rgb[2]})`}}></span>
            <span>{item['name']}</span>
        </>
    }

    render() {
        return <div className="label-manager position-relative mr-1">
            <div className="position-absolute bg-white px-1 pt-1" hidden={!this.state.show_add_menu}>
                <span className="d-block float-right mr-1" style={{cursor: "pointer"}} onClick={() => {this.setState({show_add_menu: false})}}><X /></span>
                    <div className="text-center font-weight-bold">Štítky</div>
                    <hr className="mt-1 mb-2"></hr>
                    <InputGroup className="mb-3">
                        <FormControl placeholder="Hledat" />
                        <InputGroup.Append>
                            <Button variant="outline-primary"><Search /></Button>
                        </InputGroup.Append>
                    </InputGroup>
                    <hr className="mt-1 mb-2"></hr>
                    <ModelSelector ref={this.selector}
                        klass={Label}
                        show_controls={false}
                        select={1}
                        show_search={false}
                        show_thead={false}
                        use_pagination={false}
                        table_size="sm"
                        stripped={false}
                        columns={[{name: "name", label: "Jméno", transform: this.renderLabel}]}
                        itemSelected={item => {this.addLabel(item)}}
                        />
            </div>
            <div className='d-flex flex-wrap mt-1'>
                {Object.keys(this.state.data).map(i => {
                    const item = this.state.data[i]
                    return <Badge key={i} pill className="mt-1 mr-1" style={{backgroundColor: `rgb(${item.rgb[0]},${item.rgb[1]},${item.rgb[2]})`,
                                color: `rgb(${item.rgb_inverted[0]},${item.rgb_inverted[1]},${item.rgb_inverted[2]})`}}>{item.name}
                                <span style={{cursor: "pointer"}} onClick={() => {this.removeLabel(i)}}><X style={{color: "red"}}/></span>
                            </Badge>
                })}
                <Badge className='mt-1 ml-auto' style={{cursor: "pointer"}} variant="success" onClick={() => {this.setState({show_add_menu: true})}}>Přidat štítek
                    <Plus className="text-white" />
                </Badge>
            </div>
        </div>
    }

}

export default LabelManager