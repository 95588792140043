import { Button, Modal, Form } from 'react-bootstrap'
import React from 'react'
import { Pencil, Trash } from 'react-bootstrap-icons'
import BankingAccount from '../Classes/BankingAccount'


class BankingAccountSelector extends React.Component {

    BANKING_ACCOUNT_NEW = {
        id: 0,
        account_number: "",
        iban: "",
        pohoda_id: '',
        currency: this.props.currency.code
    }

    state = {
        currency: this.props.currency,
        show: false,
        bankingaccount: new BankingAccount(this.props.currency.bankingaccount || this.BANKING_ACCOUNT_NEW)
    }

    handleClose = () => {
        this.setState({show : false})
    }

    editBankingAccount = () => {
        this.setState({
            show: true,
            /*bankingaccount: new BankingAccount(this.state.currency.bankingaccount || this.BANKING_ACCOUNT_NEW)*/
        })
    }

    deleteBankingAccout = async () => {
        await this.state.bankingaccount.delete()
        this.props.onChange && this.props.onChange(this.state.bankingaccount)
    }

    bankingAccountChange = (value, target) => {
      const bankingaccount = this.state.bankingaccount
      bankingaccount[target] = value
      this.setState({bankingaccount})
    }

    saveChanges = async () => {
      await this.state.bankingaccount.save()
      this.props.onChange && this.props.onChange(this.state.bankingaccount)
      this.setState({show: false})
    }

    render() {
        if (!this.state.currency) {
            return <div>Objekt měny je prázdný!</div>
        }
       return <>
       {
           !this.state.currency.bankingaccount ? 
                <Button onClick={() => this.editBankingAccount()} variant="primary">Přiřadit</Button> :
                <>
                  <span onClick={() => this.editBankingAccount()} className="link">{this.state.currency.bankingaccount.label} <Pencil /></span>
                  <span className="text-danger" onClick={() => {this.deleteBankingAccout()}}><Trash /></span>                
                </>
                
       }
       <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nastavení bankovního účtu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Group controlId="account_number">
            <Form.Label>Číslo účtu</Form.Label>
            <Form.Control value={this.state.bankingaccount.account_number} type="text" placeholder="Číslo účtu" onChange={e => this.bankingAccountChange(e.target.value, "account_number")} />
        </Form.Group>
        <Form.Group controlId="iban">
            <Form.Label>IBAN</Form.Label>
            <Form.Control value={this.state.bankingaccount.iban} type="text" placeholder="IBAN" onChange={e => this.bankingAccountChange(e.target.value, "iban")} />
        </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={this.saveChanges}>
            Uložit změny
          </Button>
        </Modal.Footer>
      </Modal>
      </>
    }
}

export default BankingAccountSelector