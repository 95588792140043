import fetchProxy from '../Helpers/fetchProxy'
import MessageCenter from "../Components/MessageCenter"
import { QuestionDiamond } from 'react-bootstrap-icons'

class Base {
    constructor(data, id_attr) {
        id_attr = this.constructor.pk || id_attr
        data = data || {}
        if (Object.keys(data).indexOf('id') === -1) {
            if (id_attr === undefined) {
                throw new Error("No object is allowed without ID!")
            }
            else {
                data["id"] = data[id_attr]
            }
        }
        for (const [key, value] of Object.entries(data)) {
            this[key] = value
        }
        if (this.constructor.base_url === undefined){
            throw Error("No static base url defined for " + this.constructor.name)
        }
        this.callback(this)
    }

    static pk = 'id'

    static async serverCount(filters) {
        const url_params = Object.entries(filters).map(kv => kv.map(encodeURIComponent).join("=")).join("&");
        const url = this.base_url + "count/?" + url_params
        const res = await fetchProxy(url , {
        method: "GET",
            mode: 'cors',
            redirect: 'follow'
        })
        const data = await res.json()
        return data['count']
    }

    async save(data = undefined) {
        let object = {} 
        let src = data || this //If no explicit data to be saved, save whole object
        for (let attr in src) {
            if (!this.hasOwnProperty(attr) && typeof(src[attr]) !== 'function' && attr !== 'error') {
                if (typeof(src[attr] !== 'function' && attr !== 'error')) {
                    MessageCenter.addMessage(`VAROVÁNÍ: Detekována nekonzistence dat, atribut ${attr} hodnota ${src[attr]} na objekt ` + JSON.stringify(this))
                }
                continue
            }
            object[attr] = src[attr]
        }
        const id_attr = this.constructor.pk || 'id'
        if (!object[id_attr] && object[id_attr] !== 0) {
            throw EvalError("Object id is undefined!" + JSON.stringify(object))
        }
        const method = data !== undefined && object.id !== 0 ? "PATCH" : (object.id === 0 ? "POST" : "PATCH")
        const url = this.constructor.base_url + (object.id === 0 ? "" : object[id_attr] + "/")
        const res = await fetchProxy(url, 
            {
                method: method,
                mode: 'cors',
                redirect: 'follow',
                body: JSON.stringify(object)
            })
        const new_object = await res.json()
        this.__status = res.status
        if (res.status >= 400) {
            this.__messages = new_object
        }
        if (id_attr === 'id') {
            if (new_object.id !== undefined && new_object.id > 0 && Number.isInteger(new_object.id)) {
                this.id = new_object.id
            }
        } else {
            this.id = undefined
        }
        return new_object
    }

    async delete() {
        const id_attr = this.constructor.pk || 'id'
        const res = await fetchProxy(this.constructor.base_url + this[id_attr] + "/", 
            {
                method: 'DELETE',
                mode: 'cors',
                redirect: 'follow',
            })
        if (res.status !== 204) {
            throw Error("Wasn't able to delete " + this[id_attr])
        }
        else {
            return true
        }
    }

    callback = () => {
        // Here should be default callback. 
    }

    static icon() {
        return QuestionDiamond
    }

    static icon_as_component() {
        const Icon = this.icon()
        return <Icon />
    }
}

async function getOptions(klass) {
    try {
    const res = await fetchProxy(klass.base_url, //TODO Weak spot! Needs to be rewritten to use the base_url of every single class!
        {
            method: "OPTIONS",
            mode: 'cors',
            redirect: 'follow',
        })
    return res.json()
    } catch (error) {
        console.error(error)
        MessageCenter.addMessage({
            title: "Objevila se chyba.",
            text: `Načítání dat ze serveru pro entitu ${klass.constructor.name} má problémy. Zkuste obnovit stránku, pokud se chyba bude stále projevovat kontaktujte podporu`,
            detail: 'Chyba! \n' + JSON.stringify(error)
        })
    }
}

export {getOptions};
export default Base
