import Location from '../Classes/Location.js'
import Order from '../Classes/Order.js'

function OrderFromData(data, mapping) {
    const order = new Order({id: 0})
    Object.keys(mapping).forEach(key => {
        if (mapping[key]) {
            order[mapping[key]] = data[key]
        }
    })
    return order
}

function LocationFromData(data, mapping) {
    const location = new Location({id: 0})
    Object.keys(mapping).forEach(key => {
        if (key === 'time') {
            //console.log(data[mapping[key]], new moment(data[mapping[key]], moment.CUSTOM_FORMAT))
        }
        location[key] = data[mapping[key]]
    })
    location.type = mapping.type
    return location
}

export {OrderFromData, LocationFromData}

