import React, { useState } from 'react'
import { Button, Popover, OverlayTrigger } from 'react-bootstrap'
import settings from '../Settings'

/**
 * @class OverlayComponent
 * @extends React.Component
 * @brief Component for displaying content in a popover
 *
 * @property {string} text - Text to be displayed in the popover title
 * @property {string} helpTopic - Help topic to be displayed in the popover
 * @property {string} title - Title of the popover
 * @property {string} titleSize - HTML tag for the popover title
 * @property {string} placement - Placement of the popover
 * @property {node} children - Content of the popover
 * @property {string} minWidth - Minimum width of the popover
 *
 * @author lotricek
 */
class OverlayComponent extends React.Component {
    /**
     * @brief Constructor for the component
     * @param {Object} props - Properties of the component
     */
    constructor(props) {
        super(props)
        this.state = {
            show: false
        }
    }

    render() {
        const { text, helpTopic, title, titleSize, placement, children, minWidth, trigger, onClick} = this.props
        const popover = (
            <Popover id="popover-basic" style={{minWidth: minWidth ||"300px"}}>
              <Popover.Title as={titleSize || "h2"}>{title || text ||"Titulek"}</Popover.Title>
              <Popover.Content style={{fontSize: "16px"}}>
                {children || "Bez obsahu"}
              </Popover.Content>
            </Popover>
        );
        return (
            <OverlayTrigger 
                placement={placement || "bottom"} 
                overlay={popover}
                trigger={trigger || "hover"}
                >
                {text || "Prosím, doplňte tento text"}
            </OverlayTrigger>
        )
    }
}

export { OverlayComponent }