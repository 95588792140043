import MessageCenter from "./Components/MessageCenter"
import Account from "./Classes/Account"
import Attachment from "./Classes/Attachment"
import BankingAccount from "./Classes/BankingAccount"
import Base from "./Classes/Base"
import Carrier from "./Classes/Carrier"
import Contact from "./Classes/Contact"
import Currencies from "./Classes/Currencies"
import Currency from "./Classes/Currency"
import Customer from "./Classes/Customer"
import EmailTemplate from "./Classes/EmailTemplate"
import Expense from "./Classes/Expense"
import IcoAutocomplete from "./Classes/IcoAutocomplete"
import Label from "./Classes/Label"
import Location from "./Classes/Location"
import LogEntry from "./Classes/LogEntry"
import Order from "./Classes/Order"
import Place from "./Classes/Place"
import Profile from "./Classes/Profile"
import Queryset from "./Classes/Queryset"
import Snippet from "./Classes/Snippet"
import Support from "./Classes/Support"
import User from "./Classes/User"
import Vehicle from "./Classes/Vehicle"

export function db_type_to_html_type (field_options) {
    switch (field_options.type) {
        case "integer":
            return "number"
        case "string":
            if (field_options.max_length === undefined)
                return "text"
            return "string"
        default:
            console.warn("Unkown db_type " + field_options.type)
            return "string"
    }
}

export async function downloadFetchRequest(request, filename) {
    try {
        request = await request
    }
    catch {
        request.status = 500
    }
    
    if (request.status !== 200) {
        MessageCenter.addMessage({title: "Chyba stahování", text:"Stahování se nezdařilo, zkuste to znovu, případně kontaktujte podporu. HTTP kód - " + request.status})
        return;
    }
    
    const blob = await request.blob()
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();    
    a.remove();  //afterwards we remove the element again    
}

export function prependWithZeros(string, length) {
    let value = String(string);
    while (value.length < length) {
      value = "0" + value
    }
    return value
}

/* Date helpers */
export function dateToInputValue(date) {
    date = new Date(date)
    const value = [
      date.getFullYear(),
      prependWithZeros(date.getMonth() + 1, 2),
      prependWithZeros(date.getDate(), 2)
    ]
    return value.join("-")
  }
  
export function getStartOfWeek(date) {
    const value = new Date(date);
    value.setDate(date.getDate() - ((7 + date.getDay() - 1) % 7))
    return value
  }
  
export  function getStartOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1)
  }
  
export function getWeekNumber(date) {
    const january_first = new Date(date.getFullYear(), 0, 1)
    return Math.floor((date - january_first) / (24 * 60 * 60 * 1000) / 7)
  }
  
export function dateAddDays(date, num_days) {
    const value = new Date(date);
    value.setDate(value.getDate() + num_days)
    return value
}

export function sameDay(date1, date2) {
  date1 = new Date(date1)
  date2 = new Date(date2)
  // If timezone info is the same, just compare the values
  // (because otherwise you can get a different day)
  if (date1.getTimezoneOffset() === date2.getTimezoneOffset()) {
    return date1.toDateString() === date2.toDateString()
  }
  // Extract only date for both strings and compare that
  return date1.toISOString().split("T")[0] 
      === date2.toISOString().split("T")[0]
}
  
export const endOfRange = {
  "day": start => {return new Date(start.getFullYear(), start.getMonth(), start.getDate() + 1)},
  "week": start => {return new Date(start.getFullYear(), start.getMonth(), start.getDate() + 7)},
  "month": start => {return new Date(start.getFullYear(), start.getMonth() + 1, 1)},
  "year": start => {return new Date(start.getFullYear() + 1, 0, 1)},
}

export function dateRemoveTime(date) {
    const value = new Date(date)
    return new Date(
      value.getFullYear(),
      value.getMonth(),
      value.getDate()
    )
}

export function formatTime(time) {
  const date = new Date(time)
  if (date.toString() === "Invalid Date") return "Neplatný čas";
  return `${prependWithZeros(date.getHours(), 2)}:${prependWithZeros(date.getMinutes(), 2)}`
}

export function dateToLocal(date) {
  if (!date) return;
  const d = new Date(date)
  return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`
}

export function dateGetDayName(date) {
  const formatter = new Intl.DateTimeFormat(navigator.language, {
    weekday: 'short',
  })
  return formatter.format(new Date(date))
}

export function daysBetween(val1, val2) {
  const d1 = new Date(val1);
  const d2 = new Date(val2);
  return Math.abs(Math.ceil((d1.getTime() - d2.getTime()) / (1000*3600*24)))
}

export function secondsBetween(val1, val2) {
  const d1 = new Date(val1)
  const d2 = new Date(val2)
  return d1.getTime() - d2.getTime()
}

export function HumanReadableSeconds(val) {
  if (val == 0) return null;
  let output = val < 0 ? "Odečíst" : "Přičíst" 
  val = Math.floor(val / 1000) // From ms to seconds
  const steps = [3600*24, 3600, 60, 1];
  const symbols = ["d", "h", "m", "s"]
  const values = []
  for (let i = 0; i<steps.length; i++) {
    const step = Math.floor(val / steps[i]);
    const symbol = symbols[i];
    if (step) {
      values.push(step + symbol);
      val = val - step * steps[i];
    }
  }
  return output + " " + values.join(" ")
}

export function dateAddMS(date, milliseconds) {
  return new Date(new Date(date).getTime() + milliseconds)
}

export function classByName(name) {
    const CLASSES_DICT = {
        "account": Account,
        "attachment": Attachment,
        "bankingaccount": BankingAccount,
        "base": Base,
        "carrier": Carrier,
        "contact": Contact,
        "currencies": Currencies,
        "currency": Currency,
        "customer": Customer,
        "emailtemplate": EmailTemplate,
        "expense": Expense,
        "icoautocomplete": IcoAutocomplete,
        "label": Label,
        "location": Location,
        "logentry": LogEntry,
        "order": Order,
        "place": Place,
        "profile": Profile,
        "queryset": Queryset,
        "snippet": Snippet,
        "support": Support,
        "user": User,
        "vehicle": Vehicle
    }
    return CLASSES_DICT[String(name).toLowerCase()] || null
}