import React from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { Check, Search } from 'react-bootstrap-icons'
import fetchProxy from '../Helpers/fetchProxy'
import settings from '../Settings'
import MessageCenter from './MessageCenter'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const UPDATE_COOLDOWN = 300

class PreferenceField extends React.Component {

    state = {
        value: this.props.field.field.input_type !== "file" ? this.props.field.value : undefined,
        file: undefined,
        updater: undefined
    }

    async saveChanges() {
        const headers = {}
        let body = undefined
        if (this.props.field.field.input_type !== "file") {
            body = JSON.stringify({ "value": this.state.value })
        }
        else {
            body = new FormData()
            body.append('value', this.state.file)
            headers['Content-Type'] = undefined
        }
        const req = await fetchProxy(settings.GLOBAL_PREFERENCES_URL + this.props.field.identifier + "/", {
            method: 'PATCH',
            body,
            headers
        })
        if (req.status !== 200) {
            MessageCenter.addMessage("Aktualizace selhala! Načtěte stránku a zkuste to prosím znovu!")
        }
        else {
            this.props.updated && this.props.updated(await req.json())
        }
        this.setState({ updater: undefined })
        
    }

    valueChanged = (event) => {
        if (this.props.field.field.input_type === 'checkbox') {
            if (this.state.updater) {
                clearTimeout(this.state.updater)
            }
            this.setState({ value: event.target.checked })
        }
        else if (this.props.field.field.input_type !== 'file') {
            if (this.state.updater) {
                clearTimeout(this.state.updater)
            }
            this.setState({ value: event.target.value })
        }
        else {
            this.setState({ file: event.target.files[0] })
            event.target?.form?.reset()
        }
        const updater = setTimeout(this.saveChanges.bind(this), UPDATE_COOLDOWN)
        this.setState({ updater })
    }

    render() {
        return (
            this.props.field.field.input_type === "checkbox" ?
                <Row className='border rounded py-1' style={{marginLeft: "2px", marginRight: "2px"}}>
                    <Col md={10}>
                            <Form.Label >
                                {this.props.field.verbose_name}
                                <small className="text-muted ml-3">{this.props.field.help_text}</small>
                            </Form.Label>
                    </Col>
                    <Col md={2} className="d-flex align-items-center justify-content-end">
                            {this.state.updater ? 
                                <Spinner size="m" animation="border"></Spinner> :
                                <Form.Check style={{ transform: "scale(2)" }} type="checkbox" value={this.state.value} checked={this.state.value} onChange={this.valueChanged} />}
                    </Col>
                </Row> :
                <Row>
                    <Col>
                        <Form.Group controlId={this.props.field.identifier}>
                            <Form.Label>{this.props.field.verbose_name}</Form.Label>
                            {
                                this.props.field.field.input_type === "select" ?
                                    <Form.Control as="select" value={this.state.value} onChange={this.valueChanged} readOnly={this.props.field.additional_data.choices === undefined}>
                                        {this.props.field.additional_data.choices && Object.keys(this.props.field.additional_data.choices).map(key => {
                                            const value = this.props.field.additional_data.choices[key]
                                            if (Array.isArray(value)) {
                                                return <option key={key} value={value[0]}>
                                                    {value[1]}
                                                </option>
                                            }
                                            else {
                                                return <option key={key} value={key}>
                                                    {value}
                                                </option>
                                            }
                                        })}
                                    </Form.Control> :
                                    <Form.Control type={this.props.field.field.input_type} value={this.state.value} onChange={this.valueChanged} />
                            }

                            <Form.Text className="text-muted d-flex flex-row justify-content-between">
                                <div>
                                    {this.props.field.field.input_type === 'file' && <a target="_blank" rel="noopener noreferrer" className="d-block" href={`${settings.BASE_URL}${this.props.field.value}`}>
                                        <Search /> {this.props.field.value}
                                    </a>}
                                    {this.props.field.help_text}
                                </div>
                                <div className="text-right">{this.state.updater ? <><Spinner size="sm" animation="border"></Spinner> ukládání ... </> : <><Check />Uloženo</>}</div>
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                )
    }

}

export default PreferenceField