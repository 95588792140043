import React from 'react'
import {Button} from 'react-bootstrap'
/*//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPencil } from '@fortawesome/pro-solid-svg-icons'*/
import LocationModal from './LocationModal'
import { Trash, Pencil } from 'react-bootstrap-icons'


const str_mapper = {// TODO RENAME THIS
  LOAD: "Nakládka",
  UNLOAD: "Vykládka"
}

class LocationComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      location: this.props.location
    }
  }


  async delete() {
    const result = await this.state.location.delete()
    if (result === true) {
      if (this.props.deleted !== undefined) {
        this.props.deleted(this.state.location)
      }
    }
  }

  async edited(location) {
    console.log(location)
    await location.save()
    this.setState({location})
  }

  render() {
    const contact = this.state.location.name + 
        (this.state.location.phone !== "" ? " " + this.state.location.phone : "") +
        (this.state.location.email !== "" ? " " + this.state.location.email : "")
    const date = new Date(this.state.location.time)
    const date_string = new Intl.DateTimeFormat("cs-CZ", {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }).format(date)
    return (
      <div className="clearfix list-group-item">
        { !this.props.hide_controls && <>
            <Button variant="danger" size="sm" className="float-right" onClick={() => {this.delete()}}>
              <Trash />
            </Button>
            <LocationModal label={<Pencil />} size="sm" position="right" location={this.state.location} closeCallback={(location) => this.edited(location)}></LocationModal>
          </>
        }
        <h4>{date_string} - {str_mapper[this.state.location.type].toLowerCase()} - {this.state.location.location_name}</h4>
        <p>Kontakt: {contact}</p>
        {this.state.location.code !== "" ? <p>CODE: {this.state.location.code}</p> : ""}
        <h5>Adresa</h5>
        <p>
          {this.state.location.street} {this.state.location.house_number !== 0 ? this.state.location.house_number : ""}<br />
          {this.state.location.town} {this.state.location.postal_code}<br />
          {this.state.location.country}
        </p>
      {!this.state.location.notes ? "" :
        <>
          <h5>Poznámky k zastávce</h5>
          {this.state.location.notes}
        </>
      }
      </div>
    )     
  }
}

export default LocationComponent;
