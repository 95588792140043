import { withRouter } from "react-router-dom";
import ModelView from './ModelView';
import Order from '../Classes/Order'
import MessageCenter from '../Components/MessageCenter'
import React from 'react'
import { Button } from "react-bootstrap";
import Account from "../Classes/Account";

class OrderView extends ModelView {
    constructor(props) {
        super(Order, props)
        this.jumbotronTitle = "Objednávky"
        this.jumbotronSubtitle = <em>Zvýrazněné objednávky nemají vozidlo, ani dopravce.</em>
        this.modalNewPlaceholder = "Nová objednávka"
        this.modalNewLabel = "Nová objednávka"
        this.modalNewTitle = "Nová objednávka"
        this.show_controls = false
        this.new_button = <Button variant="success" onClick={() => {this.new()}}>
            Nová objednávka
        </Button>
        this.extra_buttons = [<Button key="orderimporter" onClick={() => {this.props.history.push("/order-filter/")}}>Filtrování objednávek</Button>]
        this.filters = [
            {
                label: "Stav",
                type: "select",
                name: "state",
                default: "NEW",
                getValues: () => {
                    return this.state.options ? 
                    this.state.options.actions.POST.state.choices : []
                }
            }
        ]
        if (Account.current().level > 0 || Account.current().user_permissions.filter(i => i.codename === 'all_orders').length) {
            this.filters.push({
                label: "Jenom mé objednávky",
                type: "checkbox",
                default: window.location.host.includes('wasso') || window.location.host.includes('localhost') ? Account.currentUser().id : null,
                name: "user",
                value: Account.currentUser().id
            }) 
        }
    }

    highlight(item) {
        if (!item.carrier && !item.vehicle)
            return "Nemá dopravce ani vozidlo"
    }


    async new() {
        let object = new Order({"id": 0})
        object = await object.save()
        if (object.id !== undefined && object.id > 0) {
            this.itemDetail(object)
        }
        else {
            MessageCenter.addMessage({
                title: 'Chyba při ukládání!',
                text:'Bohužel se nepovedlo uložit objednávku. Zkuste to prosím znovu, nebo kontaktujte podporu',
                detail: 'Data použitá k uložení: \n' + JSON.stringify(object)
            })
        }
    }
}

export default withRouter(OrderView);
