import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Button, Modal, Form, Badge, InputGroup, FormControl } from "react-bootstrap";
import { Pencil, Plus, Search } from "react-bootstrap-icons";
import Queryset from "../Classes/Queryset";
import Requirement from "../Classes/Requirement";
import RequirementCategory from "../Classes/RequirementCategory";
export function OrderLoadInformation(props) {

    const [show, setShow] = useState(false);
    const [search, setSearch] = useState("");
    const [selected, setSelected] = useState();
    const [options, setOptions] = useState(null);
    const [upstreamOptions, setUpstreamOptions] = useState(null);
    const searchRef = useRef();

    function display() {
        setShow(true)
        setSearch("")
    }

    function hide() {
        setShow(false)
    }

    async function createRequirement(name) {
        const requirement = new Requirement({
            "id": 0,
            name
        })
        const obj = await requirement.save()
        const categories = [...upstreamOptions]
        const category = categories.find(i => i.id === 0)
        category.requirement_set.push(obj)
        setUpstreamOptions(categories)
        setSelected({...selected, [obj.id]: true})
    }

    useEffect(() => {
        if (show) {
            searchRef.current && searchRef.current.focus()
        }
    }, [show])

    useEffect(() => {
        if (!upstreamOptions) return;
        const search_string = search.toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        let res = upstreamOptions.map(category => {
            const cpy = {...category};
            cpy.requirement_set = category.requirement_set.filter(i => i.name.toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search_string) !== -1)
            return cpy
        })
        setOptions(res)
    }, [upstreamOptions, search])

    useEffect(() => {
        setSelected(Object.fromEntries(props.order.requirements.map(i => [i.id, true])))
    }, [props.order.requirements])

    useEffect(() => {
        const queryset = new Queryset(RequirementCategory);
        /*queryset.all().then(
            //() => {setUpstreamOptions()}
            console.log(queryset.objects)
        )*/
        async function load() {
            await queryset.all()
            setUpstreamOptions(queryset.objects)
        }
        load()
    }, [])

    async function save() {
        const requirements = Object.entries(selected).filter(i => i[1]).map(i => parseInt(i[0])).flat()
        await props.order.save({requirements, id: props.order.id})
        setShow(false);
    }

    function RequirementCategoryDiv(props) {
        const category = props.category
        return <div>
            <h5 className={category.requirement_set.length ? "" : "text-muted"}>{category.name}</h5>
            {category.requirement_set.map(i => <Form.Check
                key={i.name}
                label={i.name}
                id={i.id}
                checked={selected[i.id] !== undefined ? selected[i.id] : false}
                onChange={e => setSelected({...selected, [i.id]: e.target.checked})}
            />)}
        </div>
    }


    if (!options || !props.order) return <em>Načítání ...</em>;
    return <div className="d-flex justify-content-between align-items-center">
        <div className="mw-100 mr-2">
            {props.order.requirements?.length === 0 && <em>Bez požadavků</em>}
            <div className="d-flex flex-wrap" style={{gap: "3px"}}>
                {props.order?.requirements?.map(i => <Badge variant="primary" style={{whiteSpace: "normal"}} key={i.id}>{i.name}</Badge>)}
            </div>
        </div>
        <Button onClick={() => display()} size="sm" variant="success"><Pencil /></Button>
        <Modal size="xl" show={show} onHide={() => {setShow(false)}}>
            <Modal.Header className="d-flex justify-between-content">
                <Modal.Title>Požadavky</Modal.Title>
                <div className="d-flex">
                    <InputGroup>
                        <FormControl
                            placeholder="Hledat nebo přidat..."
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            ref={searchRef}
                        />
                        <InputGroup.Append>
                            <InputGroup.Text><Search /></InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                    <button onClick={hide} type="button" className="close">
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div style={{display: "grid", gap: "10px", gridTemplateColumns: "repeat(4, 1fr)", gridTemplateRows: "masonry"}}>
                        {options.map(i => <RequirementCategoryDiv key={i.id} category={i} />)}
                    </div>
                    {options.filter(i => i.requirement_set.length).length === 0 && <div className="d-flex align-items-center flex-column">
                        <h2 className="text-muted">Tady nic není k mání</h2>
                        {search && <Button onClick={e => createRequirement(search)} variant="success">
                                <Plus /> Vytvořit "{search}"
                        </Button>}
                    </div>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {setShow(false)}}>
                    Storno
                </Button>
                <Button variant="primary" onClick={() => save()}>
                    Uložit
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
}