import React from 'react'
import {Form, Button} from 'react-bootstrap'

class RangeSelector extends React.Component {
    state = {
        operator: "",
        value: 0
    }

    componentDidMount() {
        this.props.toggleFocus && this.props.toggleFocus(true)
    }

    submit() {
        this.props.toggleFocus && this.props.toggleFocus(false)
        this.props.valuesSelected && this.props.valuesSelected({
            "operator": this.state.operator,
            "value": this.state.value
        })
    }

    render() {
        return <div className="px-2">
            <Form.Group>
                <Form.Label>Porovnání</Form.Label>
                <Form.Control as="select" custom value={this.state.operator} onChange={e => this.setState({operator: e.target.value})}>
                    <option value="">{"="}</option>
                    <option value="__gte">{">"}</option>
                    <option value="__lte">{"<"}</option>
                </Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label>Hodnota</Form.Label>
                <Form.Control type="number" onChange={e => this.setState({value: e.target.value})} value={this.state.value} />
            </Form.Group>
            <Button onClick={e => this.submit()}>Potvrdit</Button>
        </div>
    }
}

export { RangeSelector }