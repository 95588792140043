import React from 'react'
import { withRouter } from "react-router-dom";
import { Alert, Container } from 'react-bootstrap';

class NotFoundView extends React.Component {
    render() {
        return (<Container>
            <h1>Whooooooops!</h1>
            <p>This path does not exist in the router. </p>
            <Alert variant="danger">
                <strong>Path: </strong> {this.props.history.location.pathname}
            </Alert>
        </Container>)
    }
}

export default withRouter(NotFoundView);