import React from 'react';
import { Button, Form } from 'react-bootstrap';


class DatePicker extends React.Component {

    state = {
        __gte: '',
        __lte: ''
    }

    returnResult() {
        const res = {}
        if (this.state.__gte !== '') {
            res.__gte = this.state.__gte
        }
        if (this.state.__lte !== '') {
            res.__lte = this.state.__lte
        }
        this.props.onClick && this.props.onClick(res)
    }

    render() {
        return <div className="overflow-hidden p-2">
                <Form>
                <Form.Group controlId="dateFrom">
                    <Form.Label>Od:</Form.Label>
                    <Form.Control type="date"
                                onBlur={e => this.props.onBlur && this.props.onBlur(e)}
                                onFocus={e => this.props.onFocus && this.props.onFocus(e)}
                                onChange={e => this.setState({'__gte': e.target.value})} value={this.state.__gte}/>
                </Form.Group>
                <Form.Group controlId="dateTo">
                    <Form.Label>Do:</Form.Label>
                    <Form.Control type="date"
                                onBlur={e => this.props.onBlur && this.props.onBlur(e)}
                                onFocus={e => this.props.onFocus && this.props.onFocus(e)}
                                onChange={e => this.setState({'__lte': e.target.value})} value={this.state.__lte}/>
                </Form.Group>
                <Button variant="success" onClick={() => this.returnResult()}>
                    Vybrat
                </Button>
                </Form>
            </div>
    }
}

export default DatePicker