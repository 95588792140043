import { Editor, EditorState } from "draft-js";
import { useEffect, useState } from "react";
import { Container, Jumbotron, Col, Row, Form, ButtonGroup, Button, Spinner, InputGroup } from "react-bootstrap";
import { useRouteMatch } from "react-router-dom";
import Expense from "../Classes/Expense";
import Queryset from "../Classes/Queryset";
import User from "../Classes/User";
import LoadingBanner from "../Components/LoadingBanner";
import React from 'react';
import { Trash } from "react-bootstrap-icons";
import { ManyClassModelSelector } from "../Components/ManyClassModelSelector";
import { getOptions } from "../Classes/Base";
import { classByName } from "../helpers";
import { Link } from "react-router-dom";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import Currency from "../Classes/Currency";

const SAVE_TIMEOUT = 700;

export function ExpenseDetail() {
    const expense_id = useRouteMatch().params.id;
    const [expense, setExpense] = useState();
    const [saver, setSaver] = useState();
    const [options, setOptions] = useState();
    const [currencies, setCurrencies] = useState();
    const [editorState, setEditorState] = React.useState(
        () => EditorState.createEmpty(),
    );

    const updateEditorState = state => {
        setEditorState(state);
        update("text", stateToHTML(state.getCurrentContent()))
    }

    useEffect(() => {
        async function load() {
            const queryset = new Queryset(Expense)
            const queryset_currencies = new Queryset(Currency)
            const async_expense = queryset.one(expense_id)
            const async_currencies = queryset_currencies.all()
            const actions = await getOptions(Expense);
            setOptions(actions.actions.POST)
            await async_expense
            await async_currencies
            setExpense(queryset.object)
            setCurrencies(queryset_currencies.objects)
            setEditorState(EditorState.createWithContent(stateFromHTML(queryset.object.text || "")))
        }
        load()
    }, [expense_id])

    const update = (key, value) => {
        const new_expense = new Expense({
            ...expense,
            [key]: value
        })
        setExpense(new_expense)
        if (saver) {
            clearTimeout(saver)
        }
        setSaver(setTimeout(async () => {
            const new_expense = new Expense({
                ...expense,
                [key]: value
            })
            await new_expense.save()
            clearTimeout(saver)
            setSaver()
            setExpense(new_expense)
        }, SAVE_TIMEOUT))
    }

    const relatedItemSelected = (item, klass) => {
        update("relates_to", {
            "model": klass,
            "id": item.id,
            "data": item
        })
    }

    if (!expense || !currencies) {
        return <Container>
            <LoadingBanner />
        </Container>
    }

    let relates_to;
    // Rebuild the relates_to object
    if (expense.relates_to) {
        const relates_to_klass = classByName(expense.relates_to.model)
        if (relates_to_klass) {
            relates_to = new relates_to_klass(expense.relates_to.data)
        }       
    }
    // Render
    return <Container>
        <Jumbotron>
            {saver && <div className="text-primary float-right"><Spinner size="sm" animation="border" /> Ukládání</div>}
            <h1>{expense.name}</h1>
        </Jumbotron>
        <Row>
            <Col md={4}>
                <Form.Group>
                    <Form.Label><strong>Název</strong></Form.Label>
                    <Form.Control name="name" value={expense.name} onChange={e => update("name", e.target.value)}  isInvalid={!expense.name}/>
                    <Form.Control.Feedback type="invalid">Jméno nesmí být prázdné!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Hodnota</Form.Label>
                    <InputGroup>
                        <Form.Control type="number" value={expense.value} onChange={e => update("value", e.target.value)} />
                        <InputGroup.Append>
                            <Form.Control as="select" custom value={expense.currency} onChange={e => {update("currency", e.target.value)}}>
                                {currencies.map((c, key) => {
                                        return <option key={key} value={c.code}>{c.label}</option>
                                })}
                            </Form.Control>
                        </InputGroup.Append>
                    </InputGroup>
                </Form.Group>
                <Form.Group>
                    <Form.Label><strong>Dokument</strong></Form.Label>
                    <Form.Control name="name" value={expense.document_number} onChange={e => update("document_number", e.target.value)}/>
                    <Form.Text className="text-muted">Označení faktury, účtenky, nebo jiného dokumentu, který se k výdaji vztahuje.</Form.Text>
                </Form.Group>
                <Form.Group>
                    <Form.Label><strong>Plnění</strong></Form.Label>
                    <Form.Control type="date" name="accountable_at" value={expense.accountable_at} onChange={e => update("accountable_at", e.target.value)}/>
                    <Form.Text className="text-muted">Kdy se má výdaj projevit v systému.</Form.Text>
                </Form.Group>
                <Form.Group>
                    <Form.Label><strong>Vztahuje se k</strong></Form.Label>
                    <div>{relates_to ?
                            <Link to={`/${expense.relates_to.model}/${expense.relates_to.id}`}>{React.createElement(relates_to.constructor.icon())} {relates_to.label()}</Link> :
                            <em>Není přiřazený</em>
                    }</div>
                    <ButtonGroup size="sm" className="d-flex justify-content-end">
                        {options && <ManyClassModelSelector className="flex-grow-0" klassList={options.relates_to.choices} itemSelected={relatedItemSelected} />}
                        <Button onClick={() => update("relates_to", null)} className="flex-grow-0" variant="outline-danger" disabled={!expense.relates_to}><Trash /></Button>
                    </ButtonGroup>
                </Form.Group>
                <Form.Group>
                    <Form.Label><strong>Založeno</strong></Form.Label>
                    <table className="w-100">
                        <tbody>
                            <tr>
                                <th>Datum</th>
                                <td>{new Date(expense.created).toLocaleDateString()} {new Date(expense.created).toLocaleTimeString()}</td>
                            </tr>
                            <tr>
                                <th>Uživatel</th>
                                <td>{new User(expense.user).label()}</td>
                            </tr>
                        </tbody>
                    </table>
                </Form.Group>
            </Col>
            <Col>
                <h2>Poznámky</h2>
                <div className="border p-1 rounded">
                    <Editor editorState={editorState} onChange={updateEditorState}/>
                </div>
            </Col>
        </Row>
    </Container>
}