import React from 'react'
import {Card, Dropdown, Badge} from 'react-bootstrap'
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import DropdownItem from 'react-bootstrap/DropdownItem';
import { MenuUp } from 'react-bootstrap-icons';


class ContactComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {contact: this.props.contact}
  }

  async delete() {
    if (!window.confirm("Chcete smazat tento kontakt?")) {
      return
    }
    const result = await this.props.contact.delete()
    if (result === true) {
      if (this.props.contactDeleted) {
        this.props.contactDeleted(this.props.contact)
      }
    }
  }

  edit() {
    this.props.onEdit && this.props.onEdit(this.props.contact)
  }

  async onToggled(event) {
    const contact = this.state.contact
    contact[event.target.id] = event.target.checked
    await contact.save()
    this.setState({contact})
  }  

  render() {
    const fields = ['email', 'phone']
    const informations = fields.map((item) => {
        if (this.props.contact[item] !== undefined && this.props.contact[item] !== "") {
            return <p key={item}>{this.props.contact[item]}</p>
        }
        else return ""
    })
    return (
    <Card className="card p-2 mt-2">
        <Card.Body>
            <Card.Title className="d-inline-flex justify-content-between col">
                {this.props.contact.name}
                <Dropdown>
                  <Dropdown.Toggle variant="outline-dark" size="sm"><MenuUp /></Dropdown.Toggle>
                  <DropdownMenu size="sm">
                      <DropdownItem onClick={()=>this.edit()}>Upravit</DropdownItem>
                      <DropdownItem onClick={()=>this.delete()}>Odebrat</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
            </Card.Title>
            {informations}
            {this.props.contact.is_informacni && <Badge pill label="Bude uveden na dokumentech jako kontaktní" variant="primary">Kontakt pro komunikaci</Badge>}
        </Card.Body>
    </Card>
    )     
  }
}

export default ContactComponent;
