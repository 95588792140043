import moment from "moment"

export default function transformField(value, column) {
    /* If no obj is specified, nothing should be rendered */
    if (!value) {
        return null
    }

    /* if explicit transform function is defined -> use it */
    if (typeof(value) === "object") {
        /* does the object have a labeling method, try to call it? */
        try {
            return value.label()
        } catch {
            /* Nope, let's use some std. attributes or give up */
            const label = value.label || value.name || null
            /* Just strings or numbers */
            return typeof(label) === "number" || typeof(label) === "string" ? label : "Nelze zobrazit"
        }
    }
    /* If it is a string, it could be a date */
    if (typeof(value) === "string") {
        /* it is not a date */
        if (!moment.isDate(value)) {
            return value
        }
        /* valid date - if the value is short (up to 12 chars, it's just a date without time) */
        // TODO This can backfire on TIMESTAMPS! handle time properly
        const date = moment(value)
        return value.length <= 12 ? date.format("DD. MM. YYYY") : date.format("DD. MM. YYYY HH:mm")
    }
    return value
}