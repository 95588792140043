import React from "react";
import { Jumbotron, Container } from "react-bootstrap";

class ErrorBoundary extends React.Component {
    state = {
        errorState: false
    }

    componentDidCatch(error, info) {
        this.setState({errorState: true, error, info})
    }

    render() {
        return !this.state.errorState ? 
            this.props.children :
            <Container>
                <Jumbotron>
                    <h1>Chyba!</h1>
                    <p>Aplikace je stále ve vývoji. Zkuste to prosím znovu a jestli problém přetrvává, kontaktujte prosím podporu</p>
                    <h2>Technické detaily</h2>
                    <pre>{JSON.stringify(this.state.error)}</pre>
                    <pre>{JSON.stringify(this.state.info)}</pre>
                </Jumbotron>
            </Container>
    }
}

export default ErrorBoundary