import { useState, useCallback } from "react";
import { Alert, Button, Card, CardGroup, Container, Form, Spinner, Table } from "react-bootstrap";
import { ArrowReturnLeft, Link, QuestionCircle, SendCheckFill, X } from "react-bootstrap-icons";
import { Link as RouterLink } from "react-router-dom";
import MessageCenter from "../Components/MessageCenter"
import Invoice from "../Classes/Invoice"
import Customer from "../Classes/Customer";
import { getOptions } from "../Classes/Base";

export function InvoiceConnectorWizard() {
    const [step, setStep] = useState(0);
    const [filename, setFilename] = useState();
    const [validationRunning, setValidationRunning] = useState(false)
    const [validationResult, setValidationResult] = useState()
    const [submittingChanges, setSubmittingChanges] = useState(false)
    const [patchedInvoices, setPatchedInvoices] = useState([])
    const [invoiceOptions, setInvoiceOptions] = useState(undefined)

    function reset() {
        setValidationResult()
        setValidationRunning(false)
        setSubmittingChanges(false)
        setPatchedInvoices([])
    }

    /* Submit changes to the BE */
    async function submitChanges() {
        const invoices = validationResult?.result?.filter(item => item.submit)
        if (!invoices || invoices.length === 0) {
            MessageCenter.addMessage("Nebyly nalezeny žádné faktury k odeslání")
            return;
        }

        setSubmittingChanges(true)
        try {
            const responsedInvoices = await Promise.all(invoices.map(async item => {
            const data = {
                id: item.result === "CREATE" ? 0 : item.invoice,
                ...item.changes
            }
            if (item.connector) {
                if (item.invoice) {
                    const old_connector = [item.invoice.external_connector, item.invoice.external_id].join("/")
                    if (old_connector != "/" && item.connector != old_connector) {
                        MessageCenter.addMessage({
                            title: "Konflikt při propojování faktur!",
                            text: `Pokus o přepis propojení faktur. 
                                Nahlašte tuto chybu, odešlete soubor použitý k importování.
                                Údaje o konfliktu: Staré propojení ${old_connector}, nové ${item.connector}`
                        })
                        return;
                    }
                } 
                const parts = item.connector.split("/")
                if (parts.length === 2) {
                    data['external_connector'] = parts[0]
                    data['external_id'] = parts[1]
                }
            }
            const invoice = new Invoice(data)
            return new Invoice(await invoice.save(data))
            }))
            setPatchedInvoices(responsedInvoices)
        }
        catch (error) {
            MessageCenter.addMessage({
                "title": "Import jedné nebo více faktur se nezdařil",
                "message": "Zkuste to znovu, případně kontaktujte podporu. Nezapomeňte přiložit nahrávaná data a stručně pospat incident."
            })
            console.log(error)
        }
        finally {
            setSubmittingChanges(false)
        }
    }

    /* Handle file selection */
    async function onFileSelect(file) {
        // If no file was provided
        if (!file) {
            MessageCenter.addMessage({
                "title": "Nepodařilo se nahrát soubor",
                "message": "Zkuste to znovu, případně zkontrolujte soubor!"
            })
            return;
        }

        // Submit the file
        let res;
        setFilename(file.filename)
        const loadInvoiceOptions = getOptions(Invoice);
        try {
            res = await Invoice.connectorInspectFile(file)
            if (res.status === 415) {
                const body = await res.json()
                console.log(res.status, body)
                throw new Error(body.detail)
            }
            if (res.status !== 200) {
                console.log(res.status, await res.text())
                throw new Error("Neočekávaná odpověď serveru " + res.status)
            }
            // Add "checked" true to the result
            const data = await res.json()
            data.result = data.result.map(item => {
                item.submit = item.result == "UPDATE"
                return item
            })
            setInvoiceOptions((await loadInvoiceOptions).actions.POST)
            setValidationResult(data)
            if (data.warnings) {
                for (let warning of data.warnings) {
                    MessageCenter.addMessage({
                        "title": "Pozor, nastala chyba při importu",
                        "text": <>
                            {warning}
                            <br />
                            <em>Tato chyba není kritická, ale zkontrolujte prosím data!</em>
                        </>
                    })
                }
            }
        }
        catch (err) {
            console.log(err)
            MessageCenter.addMessage({
                "title": "Nepodařilo se odeslat soubor na server",
                "text": "Zkuste to znovu, případně nahlašte chybu!\n Více o chybě:\n" + err.message
            })
            setFilename()
            setValidationRunning(false)
            return;
        }
        setValidationRunning(false)
    }

    /* Toggle Invoice Checkbox if you don't want to send it */
    function toggleInvoiceCheckbox(index) {
        try {
            let invoice = validationResult.result[index]
            invoice.submit = !invoice.submit
        }
        catch {
            MessageCenter.addMessage({
                title: `Nepodařilo se přepnout fakturu na ${index + 1}. pozici. `
            })
        }
        setValidationResult({
            ...validationResult
        })
    }


    if (patchedInvoices && patchedInvoices.length > 0) return <Container>
        <div className="d-flex justify-content-between align-items-center">
            <div>
                <h2 className="pb-0 mb-0">Výsledek</h2>
                <small>Upravené faktury</small>
            </div>
            <div>
                <Button onClick={() => reset()}>
                <ArrowReturnLeft /> Další import
                </Button>
            </div>
        </div>
        <CardGroup>
            {patchedInvoices.map(invoice => <Card key={invoice.id}>
                <Card.Body>
                    <Card.Title>
                        <div className="d-flex justify-content-between align-items-center">
                            <div><RouterLink to={`/invoice/${invoice.id}`}>Faktura #{invoice.id}</RouterLink></div>
                            <div>
                                <small>
                                    {(invoice.external_connector || invoice.external_connector) &&
                                        `${invoice.external_connector}/${invoice.external_id}`}
                                </small>
                            </div>
                        </div>
                    </Card.Title>
                    <Card.Text as="div">
                        <Table>
                            <tbody>
                                <tr>
                                    <th>Objednávky</th>
                                    <td>{invoice.orders && invoice.orders.map(order => order.code).join(", ")}</td>
                                </tr>
                                <tr>
                                    <th>Zákazník</th>
                                    <td>{invoice.customer && new Customer(invoice.customer).label()}</td>
                                </tr>
                                <tr>
                                    <th>Splatnost</th>
                                    <td>{invoice.due_date && new Date(invoice.due_date).toLocaleDateString()}</td>
                                </tr>
                                <tr>
                                    <th>Zaplaceno</th>
                                    <td>{invoice.payment ? new Date(invoice.payment.paid_on).toLocaleDateString() : <em>prázdné</em>}</td>
                                </tr>
                                {invoice?.customer?.order && <tr>
                                    <th>Číslo objednávky u zákazníka</th>
                                    <td>{invoice.customer_order}</td>
                                </tr>}
                            </tbody>
                        </Table>
                    </Card.Text>
                </Card.Body>
            </Card>)}
        </CardGroup>
    </Container>

    if (validationResult) return <>
        <Container>
            <Alert variant="secondary">
                <small>
                    Systém zkontroluje změny a předvede zobrazené úpravy.
                    Zkontrolujte změny, pokud je nějaká faktura v nepořádku, 
                    můžete ji odebrat ze seznamu a potvrďte změny.
                </small>
            </Alert>
        </Container>
        <Table>
            <thead>
                <tr>
                    <th>
                        {validationResult?.result?.filter(item => item.submit).length}
                        /
                        {validationResult?.result?.length}
                        </th>
                    <th>Interní ID</th>
                    <th>Stav</th>
                    <th>Cena</th>
                    <th>Propojení</th>
                    <th>Změny</th>
                    <th>Zprávy</th>
                </tr>
            </thead>
            <tbody>
            {validationResult?.result.length === 0 ? <tr><td>V importu nebyly nalezeny žádné faktury!</td></tr> : validationResult.result.map((res, index) => <tr key={index}>
                    <td><input type="checkbox" disabled={res.status == "ERROR" || undefined } onChange={() => toggleInvoiceCheckbox(index)} checked={res.submit} /></td>
                    <td>{res.invoice}</td>
                    <td>{res.result}</td>
                    <td>{res.customer_price} / {res.customer_currency}</td>
                    <td>{res.connector}</td>
                    <td><ul>{Object.entries(res.changes).map(item => {
                        const [key, value] = item
                        return <li key={key}>
                            <strong>{invoiceOptions[key]?.label || value?.label || key}:</strong> 
                            {value?.render || value}
                        </li>
                    })}</ul></td>
                    <td><ul>{res?.messages?.map((message, index) => {
                        return <li key={index}>
                            {message}
                        </li>
                    })}</ul></td>
                </tr>)}
            </tbody>
        </Table>
        <div className="d-flex justify-content-end mb-2">
            {!submittingChanges ? <>
            <Button className="mr-2" variant="outline-primary" onClick={() => setValidationResult()}>Storno <X /> </Button>
            <Button 
                disabled={validationResult?.result?.filter(item => item.submit).length === 0}
                onClick={submitChanges}>
                Potvrdit změny! <SendCheckFill />
            </Button>
            </> : <Spinner variant="outline" />}
        </div>
    </>


    return <>
        <div className="d-flex justify-content-center">
            <div style={{ maxWidth: "400px", flexGrow: 1 }}>
                <h2 className="text-center">Soubor s fakturami</h2>
                <Alert variant="secondary" className="text-center">
                    <small>
                        Nahrajte soubor s exportovanými fakturami.{" "}
                        <a href="https://docs.logisticiq.cz/topic/pohoda-integrace#export-faktury-ze-systemu-do-pohody">
                            Jak na to?
                        </a>
                    </small>
                </Alert>
                <Form.File label="Zdrojový soubor" custom onChange={e => onFileSelect(e.target.files[0])} />
            </div>
        </div>
    </>
}

export function InvoiceConnector() {
    return <Container fluid className="pt-3">
        <Container>
            <div className="d-flex justify-content-between align-items-center">
            <h2><Link /> Propojení faktur</h2>
            <Button target="_blank" size="sm" href="https://docs.logisticiq.cz/topic/pohoda-integrace" variant="outline-primary">
                <QuestionCircle />
            </Button>
            </div>
        </Container>
        <InvoiceConnectorWizard />
    </Container>
}