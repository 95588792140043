import React, { Component } from 'react'
import { Container, Jumbotron, Spinner, Form, Row, Col, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap'
import { TypeBold, TypeItalic, TypeUnderline } from 'react-bootstrap-icons';
import { Check2 } from 'react-bootstrap-icons'
import {withRouter} from 'react-router-dom'
import { getOptions } from '../Classes/Base'
import EmailTemplate from '../Classes/EmailTemplate'
import Queryset from '../Classes/Queryset'
import {Editor, EditorState, RichUtils} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import '../css/Editor.css';
import 'draft-js/dist/Draft.css';

const SAVE_DELAY = 1000

class EmailTemplateDetail extends Component {
    state = {
        editorState: EditorState.createEmpty(),
        queryset: new Queryset(EmailTemplate),
        options: undefined,
        updater: {}
    }

    async componentDidMount() {
        await this.state.queryset.one(this.props.match.params.id)
        const options = await getOptions(EmailTemplate)
        const editorState = EditorState.createWithContent(stateFromHTML(this.state.queryset.object.body))
        this.setState({
            options,
            editorState
        })
    }

    async delete() {
        
    }

    async valueChanged(event) {
        const et = this.state.queryset.object
        const queryset = this.state.queryset
        et[event.target.id] = event.target.value
        queryset.object = et
        if (this.state.options.actions.POST[event.target.id].type === "string" || event.target.value !== "") {
            await this.saveChanges(event.target.id)
        }
        this.forceUpdate()
    }

    async saveChanges(name) {
        // Helper function for the timeout
        const storeChange = (name) => {
            const et = this.state.queryset.object
            const updater = this.state.updater
            const data = { id: et.id }
            data[name] = et[name]
            et.save(data).then(() => {
                updater[name] = undefined
                this.state.queryset.object = et
                this.setState(updater)
            })
        }

        let updater = this.state.updater
        if (updater[name] !== undefined) {
            clearTimeout(updater[name])
        }
        updater[name] = setTimeout(storeChange.bind(this, name), SAVE_DELAY)
        this.setState(updater)
    }

    editorChange = async (editorState) => {
        this.setState({editorState})
        const event = {
            target: {
                id: "body",
                name: "body",
                value: stateToHTML(editorState.getCurrentContent())
            }
        }
        this.valueChanged(event)
    }

    onStyleButtonClick (ev) {
        const type = ev.target.dataset.type;
        this.editorChange(RichUtils.toggleInlineStyle(this.state.editorState, type));  
    }
  
    render() {
        if (!this.state.queryset.object || !this.state.options) {
            return <div>
                Načítáme data...
            </div>
        } else {
            const et = this.state.queryset.object;
            return <Container>
            <Jumbotron>
                <h1>Šablona</h1>
                <h2>#{et.id} {et.name}z</h2>
            </Jumbotron>
            <Row>
                <Col md={8}>
                    <Form.Group controlId="subject">
                            <Form.Label>Předmět:</Form.Label>
                            <Form.Control value={et.subject} onChange={(event) => { this.valueChanged(event) }} />
                            <Form.Text>
                                {this.state.updater.subject === undefined ? <><Check2 /> Změny uloženy</> :
                                    <>
                                        <Spinner animation="border" role="status" size="sm" className="mr-1">
                                            <span className="sr-only">Saving...</span>
                                        </Spinner>
                                        Ukládání změn
                                    </>
                                }
                            </Form.Text>
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group controlId="language" className="mr-1">
                        <Form.Label className="mr-1 align-self-center mb-0">Jazyk objednávky: </Form.Label>
                        <Form.Control as="select" custom value={et.language} onChange={(event) => { this.valueChanged(event) }} className="mt-2">
                        {this.state.options === undefined ? <option>Loading...</option> : this.state.options.actions.POST.language.choices.map(i => {
                            return <option key={i.value} value={i.value}>{i.display_name}</option>
                        })}
                        </Form.Control>
                        {this.state.updater.language === undefined ? <><Check2 /> Změny uloženy</> :
                            <>
                                <Spinner animation="border" role="status" size="sm" className="mr-1">
                                    <span className="sr-only">Ukládání ...</span>
                                </Spinner>
                                Ukládání změn
                            </>
                        }
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Label>Text zprávy</Form.Label>
                    <div className="editor-wrapper border rounded">
                        <ButtonToolbar aria-label="Toolbar with button groups" className="border-bottom p-2">
                            <ButtonGroup className="mr-2" aria-label="First group">
                                <Button variant="outline-secondary" onMouseDown={(ev) => {ev.preventDefault()}} onClick={ev => {this.onStyleButtonClick(ev)}} data-type="BOLD"><TypeBold /></Button>
                                <Button variant="outline-secondary" onMouseDown={(ev) => {ev.preventDefault()}} onClick={ev => {this.onStyleButtonClick(ev)}} data-type="ITALIC"><TypeItalic /></Button>
                                <Button variant="outline-secondary" onMouseDown={(ev) => {ev.preventDefault()}} onClick={ev => {this.onStyleButtonClick(ev)}} data-type="UNDERLINE"><TypeUnderline /></Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                        <div className="p-2">
                            <Editor editorState={this.state.editorState} onChange={this.editorChange} />
                        </div>
                        <input type="submit" className="invisible" />
                    </div>
                    <Form.Text>
                            {this.state.updater.body === undefined ? <><Check2 /> Změny uloženy</> :
                                <>
                                    <Spinner animation="border" role="status" size="sm" className="mr-1">
                                        <span className="sr-only">Saving...</span>
                                    </Spinner>
                                    Ukládání změn
                                </>
                            }
                    </Form.Text>
                </Col>
            </Row>
        </Container>
        }
    }
}

export default withRouter(EmailTemplateDetail)
