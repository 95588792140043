import React from 'react'
import { Spinner } from 'react-bootstrap'
import '../css/LoadingBanner.css'

class LoadingBanner extends React.Component {
    render() {
        return <div className="border border-dark text-dark bg-light rounded loading-banner d-flex justify-content-center align-items-center">
            <div className="d-flex justify-content-center align-items-center flex-column">
                <Spinner animation="border" role="status" size="lg" className="mb-1">
                    <span className="sr-only">Načítání...</span>
                </Spinner>
                <h3>{this.props.label || "Načítá se..." }</h3>
            </div>
        </div>
    }
}

export default LoadingBanner