import React from 'react'
import Vehicle from '../Classes/Vehicle'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar as BigCalendar, momentLocalizer  } from 'react-big-calendar'
import moment from 'moment';
import LoadingBanner from './LoadingBanner';

class VehicleCalendar extends React.Component {
    localizer = momentLocalizer(moment)

    state = {
        availability: undefined
    }

    colors = [
        "#007bff",
        "#6c757d",
        "#28a745",
        "#dc3545",
        "#17a2b8",
    ]

    async componentDidMount() {
        this.setState({
            availability: (await Vehicle.vehicle_availability()).map(event => {
                return {
                    title: `#${event.vehicle} - ${event.order.code} - ${event.order.route}`,
                    start: moment(event.begin),
                    end: moment(event.end),
                    allDay: true,
                    resource: event
                }
            })
       })
    }

    eventStyleGetter(event, start, end, isSelected) {
        let order_code_char_sum = 0
        Array.from(event.resource.order.code).forEach(element => {
            order_code_char_sum += element.charCodeAt(0)
        });
        return {
            style: {
                backgroundColor: this.colors[order_code_char_sum % this.colors.length],
            }
        }
    }

    render() {
        if (!this.state.availability) {
            return <LoadingBanner />
        }
        return <div>
             <BigCalendar
                localizer={this.localizer}
                events={this.state.availability}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 700 }}
                views={['month', 'agenda']}
                eventPropGetter={(event) => {return this.eventStyleGetter(event)}}
                />
        </div>
    }
}

export { VehicleCalendar }