import React from 'react'
import { Container, Tabs, Tab, Alert, Row, Col, Table, Button } from 'react-bootstrap';
import { withRouter } from "react-router-dom";
import fetchProxy from '../Helpers/fetchProxy';
import settings from '../Settings'
import PreferenceField from '../Components/PreferenceField'
import { QuestionCircle } from 'react-bootstrap-icons';
import ListGroup from 'react-bootstrap/ListGroup';

let test = true
class PreferencesView extends React.Component {

  state = {
    preferences: undefined
  }

  async componentDidMount() {
    if (test) {
      const fetchPreferences = fetchProxy(settings.GLOBAL_PREFERENCES_URL)
      const fetchConfiguration = fetchProxy(settings.GLOBAL_CONFIGURATION_URL)
      const resPreferences = await (await fetchPreferences).json()
      const resConfiguration = await (await fetchConfiguration).json()
      const preferences = {}
      resPreferences.forEach(item => {
        const items = preferences[item.section] || []
        items.push(item)
        preferences[item.section] = items
      })
      this.setState({
        'preferences': preferences,
        'configuration': resConfiguration
      })
      test = false
    }
  }

  fieldUpdated(response) {
    const section = this.state.preferences[response.section]
    // Find the section index based on the identifier
    const sectionIndex = section.findIndex(item => item.identifier === response.identifier);
    if (sectionIndex >= 0) {
      section[sectionIndex].value = response.value
      this.forceUpdate()
    }
  }

  render() {
    const dynamic_panes = {
      company: "Společnost",
      labeling: "Automatické štítky",
      copying: "Kopírování",
      sentinel: "Sledování a notifikace"
    }

    return <Container className="mt-1">
      <Tabs defaultActiveKey="company">
        {Object.keys(dynamic_panes).map(key => {
          return <Tab eventKey={key} key={key} title={dynamic_panes[key]}>
            {this.state.preferences?.[key]?.map(item => {
              return <PreferenceField key={key + item.identifier} field={item} updated={value => this.fieldUpdated(value)} />
            })}
          </Tab>}
        )}
        <Tab eventKey="configuration" title="Systémová konfigurace" className="pt-3">
          {this.state.configuration && <>
            <Row>
              <Col md="6">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Vlastnost</th>
                      <th>Hodnota</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Výchozí jazyk</th><td>{this.state.configuration.settings.LANGUAGE_CODE}</td>
                    </tr>
                    <tr>
                      <th>Domácí měna</th><td>{this.state.configuration.settings.HOME_CURRENCY}</td>
                    </tr>
                    <tr>
                      <th>Domácí země</th><td>{this.state.configuration.settings.HOME_COUNTRY}</td>
                    </tr>
                    <tr>
                      <th>Verze BE</th><td>{this.state.configuration.VERSION}</td>
                    </tr>
                    <tr>
                      <th>Verze FE</th><td>{settings.VERSION}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Vlastnost</th>
                      <th>Hodnota</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>E-mailová konfigurace</th><td>{this.state.configuration.emails.backend}</td>
                    </tr>
                    <tr>
                      <th>E-maily od</th><td>{this.state.configuration.emails.default_from}</td>
                    </tr>
                    <tr>
                      <th>SMTP</th><td>{this.state.configuration.emails.smtp ? "Ano" : "Ne"}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Alert variant="secondary" className="mt-3">
              Konfigurace zobrazena na této stránce je centrálně spravovaná, pokud potřebujete některé vlastnosti změnit, kontaktujte podporu.
            </Alert>
          </>}
        </Tab>
        <Tab eventKey="help" title={<><QuestionCircle /> Nápověda</>}>
          <h2>Nápověda</h2>
          <p>Na záložce <strong>společnost</strong> nastavte kontaktní údaje vaší společnosti, loga a pro export do pohody </p>
          <p>Karta <strong>automatické štítky</strong> umožňuje říci, které štítky budou automaticky přiřazovány objednávkám</p>
          <p>Karta <strong>systémová konfigurace</strong> zobrazuje hodnoty, které jsou do Vaší aplikace vloženy, pro jejich úpravu je nutné kontaktovat podporu</p>
          <Button variant="link" href={settings.DOCS_TOPICS_URL + "settings"}>Více informací</Button>
        </Tab>
      </Tabs>
    </Container>
  }
}
export default withRouter(PreferencesView);