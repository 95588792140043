import Base from './Base'
import settings from '../Settings'

class Expense extends Base{
    static base_url = settings.BASE_API_URL + "expense/"

    static columns = [
        {
            name: "id",
            label: "#",
        },
        {
            name: "name",
            label: "Jméno"
        },
        {
            name: "value",
            label: "Hodnota"
        },
        {
            name: "currency",
            label: "Měna",
            transform: item => {
                return item && (item.symbol || item.code)
            }
        },
        {
            name: "accountable_at",
            label: "Zúčtovatelné",
            transform: item => {
                return item && new Date(item).toLocaleDateString()
            }
        }
    ]

    label() {
        return this.name
    }

    async save() {
        delete this.user
        return await Base.prototype.save.call(this)
    }
}

export default Expense