import Base from './Base'
import settings from '../Settings'
import fetchProxy from '../Helpers/fetchProxy'
import { Paperclip } from 'react-bootstrap-icons'

export default class Attachment extends Base{
    static base_url = settings.BASE_API_URL + "attachment/"

    static async by_orders(orders) {
        const req = await fetchProxy(this.base_url + "by_orders/", {
            "method": "POST",
            "body": JSON.stringify({orders})
        })
        return await req.json()
    }

    static icon() {
        return Paperclip
    }
}
