import Base from './Base'
import settings from '../Settings'

class Carrier extends Base{
    static base_url = settings.BASE_API_URL + "snippet/"
    static columns = [{
        name: "id",
        label: "#"
    },
    {
        name: "title",
        label: 'Název'
    },
    {
        name: "body",
        label: 'Jazyky',
        transform: item => {
            item = item || {}
            const langs = []
            Object.entries(item).forEach(i => {
                if (i[1]) {
                    langs.push(i[0])
                }
            })
            return langs.length > 0 ? langs.join(",") : "... zatím žádný"
        }
    }]
}

export default Carrier