import Base from './Base'
import settings from '../Settings'

class CompanyIdentity extends Base{
    static base_url = settings.BASE_API_URL + "companyidentity/"

    label() {
        return this.name || `Identita společnosti ${this.id}`
    }

    static columns = [{
        name: "id",
        label: "#",
    },
    {
        name: "name",
        label: "Jméno"
    },
    {
        name: "ico",
        label: "IČO"
    },
    {
        name: "town",
        label: "Město"
    }]
}

export default CompanyIdentity