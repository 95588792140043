import { useRef, useState } from "react";
import { Button, Modal, Alert, Form } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import { classByName } from "../helpers";
import ListView from "./ListView";
import ModelSelector from "./ModelSelector";

export function ManyClassModelSelector(props) {
    const [show, setShow] = useState(false);
    const [klassName, setKlassName] = useState("");
    const [search, setSearch] = useState("")

    const handleClose = () => {
        setShow(false);
        reset();
    }
    const handleShow = () => setShow(true);
    const modelSelector = useRef()

    const reset = () => {
        setSearch("")
        setKlassName("")
    }

    const itemSelected = item => {
        props.itemSelected && props.itemSelected(item, klassName)
        handleClose()
    }


    const updateSearch = value => {
        modelSelector.current.searchChanged(value)
        setSearch(value)
    }

    let content = <Alert variant="danger">Neočekávaná chyba, zkuste to znovu</Alert>

    if (!klassName) {
        content = !props.klassList ?
            <Alert variant="warning">Chybí seznam modelů, ze kterých lze vybírat</Alert> : 
            <div>
                <Form.Group>
                    <Form.Label>Typ objektu</Form.Label>
                    <Form.Control as="select" value={klassName} onChange={e => setKlassName(e.target.value)}>
                        <option value="">Vyberte typ objektu</option>
                        {props.klassList.map((i, index) => {
                            return <option key={index} value={i.value}>{i.display_name}</option>
                        })}
                    </Form.Control>
                </Form.Group>
            </div>
    }
    else {
        // Klass is set. We are good to go
        const klass = classByName(klassName)
        content = <Alert variant="danger">
            Bohužel se nepodařilo najít odpovídající záznamy pro {klassName}. Zkuste to znovu
        </Alert>
        if (klass) {
            content = <ModelSelector
                ref={modelSelector}
                klass={klass}
                show_controls={false}
                select={1}
                show_search={false}
                itemSelected={itemSelected}
            />
        }
        
    }

    return (
        <>
            <Button variant="primary" onClick={handleShow} className={props.className}>
                <Search />
            </Button>

            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header>
                    <Modal.Title>{props.title || "Vyberte odpovídající záznam"}</Modal.Title>
                    <Form inline className="ml-auto">
                    <input type="text" className="form-control" placeholder="Hledat" value={search} onChange={e => updateSearch(e.target.value)}/>
                    <button type="button" className="close" onClick={handleClose}>
                        &times;
                    </button>
                    </Form>
                </Modal.Header>
                <Modal.Body>
                    {content}
                </Modal.Body>
                <Modal.Footer className="">
                    <Button variant="secondary" onClick={handleClose}>
                        Zavřít
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}