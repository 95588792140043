import { useEffect } from "react";
import { useState } from "react"
import { Alert, Button, ButtonGroup, Col, Form, ListGroup, ListGroupItem, Modal, Row } from "react-bootstrap";
import { Calendar2Date, Plus, Trash2 } from "react-bootstrap-icons";
import Note from "../Classes/Note";
import Queryset from "../Classes/Queryset";
import LoadingBanner from "./LoadingBanner";
import {dateToInputValue} from "../helpers"

const DEFAULT_NOTE = {
    "id": 0,
    "name": "",
    "text": "",
    "date": new Date(),
    "time": new Date().toTimeString().split(":", 2).join(":")
}

function NewNoteModal(props) {
    const [show, setShow] = useState(false);
    const [error, setError] = useState();
    const [note, setNote] = useState({...DEFAULT_NOTE})

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const update = (key, value) => {
        if (key === 'date' && value === '') value = null;
        setNote({
            ...note,
            [key]: value
        })
    }

    async function save() {
        setError()
        const new_note = new Note(note);
        const timestamp = new Date(new_note.date)
        const time = new_note.time || new Date().toTimeString().split(":", 2).join(":")
        timestamp.setHours(+time.split(":")[0])
        timestamp.setMinutes(+time.split(":")[1])
        timestamp.setSeconds(0)
        new_note.date = timestamp.toISOString()
        new_note.relates_to = props.relates_to
        await new_note.save()
        if (new_note.__status != 201) {
            setError("Selhalo ukládání poznámky. Napravte chyby a zkuste to znovu, případně kontaktujte podporu.")
        }
        else {
            setShow(false)
            setNote({...DEFAULT_NOTE})
            props.onCreate && props.onCreate(new_note)
        }
    }

    return (
        <>
        <Button variant="success" onClick={handleShow} block>
            <Plus /> Přidat
        </Button>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Nová poznámka</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form>
                    <Form.Group controlId="name">
                        <Form.Label>Název</Form.Label>
                        <Form.Control placeholder="Název" value={note.name} onChange={e => update("name", e.target.value)} isInvalid={!note.name}/>
                    </Form.Group>
                    <Form.Group controlId="text">
                        <Form.Label>Popis</Form.Label>
                        <Form.Control as="textarea" placeholder="Popis" value={note.text}  onChange={e => update("text", e.target.value)}/>
                    </Form.Group>
                    <Row>
                        <Col sm={7}>
                            <Form.Group controlId="date">
                                <Form.Label>Datum</Form.Label>
                                <Form.Control type="date" placeholder="Datum" value={note.date ? dateToInputValue(note.date): ''} onChange={e => update("date", e.target.value)}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="time">
                                <Form.Label>Čas</Form.Label>
                                <Form.Control type="time" placeholder="čas" value={note.time || ""} onChange={e => update("time", e.target.value)}/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Zavřit
            </Button>
            <Button variant="success" onClick={save}>
                Přidat
            </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}

export function NotesWidget(props) {
    if (!props.relates_to_type || !props.relates_to_id) {
        throw new Error("NotesWidget requires 'relates_to_type' and 'relates_to_id'")
    }
    
    const [error, setError] = useState();
    const [notes, setNotes] = useState();
    const queryset = new Queryset(Note);

    const filter = {
        relates_to_type: props.relates_to_type,
        relates_to_id: props.relates_to_id
    }

    async function load() {
        setNotes()
        await queryset.filter(filter);
        if (queryset.status == 200) {
            setNotes(queryset.objects);
        }
        else {
            setError("Selhalo načítání poznámek z databáze. Zkuste obnovit stránku, případně nahlašte problém")
        }
    }

    async function deleteNote(item) {
        if (!window.confirm(`Opravdu smazat poznámku "${item.name}"?`)) return;
        await item.delete()
        load()
    }

    useEffect(() => {
        load()
    }, [])

    if (error) {
        return <Alert variant="danger">
            {error}
        </Alert>
    }

    if (notes === undefined) {
        return <LoadingBanner />
    }

    return <ListGroup>
                {notes.length === 0 && <ListGroupItem className="bg-light text-muted text-center">
                    Zatím žádné poznámky...
                </ListGroupItem>}
                {notes.map((item, index) => {
                    return <ListGroupItem key={index}>
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <strong>{item.name}</strong>
                            </div>
                            <div>
                                {item.date && <><Calendar2Date /> {new Date(item.created).toLocaleDateString()}</>}
                            </div>
                            <ButtonGroup size="sm">
                                <Button variant="danger" onClick={() => deleteNote(item)}><Trash2/></Button>
                            </ButtonGroup>
                        </div>
                        <p>{item.text || <em>Bez popisu</em>}</p>
                    </ListGroupItem>
                })}
            <ListGroupItem>
                <NewNoteModal relates_to={{model: props.relates_to_type, id: props.relates_to_id}} onCreate={load} />
            </ListGroupItem>
        </ListGroup>
}