import Base from './Base'
import settings from '../Settings'
import { CashStack } from 'react-bootstrap-icons'

export default class Payment extends Base {
    static base_url = settings.BASE_API_URL + "payment/"

    static icon() {
        return CashStack
    }
}