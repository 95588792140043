import React from "react";
import { Form, Row } from 'react-bootstrap'

export class EntityForm extends React.Component {
    state = {
        choices: this.props.options
    }

    class = this.props.entity.constructor

    renderField(name, field, value) {
        switch (field.type) { 
            case "string":
		if (field.max_length === undefined) return <Form.Control 
			className="col-sm-7"
			type="text" 
			as="textarea"
			disabled={field.read_only}
                        value={value}
			onChange={ e => this.props.update && this.props.update(name, e.target.value)}
			/>
            case "integer":
            case "datetime":
                return <Form.Control className="col-sm-7" type={field.type === "integer" ? "number" : "text"} disabled={field.read_only}
                            value={value} onChange={ e => this.props.update && this.props.update(name, e.target.value)}/>
            case "choice":
                return <Form.Control as="select" custom  className="col-sm-7" disabled={field.read_only}
                            value={value} onChange={ e => this.props.update && this.props.update(name, e.target.value)}>
                        {field.choices.map((i, key) => {
                            return <option key={key} value={i.value}>{i.display_name}</option>
                        })}
                    </Form.Control>
            case "boolean":
                return <Form.Check
                        type="checkbox" 
                        checked={value} onChange={e => this.props.update && this.props.update(name, e.target.checked)} 
                    /> 
            default:
                return null
        }
        
    }

    render() {
        return <Form>
            {Object.entries(this.props.options.actions.POST).map(i => {
                const [key, field] = i
                const excluded = this.props.excluded || []
                if (this.class.pk === key || field.type === "nested object" || field.type === "datetime" || excluded.indexOf(key) !== -1) {
                    return null;
                }
                const value = this.props.entity[key]
		const renderedField = this.renderField(key, field, value)
		if (!renderedField) return null
                return <Form.Group key={key} controlId={key}>
                    <Row>
                        <Form.Label className="col-sm-5 col-form-label"><strong>{field.label}</strong></Form.Label>
                        {renderedField}
		    	<Form.Text className="px-3">{field.help_text}</Form.Text>
                    </Row>
                </Form.Group>
                    
            })}
        </Form>
    }
}
