import Base from './Base'
import settings from '../Settings'

class Note extends Base {
    static base_url = settings.BASE_API_URL + "note/"

    static columns = [
        {
            name: "id",
            label: "#",
        },
        {
            name: "name",
            label: "Jméno"
        },
    ]

    label() {
        return this.name
    }

    async save() {
        return await Base.prototype.save.call(this)
    }
}

export default Note