import { withRouter } from "react-router-dom";
import ModelView from './ModelView';
import Requirement from '../Classes/Requirement'
import RequirementCategory from '../Classes/RequirementCategory'
import React, { useEffect, useState } from 'react'
import { Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import { JournalBookmark, Plus, X } from "react-bootstrap-icons";
import Queryset from "../Classes/Queryset";
import MessageCenter from "../Components/MessageCenter";

const NUMBER_REGEX = /^\+?(0|[1-9]\d*)$/;

function CategorySelectModal(props) {
    const [show, setShow] = useState(false);
    const [category, setCategory] = useState(0);

    function handleClose() {
        setShow(false)
    }

    function handleShow() {
        setShow(true)
    }

    async function move() {
        if (NUMBER_REGEX.test(category)) {
            props.moveTo && props.moveTo(props.row_item.id, category !== 0 ? category : null)
        } else {
            const req_cat = new RequirementCategory({name: category, id: 0});
            await req_cat.save();
            if (req_cat.__status > 300) {
                MessageCenter.addMessage({
                    "title": "Problém při ukládání",
                    "text": Object.values(req_cat.__messages)
                })
                return;
            }
            props.moveTo && props.moveTo(props.row_item.id, req_cat.id)
        }
    }

    if (!props.categories) {
        return null;
    }

    return <>
        <Button size="sm" className="ml-1" onClick={e => {e.stopPropagation(); handleShow()}}>Přesunout <JournalBookmark /></Button>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Vybrat kategorii</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="category">
                        <Form.Label>Název kategorie</Form.Label>
                        {typeof category === "string" && (category === "" || props.categories.filter(i => i.id === Number(category)).length === 0) ?
                            <InputGroup>
                                <Form.Control autoFocus value={category || ""} onChange={e => { setCategory(e.target.value) }} />
                                <InputGroup.Append>
                                    <Button variant="warning" className="text-white" onClick={() => setCategory()}><X /></Button>
                                </InputGroup.Append>
                            </InputGroup> :
                            <Form.Control as="select" value={category} onChange={e => setCategory(e.target.value)}>
                                {props.categories.sort((a, b) => (a.id > b.id)).map(i => <option key={i.id} value={i.id}>{i.name}</option>)}
                                <option value="">＋ Nová kategorie</option>
                            </Form.Control>}
                    </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Zavřít
                </Button>
                <Button variant="success" disabled={category === ""} onClick={move}>
                    Přesunout
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}

function NewRequirementModal(props) {
    const [show, setShow] = useState(false);
    const [categories, setCategories] = useState();
    const [category, setCategory] = useState();
    const [name, setName] = useState();
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const queryset = new Queryset(RequirementCategory);
        queryset.all().then(() => {
            setCategories(queryset.objects)
        })
    }, [])

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    if (!categories) {
        return <Button disabled variant="success" onClick={() => handleShow()}><Spinner animation="border" size="sm" /> Přidat </Button>
    }

    async function saveRequirement() {
        setErrors({})
        const requirement = new Requirement({ id: 0, name, category });
        await requirement.save();
        if (requirement.__status > 300) {
            setErrors(requirement.__messages);
        }
        else {
            props.onRequirementCreated && props.onRequirementCreated(requirement);
            setShow(false)
        }
    }

    return <>
        <Button variant="success" onClick={() => handleShow()}><Plus /> Přidat </Button>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Přidat požadavek</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="name">
                    <Form.Label>Název</Form.Label>
                    <Form.Control isInvalid={!!errors.name} value={name} onChange={e => setName(e.target.value)} placeholder="Název" />
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="category">
                    <Form.Label>Kategorie</Form.Label>
                    {typeof category === "string" && (category === "" || categories.filter(i => i.id === Number(category)).length === 0) ?
                        <InputGroup>
                            <Form.Control isInvalid={!!errors.category} list="categoriesList" autoFocus value={category} onChange={e => { setCategory(e.target.value) }} />
                            <datalist id="categoriesList">
                                {categories.map(i => <option key={i.id}>{i.name}</option>)}
                            </datalist>
                            <InputGroup.Append>
                                <Button variant="warning" className="text-white" onClick={() => setCategory()}><X /></Button>
                            </InputGroup.Append>
                        </InputGroup> :
                        <Form.Control as="select" value={category} isInvalid={!!errors.category} onChange={e => setCategory(e.target.value)}>
                            {categories.sort((a, b) => (a.id > b.id)).map(i => <option key={i.id} value={i.id}>{i.name}</option>)}
                            <option value="">＋ Nová kategorie</option>
                        </Form.Control>}
                    {!!errors.category && <div style={{ width: "100%", marginTop: ".25rem", fontSize: "80%", color: "#dc3545" }}>{errors.category}</div>}
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Zavřít
                </Button>
                <Button variant="success" onClick={saveRequirement}>
                    Přidat
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}

async function moveTo(id, category) {
    const req = new Requirement({id, category});
    await req.save({id, category});
    if (req.__status >= 400) {
        MessageCenter.addMessage({
            "title": "Nepovedlo se",
            "text": "Zkuste to znovu, nebo kontaktujte podporu"
        })
    }
    window.location.reload();
}

class RequirementsView extends ModelView {
    constructor(props) {
        super(Requirement, props)
        this.jumbotronTitle = "Požadavky"
        this.show_controls = true
        this.new_button = <></>
    }

    async componentDidMount() {
        const queryset = new Queryset(RequirementCategory);
        const categoriesAsync = queryset.all()
        super.componentDidMount()
        await categoriesAsync
        this.setState({categories: queryset.objects})
    }

    extra_actions = [
        <NewRequirementModal onRequirementCreated={() => window.location.reload()} />
    ]

    extra_controls = [
        {klass: CategorySelectModal, stateToProperties: ['categories'], extraProperties: {"moveTo": moveTo}}
    ]
}
export default withRouter(RequirementsView);
