import Base from './Base'
import settings from '../Settings'

class EmailTemplate extends Base{
    static base_url = settings.BASE_API_URL + "emailtemplate/"
    static columns = [{
        name: "id",
        label: "#",
    },
    {
        name: "name",
        label: "Jméno"
    },
    {
        name: "language",
        label: "Jazyk"
    }]
}

export default EmailTemplate