import { useState, createContext } from 'react';
import browserStorage from 'store';

// This hook receives two parameters:
// storageKey: This is the name of our storage that gets used when we retrieve/save our persistent data.
// initialState: This is our default value, but only if the store doesn't exist, otherwise it gets overwritten by the store.
export function usePersistStorage(storageKey, initialState) {

  // Initiate the internal state.
  const [state, setInternalState] = useState(browserStorage.get(storageKey) || initialState);

  // Create a replacement method that will set the state like normal, but that also saves the new state into the store.
  const setState = (newState) => {
    browserStorage.set(storageKey, newState);
    setInternalState(newState);
  };

  return [state, setState];
};

export const ConfigContext = createContext()