import React, { useEffect, useState } from 'react';
import { Button, Container, Modal, Table, Alert, ButtonGroup, Pagination, Row, Col, ListGroup, ListGroupItem, Form, Spinner, Dropdown, Badge, DropdownButton, Card } from 'react-bootstrap';
import { Alarm, ArrowLeftShort, ArrowRightShort, CashCoin, Check, ChevronDoubleLeft, ChevronDoubleRight, Download, FiletypeXml, Gear, Hash, Paperclip, PlusCircle, Receipt, Search, X } from 'react-bootstrap-icons';
import Carrier from '../Classes/Carrier';
import Currency from '../Classes/Currency';
import Customer from '../Classes/Customer';
import Invoice from '../Classes/Invoice';
import Order from '../Classes/Order';
import Queryset from '../Classes/Queryset';
import LoadingBanner from '../Components/LoadingBanner';
import MessageCenter from '../Components/MessageCenter';
import { OrderListGroup } from '../Components/OrderListGroup';
import { OrderSearchModal } from '../Components/OrderSearchModal';
import { dateToInputValue } from '../helpers';
import Account from '../Classes/Account';
import fetchProxy from '../Helpers/fetchProxy';
import settings from '../Settings';
import { usePersistStorage } from '../Context';

function orderChecks(orders) {
    const errors = []
    //2. Not the same customer!
    if (new Set(orders.map(o => o.customer.id)).size > 1) {
        errors.push("Objednávky nemají stejného zákazníka")
    }
    //2. Not the same customer currency!
    if (new Set(orders.map(o => o.customer_currency.code)).size > 1) {
        errors.push("Objednávky nemají stejnou měnu")
    }
    return errors
}

export function InvoicePreviewDialog(props) {
    const { invoices } = props

    function hide() {
        props.onHide && props.onHide()
    }

    return <Modal size="xl" show={!!invoices} onHide={hide}>
        <Modal.Header closeButton>
            <Modal.Title>Přehled faktur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Table bordered hover>
                <thead>
                    <tr>
                        <th colSpan={2}>
                            ID faktury
                        </th>
                        <th colSpan={2}>
                            Zákazník
                        </th>
                        <th colSpan={3}>
                            Hodnota (součet cen pro zákazníka)
                        </th>
                    </tr>
                    <tr>
                        <th>
                            #
                        </th>
                        <th>CODE</th>
                        <th>Cena zákazník</th>
                        <th style={{ maxWidth: "250px" }}>Dopravce</th>
                        <th>Cena dopravce</th>
                        <th>Provedeno</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {invoices?.map(invoice => <React.Fragment key={invoice.id}>
                        <tr className='table-secondary'>
                            <th colSpan={2}>Faktura #{invoice.id}</th>
                            <th colSpan={2}>{Customer.icon_as_component()} {invoice.customer && new Customer(invoice.customer).label()}</th>
                            <th colSpan={3}><CashCoin /> {invoice.orders.map(i => i.price_customer).reduce((acc, current) => {
                                return acc + current
                            }, 0)} {invoice?.currency?.code}
                            </th>
                        </tr>
                        {invoice?.orders?.map(order => {
                        return <tr key={invoice.id + "/" + order.id}>
                            <td>Objednávka #{order.id}</td>
                            <td>{order.code}</td>
                            <td>{order.price_customer} {order?.customer_currency}</td>
                            <td>{order.carrier && new Carrier(order.carrier).label()}</td>
                            <td>{order.price_carrier} {order?.carrier_currency}</td>
                            <td>{order.last_location_time && new Date(order.last_location_time).toLocaleDateString()}</td>
                        </tr>})}
                    </React.Fragment>)}
                </tbody>
            </Table>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="success" onClick={() => props.onPohodaXML && props.onPohodaXML()}>
                <FiletypeXml /> Stažení do pohody
            </Button>
            <Button variant="secondary" onClick={hide}>
                Storno
            </Button>
        </Modal.Footer>
    </Modal>
}

export function AccountingDialog(props) {
    const { orders } = props
    const [show, setShow] = useState(false)
    const [saving, setSaving] = useState(false)

    const [dueDate, setDueDate] = useState(null)
    const [notes, setNotes] = useState("")
    const [customerId, setCustomerId] = useState(orders?.length === 1 ? orders[0].customer_order : "")
    const [ordersFixed, setOrdersFixed] = useState(false)

    const [orderChanges, setOrderChanges] = useState({})

    async function createInvoice() {
        setSaving(true)
        const invoice = new Invoice({
            id: 0,
            customer_order: customerId,
            customer: orders[0]?.customer?.id,
            orders: orders.map(o => o.id),
            due_date: dueDate ? dateToInputValue(dueDate) : null,
            notes
        })
        try {
            const response = await invoice.save();
            if (invoice.__status !== 201) throw Error(`Neočekávaná odpověď serveru ${invoice.__status}: ${JSON.stringify(Object.values(invoice.__messages).join(", "))}`)
            hide()
            props.invoiceCreated && props.invoiceCreated(response)
        }
        catch (e) {
            MessageCenter.addMessage({
                title: "Nepodařilo se uložit fakturu!",
                text: `Zkuste to znovu, případně kontaktujte podporu. Popis chyby: ${e.message}`
            })
            console.error(e)
        }
        finally {
            setSaving(false)
        }
    }

    async function saveOrderChanges() {
        setSaving(true)
        const res = Object.entries(orderChanges).map(async entry => {
            const [id, orderData] = entry
            const order = new Order({
                id,
                carrier_invoice: orderData.carrier_invoice,
                carrier_missing_invoice: orderData.carrier_missing_invoice
            })
            try {
                const response = await order.save()
                if (order.__status !== 200) throw Error(`Unexpected HTTP status ${order.__status}: ${response}`)
                return {
                    ...orderData,
                    ...response
                }
            }
            catch (e) {
                console.error(e)
                return null
            }
        })
        const orders_response = await Promise.all(res);
        if (!orders_response) {
            MessageCenter.addMessage({
                title: "Nepodařilo se uložit změny!",
                text: "Zkuste to znovu, případně kontaktujte podporu"
            })
        }
        else {
            if (orders[0].automaticInvoicing) {
                delete orders[0].automaticInvoicing
                props.orderCarrierInfoCompleted && props.orderCarrierInfoCompleted(orders[0])
                hide()
            }
            else {
                setOrdersFixed(true)
            }
        }
        setSaving(false)
    }

    function hide() {
        setShow(false)
        setOrderChanges({})
        setOrdersFixed(false)
        props.onHide && props.onHide()
    }

    useEffect(() => {
        orders.filter(o => !!o.force_show).length > 0 && setShow(true)
        defaultNotesText()
    }, [orders])

    async function defaultNotesText() {
        const req = await fetchProxy(settings.BASE_API_URL + "service/preferences/labeling__pohoda_invoice_text/")
        if (req.status !== 200) {
            MessageCenter.addMessage("Nepodařilo se načíst výchozí text objednávky. Zkuste to později znovu")
        }
        // let parts = ["Dobrý den, \n"]
        // if (invoice.customer.id) parts.push(`na základě vaší objednávky (${invoice.customer.id}) `)
        // parts.push("zasíláme fakturu na následující provedené přepravy: \n")
        // invoice.orders.forEach(o => {
        //     parts.push(` - ${o.code} ${o.route_text}`)
        //     if (o.customer_order) parts.push(` (zadaná jako objednávka - ${o.customer_order})`)
        //     parts.push("\n")
        // })
        // parts.push(`\nSplatnost pro tuto objednávku je: ${new Date(invoice.due_date).toLocaleDateString()}\n`)
        setNotes((await req.json()).value)
    }

    function checkOrderAndOrderChanges() {
        return !orders.map(o => {
            const carrier_invoice = orderChanges[o.id]?.carrier_invoice === undefined ?
                o.carrier_invoice : orderChanges[o.id].carrier_invoice
            const carrier_missing_invoice = orderChanges[o.id]?.carrier_missing_invoice === undefined ?
                o.carrier_missing_invoice : orderChanges[o.id].carrier_missing_invoice
            return carrier_invoice || carrier_missing_invoice
        }).every(value => !!value)
    }

    const carrier_invoices_ok = orders.every(o => o.carrier && (o.carrier_missing_invoice || o.carrier_invoice))

    function orderChanged(key, id, value) {
        const order = orderChanges[id] || {}
        order[key] = value
        setOrderChanges({
            ...orderChanges,
            [id]: order
        })
    }

    function considerShowing() {
        const nonMarginOrders = orders.filter(o => o.margin <= 0)
        if (nonMarginOrders.length > 0 && !window.confirm(`Faktura obsahuje objednávky (${nonMarginOrders.length}) s nulovou, nebo zápornou marží. Chcete i přes to fakturovat`)) {
            return
        }
        setShow(true)
    }

    return <>
        <Button size="sm" disabled={orders.length === 0} className={props.className} onClick={() => considerShowing()}>Fakturovat {orders.length === 0 && `(${orders.length})`}</Button>
        <Modal size="xl" show={show} onHide={hide}>
            <Modal.Header closeButton>
                <Modal.Title>Fakturace</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {carrier_invoices_ok || ordersFixed ? <Row>
                    <Col md={4}>
                        <h3 className='text-center'>Objednávky</h3>
                        <OrderListGroup displaySum={true} orders={orders} valueClicked={(key, value) => key === "customer_order" && setCustomerId(value)} />
                    </Col>
                    <Col md={8}>
                        <Form.Group controlId="due_date">
                            <Form.Label>Splatnost faktury</Form.Label>
                            <Form.Control type="date" as="input" value={dueDate ? dateToInputValue(dueDate) : ""} onChange={e => setDueDate(new Date(e.target.value))} />
                            <Form.Text className='text-muted'>Pokud nevyplníte žádnou hodnotu, použije se výchozí nastavení určené správcem.</Form.Text>
                        </Form.Group>
                        <Form.Group controlId="customer_order">
                            <Form.Label>Číslo objednávky u zákazníka</Form.Label>
                            <Form.Control as="input" value={customerId} onChange={e => setCustomerId(e.target.value)} />
                            <Form.Text className='text-muted'>Číslo objednávky u zákazníka. Můžete klepnout na hodnotu z objednávek pro její vybrání.</Form.Text>
                        </Form.Group>
                        <Form.Group controlId='notes'>
                            <Form.Label>Poznámka na faktuře</Form.Label>
                            <Form.Control rows={8} as="textarea" value={notes} onChange={e => setNotes(e.target.value)} />
                            <Form.Text>
                                Text, který se objeví na faktuře při exportu do Pohody, nebo PDF.
                                Můžete <span style={{ cursor: 'pointer' }} className='text-primary' onClick={defaultNotesText}>vložit výchozí text</span>
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row> : <div>
                    <Alert variant='warning'>
                        <strong>Chybějící údaje objednávek</strong><br />
                        Některé objednávky nemají vyplněné údaje o fakturaci u dopravce. Doplňte je, nebo označte chybějící faktury dopravců.
                    </Alert>
                    <Table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>CODE</th>
                                <th>Cena dopravce</th>
                                <th>Cena zákazník</th>
                                <th>Faktura dopravce</th>
                                <th>Bez faktury dopravce</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderChanges && orders.map(o => <tr key={o.id}>
                                <td>{o.id}</td>
                                <td>{o.code}</td>
                                <td>{o.price_carrier} {o.carrier_currency.label || o.carrier_currency}</td>
                                <td>{o.price_customer} {o.customer_currency.label || o.customer_currency}</td>
                                <td>
                                    <Form.Control
                                        value={orderChanges[o.id]?.carrier_invoice === undefined ? o.carrier_invoice : orderChanges[o.id]?.carrier_invoice}
                                        disabled={orderChanges[o.id]?.carrier_missing_invoice || o.carrier_missing_invoice} size="sm"
                                        onChange={e => orderChanged("carrier_invoice", o.id, e.target.value)}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        size="sm"
                                        type="checkbox"
                                        disabled={orderChanges[o.id]?.carrier_invoice !== undefined ? orderChanges[o.id].carrier_invoice : o.carrier_invoice}
                                        checked={orderChanges[o.id]?.carrier_missing_invoice !== undefined ? orderChanges[o.id]?.carrier_missing_invoice : o.carrier_missing_invoice}
                                        onChange={e => orderChanged("carrier_missing_invoice", o.id, e.target.checked)}
                                    />
                                </td>
                            </tr>)}
                        </tbody>
                    </Table>
                </div>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={hide}>
                    Storno
                </Button>
                {carrier_invoices_ok || ordersFixed ? <Button variant="success" onClick={createInvoice}>
                    Vytvořit
                </Button> : <Button disabled={checkOrderAndOrderChanges()} onClick={saveOrderChanges}>
                    {!orderChanges ? "Uložit změny" : "Pokračovat"} {saving && <Spinner animation='border' size="sm" />}
                </Button>}
            </Modal.Footer>
        </Modal>
    </>
}

export function AccountingView() {
    const [orders, setOrders] = useState([])
    const [invoices, setInvoices] = usePersistStorage("accountingView/invoices", [])
    const [invoicesIds, setInvoicesIds] = usePersistStorage("accountingView/invoicesIds", {})
    const [accountingOrders, setAccountingOrders] = useState([])
    const [orderIds, setOrderIds] = useState({})
    const [loading, setLoading] = useState(true)
    const [count, setCount] = useState()
    const [automaticInvoicing, setAutomaticInvoicing] = usePersistStorage('accountingView/automaticInvoicing', false);
    const [previewInvoices, setPreviewInvoices] = useState();
    const [filter, setFilter] = useState({
        limit: 14,
        page: 1,
        orderBy: ["last_location_time"],
        invoice: ""
    })

    function setPage(page) {
        setFilter({
            ...filter,
            page
        })
    }

    useEffect(() => {
        setLoading(true)
        const queryset = new Queryset(Order)
        const count = Order.serverCount(filter)
        queryset.filter(filter).then(() => {
            setOrders(queryset.objects)
            setLoading(false)
        }).catch(res => {
            MessageCenter.addMessage({ "title": "Chyba při načítání", "message": "Selhalo načítání. Zkuste to prosím znovu, případně kontaktujte správce" })
            console.error(res)
        })
        count.then(count => {
            setCount(count)
        }).catch(res => {
            MessageCenter.addMessage({ "title": "Chyba při načítání", "message": "Selhalo načítání metadat. Zkuste to prosím znovu, případně kontaktujte správce" })
            console.error(res)
        })
    }, [filter])

    function orderSearch(order, action) {
        if (action === "GROUP" || accountingOrders?.length) {
            addToAccounting(order)
        }
        else {
            considerForInvoicing(order)
        }
    }

    function removeFromInvoicing(order) {
        const newAccountingOrders = accountingOrders.filter(o => o.id !== order.id)
        if (accountingOrders.length === newAccountingOrders.length) {
            MessageCenter.addMessage("Selhalo odebrání ze seznamu pro hromadnou fakturaci!")
            return;
        }
        if (newAccountingOrders.length === 0) {
            resetAccounting()
            return;
        }
        setOrderIds({ ...orderIds, [order.id]: false, })
        setAccountingOrders(newAccountingOrders)
    }

    function addToAccounting(order) {
        if (accountingOrders.filter(o => o.id === order.id).length) {
            MessageCenter.addMessage("Objednávka už je ve výběru přidaná")
            return;
        };
        try {
            order.checkAccounting()
        }
        catch (e) {
            MessageCenter.addMessage({ "title": "Objednávku není možné fakturovat!", text: e.errors.join(", ") })
            return;
        }
        const possibleAccountingOrders = [...accountingOrders, order]
        if (possibleAccountingOrders.length > 1) {
            const check = orderChecks(possibleAccountingOrders)
            if (check.length > 0) {
                MessageCenter.addMessage({ "title": "Objednávku není možné přidat do výběru!", text: check.join(", ") })
                return;
            }
        }
        if (possibleAccountingOrders.length === 1) {
            setFilter({
                ...filter,
                customer: order.customer.id,
                customer_currency: order.customer_currency.code,
                page: 1
            })
        }
        setOrderIds({ ...orderIds, [order.id]: true })
        setAccountingOrders(possibleAccountingOrders)
    }

    function resetAccounting(forced) {
        if (forced || window.confirm("Opravdu chcete zrušit tento výběr?")) {
            const newFilter = { ...filter, page: 1 }
            delete newFilter.customer
            delete newFilter.customer_currency
            setFilter(newFilter)
            setAccountingOrders([])
            setOrderIds({})
        }
    }

    function toggleInvoiceFilter() {
        if (filter.invoice === undefined) {
            setFilter({
                ...filter,
                invoice: ""
            })
        }
        else {
            const newFilter = { ...filter }
            delete newFilter.invoice
            setFilter(newFilter)
        }
    }

    async function createInvoicesAndAddToSelected(orders) {
        if (!Array.isArray(orders) || orders.length === 0) {
            MessageCenter.addMessage("Selhalo vytvoření faktury")
            return
        }
        const invoice = new Invoice({
            id: 0,
            orders: orders.map(order => order.id),
            customer: orders[0].customer,
        });
        try {
            const res = await invoice.save()
            if (invoice.__status === 201) {
                addInvoice(res)
            }
            else {
                throw new Error(`Neočekáváná odpověď serveru: ${invoice.__status}. Zkuste to znovu, nebo kontaktujte podporu`);
            }
        }
        catch (e) {
            console.error(e);
            MessageCenter.addMessage({
                "title": "Nepodařilo se vyfakturovat objednávku",
                "text": `Nepodařilo se vyfakturovat následující objednávky (podle ID): ${orders.map(o => o.id)}. Podrobnosti o chybě: ${e.message}`
            })
            return false;
        }
        return true
    }

    async function considerForInvoicing(order) {
        // Triggered if an order is to wanted to be 
        if (order.margin <= 0 && !window.confirm("Objednávka má nulovou, nebo zápornou marži. Opravdu chcete fakturovat?")) {
            return;
        }
        const invoice = order.invoice
        if (invoice) {
            invoice.old = true
            addInvoice(invoice)
        }
        else {
            try {
                order.checkAccounting()
                if (automaticInvoicing && order.carrier && (order.carrier_invoice || order.carrier_missing_invoice)) {
                    createInvoicesAndAddToSelected([order])
                }
                else {
                    // This will trigger the invoice dialog to appear
                    order.force_show = true
                    // Transfer the information about automatic invoicing
                    order.automaticInvoicing = automaticInvoicing
                    addToAccounting(order)
                }
            }
            catch (e) {
                console.error(e)
                MessageCenter.addMessage({ "title": "Objednávku není možné fakturovat!", text: e.errors?.join(", ") })
                return;
            }
        }
    }

    function addInvoice(invoice) {
        if (invoicesIds[invoice.id]) {
            MessageCenter.addMessage({
                "title": "Objednávka už je v seznamu",
                "text": `Faktura ${invoice.id}. Možná je součástí faktury, která obsahovala jinou, již zvolenou objednávku.`
            })
            return;
        }
        setInvoices([...invoices, invoice])
        setInvoicesIds({ ...invoicesIds, [invoice.id]: true })
    }

    function removeInvoiceByIndex(index) {
        const id = invoices.splice(index, 1)
        delete invoicesIds[id]
        setInvoices([...invoices])
        setInvoicesIds({ ...invoicesIds })
    }

    function clearInvoices() {
        setInvoices([])
        setInvoicesIds({})
        setPreviewInvoices()
    }

    function onInvoiceCreated(invoice) {
        if (accountingOrders.length !== 0) {
            setAccountingOrders([]);
            setFilter({
                limit: 14,
                page: 1,
                orderBy: filter.orderBy,
                invoice: filter.invoice
            })
        }
        addInvoice(invoice);
    }

    function onCarrierInfoCompleted(order) {
        createInvoicesAndAddToSelected([order])
        resetAccounting(true)
    }

    return <Container fluid>
        <InvoicePreviewDialog
            invoices={previewInvoices}
            onHide={() => setPreviewInvoices()}
            onPohodaXML={async () => (await Invoice.bulkPohoda(invoices.map(i => i.id)) && clearInvoices())} />
        <Row>
            <Col lg="2" className='my-2 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <div></div>
                    <h2 className='text-center'>Fakturace</h2>
                    <Dropdown>
                        <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                            <Gear />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={toggleInvoiceFilter}>
                                <span className='mr-1'>{filter.invoice === undefined ? <X className='text-danger' /> : <Check className='text-success' />} </span>
                                Fakturované skrýt
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setAutomaticInvoicing(!automaticInvoicing)}>
                                <span className='mr-1'>{!automaticInvoicing ? <X className='text-danger' /> : <Check className='text-success' />} </span>
                                Automatická fakturace
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                {invoices.length === 0 ? <Alert variant='secondary' as="div" className='flex-grow-1'>
                    <p>Zrychlete fakturaci zapnutím možnosti "Automatická fakturace" tady v nastavení <Gear />. Automaticky se vytvoří faktura s odpovídajícím datem splatnosti a faktuře se nastaví také číslo objednávky (podle toho, co bylo v objednávce)</p>
                    <p>Lupa <Search className='text-primary' /> slouží k rychlému vyhledání objednávky podle ID-CODE
                    </p>
                    <p>Postupně jak budete vybírat objednávky budou se zde objevovat jejich faktury.</p>
                    <p>Můžete také fakturovat hromadně. Klepněte v takovém případě na ikonu hromadné fakturace v záhlaví stránky.</p>
                    <strong>Symboly</strong>
                    <ul>
                        <li><CashCoin className='text-danger' /> Nízká marže!</li>
                        <li><Receipt /> Již fakturováno</li>
                        <li><Paperclip /> Obsahuje fakturu</li>
                    </ul>
                    <p>Aby bylo možné vůbec vytvořit fakturu musí objednávka mít zákazníka a dopravce. </p>
                    <p>Přehled již vytvořených faktur najdete <a href="/invoice/">zde</a></p>
                </Alert> : <ListGroup>
                    {invoices.map((invoice, index) => <ListGroupItem key={invoice.id}>
                        <div className="d-flex justify-content-between">
                            <div className='ml-1 justify-content-end'>
                                <Badge pill className='mr-1' variant='secondary'>#{invoice.id}</Badge>
                                {invoice.orders.map(order => <Badge key={order.id} variant='primary' pill className='mr-1'><small>{order.code}</small></Badge>)}
                                {invoice.old && <Badge variant="warning" pill><small>Již fakturováno</small></Badge>}
                            </div>
                            <Button size="sm" className='border-0' variant="outline-danger" onClick={() => removeInvoiceByIndex(index)}><X /></Button>
                        </div>
                        <div>
                            {Customer.icon_as_component()} {new Customer(invoice.customer).label()}
                        </div>
                        <div>
                            {invoice.due_date && <>
                                <Alarm title="Splatnost" /> {new Date(invoice.due_date).toLocaleDateString()}
                            </>}
                        </div>
                        <div className='d-flex mt-1 justify-content-end'>

                        </div>
                    </ListGroupItem>)}
                </ListGroup>}
                {invoices.length !== 0 && <ButtonGroup className='mt-2 d-flex justify-content-end'>
                    {<Button onClick={() => setPreviewInvoices(invoices)} variant="primary" className='w-50 d-flex justify-content-between align-items-center'>
                        <Search />
                        <div>Hromadné zpracování</div>
                    </Button>}
                    <Button onClick={() => window.confirm("Opravdu zrušit tento výběr") && clearInvoices()} variant="danger" className='w-50 d-flex justify-content-between align-items-center'>
                        <X />
                        <div>Zrušit výběr</div>
                    </Button>
                </ButtonGroup>}
            </Col>
            <Col md={10}>
                <Container className="d-flex justify-content-between align-items-center my-2" fluid={accountingOrders.length > 0}>
                    {accountingOrders.length > 0 && <>
                        <div className='d-flex align-items-center flex-shrink-1 w-100'>
                            {filter.customer ?
                                <div className='d-flex w-100'>
                                    <Card>
                                        <Card.Body className='py-2 px-1'>
                                            {Invoice.icon_as_component()}{'\u00A0'}<em>zatím nefakturováno</em><br />
                                            {Customer.icon_as_component()}{'\u00A0'}{new Customer(accountingOrders[0].customer).label()}<br />
                                            {Currency.icon_as_component()}{'\u00A0'}{new Currency(accountingOrders[0].customer_currency).label}<br />
                                        </Card.Body>
                                    </Card>
                                    <div style={{ overflowX: "scroll" }} className='d-inline-flex flex-shrink-1'>
                                        {accountingOrders.map(order => <Card key={order.id} className='ml-1 flex-shrink-0'>
                                            <Card.Body className='pt-1 px-2'>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div className='d-flex align-items-center'><Hash /> <div style={{ marginTop: "4px" }}>{order.code}</div></div>
                                                    <Button onClick={() => removeFromInvoicing(order)} size='sm' variant='link text-danger'><X /></Button>
                                                </div>
                                                <div>
                                                    <div>
                                                        {order.vehicle ? order.vehicle.spz : (order.carrier && <>
                                                            {Carrier.icon_as_component()}{" "}
                                                            {new Carrier(order.carrier).label()}
                                                        </>)}
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <CashCoin className='mr-1' />
                                                    <span className={order.margin < 0 ? 'text-danger' : ''}>{order.margin} ({order.price_customer + order.surcharge_customer} - {order.price_carrier})</span>
                                                </div>
                                            </Card.Body>
                                        </Card>)}
                                    </div>
                                    <div className='d-flex flex-column justify-content-between ml-auto pl-3'>
                                        <Button className='mt-3' variant="outline-danger" onClick={() => resetAccounting()}>Storno</Button>
                                        <AccountingDialog
                                            className='mb-3'
                                            orders={accountingOrders}
                                            invoiceCreated={invoice => onInvoiceCreated(invoice)}
                                            orderCarrierInfoCompleted={onCarrierInfoCompleted}
                                            onHide={() => {
                                                if (accountingOrders && accountingOrders[0].force_show) {
                                                    delete accountingOrders[0].force_show
                                                    delete accountingOrders[0].automaticInvoicing
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                : <em><small>Jakmile vyberete první objednávku, dojde k omezení výběru pouze na vhodné objednvávky. (Již fakturované objednávky nejsou vidět)</small></em>}
                        </div>
                    </>}
                </Container>
                {!Account.currentUser().hasPerm("all_orders") && <Alert variant='warning'>
                    Nemáte oprávnění přistupovat ke všem objednávkám. Je možné, že některé nevyfakturované objednávky zde nebudou vidět. Kontaktujte nějakého administrátora systému, aby Vám oprávnění přidělil.
                </Alert>}
                <Table>
                    <thead>
                        <tr>
                            <th>
                                <div className='d-flex justify-content-between'>
                                    #
                                    <OrderSearchModal
                                        onOrderSelected={orderSearch}
                                        extraActions={!accountingOrders?.length ? [["GROUP", "Hromadná fakturace"]] : []}
                                    />
                                </div>
                            </th>
                            <th></th>
                            <th>CODE</th>
                            <th style={{ maxWidth: "250px" }}>Zákazník</th>
                            <th>Cena zákazník</th>
                            <th style={{ maxWidth: "250px" }}>Dopravce</th>
                            <th>Cena dopravce</th>
                            <th>Faktura dopravce</th>
                            <th className='text-center' style={{ minWidth: "100px" }}>Marže</th>
                            <th>Provedeno</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading || count === undefined ? <tr>
                            <td colSpan={11}>
                                <LoadingBanner>Načítání objednávek</LoadingBanner>
                            </td>
                        </tr> : orders.length === 0 ? <tr>
                            <th className='text-center' colSpan={8}>
                                <div style={{ minHeight: "200px" }} className='d-flex justify-content-center align-items-center flex-column'>
                                    <div style={{ fontSize: "100px" }}>
                                        🥳
                                    </div>
                                    <div>Zdá se, že máte hotovo. Nevidíme žádné objednávky{filter.invoice === "" && " bez faktury"}.</div>
                                </div>
                            </th>
                        </tr> : orders.map(order => {
                            const disabledForInvoicing = (!order.carrier && !order.vehicle) || !order.customer
                            return <tr key={order.id} className={orderIds[order.id] ? "table-secondary" : ""}>
                                <td><a href={`/order/${order.id}`}>{order.id}</a></td>
                                <td>
                                    {order.attachments.length > 0 && <Paperclip title="Obsahuje přílohu" />}
                                    {order.invoice && <a href={`/invoice/${order.invoice.id}`}><Receipt title="Již uvedeno na faktuře" /></a>}
                                </td>
                                <td>{order.code}</td>
                                <td>{new Customer(order.customer).label()}</td>
                                <td>{order.price_customer} {order.customer_currency?.code}</td>
                                <td>{new Carrier(order.carrier).label()}</td>
                                <td>{order.price_carrier} {order.carrier_currency?.code}</td>
                                <td>
                                    {order.carrier_missing_invoice ? <em className='text-warning'>Chybí</em> : <span className={!order.carrier_invoice ? "text-danger" : ""}>
                                        {order.carrier_invoice || "nevyplněno"}
                                    </span>}
                                </td>
                                <td className={order.margin < 0 ? 'text-danger' : ''}>
                                    {Math.floor(order.margin * 100) / 100}
                                </td>
                                <td>{order.last_location_time && new Date(order.last_location_time).toLocaleDateString()}</td>
                                <td>
                                    {accountingOrders.length === 0 ?
                                        <ButtonGroup>
                                            <Button size="sm" disabled={disabledForInvoicing} onClick={() => orderIds[order.id] ? removeFromInvoicing(order) : considerForInvoicing(order)} variant="outline-success"><PlusCircle /></Button>
                                            <DropdownButton size="sm" as={ButtonGroup} variant="outline-secondary" title="">
                                                <Dropdown.Item
                                                    disabled={order.invoice || disabledForInvoicing}
                                                    onClick={() => addToAccounting(order)}>
                                                    {Invoice.icon_as_component()} Hromadná fakturace
                                                </Dropdown.Item>
                                                <Dropdown.Divider />
                                                {order?.attachments?.length > 0 ? order.attachments.map(attachment => {
                                                    return <Dropdown.Item href={attachment.file} target="_blank" key={attachment.id}>
                                                        <Download /> {decodeURI(attachment.file.split("/").pop())}
                                                    </Dropdown.Item>
                                                }) : <Dropdown.Item disabled={true}>
                                                    bez příloh
                                                </Dropdown.Item>}
                                            </DropdownButton>
                                        </ButtonGroup> :
                                        <Button size="sm" disabled={disabledForInvoicing} onClick={() => addToAccounting(order)} variant='outline-primary'><PlusCircle /></Button>
                                    }
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table>
                <Pagination className='justify-content-center'>
                    <Pagination.Item onClick={() => { setPage(filter.page - 1) }} disabled={filter.page === 1}><ArrowLeftShort /></Pagination.Item>
                    <Pagination.Item onClick={() => { setPage(1) }} disabled={filter.page === 1}><ChevronDoubleLeft /></Pagination.Item>
                    <Pagination.Item disabled>{filter.page}</Pagination.Item>
                    <Pagination.Item onClick={() => { setPage(Math.floor(count / filter.limit) - 1) }} disabled={filter.page === Math.floor(count / filter.limit) - 1}><ChevronDoubleRight /></Pagination.Item>
                    <Pagination.Item onClick={() => { setPage(filter.page + 1) }} disabled={filter.page === Math.floor(count / filter.limit) - 1}><ArrowRightShort /></Pagination.Item>
                </Pagination>
            </Col>
        </Row>

    </Container>
}