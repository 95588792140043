import { useState } from "react"
import { Dropdown, Modal, Button, Form, Row, Col } from "react-bootstrap"
import { Alarm, GeoAlt } from "react-bootstrap-icons"
import { dateAddMS, dateToInputValue, dateToLocal, HumanReadableSeconds, secondsBetween } from "../helpers";


export default function TimeMover(props) {
    const [show, setShow] = useState(false);
    const [time, setTime] = useState(new Date(props.firstLocationTime).toLocaleString("sv"));
    const differenceInMS = secondsBetween(time, props.firstLocationTime);

    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => {
        setShow(true);
        setTime(new Date(props.firstLocationTime).toLocaleString("sv"));
    }
    const trigger = () => {
        handleClose()
        props.onSelected && props.onSelected(differenceInMS);
    }

    return <>
        <Dropdown.Item target="_blank" onClick={handleShow}><Alarm /> Posun v čase</Dropdown.Item>

        <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Posun v čase</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col md="5">
                    <Form.Group>
                        <Form.Label><strong>Nový čas</strong></Form.Label>
                        <Form.Control type="datetime-local" value={time} onChange={e => setTime(e.target.value)} />
                    </Form.Group>
                    { time === props.firstLocationTime || <div className={time > props.firstLocationTime ? "text-right text-success" : "text-right text-danger"}>
                        {HumanReadableSeconds(differenceInMS)}
                    </div>}
                    <div className="text-justify">
                        <em>Při zadání nového času dojde k posunu první zastávky na daný čas. Všechny ostatní zastávky se posunou relativně k nové zastávce</em>
                    </div>
                </Col>
                <Col md="7">
                    <table style={{width: "100%"}}>
                        <tbody>
                            {(props.locations || []).map(location => <tr key={location.id}>
                                <td>
                                    {location.place ? <div className="d-flex justify-content-between">
                                        <strong>{location.place.name}</strong>
                                        <strong><GeoAlt /> {location.place.country} {location.place.postal_code}</strong>
                                    </div> : <div><em>Neurčené místo</em></div>}
                                    <div className="d-flex justify-content-between">
                                    {
                                        !differenceInMS ? 
                                            <em className="text-success">{dateToLocal(location.time)}</em> : 
                                            <>
                                                <del className="text-danger">{dateToLocal(location.time)}</del>{" "}
                                                <span className="text-success">{dateToLocal(dateAddMS(location.time, differenceInMS))}</span>
                                            </>
                                    }
                                    </div>
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Zavřít
          </Button>
          <Button variant="success" onClick={trigger} disabled={!time || !differenceInMS}>
            <Alarm /> Posun
          </Button>
        </Modal.Footer>
      </Modal>
    </>
}