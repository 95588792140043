import { withRouter } from "react-router-dom";
import ModelView from './ModelView';
import Expense from '../Classes/Expense'
import { Button, Form, InputGroup, Modal, Alert } from "react-bootstrap";
import { useEffect, useState } from "react";
import { dateToInputValue } from "../helpers";
import Currency from "../Classes/Currency";
import Queryset from "../Classes/Queryset";
import LoadingBanner from "../Components/LoadingBanner";
import { getOptions } from "../Classes/Base";


function NewExpenseModal(props) {
    const [show, setShow] = useState(false);
    const [currencies, setCurrencies] = useState();
    const [error, setError] = useState();
    const [alert, setAlert] = useState();
    const [values, setValues] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    const [expenseOptions, setExpenseOptions] = useState({})

    function reset () {
        setValues({
            "currency": currencies[0].code,
            "value": 0,
            "name": "",
            "accountable_at": dateToInputValue(new Date())
        })
    }

    function update(name, value) {
        setValues({
            ...values,
            [name]: value
        })
    }

    function handleClose() {
        setShow(false)
        reset()
    }

    useEffect(() => {
        const queryset = new Queryset(Currency)
        queryset.all().then(async () => {
            if (!queryset.count) {
                setError("Chyba při načítání měn. Zkuste to znovu, nebo kontaktujte podporu.")
                return
            }
            setCurrencies(queryset.objects)
            setValues({
                "currency": queryset.objects[0].code,
                "value": 0,
                "name": "",
                "accountable_at": dateToInputValue(new Date())
            })
        })
        getOptions(Expense).then(options => {setExpenseOptions(options)})
    }, [])

    const handleShow = () => setShow(true);
    const save = async () => {
        const expense = new Expense({...values, id: 0})
        await expense.save()
        setFieldErrors({})
        setAlert()
        setError()
        if (expense.__status !== 201) {
            setFieldErrors(expense.__messages)
            setAlert("Ve formuláři se objevily chyby. Opravte je a zkuste to znovu.")
        }
        props.onSaved && props.onSaved();
        setShow(false)
    }
  
    return (
      <>
        <Button variant="success" onClick={handleShow}>
          Nový výdaj
        </Button>
  
        <Modal size="md" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Nový výdaj</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              { alert && <Alert variant="warning">{alert}</Alert>}
              { error ? <Alert variant="danger">
                {error}
              </Alert> : (!currencies || !expenseOptions) ? <LoadingBanner /> : <Form>
                <Form.Group className="mb-3" controlId="formName">
                    <Form.Label>Název</Form.Label>
                    <Form.Control
                        placeholder="Název"
                        value={values.name}
                        onChange={e => update("name", e.target.value)}
                        isInvalid={"name" in fieldErrors}
                        />
                    {fieldErrors.name && <Form.Control.Feedback type="invalid">{fieldErrors.name}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formName">
                    <Form.Label>Platnost</Form.Label>
                    <Form.Control isInvalid={"accountable_at" in fieldErrors} type="date"  value={values.accountable_at} onChange={e => update("accountable_at", e.target.value)} />
                    {fieldErrors.accountable_at && <Form.Control.Feedback type="invalid">{fieldErrors.accountable_at}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group>
                    <Form.Label>Hodnota</Form.Label>
                    <InputGroup>
                        <Form.Control type="number" isInvalid={"value" in fieldErrors} value={values.value} onChange={e => update("value", e.target.value)} />
                        <InputGroup.Append>
                            <Form.Control isInvalid={"currency" in fieldErrors} as="select" custom value={values.currency} onChange={e => {update("currency", e.target.value)}}>
                                {currencies.map((c, key) => {
                                        return <option key={key} value={c.code}>{c.label}</option>
                                })}
                            </Form.Control>
                        </InputGroup.Append>
                    </InputGroup>
                    {(fieldErrors.value || fieldErrors.currency) && <Form.Control.Feedback type="invalid">{fieldErrors.value}. {fieldErrors.currency}</Form.Control.Feedback>}
                </Form.Group>
              </Form>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Zavřít
            </Button>
            <Button variant="primary" onClick={save}>
              Vytvořit
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

class ExpenseView extends ModelView {
    constructor(props) {
        super(Expense, props)
    }
    new_button = <NewExpenseModal 
        onSaved={() => {this.loadData(true)}}
    />
}

export default withRouter(ExpenseView);