import React from 'react'
import { Alert, Spinner } from 'react-bootstrap';
import Queryset from '../Classes/Queryset';
import Contact from '../Classes/Contact';
import ContactComponent from './ContactComponent';
import ModalNewContact from './ModalNewContact';
import MessageCenter from './MessageCenter';


class EntityContactList extends React.Component {
    constructor(props) {
        super(props)
        if (props.entity === undefined) {
            throw Error("Entity is undefined!")
        }
        if (!Number.isInteger(props.entity.id) && props.entity.id < 1) {
            throw Error("ID is not defined correctly!")
        }
        this.state = {contacts: undefined, queryset: new Queryset(Contact)}
        this.modal_ref = React.createRef()
    }

    async componentDidMount() {
        this.loadContacts()
    }

    async loadContacts() {
        const filter = {}
        filter[this.props.entity] = this.props.id
        await this.state.queryset.filter(filter)
        this.forceUpdate()
    }

    async new(item) {
        item[this.props.entity] = this.props.id
        const contact = new Contact(item)
        await contact.save()
        console.log(contact);
        if (contact.__status && (contact.__status === 200 || contact.__status === 201)) {
            this.modal_ref.current.setShow(false)
            this.loadContacts()
        }
        else {
            MessageCenter.addMessage({"title": "Chyba při ukládání kontaktu", "text": Object.values(contact.__messages || {}).join(".")})
        }
    }

    contactDeleted(index) {
        this.state.queryset.objects.splice(index, 1)
        this.forceUpdate()
    }

    editContact(contact) {
        this.modal_ref.current.load(contact);
    }
  
    render() {
        if (this.state.queryset.count === -1) {
            return (
                <Spinner animation="border" role="status" />
            );
        }
        else {
            const contacts = this.state.queryset.objects.map((key, index) => {
                return (<ContactComponent contact={key} key={key.id} contactDeleted={() => this.contactDeleted(index)} onEdit={contact => {this.editContact(contact)}}/>)
            })
            return (
                <div className="contacts">
                     {this.state.queryset.objects.length ? 
                        contacts : 
                        <Alert variant="warning" className="mt-2">Zatím nemáte žádné kontakty. Přidejte nějaké tlačítkem níže!</Alert>}
                     <div className="mt-2 d-flex justify-content-center">
                        <ModalNewContact ref={this.modal_ref} closeCallback={(item) => {this.new(item)}} />
                     </div>
                </div>
            )
        }
    }
}

export default EntityContactList;