import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'
import Queryset from "../Classes/Queryset"
import Customer from "../Classes/Customer"
import { Alert, Jumbotron, Button, Spinner, Row, Col, Container, Tabs, Tab, ButtonGroup } from 'react-bootstrap'
import { getOptions } from "../Classes/Base"
import EntityContactList from "../Components/EntityContactList"
import { Trash } from 'react-bootstrap-icons'
import { OrderList } from '../Components/OrderList'
import { EntityForm } from '../Components/EntityForm'

const SAVE_DELAY = 1000

class CustomerDetail extends Component {
    state = {
        queryset: new Queryset(Customer),
        customer : undefined,
        updater : undefined,
        options : undefined,
        mailingAddressOpen: false,
    }

    async componentDidMount() {
        await this.state.queryset.one(this.props.match.params.id)
        if (this.state.queryset.status === 200) {
          this.setState({customer: this.state.queryset.object})
        }
        const options = await getOptions(Customer)
        this.setState({options})
    }

    async delete() {
        if (!window.confirm("Opravdu chcete smazat tento záznam?")) {
            return;
        }
        const result = await this.state.customer.delete()
        if (result) this.props.history.push("/customer/");
    }

    async valueChanged(name, value) {
        const customer = this.state.customer
        customer[name] = value
        this.setState(customer)
        await this.saveChanges(name)
    }

    async saveChanges(name) {
        // Helper function for the timeout
        const storeChange = (name) => {
            const customer = this.state.customer
            const data = { id: customer.id }
            data[name] = customer[name]
            customer.save(data).then(() => {
                this.setState({updater: undefined})
                this.setState(customer)
            })
        }

        let updater = this.state.updater
        if (updater !== undefined) {
            clearTimeout(updater[name])
        }
        updater = setTimeout(storeChange.bind(this, name), SAVE_DELAY)
        this.setState({updater})
    }
  
    async icoAuto(data) {
        data = data[Object.keys(data)[0]]
        let key = Object.keys(data)
        let value = Object.values(data)
        const customer = this.state.customer
        
        for (let i=0;i<key.length;i++) {
            customer[key[i]] = value[i]
            this.setState(customer)
            await this.saveChanges(key[i])
        }

    }

    render() {
        if (this.state.customer === undefined || this.state.options === undefined)  {
            return (<h1>Loading...</h1>)
        }
        else {
            return (
                <Container>
                    <Jumbotron>
                        <h1>{this.state.customer.name}</h1>
                        <ButtonGroup className="d-block float-right">
                        <Button className="d-block float-right" variant="danger" onClick={() => {this.delete()}}><Trash />Smazat</Button>
                        </ButtonGroup>
                        <h2>Detail zákazníka #{this.state.customer.id}</h2>
                    </Jumbotron>
                    <Row>
                        <Col md={8}>
                            {/*
                                Hiding until duplicates detection issue solved
                                <IcoAutocompleteModal options={this.state.options} ref={this.modal_ref} closeCallback={(data) => {this.icoAuto(data)}} klass={Customer}/>
                            */}
                            <EntityForm entity={this.state.customer} options={this.state.options} update={(name, value) => this.valueChanged(name, value)}/>    
                        </Col>
                        <Col md={4}>
                            {this.state.customer.blacklist && <Alert variant="danger">
                                <strong>Zákazník je na černé listině!</strong>
                            </Alert>}
                            <Tabs defaultActiveKey="contacts">
                                <Tab eventKey="contacts" title="Kontakty">
                                    <EntityContactList entity="customer" id={this.state.customer.id}/>
                                    {/*<hr/>
                                    <Button
                                        onClick={() => this.setState({mailingAddressOpen: !this.state.mailingAddressOpen})}
                                        aria-controls="example-collapse-text"
                                        aria-expanded={this.state.mailingAddressOpen}
                                        block
                                        className="d-flex justify-content-between"
                                        variant="link">
                                            Korespondenční adresa
                                            {this.state.mailingAddressOpen ? <CaretUp /> : <CaretDown />}
                                        </Button>
                                    <Collapse in={this.state.mailingAddressOpen} timeout={100}>
                                        <div id="example-collapse-text">
                                            <Form.Control placeholder='Adresát'/>
                                            <InputGroup>
                                                <Form.Control placeholder='Ulice'/>
                                                <InputGroup.Append>
                                                    <Form.Control placeholder='ČP' style={{maxWidth: "50px"}}/>
                                                </InputGroup.Append>
                                            </InputGroup>
                                            <Form.Control placeholder='Město'/>
                                            <Form.Control placeholder='PSČ'/>
                                            <Form.Control placeholder='Země'/>
                                        </div>
                                    </Collapse>*/}
                                </Tab>
                                <Tab eventKey="orders" title="Poslední objednávky">
                                    <OrderList filter={{customer: this.state.customer.id}} title="Poslední objednávky" />
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                    {this.state.updater && <div className="fixed-bottom">
                        <Container>
                            <Row>
                                <Col sm={12} md={8} lg={4} className="ml-auto bg-light d-flex p-2 mb-1 border rounded align-items-center justify-content-center">
                                    <Spinner animation="border" variant="info" /> <h3 className="ml-3 mb-0">Ukládání změn</h3>
                                </Col>
                            </Row>
                        </Container>
                    </div>}
                </Container>
            )
        }
    }
}

export default withRouter(CustomerDetail)
