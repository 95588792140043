import Queryset from './Queryset'
import Currency from './Currency'

class Currencies extends Queryset {
    constructor(props) {
        super(Currency, props)
    }

    convert(currency_old, currency_new, value) {
        //TODO move this to the for cycle and set proper objects from this.objects cycle
        this.objects.forEach((item) => {
            if (item.exchange_rate === 1.0) {
                this.base = item
            }
            if (typeof(currency_old) != "object" && currency_old === item.code) {
                currency_old = item
            }
            if (typeof(currency_new) != "object" && currency_new === item.code) {
                currency_new = item
            }
        })
        if (!this.base) {
            throw new Error("No base currency found!")
        }
        if (currency_old.code !== this.base.code) {
            value /= currency_old.exchange_rate
        }
        return value * currency_new.exchange_rate
    }
}

export default Currencies
