import React, { useState } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { ArrowLeft, ArrowRight, Binoculars } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

export function OrderPreviewModal(props) {
  const order = props.order;
  const [show, setShow] = useState(false);
  const [file, _] = useState({
    url: order.carrierPdfUrl(),
    httpHeaders: {
        "Authorization": `Token ${sessionStorage.getItem("key")}`
    }
  })
  const [numPages, setNumPages] = useState(0);
  const [page, setPage] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  return (
    <>
      <Button className={props.className} variant="primary" onClick={handleShow}>
        <Binoculars /> Náhled
      </Button>

      <Modal show={show} size="xl" onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className='d-flex align-items-center flex-column'>
            <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={page} />
            </Document>
            <ButtonGroup>
                <Button disabled={page <= 1} onClick={() => setPage(page-1)}><ArrowLeft /></Button>
                <Button disabled={page >= numPages} onClick={() => setPage(page+1)}><ArrowRight /></Button>
            </ButtonGroup>
        </Modal.Body>
      </Modal>
    </>
  );
}