import Base from './Base'
import settings from '../Settings'
import { Building, ExclamationTriangleFill } from 'react-bootstrap-icons'

class Customer extends Base{
    static base_url = settings.BASE_API_URL + "customer/"

    label() {
        return this.name || `Zákazník ${this.id}`
    }

    static columns = [{
        name: "id",
        label: "#",
    },
    {
        name: "name",
        label: "Jméno"
    },
    {
        name: "ico",
        label: "IČO"
    },
    {
        name: "town",
        label: "Město"
    },
    {
        name: "raal",
        label: "RAAL kód"
    },
    {
        name: 'blacklist',
        label: 'Černá listina',
        transform: value => !value ? null : <div className='text-danger'><ExclamationTriangleFill /> Na černé listině</div>
    }]

    static icon() {
        return Building
    }
}

export default Customer