import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import UserNavbarMenu from './UserNavbarMenu'
import { withRouter } from "react-router-dom";
import Account from '../Classes/Account';
import '../css/LogisticNavbar.css'

class LogisticNavbar extends React.Component {
  async logout() {
    await Account.logout()
    this.props.history.push("/logout")
    window.location.reload()
  }

  render() {
    const menu_items = Account.isLogged() ? this.props.menu.filter(item => true).map((item, key) => {
      if (item.label) {
        return item.toReactComponent(key)
      }
      else {
        return null
      }
    }) : ""
    return <Navbar expand="lg" bg="dark" variant="dark">
      <div className="container" >
        <Navbar.Brand href="/" className="flex-grow-0">Logistic</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="flex-grow-1">
            {menu_items}
          </Nav>
        </Navbar.Collapse>
        <Nav className="ml-auto flex-grow-0">
          <UserNavbarMenu menu={this.props.menu} logout={() => this.logout()}/>
        </Nav>
      </div>
    </Navbar>
  }

}
export default withRouter(LogisticNavbar);