import React from "react";
import { ListGroup, Spinner, Alert } from 'react-bootstrap'
import { Building, Map, Truck } from 'react-bootstrap-icons'
import Order from "../Classes/Order";
import Location from "../Classes/Location";
import Queryset from "../Classes/Queryset";
import { getOptions } from "../Classes/Base";

export class OrderList extends React.Component {

    state = {
        "orders" : new Queryset(Order),
        "options" : null
    }

    async componentDidMount() {
        const filter = this.props.filter
        filter.limit = 10
        const options = getOptions(Order);
        await this.state.orders.filter(filter)
        this.forceUpdate()
        this.setState({options: await options})
    }


    render() {
        return <div>
            <ListGroup>
                {!this.state.orders ? <Spinner animation="border" role="status" size="sm">
                        <span className="sr-only">Čekáme na data...</span>
                    </Spinner> : this.state.orders.objects.length ? this.state.orders.objects.map(item => {
                        // state has to be translated from options, if not fallback take the value
                        const state = (this.state.options && this.state.options.actions.POST.state.choices.find(i => i.value === item.state)) || {"display_name" : item.state};
                        return <ListGroup.Item key={item.id}>
                                # <a href={`/order/${item.id}`}><strong>{item.code}</strong></a>
                                <div>Stav: <strong>{state.display_name}</strong></div>
                                <div><Map />{'\u00A0'}{item.locations.map(i => {return Location.prototype.prettyName.call(i, true)}).join(" \u{1f852} ") || "Bez zastávek"}</div>
                                {!this.props.filter.customer && <div><Building /> {item.customer ? 
                                    <a href={"/customer/" + item.customer.id}>{item.customer.name}</a> : "Bez zákazníka"}</div>}
                                {!this.props.filter.carrier && <div><Truck /> {item.carrier ? 
                                    <a href={"/customer/" + item.carrier.id}>{item.carrier.name}</a> : "Bez dopravce"}</div>}
                                <div>{Order.valueTransforms.labels(item.labels)}</div>
                        </ListGroup.Item>
                    }) : <Alert variant="warning" className="mt-2">Žádné objednávky nebyly v systému nalezeny</Alert>}
            </ListGroup>
        </div>
    }
}