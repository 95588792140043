import React from "react"
import { withRouter } from "react-router-dom";
import ModelView from './ModelView';
import Carrier from '../Classes/Carrier'
import IcoAutocompleteModal from "../Components/IcoAutocompleteModal";
import MessageCenter from "../Components/MessageCenter";

class CarrierView extends ModelView {
    constructor(props) {
        super(Carrier, props)
    }
    help = <div>
        <p>Dodavatelé Vám pomáhají poskytovat služby</p>
        <p>Nadefinujte si kontakty, zobrazují se na několika místech v aplikaci a můžete je použít při posílání e-mailů</p>
        <p>U dopravců také snadno objevíte poslední objednávky, které jste s dopravcem dělali.</p>
    </div>
    helpTopic = "carrier"
    extra_actions = [
        <IcoAutocompleteModal klass={Carrier} className="ml-2" closeCallback={async result => {
            if (!result.data) {
                MessageCenter.addMessage({
                    "title": "Hledání v ARESu selhalo",
                    "text": "Bohužel selhalo hledání v ARESu, zkuste to znovu, nebo kontaktujte podporu, nezapomeňte přitom uvést i IČO hledaného subjektu"
                })
                return;
            }
            const carrier = new Carrier({id: 0, ...result.data});
            await carrier.save();
            if (carrier.id === 0) {
                MessageCenter.addMessage({
                    "title": "Ukládání dat selhalo",
                    "text": "Bohužel se nepodařilo uložit data, zkuste to znovu, nebo kontaktujte podporu, nezapomeňte přitom uvést i IČO hledaného subjektu"
                })
                return;
            }
            this.itemDetail(carrier);
        }}/>
    ]
}

export default withRouter(CarrierView);