import React from "react"
import { withRouter } from "react-router-dom";
import ModelView from './ModelView';
import Snippet from '../Classes/Snippet'

class SnippetView extends ModelView {
    constructor(props) {
        super(Snippet, props)
    }
    modalNewPlaceholder = "Text"
    modalNewLabel = "Nový útržek"
    modalNewTitle = "Přidávání nového útržku"
    jumbotronTitle = "Útržky"
    jumbotronSubtitle = "Správa útržků"
    newAttribute = "title"

    help = <div>
        <p>Úryvky slouží ke vkládání textu do některých částí generovaných dokumentů. Třeba k PDF objednávkám.</p>
        <p>Podle jazyka použitého ke generování se použije i správná jazyková varianta útržku.</p>
    </div>
}

export default withRouter(SnippetView);