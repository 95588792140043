import { data } from "jquery";
import { useEffect, useState } from "react";
import { Alert, Container, Form, Jumbotron, OverlayTrigger, Popover, Table } from "react-bootstrap";
import { BoxArrowDownRight, BoxArrowInUpRight } from "react-bootstrap-icons";
import { Route, BrowserRouter, useRouteMatch, Redirect, useParams, useHistory } from "react-router-dom";
import Carrier from "../Classes/Carrier";
import Customer from "../Classes/Customer";
import Order from "../Classes/Order";
import User from "../Classes/User";
import Vehicle from "../Classes/Vehicle";
import LoadingBanner from "../Components/LoadingBanner";
import { dateAddDays, dateToInputValue, dateToLocal, daysBetween, getStartOfWeek, sameDay } from "../helpers";
import { RangeRenderer } from "./OrderReportingView";

const SHORT_TO_LABEL = {
    'vehicle': 'api.Vehicle',
    'user': 'auth.User',
}

function NoteCard(props) {
    const {note} = props
    return <div className="p-2 bg-light rounded bordered">
        <div className="d-flex justify-content-between">
            <strong>{note.name}</strong>
            <em>{dateToLocal(note.date)}</em>
        </div>
        <p>{note.text}</p>
    </div>
}

function OrderCard(props) {
    const { order, day } = props
    const [ locations, setLocation] = useState([])

    useEffect(() => {
        const locations = []
        for (let i = 0; i<order.locations.length; ++i) {
            const location = order.locations[i]
            if (sameDay(location.time, day)) {
                locations.push(location)
                break;
            }
        }
        setLocation(locations)
    }, [order, day])
    const popover = (
        <Popover id={`${order.id}-popover`}>
            <Popover.Title as="h3"><a href={`/order/${order.id}`}>#{order.id} - {order.code}</a></Popover.Title>
            <Popover.Content>
                <div><strong>{order.route_text}</strong></div>
                <div>{Carrier.icon_as_component()} {order.carrier ? order.carrier.name : <em>Bez dopravce</em>}</div>
                <div>{Customer.icon_as_component()} {order.customer ? order.customer.name : <em>Bez zákazníka</em>}</div>
                <div>{Vehicle.icon_as_component()} {order.vehicle ? order.vehicle.spz : <em>Bez vozidla</em>}</div>
                <hr />
                {!!order.locations.length && <ul style={{paddingLeft: "1em", marginBottom: 0}}>
                    {order.locations.map(location => <li style={{listStyle: "none"}} key={location.id}>
                        <div className="d-flex align-items-center">
                            {location.type === "LOAD" ? <BoxArrowInUpRight /> : <BoxArrowDownRight /> }
                            <div style={{marginLeft: '3px'}}>{location.place && location.place.name}</div>
                        </div>
                        <div><em>{dateToLocal(location.time)}</em></div>
                    </li>)}
                </ul>}
            </Popover.Content>
        </Popover>
    );

    // Place it to the left if it's sunday (0) or saturday (6)
    const placement = (new Date(day).getDay() % 6) ? "right" : "left"

    return <OverlayTrigger rootClose trigger="click" placement={placement} overlay={popover}>
        <div className="border rounded py-1 px-1 mb-1" style={{cursor: "pointer"}}>
            <span className={locations.length === 0 ? "text-muted" : ""}>{order.route_text}</span>
            {!!locations.length && <ul style={{paddingLeft: "1em", marginBottom: "0"}}>
                {locations.map(location => <li style={{listStyle: "none"}} key={location.id}>
                    {location.type === "LOAD" ? <BoxArrowInUpRight /> : <BoxArrowDownRight /> }
                    {" "} {location.place && location.place.name}
                </li>)}
            </ul>}
        </div>
    </OverlayTrigger>
}

function AgendaTableHead(props) {
    const {start, end} = props
    //const days_range = new Array())
    const ths = []
    for (let i=0; i<=daysBetween(start, end); i++) {
        ths.push(<th key={i}>{dateAddDays(start, i).toLocaleDateString()}</th>)
    }
    return <thead><tr>
            <th></th>
            {ths}
    </tr></thead>
}

function AgendaTableFocusedObject(props) {
    const {obj, focus} = props
    console.log(focus)
    if (focus == "user") {
        return obj.first_name || obj.last_name ? `${obj.first_name} ${obj.last_name}` : `${obj.username}`
    }
    else if (focus == "vehicle") {
        return <a href={`/vehicle/${obj.id}`}>{obj.spz}</a>
    }
    else {
        return `Neznámý - ${focus}`
    }
}

function AgendaTableBody(props) {
    const {start, end, values, focus_type} = props
    const {data, focus} = values
    const related_objects_rows = focus.map(focused_object => {
            const orders = data[focused_object.id]['orders']
            const notes = data[focused_object.id]['notes']
            const row_data_columns = []
            row_data_columns.push(
                <th key={"focused_object"}><AgendaTableFocusedObject obj={focused_object} focus={focus_type} /></th>
            )
            let running_orders = []
            for (let i=0; i<=daysBetween(start, end); i++) {
                const the_day = dateAddDays(start, i)
                const todays_notes = []
                // Let's have a look, which orders will we add to the list of running orders
                while (orders.length && Order.prototype.isRunning.call(orders[0], the_day, true)) {
                    running_orders.push(orders.shift())
                }
                //And what notes are actual for the day
                while (notes.length && sameDay(the_day, notes[0].date)) {
                    todays_notes.unshift(notes.shift())
                }
                // Let's render now!
                row_data_columns.push(
                    <td key={i}>
                        {running_orders.map((order, index) => {
                            return <OrderCard key={index} order={order} day={the_day} />
                        })}
                        {todays_notes.map((note, index) => {
                            return <NoteCard note={note} key={index}/>
                        })}
                    </td>
                )
                //Let's drop those orders who are over
                running_orders = running_orders.filter(i => !Order.prototype.isOver.call(i, the_day, true))
                
            }
            return <tr key={focused_object.id}>
                {row_data_columns}
            </tr>
        })
    
    
    return <tbody>
        {related_objects_rows}
    </tbody>
}

function AgendaTable(props) {
    const {values, start, end, focus} = props
    return <Table>
        <AgendaTableHead start={start} end={end}/>
        <AgendaTableBody focus_type={focus} start={start} end={end} values={values}/>
    </Table>
}

function AgendaViewer() {
    const { focus, start, end } = useParams();
    const [agendaData, setAgendaData] = useState();
    const [error, setError] = useState();
    const history = useHistory()

    useEffect(() => {
        async function load() {
            setAgendaData()
            const values = await Order.loadAgenda(SHORT_TO_LABEL[focus], start, end)
            if (values.http_status === 200) {
                setError()
                setAgendaData(values)
            }
            else if (values.http_status === 403) {
                setError("Nedostatečná oprávnění. Kontaktujte svého správce")
            }
            else {
                setError("Neočekávaná opověď ze serveru, zkuste to později, případně kontaktujte podporu")
            }
        }
        load()
    }, [focus, start, end])

    function dayRangeChanged(new_start) {
        const end = dateAddDays(new_start, 6)
        if (sameDay(start, new_start)) return;
        history.push(`/agenda/${focus}/${dateToInputValue(new_start)}/${dateToInputValue(end)}`)
    }

    function focusChanged(focus) {
        history.push(`/agenda/${focus}/${dateToInputValue(start)}/${dateToInputValue(end)}`)
    }

    return <>
        <Container fluid>
            <Container>
                <Jumbotron className="py-3">
                    <h1>Týdenní agenda</h1>
                    <div className="d-flex justify-content-between">
                        <RangeRenderer reportType="week" onChange={dayRangeChanged}/>
                        <div>
                            <Form.Control as="select" value={focus} onChange={e => focusChanged(e.target.value)}>
                                <option value="vehicle">Vozidla</option>
                                <option value="user">Operátoři</option>
                            </Form.Control>
                        </div>
                    </div>
                </Jumbotron>
            </Container>
            {error ? <Container><Alert variant="danger">{error}</Alert></Container> : !agendaData ?
                <Container><LoadingBanner /></Container> : 
                <AgendaTable focus={focus} values={agendaData} start={new Date(start)} end={new Date(end)} />}
        </Container>
    </>
}

function basePath(focus) {
    const start = getStartOfWeek(new Date())
    const end = dateAddDays(start, 6)
    return `/agenda/${focus}/${dateToInputValue(start)}/${dateToInputValue(end)}`
}

export function AgendaView() {
    const { path } = useRouteMatch();
    return <BrowserRouter>
        <Route path={`${path}:focus/:start/:end`} children={<AgendaViewer />} />
        <Route path={`${path}`} exact children={<Redirect to={basePath("vehicle")}/>} />
    </BrowserRouter>
}