import React from 'react'
import '../../css/DefaultRenderer.css'

function DefaultRenderer(log_entry) {
    return <table className="logentry-renderer">
        <tbody>
            {Object.entries(log_entry).map(item => {
                if (log_entry.hasOwnProperty(item[0]) && typeof(item[1]) !== 'function') {
                    return <tr key={item[0]}>
                <th>{item[0]}</th>
                <td>{typeof(item[1]) === "object" ? JSON.stringify(item[1]) : item[1]}</td>
                </tr>
                }
                else return null
            })}
        </tbody>
    </table>
}

export default DefaultRenderer;