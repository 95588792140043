import React, { Component } from 'react'
import { Container, Jumbotron } from 'react-bootstrap'
import {withRouter} from 'react-router-dom'
import {VehicleCalendar} from '../Components/VehicleCalendar'

class VehicleOverview extends Component {

    render() {
        return <Container>
            <Jumbotron>
                <h1>Přehled jízd vozidel</h1>
                <p>V tomto přehledu jsou vidět všechny objednávky, které mají Vaše vozidla odjet v tomto měsíci. </p>
            </Jumbotron>
            <VehicleCalendar />            
        </Container>
    }
}

export default withRouter(VehicleOverview)
