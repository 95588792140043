import Base from './Base'
import settings from '../Settings'
import { Cash } from 'react-bootstrap-icons'

class Currency extends Base{
    static base_url = settings.BASE_API_URL + "currency/"
    static pk = 'code'

    static icon() {
        return Cash
    }
}

export default Currency