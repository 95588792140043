import Base from './Base'
import settings from '../Settings'
import React from 'react'
import '../css/LabelManager.css'
import {Form} from 'react-bootstrap';

function renderLabel(value, options, item) {
    return  <>
        <span className="colored-dot" style={{backgroundColor: `rgb(${item.rgb[0]}, ${item.rgb[1]}, ${item.rgb[2]})`}}></span>
        <span>{item['name']}</span>
    </>
}

function renderBlockingCheckbox(value, options, item) {
    return <>
        <Form.Check checked={item.blocking} readOnly={true}/>
    </>
}

class Label extends Base{
    static base_url = settings.BASE_API_URL + "label/"
    static columns=[{
            name: "name",
            label: "Jméno",
            transform: renderLabel
        }, {
            name: "blocking",
            label: "Blokující",
            transform: renderBlockingCheckbox
        }]

    render() {
        return renderLabel(undefined, undefined, this)
    }

    label() {
        return this.render()
    }
}

export default Label
