import { useEffect, useState } from "react";
import { Alert, Modal, Row, Col } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import Order from "../Classes/Order";
import LoadingBanner from "./LoadingBanner";

export function VehicleTypeModal(props) {
    const [show, setShow] = useState(false)
    const [vehicleTypes, setVehicleTypes] = useState([])
    const [error, setError] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        Order.autocomplete("vehicle_type", "").then(res => setVehicleTypes(res.values)).catch(() => setError(true)).finally(setLoading(false))
    }, [])

    function valueSelected(value) {
        setShow(false);
        props.valueSelected && props.valueSelected(value)
    }

    return <>
        <a className="text-primary" onClick={() => setShow(true)} style={{ cursor: "pointer" }} role="button"><Search /></a>
        <Modal show={show} onHide={() => setShow(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Výběr typu vozidla</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading && <LoadingBanner />}
                {error && <Alert variant="danger">
                    <strong>Nepodařilo se načíst seznam vozidel.</strong>
                </Alert>}
                {!loading && !error && vehicleTypes.length === 0 ? <Alert variant="warning">Na výběr nejsou žádné typy vozidel</Alert> : <Row>
                    {vehicleTypes.map((i, index) => <Col sm={4} key={index}>
                        <span rel="button" className="text-primary" style={{cursor: "pointer"}} onClick={() => valueSelected(i.vehicle_type)}>
                            {i.vehicle_type || <em>Prázdné</em>}
                        </span> <span> {i.total}{"\u00D7"}</span>
                    </Col>)}
                </Row>}
                <Alert variant="secondary" className="mt-5">
                    <small>Hodnoty zde zobrazené pocházejí ze všech objednávek. Objevují se pouze takové, které máte ve svých datech. Pokud chcete z tohoto seznamu nějaký odstranit, vyfiltrujte si danou objednávku a danou hodnotu upravte.</small>
                </Alert>
            </Modal.Body>
        </Modal>
    </>
}