import React from 'react'
import { Row, Col, Table, Button, Container} from 'react-bootstrap'
import ModelView from './ModelView';
import { withRouter } from 'react-router-dom'
import LogEntryViewerModal from '../Components/LogEntryViewerModal';
import Queryset from '../Classes/Queryset';
import LogEntry from '../Classes/LogEntry';
import User from '../Classes/User'

const cols = ['view', 'action', 'method', 'path']

class LogView extends ModelView {
    constructor(props) {
        const today = new Date()
        const tommorow = new Date()
        tommorow.setDate(tommorow.getDate() + 1);
        console.log(tommorow)
        super(LogEntry, props)
        this.state.users = new Queryset(User);
        this.state.filter = {
            'view' : undefined,
            'action' : undefined,
            'user' : undefined,
            'after': `${today.getFullYear()}-${("0"+(today.getMonth() + 1)).slice(-2)}-${("0"+today.getDate()).slice(-2)}`,
            'before': `${tommorow.getFullYear()}-${("0"+(tommorow.getMonth() +1)).slice(-2)}-${("0"+tommorow.getDate()).slice(-2)}`
        }
        this.log_viewer = React.createRef()
    }

    

    async componentDidMount() {
        const users_load = this.state.users.all()
        const log_load = this.state.queryset.filter({before: this.state.filter.before, after: this.state.filter.after})
        const views_actions = await LogEntry.views_actions()
        const views_actions_mapping = {}
        for (let i = 0; i < views_actions.length; ++i) {
            const view = views_actions[i].view 
            const action = views_actions[i].action || "unknown"
            if (!(view in views_actions_mapping)) {
                views_actions_mapping[view] = []
            }
            views_actions_mapping[view].push(action)
        }
        await users_load;
        await log_load;
        this.setState({
            "views_actions": views_actions_mapping
        })
    }

    updateFilter(name, value, unset) {
        const filter = this.state.filter
        filter[name] = value
        if (unset) {
            filter[unset] = undefined
        }
        this.setState(filter)
    }

    viewChanged(ev) {
        this.updateFilter('view', ev.target.value, 'action')
    }

    actionChanged(ev) {
        this.updateFilter('action', ev.target.value)
    }

    userChanged(ev) {
        this.updateFilter('user', ev.target.value)
    }

    dateChanged(el) {
        const filter = this.state.filter
        filter['after'] = el.target.value
        filter['before'] = el.target.value
        console.log(el.target.value)
        this.setState({filter})
    }

    async filterClicked() {
        const filter = {}
        const keys = ['view', 'action', 'user', 'before', 'after']
        keys.forEach(key => {
            if (this.state.filter[key] !== "" && this.state.filter[key] !== undefined) {
                filter[key] = this.state.filter[key]
            }
        })
        console.info("New filter applied: ", filter)
        await this.state.queryset.filter(filter)
        this.forceUpdate()
    }



    render() {
        let content;
        if (this.state.queryset.count < 0 || !this.state.views_actions) {
            content = "Loading ..."
        }
        else if (this.state.queryset.count === 0) {
            content = "No entries ..."
        }
        else {
            content = <Table>
            <thead>
                <tr>
                    <th>user</th>
                    <th>datum</th>
                    {cols.map(col => {
                        return <th key={col}>{col}</th>
                    })}
                    <th>show detail</th>
                </tr>
            </thead>
            <tbody>
                {this.state.queryset.objects.map(item => {
                return <tr key={item.id}>
                    <td>
                        {item.user ? item.user.username : "None" }
                    </td>
                    <td>{new Intl.DateTimeFormat("cs-CZ", {
                                    year: "numeric",
                                    month: "long",
                                    day: "2-digit",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    }).format(new Date(item.timestamp))
                        }</td>
                    {cols.map(col => {
                        return <td key={col}>{item[col]}</td>
                    })}
                    <td>
                        <Button onClick={()=>{
                            this.log_viewer.current.setState({
                                "show" : true,
                                "log_entry": item
                            })
                        }}>{/*<FontAwesomeIcon variant="outline-secondary" icon={faEye} />*/}E</Button>
                    </td>
                </tr>
                })}
            </tbody>
          </Table>
        }
        return (
            <Container>
                <LogEntryViewerModal ref={this.log_viewer}></LogEntryViewerModal>
                <Row>
                    <Col>
                    { !this.state.views_actions ? "" : 
                        <>
                            <strong>Pohledy a akce: </strong>
                            <select value={this.state.filter.view} onChange={ ev => {this.viewChanged(ev)}}>
                                <option value="">Žádné omezení na pohledy</option>
                                {Object.keys(this.state.views_actions).map(item => {
                                    return <option key={item}>{item}</option>
                                })}
                            </select>
                            {this.state.filter.view && <select value={this.state.filter.action || ""} onChange={ ev => {this.actionChanged(ev)}}>
                                <option value="">Všechny akce</option>
                                {Object.values(this.state.views_actions[this.state.filter.view]).map(item => {
                                    return <option key={item}>{item}</option>
                                })}
                            </select>
                            }
                            <strong>Uživatelé: </strong>
                            <select value={this.state.filter.user} onChange={ ev => {this.userChanged(ev)}}>
                                <option value="">Všichni uživatelé</option>
                                {this.state.users.objects.map(item => {
                                    return <option value={item.id} key={item.id}>{item.username}</option>
                                })}
                            </select>
                            <strong>Datum: </strong>
                            <input type="date" value={this.state.filter.after} onChange={el => {this.dateChanged(el)}} />
                        </>
                    }
                    <Button className="ml-auto" variant='outline-secondary' onClick={()=>{this.filterClicked()}}>Hledej</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    {content}
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withRouter(LogView);
