import React from 'react'
import {Card, Badge} from 'react-bootstrap';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import { Dropdown } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/DropdownItem';
import { MenuUp } from 'react-bootstrap-icons';


class UserCard extends React.Component {
  constructor(props) {
    super(props)
    if (this.props.actions === null)
      this.props.actions = () => {
        // Default callback for all user actions must be implemented here. 
      }
    this.state = {user: this.props.user}
  }

  render() {
    let title;
    if (this.state.user.first_name.length === 0 && this.state.user.last_name.length === 0) {
      title = <h3 className="text-muted">bezejmenný</h3>
    }
    else {
      title = <h3>{this.state.user.first_name} {this.state.user.last_name}</h3> 
    }
    return (
        <div className="col-sm-6 col-md-4">
            <Card className="card p-2 mt-2">
                <Card.Body>
                    <Card.Title className="d-inline-flex justify-content-between col">
                        {title}
                        <Dropdown>
                          <Dropdown.Toggle variant="light">
                              <MenuUp />
                          </Dropdown.Toggle>
                          <DropdownMenu>
                              <DropdownItem onClick={()=>this.props.actions("edit", this.state.user)}>Upravit uživatele</DropdownItem>
                              <DropdownItem onClick={()=>this.props.actions("passwd", this.state.user)}>Změnit heslo</DropdownItem>
                              <DropdownItem onClick={()=>this.props.actions("delete", this.state.user)}>Smazat uživatele</DropdownItem>
                              <DropdownItem onClick={()=>this.props.actions("terminate-sessions", this.state.user)}>Zneplatit přihlášení</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                    </Card.Title>
                        <ul>
                          <li>Uživatelské jméno: {this.state.user.username}</li> 
                          <li>E-mail: {this.state.user.email || <span className="text-secondary">bez e-mailu</span>}</li> 
                          {this.state.user.operatorprofile && this.state.user.operatorprofile.phone_number && <li>Telefonní číslo {this.state.user.operatorprofile.phone_number}</li>}
                        </ul>
                        {this.state.user.active_session && <Badge pill variant="primary" title='Uživatele můžete odhlásit z nabídky'>Aktivní přihlášení</Badge>}
                        {this.state.user.is_superuser ? <Badge pill variant="danger">Administrátor</Badge> :
                        <Badge pill variant="warning">Oprávnění: {this.state.user.user_permissions.length}</Badge>}
                        
                </Card.Body>
            </Card>
        </div>
    )
  }
}

export default UserCard;