import Base from './Base'
import settings from '../Settings'
import fetchProxy from '../Helpers/fetchProxy'
import MessageCenter from '../Components/MessageCenter'
import { Truck } from 'react-bootstrap-icons'

class Vehicle extends Base{
    static base_url = settings.BASE_API_URL + "vehicle/"
    static columns = [{
        name: "id",
        label: "#"
    },
    {
        name: "spz",
        label: 'SPZ'
    },
    {
        name: "type",
        label: 'Typ',
    }]

    label() {
        return this.spz
    }

    static icon() {
        return Truck
    }

    static async vehicle_availability(before, after) {
        let url  = this.base_url + 'availability'
        if (before || after) {
            url += `?after=${after}&before=${before}`
        }
        const req = await fetchProxy(url)
        if (req.status !== 200) {
            MessageCenter.addMessage({"title": "Chyba při načítání stavu vozidel", "message": "Bohužel při načítání dostupnosti vozidel se stala chyba, zkuste obnovit stránku."})
        }
        return await req.json()
    }
}

export default Vehicle