import { useEffect, useState } from 'react'
import { Alert, Button, Container, Form, InputGroup, Pagination, Table } from 'react-bootstrap'
import { ArrowLeftShort, ArrowRightShort, ChevronDoubleLeft, ChevronDoubleRight, Search } from 'react-bootstrap-icons'
import { useHistory } from 'react-router-dom'
import Customer from '../Classes/Customer'
import Invoice from '../Classes/Invoice'
import Queryset from '../Classes/Queryset'
import LoadingBanner from '../Components/LoadingBanner'
import SearchModal from '../Components/SearchModal'


function PaidDateRenderer(props) {
    const { date } = props
    if (!date) return null;
    return new Date(date).toLocaleDateString()

}

function isDefaultFilter(filter) {
    return Object.keys(filter).map(key => DEFAULT_FILTER[key] != undefined).every(x => x)
}

const DEFAULT_FILTER = {
    "page": 1,
    "limit": 10,
    "customer": undefined
}

export default function InvoiceView() {
    const history = useHistory()
    const [invoices, setInvoices] = useState()
    const [loading, setLoading] = useState()
    const [error, setError] = useState()
    const [count, setCount] = useState()
    const [filter, setFilter] = useState({...DEFAULT_FILTER})
    const [search, setSearch] = useState("")
    const [searchFieldValue, setSearchFieldValue] = useState("")

    const [customerHovered, setCustomerHovered] = useState(false);

    function setPage(page) {
        setFilter({
            ...filter,
            page
        })
    }

    function updateFilter(name, value) {
        setFilter({
            ...filter,
            [name]: value
        })
    }

    function filterByDueDate(dueFilter) {
        const newFilter = { ...filter }
        delete newFilter.pay_date__isnull
        delete newFilter.due_date
        delete newFilter.due_date__lte
        switch (dueFilter.target.value) {
            case 'all':
                setFilter(newFilter)
                break;
            case 'paid':
                setFilter({ ...newFilter, payment__isnull: false })
                break;
            case 'unpaid':
                setFilter({ ...newFilter, payment__isnull: true })
                break;
            case 'due':
                const today = new Date().toISOString().split("T")[0]
                setFilter({ ...filter, payment__isnull: true, due_date__lte: today })
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        const loader = async () => {
            setLoading(true);
            const queryFilter = Object.fromEntries(
                Object.entries(filter).filter(item => item[1] !== undefined)
            )
            if (queryFilter.customer) {
                queryFilter.customer = queryFilter.customer.id
            }
            if (search) {
                queryFilter.search = search
            }
            if (!queryFilter) return;
            const queryset = new Queryset(Invoice)
            const serverCount = Invoice.serverCount(queryFilter)
            try {
                await queryset.filter(queryFilter)
                if (queryset.status !== 200) {
                    throw Error(`Server vrátil neočekávaný stavový kód - ${queryset.status}. Zkuste to znovu, případně kontaktujte podporu`)
                }
                setInvoices(queryset.objects)
                setCount(await serverCount)
            }
            catch (e) {
                setError(e)
            }
            finally {
                setLoading(false);
            }
        }
        loader()
    }, [filter, search])

    if (loading) {
        return <Container>
            <LoadingBanner />
        </Container>
    }

    if (error) {
        return <Container>
            <Alert variant='danger'>
                <strong>Bohužel nastala chyba</strong><br />
                {error}
            </Alert>
        </Container>
    }

    return <div className='w-100 d-flex flex-column align-items-center'>
            <div className='my-2 d-flex'>
                <div className='search-input-wrapper'>
                    <InputGroup size='sm'>
                        <Form.Control
                            type='text'
                            placeholder='Hledat'
                            value={searchFieldValue}
                            onChange={(e) => setSearchFieldValue(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    setSearch(searchFieldValue)
                                }
                            }}
                        />
                        <InputGroup.Append>
                            <Button
                                variant='outline-secondary'
                                onClick={() => {
                                    setSearch(searchFieldValue)
                                }}
                                className='mr-2'
                            >
                                <Search />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </div>
                {filter.customer ?
                    <div
                        onMouseEnter={() => setCustomerHovered(true)}
                        onMouseLeave={() => setCustomerHovered(false)}
                        onClick={() => updateFilter("customer", undefined)}
                        style={{ ...(!customerHovered ? {} : { "color": "red", "cursor": "pointer" }) }}>
                        {Customer.icon_as_component()} {new Customer(filter.customer).label()}
                    </div> :
                    <SearchModal
                        label="Vybrat podle zákazníka"
                        size="sm"
                        variant="outline-secondary"
                        closeCallback={customer => updateFilter("customer", customer)}
                        klass={Customer} />
                }
                <div className='ml-2'>
                    <Form.Control as="select" size="sm" onChange={filterByDueDate}
                        value={filter.payment__isnull === undefined ?
                            "all" : filter.payment__isnull === false ?
                            "paid" : !filter.due_date__lte ?
                            "unpaid" : "due"}
                            >
                        <option value="all">Všechny splatnosti</option>
                        <option value="paid">Splacené</option>
                        <option value="unpaid">Nesplacené</option>
                        <option value="due">Po splatnosti</option>
                    </Form.Control>
                </div>
            </div>
        <Container fluid>
            {!invoices || invoices.length < 1 ? <Alert className="d-flex align-items-center flex-column" variant="secondary" style={{maxWidth: "400px", margin: "20px auto 30px"}}>
                    {JSON.stringify(DEFAULT_FILTER) === JSON.stringify(filter) ?
                        <div>{isDefaultFilter(filter) ? "Ještě neevidujeme žádné faktury. Vytvořte nějaké v nabídce fakturace." : "Na základě zadaných parametrů se nám nepodařilo žádnou fakturu najít"} </div> :
                        <>
                            <div>Vašemu hledání nevyhovuje žádná faktura.</div>
                            <Button variant="outline-danger" onClick={setFilter({...DEFAULT_FILTER})}>Zobrazit všechny faktury</Button>
                        </>}
            </Alert> : <Table striped hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Zákazník</th>
                        <th>Objednávky</th>
                        <th>Datum splatnosti</th>
                        <th>Splaceno</th>
                        <th>Objednávka u zákazníka</th>
                        <th>Označení v ext. systému</th>
                    </tr>
                </thead>
                <tbody>
                    {invoices?.map(invoice => <tr key={invoice.id} onClick={() => history.replace("/invoice/" + invoice.id)}>
                        <td>{invoice.id}</td>
                        <td>{invoice.customer && new Customer(invoice.customer).label()}</td>
                        <td>{invoice.orders?.map(o => o.code).join(", ")}</td>
                        <td 
                            style={
                                invoice.due_date && !invoice.payment && new Date() > new Date(invoice.due_date) ? {"color": "red"} : {}}>
                                    {invoice.due_date && new Date(invoice.due_date).toLocaleDateString()}
                                </td>
                        <td><PaidDateRenderer date={invoice?.payment?.paid_on} /></td>
                        <td>{invoice.customer_order}</td>
                        <td>
                            {!!(invoice.external_connector || invoice.external_id) &&
                            `${invoice.external_connector}/${invoice.external_id}`}
                        </td>
                    </tr>)}
                </tbody>
            </Table>}
            <Pagination className='justify-content-center'>
                <Pagination.Item onClick={() => { setPage(filter.page - 1) }} disabled={filter.page === 1}><ArrowLeftShort /></Pagination.Item>
                <Pagination.Item onClick={() => { setPage(1) }} disabled={filter.page === 1}><ChevronDoubleLeft /></Pagination.Item>
                <Pagination.Item disabled>{filter.page}</Pagination.Item>
                <Pagination.Item onClick={() => { setPage(Math.floor(count / filter.limit) - 1) }} disabled={filter.page >= Math.floor(count / filter.limit) + 1}><ChevronDoubleRight /></Pagination.Item>
                <Pagination.Item onClick={() => { setPage(filter.page + 1) }} disabled={filter.page >= Math.floor(count / filter.limit) + 1}><ArrowRightShort /></Pagination.Item>
            </Pagination>
        </Container>
    </div>
}

