import React from "react"
import { withRouter } from "react-router-dom";
import ModelView from './ModelView';
import Customer from '../Classes/Customer'
import IcoAutocompleteModal from "../Components/IcoAutocompleteModal";
import MessageCenter from "../Components/MessageCenter";

class CustomerView extends ModelView {
    constructor(props) {
        super(Customer, props)
    }

    help = <div>
        <p>Zákazníci jsou příjemci vašich objednávek</p>
        <p>U zákazníků si nezapomeňte definovat kontakty! Uvedené kontakty se zobrazují na některých místech v aplikaci.</p>
        <p>U zákazníků snadno objevíte také poslední objednávky, které u Vás zákazník udělal.</p>
    </div>
    helpTopic = "customer"
    extra_actions = [
        <IcoAutocompleteModal klass={Customer}  className="ml-2" closeCallback={async result => {
            if (!result.data) {
                MessageCenter.addMessage({
                    "title": "Hledání v ARESu selhalo",
                    "text": "Bohužel selhalo hledání v ARESu, zkuste to znovu, nebo kontaktujte podporu, nezapomeňte přitom uvést i IČO hledaného subjektu"
                })
                return;
            }
            const customer = new Customer({id: 0, ...result.data});
            await customer.save();
            if (customer.id === 0) {
                MessageCenter.addMessage({
                    "title": "Ukládání dat selhalo",
                    "text": "Bohužel se nepodařilo uložit data, zkuste to znovu, nebo kontaktujte podporu, nezapomeňte přitom uvést i IČO hledaného subjektu"
                })
                return;
            }
            this.itemDetail(customer);
        }}/>
    ]
}

export default withRouter(CustomerView);