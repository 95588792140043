import Base from './Base'
import settings from '../Settings'

export default class RequirementCategory extends Base {
    static base_url = settings.BASE_API_URL + "requirementscategory/"

    static columns = [
        {
            name: "id",
            label: "#",
        },
        {
            name: "name",
            label: "Jméno"
        },
    ]

    label() {
        return this.name
    }
}