import React from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { Search } from 'react-bootstrap-icons'
import Order from '../Classes/Order'
import '../css/Omnibar.css'
import fetchProxy from '../Helpers/fetchProxy'
import settings from '../Settings'
import DatePicker from './DatePicker'
import { djangoFieldToJsType } from '../Helpers/translators'
import Account from '../Classes/Account'
import { RangeSelector } from './RangeSelector'

const AUTOCOMPLETE_URL = settings.BASE_API_URL + "order/autocomplete/"

class Omnibar extends React.Component {

    state = {
        "filter_options" : undefined,
        "filter_field": undefined,  //From which field do you want to filter from?
        "autocomplete": undefined,
        "input": "", //Omnibar text input
        "filters": window.location.href.indexOf("?") >= 0 ? [] : [
            {
              "field": {
                "type": "field",
                "key": "user",
                "label": "Uživatel"
              },
              "value": Account.currentUser().id,
              "label": Account.currentUser().label()
            }
          ], 
        "pin_search_menu": false,
        "pin_search_menu_override": false,
        /* "active_menuitem": 0, */
    }
    
    
    async componentDidMount() {
        window.location.href.endsWith("/") < 0 && this.props.searchTriggered && this.props.searchTriggered(this.state.filters)
        const res = await fetchProxy(AUTOCOMPLETE_URL, {
            method: "OPTIONS",
        })
        /* keys limit */
        const filter_options = await res.json()
        if (this.props.limitedKeys) {
            const _ = {}
            this.props.limitedKeys.forEach(key => {
                _[key] = ""
            })
            const searchKeys = Object.keys(_)
            filter_options.field.choices = filter_options.field.choices.filter(i => {return searchKeys.indexOf(i.key) >= 0})
        }
        this.setState({filter_options})


        // Map url params to fields
        const filters = []
        const filter_search = {}
        filter_options.field.choices.forEach((i, index) => { filter_search[i.key] = index })
        const urlparams = new URLSearchParams(window.location.search)
        
        for (let key of urlparams.keys()) {
            //Try to map it directly
            if (filter_search[key]) {
                const field = filter_options.field.choices[filter_search[key]]
                filters.push({field, value: urlparams.get(key)})
                continue
            }
            // Try to seperate the operator (which is the last fragment)
            const key_fragments = key.split("__")
            const key_without_operator = key_fragments.splice(0, key_fragments.length - 1).join("__")
            const filter_search_index = filter_search[key_without_operator]
            if (filter_search_index) {
                const field = filter_options.field.choices[filter_search_index]
                filters.push({field, value: urlparams.get(key), operator: "__" + key_fragments.pop()})
                continue
            }
            console.log("Unsupported Omnibar location.search parameter " + key)
        }

        if (filters.length) {
            this.setState({filters})
        }
        !window.location.href.endsWith("/") >= 0 && this.props.searchTriggered && this.props.searchTriggered(this.state.filters)
    }

    async selectFilterField(item) {
        await this.setState({filter_field: item, input: ""})
        await this.triggerAutocomplete()
    }

    activateFilter(value, label, operator) {
        const filters = this.state.filters
        filters.push({field: this.state.filter_field, value, label, operator})
        this.setState({filter_field: undefined, input: ''})
    }

    activateDateFilter(value) {
        const filters = this.state.filters
        Object.entries(value).forEach( item => {
            filters.push({
                field: {
                    key : this.state.filter_field.key,
                    label : this.state.filter_field.label
                },
                value: item[1],
                operator: item[0]
            })
        })
        this.setState({filter_field: undefined, pin_search_menu_override: false,  input: ''})
    }

    removeFilter(index) {
        const filters = this.state.filters
        filters.splice(index, 1)
        this.setState({filters})
    }

    async inputNavigationKey(event) {
        /* TODO KEYNAVIGATION
        if (event.keyCode === 13) {
            console.log("enter")
            return;
        } else if (event.keyCode === 38 || event.keyCode === 40) {
            const active_menuitem = this.state.active_menuitem + (event.keyCode === 38 ? -1 : +1)
            this.setState({active_menuitem})
        }
        */
    }

    renderFilterMenuFilters() {
        return this.state.filter_options.field.choices.filter(
            i => {return  i.label.toLocaleLowerCase().indexOf(this.state.input.toLocaleLowerCase()) >= 0}
        ).map((item, key) => {
            /* Find matched string from input and divide it to parts (before matched, matched, rest of the string)*/
            const index = item.label.toLocaleLowerCase().indexOf(this.state.input.toLocaleLowerCase())
            const length = this.state.input.length
            const label_parts = [item.label.slice(0, index), item.label.slice(index, index+length), item.label.slice(index+length, item.label.length)].map(i => {return i.replaceAll("__", " \u{1F812} ")})
            return <div key={item.key} onClick={() => this.selectFilterField(item)} className={`menuitem ${key === this.state.active_menuitem ? "active" : "" }`} >
                {label_parts[0] && label_parts[0]}
                {label_parts[1] && <span className="text-danger font-weight-bold">{label_parts[1]}</span>}
                {label_parts[2]}
            </div>
        })
    }

    renderFilterMenuItems() {
        if (this.state.autocomplete === undefined) {
            return <div className="menuitem">
                <Spinner animation="border" role="status" size="sm"></Spinner> ... Načítání výsledků
            </div>
        }
        if (this.state.filter_field.type.startsWith('date')) {
            return <DatePicker onClick={value => {this.activateDateFilter(value)}}
                onFocus={() => this.setState({pin_search_menu_override: true})}
                onBlur={() => this.setState({pin_search_menu_override: true})}/>
        }
        if (this.state.filter_field.type.startsWith("float")) {
            return <RangeSelector
                valuesSelected={res => this.activateFilter(res.value, undefined, res.operator || undefined)}
                toggleFocus={focus => this.setState({pin_search_menu_override: focus})}
            />
        }
        /* If no field is matched, display the message */
        if (this.state.autocomplete.matched_field === undefined) {
            let message;
            /* String message from autocomplete */
            if (typeof this.state.autocomplete.message === "string") {
                message = this.state.autocomplete.message
            }
            /* Object like message from autocomplete */
            else {
                message = this.state.autocomplete.message.field
                /* Find the first non-object-argument */
                while (typeof message === "object") {
                    message = Object.values(message).pop()
                }
            }
            return <div className="menuitem">
                {message}
            </div>
        }
        /* If it is an object instance */
        if (this.state.autocomplete.instance === true) {
            return <div className="menuitem" onClick={() => this.activateFilter(this.state.autocomplete.object.id, this.state.autocomplete.label)}>
                {this.state.autocomplete.label}
            </div>
        }
        /* If it is a value (can be assigned to more entries */
        const options = this.state.autocomplete.values.map(i => {
            const value = i[this.state.autocomplete.matched_field.name]
            return <div className="menuitem" key={value} onClick={() => this.activateFilter(value)}>
                {value} <span className="text-muted">{i['total']}x</span>
            </div>
        })
        /* if the value is a string, we can use the substring search */
        if (djangoFieldToJsType(this.state.autocomplete.matched_field.python_type) === "string" && this.state.input !== "") {
            const value = this.state.input
            options.unshift(<div className="menuitem" key={`__icontains_${value}`} onClick={() => this.activateFilter(value, undefined, "__icontains")}>
                {value} <span className="text-muted">(obsahuje)</span>
            </div>)
            options.unshift(<div className="menuitem" key={`__istartswith_${value}`} onClick={() => this.activateFilter(value, undefined, "__istartswith")}>
                {value} <span className="text-muted">(začíná na)</span>
            </div>)
        }
        return options
    }

    async triggerAutocomplete(value) {
        const autocomplete = await Order.autocomplete(this.state.filter_field.key, value)
        this.setState({autocomplete})
    }

    async inputChanged(value) {
        this.setState({input: value})
        if (!this.state.filter_field) {
            console.log("filter_field is not yet defined")
        } else {
            this.triggerAutocomplete(value)
        }
    }

    renderSearchMenu() {
        let menu_children = []
        if (this.state.filter_field === undefined) {
            menu_children = this.renderFilterMenuFilters()
        }
        else {
            menu_children = this.renderFilterMenuItems()
        }
        return <div className={"searchmenu" + (this.state.pin_search_menu || this.state.pin_search_menu_override ? " open" : "")}
                    onMouseEnter={e => this.setState({pin_search_menu: true})}
                    onMouseLeave={e => this.setState({pin_search_menu: false})}>
            {!Array.isArray(menu_children) || menu_children.length ? menu_children : <div className="menuitem text-danger">Nic nenalezeno</div>}
        </div>
    }

    renderOperator(operator) {
        if (operator === undefined) {
            return "="
        }
        if (operator.indexOf("__icontains") !== -1) {
            return "~"
        }

        if (operator.indexOf("__istartswith") !== -1) {
            return <em>začíná</em>
        }

        switch(operator) {
            case '__gte':
                return '>='
            case '__lte':
                return '<='
            default:
                return operator
        }
    }

    render() {
        if (this.state.filter_options !== undefined) {
            return <div className="omnibox-search">
                <div className="filters">
                    {this.state.filters.map( (i, index) => {
                        return <div className="item" onClick={() => {this.removeFilter(index)}} key={index}>
                            {(i.field.label || i.field.key).replaceAll("__", " \u21fe ") } {this.renderOperator(i.operator)} {i.label || i.value}
                        </div>
                    })}
                </div>
                <div className="search-box">
                    <div className="filter" onClick={() => this.setState({filter_field: undefined})}>{this.state.filter_field && this.state.filter_field.label.replaceAll("__", " \u21fe ") + " = "}</div>
                    <div className="filtered-input">
                        <input type="search" value={this.state.input}
                            onChange={e => this.inputChanged(e.target.value)}
                            onKeyUp={e => {[38, 40, 13].indexOf(e.keyCode) !== -1 && this.inputNavigationKey(e)}}/>
                        {this.renderSearchMenu()}
                    </div>
                </div>
                <Button className="search-btn" variant="success" onClick={() => {this.props.searchTriggered && this.props.searchTriggered(this.state.filters)}}>
                    <Search/>
                </Button>
            </div>
        }
        else {
            return <div><Spinner animation="border" role="status" size="sm"></Spinner> ... Načítání filtrů</div>
        }
    }
}

export default Omnibar