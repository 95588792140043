import { useEffect, useRef } from "react";
import { useState } from "react";
import { Alert, Container, Form, Jumbotron, Row, Col, Spinner, ListGroup, ListGroupItem, Table, Button, InputGroup } from "react-bootstrap";
import { Check, ExclamationTriangle, HouseFill, Person } from "react-bootstrap-icons";
import Account from "../Classes/Account";
import Location from "../Classes/Location";
import Order from "../Classes/Order";
import Queryset from "../Classes/Queryset";
import MessageCenter from "../Components/MessageCenter";
import { dateAddDays, dateGetDayName, dateRemoveTime, dateToInputValue, dateToLocal, sameDay } from "../helpers";
import fetchProxy from "../Helpers/fetchProxy";
import settings from "../Settings";
import LoadingBanner from "../Components/LoadingBanner"
import { Link } from "react-router-dom";

const DAYS_SPAN = 7

function dateToFilterObject(date) {
    date = new Date(date)
    return {
        time__year: date.getFullYear(),
        time__month: date.getMonth() + 1,
        time__day: date.getDate(),
    }
}

function calculateColor(order, the_day) {
    the_day = new Date(the_day)
    const max_span = dateAddDays(the_day, DAYS_SPAN)
    if (order.carrier || order.vehicle) {
        return null
    }
    // Count the precentage difference between "order_start from today" and "today from time_limit"
    const difference = (new Date(order.first_location_time) - the_day) / (max_span - the_day)
    if (difference < 0) {
        return "#FF0000"
    }
    return `rgb(255, ${difference * 255}, ${difference * 255})`
}

async function categorizeOrders(orders, homeCountry) {
    function getOrderCategory(order, homeCountry) {
        if (!order.origin_country || !order.destination_country) return "invalid"
        if (order.origin_country === homeCountry && order.destination_country === homeCountry) return "interior"
        if (order.origin_country === homeCountry) return "export"
        if (order.destination_country === homeCountry) return "import"
        if (order.origin_country !== homeCountry && order.destination_country !== homeCountry) return "exterior"
        MessageCenter.addMessage({
            "title": "Chyba při rozřazování objednávky",
            "text": `Objednávka #${order.id} (${order.code}) nebyla rozřazena. Jde o chybu. S číslem objednávky kontaktujte prosím podporu.`
        })
    }
    const result = {
        "import": [],
        "export": [],
        "interior": [],
        "exterior": [],
        "invalid": [],
    }
    if (!orders) {
        return result;
    }
    orders.forEach(order => {
        result[getOrderCategory(order, homeCountry)].unshift(order)
    })
    return result
}

async function categorizeLocations(locations) {
    const result = {
        "load": [],
        "unload": [],
    }
    if (!locations) {
        return result
    }
    locations.forEach(location => {
        result[location.type.toLowerCase()].unshift(location)
    })
    return result
}

function OrderTable(props) {
    const { order, the_day } = props
    return <table className="w-100">
        <tbody>
            <tr>
                <td className="text-center">{order.transportationLabel(<em>Chybí doprava</em>)}</td>
            </tr>
            <tr>
                <td className="text-center"><Link to={`/order/${order.id}`}>{order.code}</Link></td>
            </tr>
            <tr>
                <td className="text-center">{order.route_text}</td>
            </tr>
            <tr>
                <td className="text-center">
                    {/*sameDay(the_day, order.first_location_time)*/ true ?
                        dateToLocal(order.first_location_time) :
                        new Date(order.first_location_time).toLocaleDateString()}
                </td>
            </tr>
        </tbody>
    </table>
}


export function DailyLocationView(props) {
    const [locations, setLocations] = useState();
    const [orders, setOrders] = useState();
    const [homeCountry, setHomeCountry] = useState();
    const [justMyOrders, setJustMyOrders] = useState(true);
    const theDatePicker = useRef();
    const [theDay, setTheDay] = useState(new Date())
    const [dateSelectorVisible, setDateSelectorVisible] = useState(false)

    const current_user = Account.current()

    function toggleJustMyOrders(state) {
        setOrders()
        setLocations()
        setJustMyOrders(state)
    }

    function updateTheDay(value) {
        setTheDay(new Date(value))
        setDateSelectorVisible(false)
    }
    useEffect(() => {
        async function load() {
            const locationFilter = dateToFilterObject(theDay)
            locationFilter.order_by = ['start', 'end']
            locationFilter.order__state__ne = ["CANCELED", "TRASH"]
            const orderFilter = {
                "first_location_time__lt": dateToInputValue(dateRemoveTime(dateAddDays(theDay, DAYS_SPAN))),
                "last_location_time__gt": dateToInputValue(dateRemoveTime(theDay)),
                "order_by": ["-first_location_time", "-last_location_time"],
                "state__ne": ["CANCELED", "TRASH"]
            }
            if (justMyOrders) {
                locationFilter['order__user'] = current_user.id
                orderFilter['user'] = current_user.id
            }
            // Prepare the querysets
            const locationsQueryset = new Queryset(Location);
            const orderQueryset = new Queryset(Order);

            // Gather all the data
            const asyncLocations = locationsQueryset.filter(locationFilter)
            const fetchConfiguration = fetchProxy(settings.GLOBAL_CONFIGURATION_URL)
            await orderQueryset.filter(orderFilter)
            await asyncLocations
            const categorizedOrders = categorizeOrders(orderQueryset.objects, homeCountry)
            const configuration = await fetchConfiguration
            if (configuration.status !== 200) {
                MessageCenter.addMessage({
                    "title": "Selhalo načítání konfigurace",
                    "text": "Zkuste to znovu, pokud problém přetvrává, kontaktujte prosím podporu"
                })
            } else {
                const data = await configuration.json()
                setHomeCountry(data.settings.HOME_COUNTRY)
            }
            setLocations(await categorizeLocations(locationsQueryset.objects, homeCountry))
            setOrders(await categorizedOrders)
        }
        load()
    }, [theDay, justMyOrders, current_user.id, homeCountry])

    async function onLocationCheckChange(type, index, value) {
        const new_locations = { ...locations }
        const location = new_locations[type][index]
        location.checked = value
        await location.save()
        if (location.__status !== 200) {
            MessageCenter.addMessage({
                title: "Bohužel selhalo ukládání stavu objednávky!",
                message: `Bohužel se nepodařilo uložit stav zastávky #${location.id} u objednávky #${location.order}. Zkuste to znovu, nebo kontaktujte podporu.`
            })
        }
        setLocations(new_locations)
    }


    return <Container fluid>
        <Container>
            <Jumbotron className="py-3 mb-0">
                <h1>Denní přehled zastávek</h1>
            </Jumbotron>
            <div className="d-flex justify-content-between align-items-center p-1">
                <div>
                    <HouseFill /> {homeCountry || <Spinner animation="border" size="sm" />}
                </div>
                <div>
                    {!dateSelectorVisible && <span style={{ color: "#007bff", cursor: "pointer" }} onClick={
                        () => {
                            setDateSelectorVisible(true)
                            setTimeout(() => theDatePicker.current.showPicker(), 100)
                        }}
                    >
                        {dateGetDayName(theDay)} {theDay.toLocaleDateString()}
                    </span>}
                    <InputGroup style={dateSelectorVisible ? {} : { display: "none" }}>
                        <Form.Control value={dateToInputValue(theDay)} required ref={theDatePicker} onChange={e => {
                            updateTheDay(e.target.value || new Date())

                        }} type="date" />
                    </InputGroup>
                </div>
                {(current_user.is_superuser || current_user.hasPerm("all_orders")) ?
                    <Form.Check label="Pouze mé objednávky" checked={justMyOrders} onChange={e => toggleJustMyOrders(e.target.checked)} /> :
                    <span><Person />{current_user.label()}</span>}
            </div>
        </Container>
        <Container fluid>
            <pre>
            </pre>
            <Row>
                {!orders || !locations ? <>
                    <Col md="8">
                        <LoadingBanner label="Načítám objednávky ..." />
                    </Col>
                    <Col md="4">
                        <LoadingBanner label="... a zastávky" />
                    </Col>
                </> : <>
                    <Col md="8">
                        {orders.invalid.length > 0 &&
                            <Alert variant="danger">
                                Některým umístěním chybí země! Počet zastávek, které se nepodařilo rozřadit - {orders.invalid.length}.
                                Podívejte se, jestli mají v umístění uvedenou zemi původu a případně ji doplňte. Jde o tyto objednávky: {orders.invalid.map(o => <a href={`/order/${o.id}`}>{o.code}</a>)}
                            </Alert>}
                        <Row>
                            {[["interior", `Uvnitř ${homeCountry}`], ["import", "Import"], ["export", "Export"], ["exterior", "Zahraničí"]].map(category => {
                                const [key, label] = category
                                const categoryOrders = orders[key]
                                return <Col key={key}>
                                    <h3 className="text-center">{label}</h3>
                                    <ListGroup>
                                        {categoryOrders.length ?
                                            categoryOrders.map(order =>
                                                <ListGroupItem key={`${key}-${order.id}`} style={{ backgroundColor: calculateColor(order, theDay) }}>
                                                    <OrderTable order={order} the_day={theDay} />
                                                </ListGroupItem>
                                            ) : <ListGroupItem className="text-center">Bez objednávek</ListGroupItem>}
                                    </ListGroup>
                                </Col>
                            })}
                        </Row>
                    </Col>
                    <Col md="4">
                        <Row>
                            {[["load", "Nakládka"], ["unload", "Vykládka"]].map(category => {
                                const [key, label] = category
                                const categoryLocations = locations[key]
                                const remainingChecked = categoryLocations.filter(i => !i.checked).length
                                return <Col key={key}>
                                    <h3 className="text-center">{label}</h3>
                                    {remainingChecked > 0 && <Alert className="text-center" variant="warning">
                                        Zbývá zkontrolovat {remainingChecked} {remainingChecked === 0 || remainingChecked >= 5 ? "zastávek" : remainingChecked === 1 ? "zastávku" : "zastávky"}.
                                    </Alert>}
                                    <ListGroup>
                                        {!categoryLocations.length ?
                                            <ListGroupItem className="text-center">Dnes vůbec nic</ListGroupItem> :
                                            categoryLocations.map((location, index) => <ListGroupItem key={location.id}>
                                                <table className="w-100">
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center">
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="transportation-label">{Order.prototype.transportationLabel.call(location.order)}</div>
                                                                    <div className="checked"><Form.Check checked={location.checked} title="Zkontrolována" onChange={e => onLocationCheckChange(key, index, e.target.checked)} /></div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><Link to={`/order/${location.order.id}`}>{location.order.code}</Link></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">{location.order.route_text}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">
                                                                {dateToLocal(location.time)}
                                                            </td>
                                                        </tr>
                                                        {location.fixed_window && <tr><td>
                                                            <ExclamationTriangle title='Fixní časové okno zastávky' />
                                                            &nbsp;Fixní okno
                                                        </td></tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </ListGroupItem>)}
                                    </ListGroup>
                                </Col>
                            })}
                        </Row>
                    </Col>
                </>}
            </Row>
        </Container>
    </Container>
}