import { Button, ButtonGroup, Container, ToggleButton, Modal, Table, Row, Col, Form, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import { Building, CloudArrowDown, Download, Filter, Globe, Person, Receipt, Search, Trash, Truck } from 'react-bootstrap-icons';
import SearchModal from '../Components/SearchModal';
import Customer from '../Classes/Customer';
import Carrier from '../Classes/Carrier';
import Vehicle from '../Classes/Vehicle';
import User from '../Classes/User';
import React from 'react';
import LoadingBanner from '../Components/LoadingBanner';
import Order from '../Classes/Order';
import { Link } from 'react-router-dom';
import { endOfRange, dateAddDays, getWeekNumber, getStartOfMonth,
             getStartOfWeek, dateToInputValue, dateRemoveTime } from '../helpers';
import Account from '../Classes/Account';
import MessageCenter from '../Components/MessageCenter';

// Timeout between triggering actuall search
const LOADER_TIMEOUT = 300;

// Types of reports
// [name, label]
const reportTypes = [
  ["day", "Den"],
  ["week", "Týden"],
  ["month", "Měsíc"],
  ["year", "Rok"],
]

// Helper functions to create a label from filter type
const filterTypeToLabel = {
  "user": label => {return <><Person />{"\u00a0"}{label}</>},
  "carrier": label => {return <><Globe />{"\u00a0"}{label}</>},
  "customer": label => {return <><Building />{"\u00a0"}{label}</>},
  "vehicle": label => {return <><Truck />{"\u00a0"}{label}</>}
}


const reportTypesDefaultMapping = {
  "day": () => {return dateRemoveTime(new Date())},
  "week": () => {return getStartOfWeek(dateRemoveTime(new Date()))},
  "month": () => {return getStartOfMonth(dateRemoveTime(new Date()))},
  "year": () => {return new Date((new Date()).getFullYear(), 0, 1)}
}

// Component rendering filters
function AdditionalFilters(props) {
  const [show, setShow] = useState(false)
  const [filters, setFilters] = useState({})

  function addFilter(name, obj) {
    setFilters({
      ...filters,
      [name]: obj
    })
  }

  function removeFilter(name) {
    const newFilter = {...filters};
    delete newFilter[name];
    setFilters(newFilter);
  }

  function apply() {
    setShow(false);
    props.onChange && props.onChange(filters)
  }
  
  // Displays filters next to the filter button
  return <div className={props.className + " d-flex align-items-center"}>
    {show ? 
      "..." : 
      filters.length === 0 ?
        <em>No filters defined</em> :
        Object.entries(filters).map(entries => {
          const [name, data] = entries;
          const label = typeof(data.label) == "function" ? data.label() : data.label || data.name
          return <div className="ml-2" key={name}>{filterTypeToLabel[name](label)}</div>
        })
    }
    <Button className="ml-2" onClick={() => setShow(true)}><Filter /></Button>
    <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Filtrování</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col style={{textAlign: "center"}}>
              <Building style={{fontSize: "3em"}} />
              <div className='mt-2'>{filters.customer ? new Customer(filters.customer).label() : <em>Všichni zákazníci</em>}</div>
              <div className='mx-auto'>
                <ButtonGroup size='sm'>
                    <SearchModal label={<Search />} variant="outline-primary" klass={Customer} fields={['ID', 'name']} 
                                                    closeCallback={customer => addFilter("customer", customer) } />
                    <Button variant="danger" onClick={() => removeFilter("customer")}><Trash /></Button>
                </ButtonGroup>
              </div>
            </Col>
            <Col style={{textAlign: "center"}}>
              <Globe style={{fontSize: "3em"}} />
              <div className='mt-2'>{filters.carrier ? new Carrier(filters.carrier).label() : <em>Všichni dopravci</em>}</div>
              <div className='mx-auto'>
                <ButtonGroup size='sm'>
                    <SearchModal label={<Search />} variant="outline-primary" klass={Carrier} fields={['ID', 'name']} 
                                                    closeCallback={carrier => addFilter("carrier", carrier) } />
                    <Button variant="danger" onClick={() => removeFilter("carrier")}><Trash /></Button>
                </ButtonGroup>
              </div>
            </Col>
            <Col style={{textAlign: "center"}}>
              <Truck style={{fontSize: "3em"}} />
              <div className='mt-2'>{filters.vehicle ? new Vehicle(filters.vehicle).label() : <em>Všechna vozidla</em>}</div>
              <div className='mx-auto'>
                <ButtonGroup size='sm'>
                    <SearchModal label={<Search />} variant="outline-primary" klass={Vehicle} fields={['ID', 'name']} 
                                                    closeCallback={vehicle => addFilter("vehicle", vehicle) } />
                    <Button variant="danger" onClick={() => removeFilter("vehicle")}><Trash /></Button>
                </ButtonGroup>
              </div>
            </Col>
            {props.canFilterUsers && <Col style={{textAlign: "center"}}>
              <Person style={{fontSize: "3em"}} />
              <div className='mt-2'>{filters.user ? new User(filters.user).label() : <em>Všichni operátoři</em>}</div>
              <div className='mx-auto'>
                <ButtonGroup size='sm'>
                    <SearchModal label={<Search />} variant="outline-primary" klass={User} fields={['ID', 'username', 'first_name', 'last_name']} 
                                                        closeCallback={user => addFilter("user", user) } />
                    <Button variant="danger" onClick={() => removeFilter("user")}><Trash /></Button>
                </ButtonGroup>
              </div>
            </Col>}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={apply}>
            Aplikovat ({Object.keys(filters).length})
          </Button>
        </Modal.Footer>
      </Modal>
  </div>
}

// Renders the selector of range based on props.reportType
// Day - date-input
// Week - week selector
// Month - month selector
// Year - year selector
export function RangeRenderer(props) {  
  /* Do the React magic */
  const [startDate, setStartDate] = useState(reportTypesDefaultMapping[props.reportType]());

  function updateDate(date) {
    setStartDate(date)
    props.onChange && props.onChange(date);
  }

  const reportType = props.reportType
  const onChange = props.onChange

  useEffect(() => {
    const def_date = reportTypesDefaultMapping[reportType]()
    setStartDate(def_date)
    onChange && onChange(def_date);
  }, [reportType])

  /* Build the content */
  var content = ""
  switch (props.reportType) {
    case "day":
      content = <div className='ml-2'>
        <Form.Control type="date" value={dateToInputValue(startDate)} onChange={e => updateDate(new Date(e.target.value))}/>
      </div>
    break;
    case "week":
      content = <div className='d-flex align-items-center'>
        <Button variant='link' onClick={() => updateDate(getStartOfWeek(dateAddDays(startDate, -7)))}>{"\u00AB"}</Button>
        {startDate.getFullYear()} / týden {getWeekNumber(startDate)}{'\u00A0'}<span className='text-muted'>(začíná {startDate.toLocaleDateString()})</span>
        <Button variant='link' onClick={() => updateDate(getStartOfWeek(dateAddDays(startDate, 7)))}>{"\u00BB"}</Button>
      </div>
    break;
    case "month":
      content = <div className='d-flex align-items-center'>
      <Button variant='link' onClick={() => updateDate(new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1))}>{"\u00AB"}</Button>
        {startDate.getFullYear()}/{startDate.toLocaleString(undefined, { month: "long" })}
      <Button variant='link' onClick={() => updateDate(new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1))}>{"\u00BB"}</Button>
      </div>
    break;
    case "year":
      content = <div className='d-flex align-items-center'>
      <Button variant='link' onClick={() => updateDate(new Date(startDate.getFullYear() -1, 0, 1))}>{"\u00AB"}</Button>
        {startDate.getFullYear()}
      <Button variant='link' onClick={() => updateDate(new Date(startDate.getFullYear() +1, 0, 1))}>{"\u00BB"}</Button>
      </div>
    break;
    default:
      content = `Failed to detect the reportType for "${props.reportType}"`
  }
  /* render */
  return <div className={props.className}>
      {content}
    </div>
}
// Used by StatsRenderer to display data in the Table
// Displays data as links
const STATS_RENDERER_TABLE_DEFINITION = {
    "user": {
        "label": "Operátor",
        "render": user => {return <Link to={`user/${user.user}/`}>{(user.first_name || user.last_name) ? `${user.user__first_name} ${user.user__last_name}` : user.user__username}</Link>}
    },
    "carrier": {
        "label": "Dopravce",
        "render": carrier => {return <Link to={`user/${carrier.carrier}/`}>{carrier.carrier__name || `Dopravce: ${carrier.carrier}`}</Link>}
        
    },
    "customer": {
        "label": "Zákazník",
        "render": customer => {return <Link to={`user/${customer.customer}/`}>{customer.customer__name || `Zákazník: ${customer.customer}`}</Link>}
    },
    "vehicle": {
        "label": "Vozidlo",
        "render": vehicle => {return <Link to={`user/${vehicle.vehicle}/`}>{vehicle.vehicle__spz || vehicle.vehicle}</Link>}
    }
}

// Component which renderes loaded Order stats
// - Shows data
// - Enables toggling between view on counts/margin sums
// - Enables downloading PohodaXML or CSV
export function StatsRenderer(props) {
    // Load values
    const [spotlightMode, setSpotlightMode]  = useState(0);
    const spotlightHide = props.spotlightHide || []
    const stats = props.stats
    const currency = "CZK" //TODO FIXME
    const margins = stats.margins
    const spotlightFields = Object.keys(STATS_RENDERER_TABLE_DEFINITION)
    // Hide all fields which got asked
    spotlightHide.forEach(value => {delete spotlightFields[value]})
    // Check if stats have been propagated
    if (!stats) {
        return <Alert variant="danger">
            Nebyla předána žádna data. Zkuste to znovu, nebo kontaktujte podporu.
        </Alert>
    }
    if (!stats.orders.length) {
        return <Alert variant="primary">
            Pro zadané hledání nebyla nalezena žádná objednávka.
        </Alert>
    }

    function csvDownload() {
        Order.bulkCSVExport(stats.orders.map(i => i.id))
    }

    function pohodaExport() {
        Order.bulkPohodaXMLUrl(stats.orders.map(i => i.id))
    }

    function downloadOverview() {
      props.downloadOverview && props.downloadOverview()
    }

    // Which stats type we are interested in (absolute or count)
    const spotlightTableDataKey = spotlightMode === 0 ? "absolute" : "count"
    return <Row className="mt-2">
            <Col md="4">
              <h2>Statistiky</h2>
              <div className='px-2 py-3 fs-3 bg-light rounded'>
                Počet objednávek: {stats.orders.length}
              </div>
              <div className='px-2 py-3 fs-3 bg-light rounded mt-2'>
                Celková marže: {margins.sum.toFixed(2)} {currency}
              </div>
              <div className='px-2 py-3 bg-light rounded mt-2'>
                <p>Nejvyšší marže: {margins.max[0].toFixed(2)} {currency} (<Link to={`/order/${margins.max[1].id}/`}>{margins.max[1].code}</Link>)</p>
                <p>Nejnižší marže: {margins.min[0].toFixed(2)} {currency} (<Link to={`/order/${margins.min[1].id}/`}>{margins.min[1].code}</Link>)</p>
                <p>Průměrná marže: {margins.avg.toFixed(2)} {currency}</p>
              </div>
              <h3>Výdaje</h3>
              <p className='text-muted'>Všechny výdaje za časové období</p>
              <p><strong>Součet: {stats.expenses?.sum?.toFixed(2)} {stats.currency}</strong></p>
              <table>
                <tbody>
                  {
                    !stats.expenses.objects.length ?
                      <tr><td>V určeném období nebyly žádné výdaje</td></tr> :
                      stats.expenses.objects.map((expense, key) => {
                        return <tr key={key}>
                          <td>
                            <strong>{expense.name}</strong> - {expense.value} {expense.currency} <em>({expense.user})</em>
                          </td>
                        </tr>
                      })
                  }
                </tbody>
              </table>
            </Col>
            <Col md="4">
              <div className='px-2 py-3 bg-light rounded'>
                <h2>Spotlight</h2>
                <ButtonGroup toggle className="d-flex">
                    {["Podle celkové marže", "Podle počtu"].map((label, index) => {
                        return <ToggleButton 
                            value={index}
                            key={index}
                            type="checkbox"
                            checked={index === spotlightMode}
                            onChange={() => setSpotlightMode(index)}
                        >
                        {label}
                        </ToggleButton>
                    })}
                </ButtonGroup>
                {spotlightFields.map(key => {
                    // Take every object that we can think of
                    // Key is the name of the object/class (user, carrier, etc.)
                    const label = STATS_RENDERER_TABLE_DEFINITION[key].label
                    // Check if it has any items at all
                    if (!stats.spotlight[key][spotlightTableDataKey].length) {
                        return ""
                    }
                    return <Table key={key}>
                        <thead><tr>
                            <th>{filterTypeToLabel[key](label)}</th>
                            {/*If the mode is margin, we wan't to show the average*/}
                            <th>{spotlightMode === 0 && 
                                    `${stats.spotlight[key][spotlightTableDataKey][0].avg.toFixed(2)} ${currency}`}</th>
                        </tr></thead>
                        <tbody>
                            {
                                /* For every entrance in the data (for every user, carrier ...) */
                                stats.spotlight[key][spotlightTableDataKey].map((obj, index) => {
                                    // Use the defined render function in the helper dictionary
                                    // If margin should be displayed, display currency as well
                                    return <tr key={index}>
                                        <td>{STATS_RENDERER_TABLE_DEFINITION[key].render(obj)}</td>
                                        <td>{spotlightMode === 0 ? `${obj.value.toFixed(2)} ${currency}` : obj.value}</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table>
                })}
                <p className='text-muted'><small>Zde se objevují pouze záznamy, které měly v daném období alespoň jednu objednávku.</small></p>
              </div>
            </Col>
            <Col>
              <div className='px-2 py-3 bg-light rounded'>
                <h2>Operace</h2>
                <Button block={true} onClick={csvDownload} disabled={!stats.orders || !stats.orders.length}>Objednávky jako CSV <CloudArrowDown /></Button>
                <Button block={true} onClick={pohodaExport} disabled={!stats.orders || !stats.orders.length}>Exportovat do Pohody <Receipt /></Button>
                <Button variant="secondary" block={true} onClick={downloadOverview} disabled={!stats.orders || !stats.orders.length}>Stáhnout celý přehled <Download /></Button>
              </div>
            </Col>
          </Row>
}

// Main Reporting view
export function ReportingView() {
  // What report interval you want to see
  const [reportType, setReportType] = useState("day");
  // When does the report interval start
  const [startingDate, setStartingDate] = useState(undefined);
  // What additional filters are defined
  const [filters, setFilters] = useState({});
  // Is Loading in progress? (used for delaying the search when user is changing the range)
  const [loader, setLoader] = useState();
  // Data & error storage
  const [data, setData] = useState();
  const [error, setError] = useState();
  // Check user permissions for all orders
  const current_user = Account.currentUser()
  const see_others_orders = current_user.is_superuser || current_user.hasPerm("all_orders")
  // And display warning if necessary
  useEffect(() => {
    !see_others_orders && MessageCenter.addMessage({
        "title": "Omezený přehled",
        "text": "Pro zobrazení objednávek ostatních uživatelů budete potřebovat vyšší oprávnění."
    })
  }, [see_others_orders])

  async function loadReport() {
    if (!startingDate) {
        setError("Není definováno žádné rozmezí pro vygenerování reportu. Zkuste nějaké vybrat.")
        return
    }   
    const values = await Order.loadReport(
        dateToInputValue(startingDate),
        dateToInputValue(endOfRange[reportType](startingDate)),
        // Filter objects with null and filter only ids from the filter objects what is used.
        Object.fromEntries(Object.entries(filters).filter(i => i[1] != null).map(i => {return [i[0], i[1].id]}))
    )
    if (values.http_status !== 200) {
        setError(`Server vrátil neočekávaně kód ${values.http_status}. Zkuste to znovu, případně kontaktujte podporu. `)
    }
    else {
        setData(values)
    }
  }

  // Everytime filter, reportType or startingDate changes, reload the data
  useEffect(() => {
    if (loader) {
        clearTimeout(loader)
    }
    setLoader(setTimeout(async () => {
        await loadReport();
        setLoader(null)
    }, LOADER_TIMEOUT))
  }, [filters, reportType, startingDate])

  async function downloadOverview() {
    const blob = await Order.loadReport(
      dateToInputValue(startingDate),
      dateToInputValue(endOfRange[reportType](startingDate)),
      // Filter objects with null and filter only ids from the filter objects what is used.
      Object.fromEntries(Object.entries(filters).filter(i => i[1] != null).map(i => {return [i[0], i[1].id]})),
      true
    )
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = url;
    a.download = "LogisticIQ_export.ods";
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();    
    a.remove();  //afterwards we remove the element again    
  }

  return <Container fluid>
      <Container>
        <div className="d-flex align-items-center justify-content-between mt-1">
          <ButtonGroup toggle>
            {reportTypes.map(typeSet => {
              //Render Toggles for range type selectors
              const [name, label] = typeSet;
              return <ToggleButton
                id={`toggle-${name}`}
                type="checkbox"
                key={name}
                checked={reportType === name}
                value={name}
                onChange={(e) => {
                  setReportType(e.currentTarget.value)
                }}
              >
                {label}
              </ToggleButton>
            })}
          </ButtonGroup>
          <RangeRenderer
            reportType={reportType}
            className="ps-3"
            onChange={date => setStartingDate(date)} />
          <AdditionalFilters onChange={filters => setFilters(filters)} className="" canFilterUsers={see_others_orders} />
        </div>
      </Container>
      <Container fluid style={{maxWidth: "1400px"}}>
        {loader ? <LoadingBanner /> : 
          error ? <Alert variant='danger'>{error}</Alert> : 
              data && <StatsRenderer
                isUserLimited={see_others_orders}
                stats={data.data}
                spotlightHide={Object.keys(filters)}
                downloadOverview={downloadOverview}
              />
        }
      </Container>
    </Container> 
}