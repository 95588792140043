import Base from './Base'
import settings from '../Settings'

class Contact extends Base{
    static base_url = settings.BASE_API_URL + "contact/"

    static columns = [{
        name: "id",
        label: "#",
    },
    {
        name: "name",
        label: "Jméno"
    },
    {
        name: "email",
        label: "E-mail"
    },
    {
        name: "phone",
        label: "Telefon"
    }]
}

export default Contact