import Base from './Base'
import settings from '../Settings'

class Requirement extends Base {
    static base_url = settings.BASE_API_URL + "requirements/"

    static columns = [
        {
            name: "id",
            label: "#",
        },
        {
            name: "name",
            label: "Jméno"
        },
        {
            name: "categoryname",
            label: "Kategorie"
        }
    ]

    label() {
        return this.name
    }
}

export default Requirement