import Base from './Base'
import settings from '../Settings'

class Place extends Base{
    static base_url = settings.BASE_API_URL + "place/"

    save() {
        this.phone = this.phone !== "" ? this.phone : undefined
        this.house_number = this.house_number !== "" ? this.house_number : undefined
        return super.save()
    }

    address() {
        return ["name", "country", "town", "postal_code", "street", "house_number"].map(element => {
            return this[element]
        }).filter(item => {
            return !!item
        }).join(" ") || "Neurčené místo"
    }

    label() {
        return this.address()
    }
}
export default Place
