import React from 'react'
import {Modal, Button, Form} from 'react-bootstrap';
import MessageCenter from './MessageCenter';

class ModalNewContact extends React.Component {
    constructor(props) {
        super(props)
       
        if (props.closeCallback !== undefined) {
            this.closeCallback = props.closeCallback
        }
        this.state = {
            show: false,
            name: '',
            email: '',
            phone: '',
            is_informacni: false,
            id: 0
        }
    }

    onChange = (event) => {
        const newState = {}
        newState[event.target.id] = event.target.value
        this.setState(newState)
    }

    setShow = (show) => {
        this.setState({
            show,
            ...(this.props.defaults || {})
        })
    }

    closeCallback = () => {
        // Default callback can be implemented here. 
    }

    handleShow = () => this.setShow(true)
    handleClose = () => {
        this.setShow(false)
        this.setState({name: "", email: "", phone: "", id: 0})
    }

    load = (contact) => {
        this.setState({
            ...contact,
            show: true
        })
    }

    saveChanges = () => {
        if (!this.state.name) {
            MessageCenter.addMessage({title:"Jméno kontaktu je povinné pole!"})
            return;
        }
        if (this.closeCallback !== null) {
            this.closeCallback({
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                id: this.state.id,
                is_informacni: this.state.is_informacni
            })
        }
        this.handleClose()
    }
  
    render() {
        return (
        <>
            <Button variant={this.props.buttonVariant || "success"}
                size={this.props.buttonSize || "md"}
                title={this.props.buttonTitle || "Vytvořit nový kontakt"}
                onClick={this.handleShow}>
            {this.props.children || "Nový kontakt"}</Button>
            <Modal show={this.state.show} onHide={this.handleClose}>    
            <Modal.Header closeButton>
                <Modal.Title>Nový kontakt</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form>
                <Form.Group controlId="name">
                        <Form.Label>Jméno</Form.Label>
                        <Form.Control type="text" placeholder="Jméno" value={this.state.name} onChange={(event) => {this.onChange(event)}}/>
                </Form.Group>
                <Form.Group controlId="email">
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control type="email" placeholder="E-mail" value={this.state.email} onChange={(event) => {this.onChange(event)}}/>
                </Form.Group>
                <Form.Group controlId="phone">
                        <Form.Label>Telefonní číslo</Form.Label>
                        <Form.Control type="text" placeholder="Telefonní číslo" value={this.state.phone} onChange={(event) => {this.onChange(event)}}/>
                </Form.Group>
                <Form.Group controlId="contact_email">
                        <Form.Check type="checkbox" label="Komunikační kontakt" checked={this.state.is_informacni} onChange={() => this.setState({"is_informacni": !this.state.is_informacni})}/>
                        <Form.Text><small>Tento kontakt se může objevovat na některých dokumentech (třeba faktury, dodací listy, apod.). Jde o primární komunikační prostředek.</small></Form.Text>
                </Form.Group>
            </Form>                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                    Zavřít
                </Button>
                <Button variant="primary" onClick={this.saveChanges}>
                    Uložit změny
                </Button>
            </Modal.Footer>
            </Modal>
        </>)
    }
  }
  
  export default ModalNewContact;