import { Dropdown } from 'react-bootstrap'
import React from 'react'

class ContactList extends React.Component {

    state = {
        contacts: this.props.contacts
    }

    async componentDidMount() {
        if(!this.props.contacts) {
            const contacts = await this.props.loadContacts()
            this.setState({contacts})
        }
    }

    itemSelected(item) {
        this.props.itemSelected && this.props.itemSelected(item)
    }

    render() {
        return <Dropdown className={this.props.className}>
            <Dropdown.Toggle disabled={this.props.disabled || !(this.state.contacts && this.state.contacts.length)}>{this.props.children}</Dropdown.Toggle>
            <Dropdown.Menu>
            {this.state.contacts ? this.state.contacts.map((item, index) => {
                return <Dropdown.Item key={index} onClick={() => {this.itemSelected(item[1])}}>{item[0] }{`<${item[1]}>`}</Dropdown.Item>
            }) :
            <Dropdown.Item>Žádný záznam</Dropdown.Item>}
            </Dropdown.Menu>
        </Dropdown>
    }
}

export default ContactList