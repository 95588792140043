import React from 'react'
import Location from '../Classes/Location'
import { ListGroup } from 'react-bootstrap'
import { Map, Building, CalendarRange } from 'react-bootstrap-icons'
import Queryset from '../Classes/Queryset'
import Order from '../Classes/Order'
import LoadingBanner from './LoadingBanner'
import moment from 'moment'


class OrderFilterWidget extends (React.Component) {
    state = {
        queryset: new Queryset(Order)
    }

    async componentDidMount() {
        const filter = this.props.filter || {}
        filter.limit = 10
        await this.state.queryset.filter(filter)
        this.forceUpdate()
    }

    

    render() {
        if (!this.state.queryset.count === -1) {
            return <LoadingBanner />
        }
        return <div>
            <ListGroup>
                {this.state.queryset.objects.map(item => {
                    return <ListGroup.Item key={item.id}>
                            <a href={`/order/${item.id}`}><strong>{item.code}</strong></a> 
                            {item.customer && <a href={`/customer/${item.customer.id}`}> <br/><Building />{'\u00A0'}{item.customer.name}</a>}
                            <br /><CalendarRange /> {
                                item.locations.length ? 
                                    moment(item.locations[0].time).isSame(moment(item.locations[item.locations.length -1].time, "day")) ?
                                        moment(item.locations[0].time).format('DD. MM. YYYY') : 
                                        <>
                                            {moment(item.locations[0].time).format('DD. MM. YYYY')}
                                            {" - "}
                                            {moment(item.locations[item.locations.length -1].time).format('DD. MM. YYYY')}
                                        </>
                                    : 
                                    <em>Zatím bez plánu</em>
                             }
                            <> <br/><Map />{'\u00A0'}{item.locations.map(i => {return new Location(i).prettyName()}).join(" \u{1f852} ") || <em>Bez zastávek</em>}</>
                    </ListGroup.Item>
                })}
            </ListGroup>
        </div>
    }
}

export { OrderFilterWidget }