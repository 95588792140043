import ReactDOM from 'react-dom'
import React from 'react'
import LogisticNavbar from './Components/LogisticNavbar'
import { BrowserRouter as Router } from 'react-router-dom'
import generateMenu from './menu'
import App from './app'
import 'bootstrap/dist/css/bootstrap.min.css';
import MessageCenter from './Components/MessageCenter'
import { usePersistStorage } from './Context'
import { useMemo } from 'react'
// ======================================== 
const message_center = <MessageCenter />

function LogisticRouter() {
  const [config, _] = usePersistStorage("config")
  const menu = useMemo(() => generateMenu(config), [config])
   

  return <Router>
    <LogisticNavbar menu={menu} />
    <div className="position-relative">
      {message_center}
      <App />
    </div>
  </Router>
}


ReactDOM.render(<LogisticRouter />, document.getElementById('app'));
