import React from 'react'
import { Modal, Button, Form } from 'react-bootstrap';
import Queryset from '../Classes/Queryset';
import ModelSelector from '../Components/ModelSelector'
import { NewObjectForm } from './NewObjectForm';
import { Plus, Search } from 'react-bootstrap-icons';
import MessageCenter from './MessageCenter';

class SearchModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            queryset: new Queryset(props.klass),
            createNewObjectState: {},
        }
        this.selector = React.createRef()
    }

    setShow = (show) => {
        this.setState({ show })
    }

    async handleShow() {
        this.setShow(true)
    }
    handleClose = () => {
        this.setShow(false)
    }

    cancelCreating = () => {
        this.setState({
            createNew: false
        })
    }

    createNew = async () => {
        const req_obj = new this.props.klass({
            ...this.state.createNewObjectState,
            ...this.props.createNewFixedValues,
            id: 0
        });
        const obj = await req_obj.save()
        if (req_obj.__status !== 201) {
            MessageCenter.addMessage({
                title: "Nepodařilo se vytvořit nový záznam",
                text: `Více informací o chybě: ${Object.entries(req_obj.__messages).map(i => i[0] + ": " + i[1]).join(", ")}`,
                detail: req_obj
            })
            return;
        } else {
            this.itemSelected(obj);
        }
    }

    itemSelected = (item) => {
        this.props.closeCallback && this.props.closeCallback(item)
        this.setState({createNew: false, createNewObjectState: {}})
        this.handleClose()
    }

    onPageChange = () => {/* None */}

    startCreatingNew = () => {
        this.setState({
            "createNew": true
        })
    }

    render() {
        return <>
            <Button size={this.props.size || ''} variant={this.props.variant || "success"} onClick={() => {this.handleShow()}}>{this.props.label || "Vybrat"}</Button>
            <Modal show={this.state.show} onHide={this.handleClose} size="xl" style={{zIndex: 2000}}>
            <Modal.Header>
                <Modal.Title>
                    {this.state.createNew
                        ? this.props.createNewTitle || "Vytvořit nový záznam" 
                        : this.props.title || "Vyber požadovaný záznam"}
                </Modal.Title>
                <Form inline className="ml-auto">
                {!this.state.createNew && <input type="text" className="form-control" placeholder="Hledat" onChange={(event) => {this.selector.current && this.selector.current.searchChanged(event.target.value)}} />}
                <button type="button" className="close" onClick={() => {this.setShow(false)}}>
                    &times;
                </button>
                </Form>
            </Modal.Header>
            <Modal.Body>
                {!this.state.createNew ? <ModelSelector ref={this.selector}
                    klass={this.props.klass}
                    filter={this.props.filter}
                    show_controls={false}
                    select={1}
                    emptySelector={this.props.emptySelector}
                    show_search={false}
                    itemSelected={item => {this.itemSelected(item)}}
                    use_pagination={true}
                    pkSearch={!!this.props.pkSearch}
                    /> : <NewObjectForm
                        fields={Array.isArray(this.props.createNew) ? this.props.createNew : []}
                        klass={this.props.klass}
                        objectValues={this.state.createNewObjectState}
                        onStateChange={state => this.setState({
                            createNewObjectState: state
                        })} />}
            </Modal.Body>
            <Modal.Footer>
                <div className='d-flex justify-content-between align-items-center w-100'>
                    {!this.state.createNew && <Button variant="success" onClick={() => {this.startCreatingNew()}}>Vytvořit nový</Button>}
                    {this.state.createNew && <Button
                                                variant="success"
                                                disabled={Object.values(this.state.createNewObjectState).filter(i => i).length === 0}
                                                onClick={() => {this.createNew()}}>
                                                    <Plus /> Vytvořit
                                            </Button>}
                    <div>
                        {this.state.createNew && <Button variant="outline-secondary" className="mr-2" onClick={ () => this.cancelCreating() }>
                            <Search /> Zpět na hledání
                        </Button>}
                        <Button variant="secondary" onClick={() => {this.handleClose() }}>
                            Zavřít
                        </Button>
                    </div>
                </div>
            </Modal.Footer>
            </Modal>
        </>
    }
}

export default SearchModal;