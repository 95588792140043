import React from 'react'
import { Modal, Button, } from 'react-bootstrap';
import DefaultRenderer from './LogEntryRenderer/DefaultRenderer'
import OrderEmailRenderer from './LogEntryRenderer/OrderEmailRenderer'

class LogEntryViewerModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            "show" : false
        }
        this.renderes = {
            "default" : DefaultRenderer
        }
    }

    renderLogEntry(log_entry) {
        if (log_entry.view === "OrderList" && log_entry.action === "send_email" && log_entry.method === "POST") {
            return OrderEmailRenderer(log_entry)
        }
        return DefaultRenderer(log_entry)
    }

    setShow = (show) => {
        this.setState({show})
    }

    submitCallback = () => {
        // close call back should be implemented here
    }

    handleShow = () => this.setShow(true)
    handleClose = () => {
        this.setShow(false)
    }

    
    render() {
        return (
        <>
            <Modal show={this.state.show} onHide={this.handleClose} size="xl">    
            <Modal.Header closeButton>
                <Modal.Title>Detail záznamu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {
                !this.state.log_entry ? "No log passed into the modal" : this.renderLogEntry(this.state.log_entry)
            }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Zavřít
                </Button>
            </Modal.Footer>
            </Modal>
        </>)
    }
  }
  
  export default LogEntryViewerModal;