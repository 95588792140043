import MessageCenter from "../Components/MessageCenter";
import fetchProxy from "../Helpers/fetchProxy";
import {getOptions} from "./Base"

class Queryset {
    constructor(klass, props) {
        if (props === undefined) {
            props = this.defaultProps();
        }
        this.klass = klass
        // Count of local entries. -1 not loaded at all
        this.count = -1 
        this.objects = []
        if(this.klass === undefined) {
            throw new Error("Class undefined for the query set")
        }
    }

    defaultProps() {
        let props = {}
        props.base_url = undefined // BASE URL
        return props
    }

    async all(filter = {}) {
        try {
            let url_params = ''
            if (Object.keys(filter).length !== 0) {
                url_params = "?" + Object.entries(filter).map(kv => kv.map(encodeURIComponent).join("=")).join("&");
            }
            const res = await fetchProxy(this.klass.base_url + url_params);
            this.populate(await res.json())
            this.status = res.status
          } catch (e) {
            MessageCenter.addMessage({
                detail: 'Chyba! \n' + JSON.stringify(e)
            })
          }
    }

    async search(str, limit) {
        return this.filter({
            search: str || "",
            limit: limit || 10
        })
    }

    async searchByFields(fields, value) {
        const data = {
            fields: Array.isArray(fields) ? fields : [fields], 
            value
        }
        const url = this.klass.base_url + "search_by_fields/"
        try {
            const res = await fetchProxy(url, {
                method: "POST",
                body: JSON.stringify(data)
            })
            this.status = res.status
            this.populate(await res.json())    
        } catch (e) {
            console.error(e)
        }
        
    }

    populate(data) {
        this.objects = []
        data.forEach((item) => {
            item.qindex = this.objects.length
            this.objects.push(new this.klass(item))
        })
        this.count = data.length
    }

    async filter(filters = {}) {
        // Change items to URI components
        const url_params = Object.entries(filters).map(kv => {
            const [key, value] = kv
            // If it's not an array return it
            if (!Array.isArray(value)) {
                return [key, value].map(encodeURIComponent).join("=")
            }
            else {
                // Values is an array
                // 1. map, create [key, value], [key, value2], [key, value3]
                return value.map(i=> [key, i]).
                    // 2. map translate every [key, value?] to urisafe key=value (3. map is just for saving )
                    map(i => i.map(encodeURIComponent).join("=")).join("&")
                // We join this with &, but it will be joined on the top level to other params as well
            }
        }).join("&");
        try {
            const res = await fetchProxy(this.klass.base_url + "?" + url_params);
            this.populate(await res.json())
            this.status = res.status
        } catch (e) {
            console.log(e)
        }
    }

    async loadOptions() {
        if(!this.options) {
            this.options = await getOptions(this.klass)
        }
        return this.options
    }

    push(object) {
        const pk = this.klass.pk || 'id'
        if (!object[pk]) {
            MessageCenter.addMessage({
                detail: '"Missing or invalid pk, cant push. Object was: "' + JSON.stringify(object)
            })
            return
        }
        if (object.constructor.name !== this.klass.name) {
            if (object.constructor.name.toLowerCase() === "object") {
                object = new this.klass(object)
            } 
            else {
                MessageCenter.addMessage({
                    detail: `Got type ${object.constructor.name}, expected ${this.klass.name}`
                })
            }
        }
        object.qindex = this.objects.length
        this.objects.push(object)
    }
    remove(object) {
        const index = object.qindex
        if (index === undefined) {
            console.warn("Could not find the index value of this object, can't remove", object)
            return false
        }
        delete this.objects[index]
        return true
    }

    findOne(key, value) {
        for (let i=0; i<this.objects.length; ++i) {
            if (this.objects[i][key] === value) {
                return this.objects[i]
            }
        }
    }

    async one(id) {
        const req = fetchProxy(this.klass.base_url + id + "/", {
            headers: {
                Authorization: 'Token ' + sessionStorage.getItem('key') || ''
            }
        });
        try {
            const res = await req
            if (res.status != 200) {
                throw Error(`Unexpected HTTP status ${res.status}`)
            }
            this.object = new this.klass(await res.json())
            this.status = res.status
        } catch (e) {
            return {
                error: e.message
            }
        }
    }
}

export default Queryset

