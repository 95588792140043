import { Button, Form, Row, Col, Table } from 'react-bootstrap'
import React from 'react'
import Place from '../Classes/Place'
import Queryset from '../Classes/Queryset'
import { ArrowReturnLeft, Building, Plus, Trash } from 'react-bootstrap-icons'
import highlightParts from '../Helpers/highlightParts'
import { getOptions } from '../Classes/Base'
import LoadingBanner from './LoadingBanner'
import FormField from './FormField'
import MessageCenter from '../Components/MessageCenter'
import { withRouter } from 'react-router'

const NEW_PLACE = {
    id: 0,
    name: "",
    street: "",
    house_number: "",
    town: "",
    country: "CZ",
}

class PlaceSelector extends React.Component {

    state = {
        queryset: new Queryset(Place),
        options: undefined,
        active_index: -1,
        place_search_input: "",
        place : undefined,
        new_place: undefined
    }

    async search(value) {
        await this.state.queryset.search(value)
        this.setState({active_index: -1, "place_search_input": value || ""})
    }

    inputKeyUp(keyCode) {
        let active_index = this.state.active_index
        switch (keyCode) {
            case 40:
                active_index+= 1
                this.setState({active_index: active_index >= 10 ? 0 : active_index})

                break;
            case 38:
                active_index-= 1
                this.setState({active_index: active_index < 0 ? 9 : active_index})
                break;
            case 13:
                this.placeSelected()
                break;
            default:
                break;
        }
    }

    

    async newPlacePre() {
        const options = getOptions(Place)
        this.setState({
            new_place: {...NEW_PLACE}
        })
        this.setState({options: await options})
        this.props.blockingStateChanged && this.props.blockingStateChanged(true)
    }

    async cancelNewPlace() {
        this.setState({
            new_place: null
        })
    }

    newPlaceChange(value, name) {
        const new_place = this.state.new_place
        new_place[name] = value
        this.setState({new_place})
    }

    async addNewPlace() {
        const place = new Place(this.state.new_place)
        await place.save()

        if (place.__messages && Object.keys(place.__messages).length) {
            MessageCenter.addMessage({
                "title" : "Chyba při ukládaní nové adresy",
                "text" : Object.values(place).map(i => {return JSON.stringify(i)}).join(", ")
            })
        }
        if (place.__status >= 200 && place.__status <= 300) {
            this.props.blockingStateChanged && this.props.blockingStateChanged(false)
            this.props.placeSelected && this.props.placeSelected(place)
        }
        this.setState({new_place: undefined})
    }

    placeSelected() {
        if (this.state.active_index < 0 || this.state.active_index > 9) {
            return;
        }
        this.props.blockingStateChanged && this.props.blockingStateChanged(false)
        this.props.placeSelected && this.props.placeSelected(this.state.queryset.objects[this.state.active_index])
    }

    render() {
        if (this.props.place) {
            const rows = []
            if (this.props.place.street) rows.push(<tr key="1"><td>{this.props.place.street} {this.props.place.house_number}</td></tr>)
            if (this.props.place.town || this.props.place.postal_code) rows.push(<tr key="2"><td>{this.props.place.town} {this.props.place.postal_code}</td></tr>)
            if (this.props.place.country) rows.push(<tr key="3"><td>{this.props.place.country}</td></tr>)
            return <div className="d-flex">
                <table style={{flexGrow: 1}}>
                    <tbody>
                        <tr>
                            <td>
                                <Building />{" "}
                                <Button variant="link"
                                    style={{ padding: "0" }}
                                    onClick={() => {window.confirm("Chcete přejít na editaci tohoto umístění, přijdete o všechny neuložené změny?") && this.props.history.push("/places/" + this.props.place.id)}}>
                                    {this.props.place.name}
                                </Button>
                            </td>
                        </tr>
                        {rows}
                    </tbody>
                </table>
                <div className="d-flex flex-column">
                    <Button className="float-right" size="sm" variant="danger"
                        onClick={() => {window.confirm("Opravdu chcete znovu nastavit umístění?") && this.props.placeSelected && this.props.placeSelected(null)}}><Trash /></Button>
                </div>
            </div>
        }
        else if(!this.state.new_place) 
            return <div className="d-flex">
                        <div className="flex-grow-1 d-relative" onMouseOut={() => this.setState({active_index: -1})}>
                            <Form.Control placeholder="Hledat místo" onChange={e => this.search(e.target.value)} onKeyUp={e => {this.inputKeyUp(e.keyCode)}}/>
                            {(this.state.place_search_input.length >= 2) && <div style={{position: "absolute", zIndex: 1, background: '#FFFFFF'}}>
                                <Table className="border border-rounded" style={{width: "496px", maxWidth: "100%"}}>
                                    <thead>
                                        <tr><th>Jméno</th><th>Adresa</th></tr>
                                    </thead>
                                    <tbody>
                                        {this.state.queryset.objects && this.state.queryset.objects.map((item, key) => {
                                            const address_hg = highlightParts(item.address(), this.state.place_search_input)
                                            const name_hg = highlightParts(item.name, this.state.place_search_input)
                                            return <tr style={{cursor: "pointer"}} onClick={() => this.placeSelected()} onMouseOver={() => this.setState({active_index: key})} className={this.state.active_index === key ? "table-active" : ""} key={key}>
                                                <td>{name_hg[0]}<strong className="text-danger">{name_hg[1]}</strong>{name_hg[2]}</td>
                                                <td>{address_hg[0]}<strong className="text-danger">{address_hg[1]}</strong>{address_hg[2]}</td>
                                            </tr>
                                        })}
                                        {!this.state.queryset.objects && <tr><td>Nebyly nalezeny žádné záznamy</td></tr>}
                                    </tbody>
                                </Table>
                            </div>}
                        </div>
                        <Button className="ml-1" variant="success" onClick={() => this.newPlacePre()}>Vytvořit novou</Button>
                    <div>
                </div>
            </div>
        else return <div>
            {!this.state.options ? <LoadingBanner /> : <div className="d-flex">
                <div>
                    <FormField id="name" label="Pojmenování místa" placeholder="Jméno místa nebo firmy"
                        value={this.state.new_place.name} onChange={e => {this.newPlaceChange(e.target.value, "name")}} />
                    <div className="d-flex">
                        <FormField id="street" label="Ulice" placeholder="Ulice" className="flex-grow-1 w-auto"
                        value={this.state.new_place.street} onChange={e => {this.newPlaceChange(e.target.value, "street")}} />
                        <FormField id="house_number" className="ml-1 w-25 flex-shrink-0" label="č.p." placeholder="č.p."
                        value={this.state.new_place.house_number} onChange={e => {this.newPlaceChange(e.target.value, "house_number")}} />
                    </div>
                    <FormField id="town" label="Město" placeholder="Město"
                        value={this.state.new_place.town} onChange={e => {this.newPlaceChange(e.target.value, "town")}} />
                    <FormField id="postal_code" label="PSČ" placeholder="PSČ"
                        value={this.state.new_place.postal_code} onChange={e => {this.newPlaceChange(e.target.value, "postal_code")}} />
                    <FormField id="type" label="Země" as="select" custom
                        value={this.state.new_place.country || "CZ"} onChange={(e) => this.newPlaceChange(e.target.value, "country")}>
                            {this.state.options && this.state.options.actions.POST.country.choices.map((item, key) => {
                            return <option key={key} value={item.value}>
                                {item.display_name}
                            </option>})}
                    </FormField>
                </div>
                <div className='ml-1 d-flex flex-column'>
                    <Button className="mb-1 flex-grow-1" onClick={() => this.addNewPlace()} variant="success"><Plus /></Button>
                    <Button className='mb-1' onClick={() => this.cancelNewPlace()}><ArrowReturnLeft /></Button>
                </div>
            </div>}
        </div>
    }
}

export default withRouter(PlaceSelector)