import { useEffect, useState } from "react";
import { Alert, Button, Container, Form, Jumbotron } from "react-bootstrap";
import { Save2 } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import { getOptions } from "../Classes/Base";
import Queryset from "../Classes/Queryset";
import Requirement from "../Classes/Requirement";
import LoadingBanner from "../Components/LoadingBanner";
import MessageCenter from "../Components/MessageCenter";
import { ModelForm } from "../Components/ModelForm";

export function RequirementsDetail(props) {
    const [requirement, setRequirement] = useState();
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [requirementOptions, setRequirementOptions] = useState();
    const requirementId = useParams().id;

    useEffect(() => {
        async function load() {
            const queryset = new Queryset(Requirement);
            const loadRequirement = queryset.one(requirementId);
            getOptions(Requirement).then(options => setRequirementOptions(options))
            await Promise.all([loadRequirement, ])
            if (queryset.status !== 200) {
                MessageCenter.addMessage({
                    "title": "Problém při načítání",
                    "message": "Nepodařilo se načíst požadavek, zkuste to znovu, obnovte stránku, či případně kontaktujte podporu"
                })
            }
            else {
                setRequirement(queryset.object)
            }
        }
        load()
    }, [requirementId])

    function updateRequirement(name, value) {
        setRequirement(
            new Requirement({
                ...Object.fromEntries(
                    Object.keys(requirementOptions.actions.POST).map(key => [key, requirement[key]])
                ),
                [name]: value
            })
        )
        !unsavedChanges && setUnsavedChanges(true)
    }

    if (requirement === undefined || requirementOptions === undefined) return <Container>
        <LoadingBanner />
    </Container>

    async function saveChanges() {
        await requirement.save()
        if (requirement.__status !== 200) {
            MessageCenter.addMessage({
                "title": "Nepodařilo se uložit",
                "message": "Zkuste to znovu, případně kontaktujte podporu"
            })
        } else {
            setUnsavedChanges(false)
        }
    }

    return <Container>
        <Jumbotron className="py-3 mt-1 mb-1">
            <h2>Požadavek #{requirement.id}</h2>
            <div>
                <span label="Kategorii je možné změnit v hlavním přehledu" className="text-muted">{requirement.categoryname}</span> / {requirement.name}
            </div>
        </Jumbotron>
        <Alert variant="info">
            Kategorii je možné změnit v přehledu požadavků
        </Alert>
        <Form.Group controlId="name">
            <div className="align-items-center d-flex">
                <Form.Label className="mt-2">Název</Form.Label>
                <Button onClick={saveChanges} className="ml-auto mb-1" disabled={!unsavedChanges} variant="success" size="sm"><Save2 /> Uložit změny</Button>
            </div>
            <Form.Control value={requirement.name} onChange={e => updateRequirement("name", e.target.value)} />
        </Form.Group>
        <Form.Group controlId="description">
            <Form.Label>Popis</Form.Label>
            <Form.Control value={requirement.description} onChange={e => updateRequirement("description", e.target.value)} />
        </Form.Group>
        <Form.Group controlId="urgent">
            <Form.Check label="Důležité" checked={requirement.urgent} onChange={e => updateRequirement("urgent", e.target.checked)} />
        </Form.Group>
    </Container>
}