import React from "react";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar as BigCalendar, momentLocalizer  } from 'react-big-calendar'
import {BoxArrowInUpRight, BoxArrowDownRight} from 'react-bootstrap-icons'
import moment from 'moment'
import Location from "../Classes/Location";
import Account from "../Classes/Account";
import Queryset from "../Classes/Queryset";
import { Modal, Button } from "react-bootstrap";
import LocationComponent from "./LocationComponent";

const localizer = momentLocalizer(moment) // or globalizeLocalizer
const queryset = new Queryset(Location)


class Event extends React.Component {
    render() {
        return <span>
            {this.props.event.resource.type === "LOAD" && <BoxArrowInUpRight />}
            {this.props.event.resource.type === "UNLOAD" && <BoxArrowDownRight />}
            <> {this.props.event.title}</>
        </span>
    }
}


class Calendar extends React.Component {

    state = {
        events: [],
        event: null,
        show: false
    }

    async componentDidMount() {
        this.updateEvents({start: moment().startOf("month").subtract(6, "days"), end: moment().endOf("month").add(6, "days")})
    }

    async updateEvents(range) {
        const before = moment(range.end).add(1, "day").format('YYYY-MM-DD')
        const after = moment(range.start).subtract(1, "day").format('YYYY-MM-DD')
        const user = Account.current().id
        await queryset.filter({before, after, order__user: user})
        this.setState({
            events: queryset.objects.map(location => {return {
                title: location.prettyName(),
                start: moment(location.time),
                end: moment(location.time),
                allDay: true,
                resource: location
            }})
        })
    }


    eventSelected = event => {
        this.setState({event})
    }

    render() { return <>
            <BigCalendar
                localizer={localizer}
                events={this.state.events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
                views={['month', 'week', 'agenda', 'day']}
                onRangeChange={range => {this.updateEvents(range)}}
                onSelectEvent={event => {this.eventSelected(event)}}
                components={{
                    event: Event
                }}
                />
            <Modal size="lg" show={!!this.state.event} onHide={() => {this.setState({event: null})}}>
                <Modal.Header closeButton>
                <Modal.Title>{this.state.event && this.state.event.resource.prettyName()}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.event && <LocationComponent location={this.state.event.resource} hide_controls={true}/>}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="success" href={`/order/${this.state.event && this.state.event.resource.order}`}>
                    Přejít na objednávku
                </Button>
                <Button variant="secondary" onClick={() => {this.setState({event: null})}}>
                    Zavřít
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    }
}

export default Calendar