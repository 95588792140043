import { withRouter } from "react-router-dom";
import ModelView from './ModelView';
import Place from '../Classes/Place'
import React from 'react'

class PlaceView extends ModelView {
    jumbotronTitle = "Umístění"
    jumbotronSubtitle = "Umístění pro zastávky v objednávkách"
    modalNewPlaceholder = "Nový cíl"
    modalNewLabel = "Nový cíl"
    modalNewTitle = "Nový cíl"

    help = <div>
        <p>
            Objednávky mají zastávky, zastávky mohou vést na stejné adresy/místa.
            Pokud se třeba firma přestěhuje, stačí změnit "místo" a všechny objednávky budou toto nové místo obsahovat.
        </p>
        <p>
            V detailní náhledu také uvidíte, jak často jste na dané místo jezdili v poslední době.
        </p>
    </div>
    helpTopic = "places"

    constructor(props) {
        super(Place, props)
    }

    columnsGenerator(item, options) {
        return <td><small className="text-secondary">#{item.id}</small> {item.label()}</td>
    }
}

export default withRouter(PlaceView);