import React from 'react'
import {Modal, Button, Form, Alert} from 'react-bootstrap';

import settings from '../Settings'
import fetchProxy from '../Helpers/fetchProxy'
import LoadingBanner from './LoadingBanner';
import { getOptions } from "../Classes/Base"
import Queryset from '../Classes/Queryset';



const initialState = {
    show: false,
    ico: "",
    loading: false,
    request: false,
    errors: "",
    data: {},
    options: undefined,
    dataReady: false,
    duplicateSearch: undefined
}

class IcoAutocompleteModal extends React.Component {
    constructor(props) {
        super(props)
       
        if (props.closeCallback !== undefined) {
            this.closeCallback = props.closeCallback
        }
        if (!this.props.klass) {
            throw new Error("This.props.klass missing")
        }
        this.state = {
            ...initialState
        }
    }
    async componentDidMount() {
             this.setState({loading:true})
             const fetched_options = await getOptions(this.props.klass);
             this.setState({
                options : fetched_options.actions.POST,
                loading:false,
            });        
    }

    onIcoChange = ico => {
        this.setState({
            ico,
            request: null,
            duplicateSearch: undefined
        })
    }

    setShow = (show) => {
        this.setState({show});
    }

    closeCallback = () => {
        // Default callback can be implemented here. 
    }

    handleShow = () => this.setShow(true)
    handleClose = () => {
            this.setState({
                ...initialState,
                options:this.state.options,
                show:false,
            })
    }
    
    saveData = async () => {
        this.closeCallback({
            data: this.state.data
        })
        this.handleClose()
}

    validate = (ico) => {
        
        if (ico.length === 8) { 
            /* See https://cs.wikipedia.org/wiki/Identifika%C4%8Dn%C3%AD_%C4%8D%C3%ADslo_osoby */
            let nsum = 0
            let chsum = ico[7]
            for(let n=0;n<7;n++) {
                nsum = nsum + ico[n]*(8-n)
            }
            let x = (11 - nsum%11)%10
            if (x === +chsum) {
                return true
            }
        }

    return false
    }

    async search_for_duplicates() {
        const {ico, name} = this.state.data
        const queryset_ico = new Queryset(this.props.klass)
        const queryset_name = new Queryset(this.props.klass)
        const async_one = queryset_ico.filter({ico})
        const async_two = queryset_name.filter({name})
        await Promise.all([async_one, async_two])
        this.setState({
            duplicateSearch: queryset_ico.objects[0] || queryset_name.objects[0] || null
        })
    }

    async ico_autocomplete(icopacket) {
        const res = await fetchProxy(settings.BASE_API_URL + "service/ico/ico_autocomplete", /*TODO: this is ugly*/
            {
                method: 'POST',
                mode: 'cors',
                redirect: 'follow',
                body: JSON.stringify({ico: icopacket}),
                credentials: 'include'
            })
        if (res.status !== 200) {
            console.error(`Error sending the message! HTTP RES: ${res.status}\n`, await res.json())
            this.state.data = ""
            this.setState({
                request: false,
                oading: false,
                errors:"Nepodařilo se získat validní odpověď od serverů ARES"
            })
            return false
        }
        else if (res.status === 200) {
            this.setState({
                loading: false,
                request: true,
                data: await res.json()
            })
            this.search_for_duplicates()
            return true
        }
    }

    showData = (data) =>{
        let key = Object.keys(data)
        let value = Object.values(data)
        let table = [[],[]]
        for (let i = 0; i < key.length; i++) {
            let label = this.state.options[key[i]].label
            table[0].push(label)
            table[1].push(value[i])

        }
        return table[1].map((item, num) => {return <li key={num}><b>{table[0][num]}</b>: {item}</li>;} )
        
    }

    confirmClick= () =>{
        if (this.state.request) {
            this.saveData()
        }
        else if (!this.state.request) {
            this.apiCall()
        }
    }

    onSubmit= (event) =>{
        event.preventDefault()
        if (!this.state.loading) {this.apiCall()}
    }

    apiCall = () => {
        this.setState({
            errors:"",
            ico: this.state.ico.replace(/\s/g, ""), //Remove whitespaces which mess up validation
        })

        if (isNaN(this.state.ico) || !this.state.ico) {
            this.setState({errors:"Vyplňte IČO!"})
            return;
        }
        /*validate*/
        if (!this.validate(this.state.ico)) {
            this.setState({errors:"Neplatné IČO!"})
            return;
        }
        else{
            this.setState({
                primaryText:"Uložit změny",
                loading: true,
                duplicateSearch: undefined
            })
            this.ico_autocomplete(this.state.ico)
        }
    }

    render() {
        return (
        <>
            <Button variant={this.props.variant || "success"} className={this.props.className} onClick={this.handleShow}>{this.props.label || "Doplňte pomocí IČO"}</Button>
            <Modal show={this.state.show} onHide={this.handleClose}>    
            <Modal.Header closeButton>
                <Modal.Title>Doplnit pomocí IČO</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form onSubmit={(event) => (this.onSubmit(event))}> 
                <Form.Group controlId="ico">
                        <Form.Label>IČO</Form.Label>
                        <Form.Control type="text" placeholder="IČO" value={this.state.ico} onChange={(event) => {this.onIcoChange(event.target.value)}}/>
                </Form.Group>
            </Form> 
            <div style={{color:"red"}} hidden={(!this.state.errors)}>
            {this.state.errors}   
            </div>
            <div hidden={(!this.state.loading)}>
                <LoadingBanner />
            </div>
            {this.state.duplicateSearch && <Alert variant='danger'>
                    Záznam pro IČO {this.state.ico} již existuje - {this.state.duplicateSearch.name} (#{this.state.duplicateSearch.id})
            </Alert>}
            <div hidden={!this.state.request}>
                <ul style={{listStyleType: "none"}}>
                    {this.showData(this.state.data)}
                </ul>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                Zavřít
                </Button>
                <Button variant="primary" disabled={this.state.loading || (this.state.request && this.state.duplicateSearch)} onClick={this.confirmClick}>
                {this.state.request ? "Uložit" : "Načíst"}
                </Button>
            </Modal.Footer>
            </Modal>
        </>)
    }
  }
  
  export default IcoAutocompleteModal;